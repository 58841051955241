import React from "react";
import { Button, Avatar } from "@mui/material";
import { UploadFile  } from '@mui/icons-material';

const UploadButton = ({ onClick=()=>{}, uploadText="Upload Report" }) => {
  return (
    <Button
      variant="outlined"
      sx={{
        color: "#000",
        borderColor: "#d3d3d3",
        fontSize: "14px",
        borderRadius: "0 !important",
      }}
      onClick={onClick}
    >
      <UploadFile sx={{fill:'#9c9c9c'}} fontSize="small"/>
      {uploadText}
    </Button>
  );
};

export default UploadButton;
