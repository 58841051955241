import { useState } from 'react'
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"

import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import SaveIcon from '@mui/icons-material/Save'

import WhiteTextField from 'components/form-utilities/white-text-field'

const AuthForm = () => {

	const dispatch = useDispatch()
	const history = useHistory()
	const { http } = global.services
	const [ state, setState ] = useState({
		email: '',
		password: '',
		submitting: false,
		error: null
	})

	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);

	const handleSubmission = async (event = null) => {
		if (event)
			event.preventDefault()

		if(state.email == ""){
			setEmailError(true)
		}
		if(state.password == ""){
			setPasswordError(true)
		}
		if(state.email && state.password){
			setState(prevState => ({ ...prevState, submitting: true, error: null }))
			try {
				const response = await http.post('/user/management/login', {
					email: state.email.trim(),
					password: state.password.trim()
				})
				const responseData = response.data.data
				history.push({
					pathname: '/admin/2FA',
					state: {
						key:responseData.key
					}
				})
			} catch (error) {
				const response = error.response;
				console.log(response);
				const defaultErrorMsg = 'An unknown error occured. Please refresh the page and try again.'
				const stateToReplace = state
				if (error?.response) {
					stateToReplace.submitting = false
					stateToReplace.error = { title: 'Unknown Error', message: defaultErrorMsg }

					if (error.response.status === 403 || error.response.status === 400)
						stateToReplace.error = { title: 'Invalid Credentials', message: response.data.message }
				}

				setState(stateToReplace)
			}
		}
	}

	return (
		<form onSubmit={ (event) => handleSubmission(event) }>

			{
				state.error && (
					<div className="mb-10">
						<Alert severity="error">
							<AlertTitle>{ state.error?.title }</AlertTitle>
							{ state.error?.message }
						</Alert>
					</div>
				)
			}

			<div className="input-group mb-5">
				<WhiteTextField
					error={emailError}
					id="email-outlined-basic"
					className="w-full"
					label="Email"
					variant="outlined"
					onChange={ (event) => setState(prev => ({ ...prev, email: event.target.value })) }
					autoComplete="off"
					required
				/>
			</div>

			<div className="input-group mb-5">
				<WhiteTextField
					type="password"
					id="password-outlined-basic"
					className="w-full"
					label="Password"
					variant="outlined"
					onChange={ (event) => setState(prev => ({ ...prev, password: event.target.value })) }
					autoComplete="off"
					error={passwordError}
					required
				/>
			</div>

			<div className="inline-block flex flex-row space-x-5">
				<LoadingButton
					variant="contained"
					//color={'red-0'}
					onClick={() => handleSubmission() }
					size={'large'}
					loadingPosition="start"
					startIcon={<></>}
					loading={ state.submitting }
				>
					<span className={ state.submitting ? 'pl-5' : '' }>
						{ state.submitting ? 'Authenticating' : 'Authenticate' }
					</span>
				</LoadingButton>

				<Button
					variant="text"
					//color={'white'}
					size={'large'}
				>
					Reset Password
				</Button>
			</div>
		</form>
	)

}

export default AuthForm