import React from "react";
import { useHistory } from "react-router";
import { Breadcrumbs, Box, Typography, Link, Button } from "@mui/material";
import BackIcon from 'assets/images/icons/Vector.png';
// import { Link } from "react-router-dom"
import Download from "./download";
import UploadFile from "./upload-file";
import DeleteFile from "./delete-file";

import { useSelector, useDispatch } from "react-redux";
import useReportCheck from '../components/useReportCheck';
import { addToCart } from 'redux/modules/cart/actions';
import AppAction from 'redux/modules/app/actions';
import { currentCurrency } from "helpers/checkCurrency";
const ReportsHeader = ({accessGroup = null, pageName, workforce, benchmark, ...props}) => {
	const dispatch = useDispatch()
	const { user, asAdmin } = useSelector(({user}) => user);
	const { onCart, isPurchased, targetItem, price, accessList } = useReportCheck(accessGroup);

	const { selectedProgramId } = useSelector(({ app }) => app);
	const organizationProgram = user.userData?.organizationProgram || [];
	const { items } = useSelector(({cart}) => cart);
	const isFDD = ['WFR_Access', 'EV_Access', 'WBC_Access', 'BBP_Access'].find(i => i === accessGroup);

	const canBuy = React.useMemo(() => {
	  const programSelected = organizationProgram?.find(i => i?.programId?._id == selectedProgramId) || organizationProgram[0]
		if(!programSelected) return false;
	  const hasPaidFDD = programSelected?.FDD_Payment_Details !== null;
	  const hasReport = ['WFR_Access', 'EV_Access', 'WBC_Access', 'BBP_Access'].find(i => (programSelected[i] || '').trim().toLowerCase() === 'yes');
	  const onCart = items.find(i => i.forPayment === 'FDD');
	  return hasPaidFDD || hasReport || onCart;
	}, [items, selectedProgramId])

	const verbatimResults = props?.verbatimResults
	const download = props?.download

	const history = useHistory();

	const renderParent = () => {
		if(workforce)
			return "Workforce Feedback Results: "

		if(benchmark)
			return "Workforce Benchmark Comparisons: "

		if(verbatimResults)
		return "Employee Verbatims: "

		return ""
	}

	const goBack = () => {
		history.goBack();
	}

	const shopCondition = (!isPurchased && !asAdmin && targetItem?.demo);

	const handleAddToCart = () => {
		const { id, title, amount, access, forPayment } = targetItem;
		if(!canBuy) {
			dispatch( AppAction.setAlert(`You need to include Feedback Data Dashboard Package to buy this report!`) );
			return;
		}
		let setTitle = null, subTitle=""; 
		if(forPayment == 'FDD') {
			setTitle = "Employee Feedback Data Dashboard";
			subTitle = "Workforce Feedback Results, Employee Verbatims, Workforce Benchmark Comparisons, Benefits & Best Practices";
		}
		const item = { 
			id, 
			title: setTitle || title, 
			amount, forPayment,
			subTitle,
			keys: {
				[forPayment + '_Payment_Details']: new Date(),
				[forPayment + '_Payment_Type']: null,
				// [access]: "Yes",
				// ...accessList
			}
		};
		dispatch(addToCart(item, price))
	}
	return (
		<React.Fragment>
			<div className="employee_response_breakdown_section">
				<Breadcrumbs separator="|" aria-label="breadcrumb">
					<Typography className='cursor-pointer' onClick={ () => { history.push('/dashboard')} }>My Reports</Typography>
					<Typography color="text.primary">{renderParent()}{pageName}</Typography>
				</Breadcrumbs>
			</div>
			{
				shopCondition ? <div className="flex flex-col relative">
					<Box className={'my-2 flex items-center justify-between'}>
						<Typography variant="h5" sx={{fontWeight:'bold'}}>{pageName} <span className="font-normal">(Free Demo)</span></Typography>
						{
							(!isPurchased && price > 0 && !isFDD) &&
							<div className="flex items-center">
								<Typography variant="h6">Price: <span className="text-red-10 mr-3">{currentCurrency()} {price || 0}</span></Typography>
								{/* <Typography variant="h6">Price: <span className="text-red-10 mr-3">$ {price || 0}</span></Typography> */}
								<Button
									variant="contained"
									type="submit"
									color="error"
									className="normal-case rounded-none px-5"
									onClick={handleAddToCart}
									disabled={onCart}
								>
								{ !onCart ? 'Add to Cart' : 'Added to Cart' }
								</Button>
							</div>
						}
					</Box>
					<div className="absolute top-14 right-0">
						<Download {...download} downloadConfigs={props.downloadConfigs} />
					</div>
				</div> :
				<Box className={'mt-2 flex items-center justify-between'}>
					<Typography variant="h5" sx={{ mt:2, mb:2, fontWeight:'bold'}}>{pageName}</Typography>
					<div>
					{(props.onClickDelete && asAdmin) && <DeleteFile onClick={props.onClickDelete} deleteText={props.deleteText} />}
					{(props.onClickUpload && asAdmin) && <UploadFile onClick={props.onClickUpload} uploadText={props.uploadText} />}
					{!download?.types.includes('none') && <Download data={download?.data} filters={download?.filters} types={download?.types} downloadConfigs={props.downloadConfigs} />}
					</div>
				</Box>
			}
			{
				(history.length > 1) &&
				<Link className="flex items-center text-xs cursor-pointer" onClick={goBack}>
					<img src={BackIcon} className="w-2 h-2 mr-1"/> Back 
				</Link>
			}

		</React.Fragment>
	)

}

export default ReportsHeader
