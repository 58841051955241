import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import AppAction from 'redux/modules/app/actions';
import EmployeeResponseSummary from "./components/summary";
import DemographicCards from "./components/cards";
import ReportsHeader from "../../components/reports-header";
import ReportStoreList from "../../reports-store/components/reports-store-list";
import ReportDescription from "../../components/report-description";
import downloadFile from "helpers/downloadFile";
import { API_ENDPOINT } from "config/api";
import SectionLoading from "components/loaders/SectionLoading";
import Skeleton from "@mui/material/Skeleton";

import useAccessible from 'components/hooks/useSideBarAccessible';
import PageDropdown from "../../components/page-dropdown";

const pages = [{
  "id": 'employee-response-breakdown',
  "path": "/employee-response-breakdown",
  "title": "Employee Response Breakdown"
},
{
  "id": 'detailed-results',
  "path": "/detailed-results",
  "title": "Detailed Results"
},
{
  "id": 'response-patterns',
  "path": "/response-patterns",
  "title": "Response Patterns"
},
{
  "id": 'annual-trends',
  "path": "/annual-trends",
  "title": "Annual Trends"
}];

const EmployeeResponseBreakdown = () => {
  const { http } = global.services;
  const dispatch = useDispatch();
  const isAvailable = useAccessible('WFR_Access');
  const [filters, setFilters] = useState([]);
  const [demographics, setDemograpics] = useState([]);
  const [sectionLoadingVisible, setSectionLoadingVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {user, admin} = useSelector(({user}) => user);
  const { selectedProgramId, timeEstimate, clearcache } = useSelector(({ app }) => app);
  const { organizationProgram } = user.userData ? user.userData : user.user;
  const programSelected = organizationProgram?.find(i => i?.programId?._id == selectedProgramId) || organizationProgram[0]
  const programId = selectedProgramId || programSelected?.programId?._id;

  useEffect(() => {
    loadDemographicCategories();
  }, [selectedProgramId]);

  const loadDemographicCategories = async () => {
    let params = {};
    setIsLoading(true);
    var { organizationProgram } = user.userData ? user.userData : user.user;

    if (organizationProgram) {
      params.selectedProgramId = programId;
    }
    
    if( (programSelected["WFR_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) params.isDummy = true;

    if(clearcache) params.clearCache = clearcache
    try {
      const { data } = await http.get(
        `/client/responseCountByDemographicCategory`,
        params
      );
      setDemograpics(data.data);
    } catch (err) {
      console.log(err);
      return;
    } finally {
      setIsLoading(false);
    }
  };

  const download = {
    data: "",
    filters: filters,
    types: ["JPEG", "PDF", "SVG", "XLS"],
  };

  const downloadFullReport = () => {
    setSectionLoadingVisible(true);
    var downloadable = false;
    setTimeout(()=> {
      if(!downloadable) {
        dispatch( AppAction.setAlert(`Compiling report. Estimated download time is ${timeEstimate} minute${timeEstimate > 1 ? "s" :''}`) )
      }
    }, [3000])

    const params = {
      selectedProgramId: programId,
    }
    if(clearcache) params.clearCache = clearcache;
    if( (programSelected["WFR_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) params.isDummy = true;
    const paramsString = new URLSearchParams(params).toString()

    downloadFile(
      `${API_ENDPOINT}/client/generateHeatMap?${paramsString}`,
      undefined,
      "post"
    ).then((response)=>{
      const { headers } = response;
      const contentType = headers.get('content-type');
      if(contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then(({data}) => {
          const { signedUrl } = data.data
          const { pathname } = new URL(signedUrl);
          const dlFile = document.createElement("a");
          dlFile.download = pathname.replace('/', '');
          dlFile.href = signedUrl;
          dlFile.target = "_blank";
          dlFile.click();
        })
      }
    })
      .catch((err) => {
        console.log(err);
        return;
      })
      .finally(() => {
        setSectionLoadingVisible(false)
        downloadable = false
      });
  };

  const downloadConfigs = [
    {
      action: downloadFullReport,
    }
  ];

  return (
    <React.Fragment>
      {
        /* SECTION LOADING */
        sectionLoadingVisible && <SectionLoading />
      }
      <ReportsHeader
        download={download}
        pageName="Employee Response Breakdown"
        accessGroup="WFR_Access"
        workforce
        downloadConfigs={downloadConfigs}
      />
      <ReportDescription description="Review the number of surveys completed within each demographic of your respondent population." />
      
      {
        !isAvailable && <div className="mt-4"><PageDropdown pages={pages} /></div>
      }

      {isLoading ? (
        <>
          <Skeleton
            animation="wave"
            height={85}
            style={{ marginBottom: "-1rem" }}
          />
          <Skeleton
            animation="wave"
            height={85}
            style={{ marginBottom: "-1rem" }}
          />
          <Skeleton
            animation="wave"
            height={85}
            style={{ marginBottom: "-1rem" }}
          />
        </>
      ) : (
        <DemographicCards data={demographics} isLoading={isLoading} />
      )}
    </React.Fragment>
  );
};

export default EmployeeResponseBreakdown;
