import DefaultNavbar from "./components/default-navbar"

const DefaultLayout = ({
	children = null
}) => {

	return (
		<div className="relative">
			<div className="relative z-10 ">
				<DefaultNavbar />
				<div id="default-layout-content-wrapper">
					<div id="default-layout-content-container">
						{ children }
					</div>
				</div>
			</div>
		</div>
	)

}

export default DefaultLayout