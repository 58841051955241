/** ----------------------------------------------------------------------- */
/** Global Pages */
/** ----------------------------------------------------------------------- */
import Home from "pages/global/home"
/** ----------------------------------------------------------------------- */



/** ----------------------------------------------------------------------- */
/** Guest Pages */
/** ----------------------------------------------------------------------- */
import Login from "pages/guest/login"
import ForgotPassword from "pages/guest/forgot-password"
import ForgotUsername from "pages/guest/forgot-username"
import OtpForm from "pages/guest/forgot-password/components/otp-form"
import PasswordReset from "pages/guest/forgot-password/components/password-reset"

/** ----------------------------------------------------------------------- */

/** Admin Login */
import AdminLogin from "pages/guest/admin-login"

/** ----------------------------------------------------------------------- */
/** Dashboard Pages */
/** ----------------------------------------------------------------------- */
import DashboardHome from "pages/authenticated/dashboard/client/home"
import ReportStorePage from "pages/authenticated/dashboard/client/reports-store"
// import BasicReports from "pages/authenticated/dashboard/client/reports-store/basic-reports"

import EmployeeVerbatims from "pages/authenticated/dashboard/client/employee-verbatims"
import EmployeeVerbatimsDetails from "pages/authenticated/dashboard/client/employee-verbatims/pages/details"
import BenchmarkData from "pages/authenticated/dashboard/client/workforce-benchmark-comparison"
import ComparisonData from "pages/authenticated/dashboard/client/workforce-benchmark-comparison/comparison"

import MeanScoringReport from "pages/authenticated/dashboard/client/mean-scoring-report"
import BenefitsBestPractices from "pages/authenticated/dashboard/client/benefits-best-practices"
import KeyImpactAnalysis from "pages/authenticated/dashboard/client/key-impact-analysis"
import ResponseDetail from "pages/authenticated/dashboard/client/response-detail"
import Settings from "pages/authenticated/dashboard/client/client-settings/settings"
import Help from "pages/authenticated/dashboard/client/client-settings/help"
// import ContactUs from "pages/authenticated/dashboard/client/client-settings/contact-us"

/** Workforce Feedback Results  */
// import WorkforceFeedbackResults from "pages/authenticated/dashboard/client/workforce-feedback-results/index.js"
import EmployeeResponseBreakdown from "pages/authenticated/dashboard/client/workforce-feedback-results/employee-response-breakdown"
import DetailedResults from "pages/authenticated/dashboard/client/workforce-feedback-results/detailed-results"
import ResponsePatterns from "pages/authenticated/dashboard/client/workforce-feedback-results/response-patterns"
import AnnualTrends from "pages/authenticated/dashboard/client/workforce-feedback-results/annual-trends"

import ProjectsPage from "pages/authenticated/dashboard/admin/projects"
import ProjectDetailPage from "pages/authenticated/dashboard/admin/projects/pages/details"
import ProgramDetails from "pages/authenticated/dashboard/admin/projects/pages/programs"
// import ProgramClientsPage from "pages/authenticated/dashboard/admin/projects/pages/clients"

// import ProgramsPage from "pages/authenticated/dashboard/admin/programs"
import ClientsPage from "pages/authenticated/dashboard/admin/clients"
import ClientDetails from "pages/authenticated/dashboard/admin/clients/pages/details"
import UsersPage from "pages/authenticated/dashboard/admin/users"
import UserDetails from "pages/authenticated/dashboard/admin/users/pages/details"
import OrderLogPage from "pages/authenticated/dashboard/admin/order-log"
import DataPage from "pages/authenticated/dashboard/admin/data"
import DataEditListPage from "pages/authenticated/dashboard/admin/data/edit-list"
import EmailPage from "pages/authenticated/dashboard/admin/email"
import RolePermissionsPage from "pages/authenticated/dashboard/admin/role-permissions"
import RolePermissionsUsersPage from "pages/authenticated/dashboard/admin/role-permissions/pages/users/index.js"
import SystemLogPage from "pages/authenticated/dashboard/admin/system-log"

// import CustomReporting from "pages/authenticated/dashboard/client/custom-reporting"
import MyCustomReports from "pages/authenticated/dashboard/client/custom-reporting/my-reports"
/** ----------------------------------------------------------------------- */


import Cart from "pages/authenticated/dashboard/client/reports-store/pages/cart";
import Checkout from "pages/authenticated/dashboard/client/reports-store/pages/checkout";
/** ----------------------------------------------------------------------- */
/** Error Pages */
import ResourceNotFound from "pages/error-pages/resource-not-found"
import NotAuthorized from "pages/error-pages/not-authorized"
import ReportsStore from "pages/authenticated/dashboard/client/reports-store"
import SettingPage from "pages/authenticated/dashboard/admin/settings"
/** ----------------------------------------------------------------------- */


const routes = [
 {
  path: "/",
  component: () => <Home />,
  title: "Homepage",
  layout: "default",
  access: {
   global: true,
  },
 },
 {
  path: ["/login", "/admin-login"],
  component: () => <Login />,
  title: "Login",
  layout: "two-column",
  access: {
   guest: true,
  },
 },
 {
  path: "/admin/2FA",
  component: () => <AdminLogin section="otp-form" />,
  title: "Login",
  layout: "two-column",
  access: {
   guest: true,
  },
 },
 {
  path: "/forgot-password",
  component: () => <ForgotPassword />,
  title: "Forgot Password",
  layout: "single-column",
  access: {
   guest: true,
  },
 },
 {
  path: "/forgot-username",
  component: () => <ForgotUsername />,
  title: "Forgot Password",
  layout: "single-column",
  access: {
   guest: true,
  },
 },
 {
  path: "/verify-otp",
  component: () => <OtpForm />,
  title: "Verify Otp",
  layout: "single-column",
  access: {
   guest: true,
  },
 },

 {
  path: "/reset-password",
  component: () => <PasswordReset />,
  title: "Password Reset",
  layout: "single-column",
  access: {
   guest: true,
  },
 },

 {
  path: "/404",
  component: () => <ResourceNotFound />,
  title: "404 Page",
  layout: "default",
  access: {
   global: true,
  },
 },
 {
  path: "/not-authorized",
  component: () => <NotAuthorized />,
  title: "Unauthorized",
  layout: "default",
  access: {
   global: true,
  },
 },

 {
  path: "/dashboard",
  component: () => <DashboardHome />,
  title: "Dashboard",
  layout: "dashboard",
  access: {
   client: true,
  },
 },

 // {
 //  path: "/reports-sample",
 //  component: () => <BasicReports />,
 //  layout: "dashboard",
 //  access: {
 //   client: true,
 //  },
 // },

 {
  path: "/reports-store",
  component: () => <ReportStorePage />,
  layout: "dashboard",
  access: {
   client: true,
  },
 },

 {
  path: "/cart",
  component: () => <Cart />,
  layout: "dashboard",
  access: {
   client: true,
  },
 },

 {
  path: "/checkout",
  component: () => <Checkout />,
  layout: "dashboard",
  access: {
   client: true,
  },
 },

 {
  path: "/custom-reporting",
  component: () => <MyCustomReports />,
  layout: "dashboard",
  access: {
   client: true,
  },
 },
 {
  path: "/my-reports",
  component: () => <MyCustomReports />,
  layout: "dashboard",
  access: {
   client: true,
  },
 },
 // {
 //  path: "/workforce-feedback-results",
 //  component: () => <WorkforceFeedbackResults />,
 //  layout: "dashboard",
 //  access: {
 //   client: true,
 //  },
 // },
 {
  path: "/employee-response-breakdown",
  component: () => <EmployeeResponseBreakdown />,
  layout: "dashboard",
  access: {
   client: true,
  },
 },

 {
  path: "/detailed-results",
  component: () => <DetailedResults />,
  layout: "dashboard",
  access: {
   client: true,
  },
 },

 {
  path: "/response-patterns",
  component: () => <ResponsePatterns />,
  layout: "dashboard",
  access: {
   client: true,
  },
 },

 {
  path: "/annual-trends",
  component: () => <AnnualTrends />,
  layout: "dashboard",
  access: {
   client: true,
  },
 },

 {
  path: "/employee-verbatims",
  component: () => <EmployeeVerbatims />,
  layout: "dashboard",
  access: {
   client: true,
  },
 },

 {
  path: "/employee-verbatims/:slug",
  component: () => <EmployeeVerbatimsDetails />,
  layout: "dashboard",
  access: {
   client: true,
  },
 },

 {
  path: "/workforce-benchmark-comparisons",
  component: () => <BenchmarkData />,
  layout: "dashboard",
  access: {
   client: true,
  },
 },

 {
  path: "/comparison-data",
  component: () => <ComparisonData />,
  layout: "dashboard",
  access: {
   client: true,
  },
 },

 {
  path: "/benefits-and-best-practices",
  component: () => <BenefitsBestPractices />,
  layout: "dashboard",
  access: {
   client: true,
  },
 },

 {
  path: "/key-impact-analysis",
  component: () => <KeyImpactAnalysis />,
  layout: "dashboard",
  access: {
   client: true,
  },
 },
 {
  path: "/response-detail",
  component: () => <ResponseDetail />,
  layout: "dashboard",
  access: {
   client: true,
  },
 },
 {
  path: "/mean-scoring",
  component: () => <MeanScoringReport />,
  layout: "dashboard",
  access: {
   client: true,
  },
 },

 {
  path: "/settings",
  component: () => <Settings />,
  layout: "dashboard",
  access: {
   client: true,
  },
 },

 {
    path: "/admin/settings",
    component: () => <SettingPage />,
    layout: "dashboard",
    access: {
     admin: true,
    },
},

 {
  path: "/help",
  component: () => <Help />,
  layout: "dashboard",
  access: {
   client: true,
  },
 },

 // {
 //  path: "/contact-us",
 //  component: () => <ContactUs />,
 //  layout: "dashboard",
 //  access: {
 //   client: true,
 //  },
 // },

 {
  path: "/reports/store",
  component: () => <ReportsStore />,
  layout: "dashboard",
  access: {
   client: true,
  },
 },

 {
  path: "/admin/projects",
  component: () => <ProjectsPage />,
  layout: "dashboard",
  access: {
   admin: true,
  },
 },

 {
  path: "/admin/projects/:page",
  component: () => <ProjectDetailPage />,
  layout: "dashboard",
  access: {
   admin: true,
  },
 },

 // {
 //  path: "/admin/projects/:page/programs/:page/client/:page",
 //  component: () => <ProgramClientsPage />,
 //  layout: "dashboard",
 //  access: {
 //   admin: true,
 //  },
 // },

 {
  path: "/admin/projects/:page/programs/:programId",
  component: () => <ProgramDetails />,
  layout: "dashboard",
  access: {
   admin: true,
  },
 },
 {
  path: "/admin/clients",
  component: () => <ClientsPage />,
  layout: "dashboard",
  access: {
   admin: true,
  },
 },
 {
  path: "/admin/clients/:page",
  component: () => <ClientDetails />,
  layout: "dashboard",
  access: {
   admin: true,
  },
 },

 {
  path: "/admin/users",
  component: () => <UsersPage />,
  layout: "dashboard",
  access: {
   admin: true,
  },
 },
 {
  path: "/admin/users/:page",
  component: () => <UserDetails />,
  layout: "dashboard",
  access: {
   admin: true,
  },
 },

 {
  path: "/admin/order-log",
  component: () => <OrderLogPage />,
  layout: "dashboard",
  access: {
   admin: true,
  },
 },

 {
    path: "/admin/system-log",
    component: () => <SystemLogPage />,
    layout: "dashboard",
    access: {
     admin: true,
    },
   },

 {
  path: "/admin/settings",
  component: () => <DataPage />,
  layout: "dashboard",
  access: {
   admin: true,
  },
 },

 {
  path: "/admin/data/edit",
  component: () => <DataEditListPage />,
  layout: "dashboard",
  access: {
   admin: true,
  },
 },

 {
  path: "/admin/email",
  component: () => <EmailPage />,
  layout: "dashboard",
  access: {
   admin: true,
  },
 },

 {
  path: "/admin/role-permissions",
  component: () => <RolePermissionsPage />,
  layout: "dashboard",
  access: {
   admin: true,
  },
 },
 {
  path: "/admin/role-permissions/:role/users",
  component: () => <RolePermissionsUsersPage />,
  layout: "dashboard",
  access: {
   admin: true,
  },
 },
];

export default routes
