export const Permissions = [
    {
        id: 1,
        shortcode:"clientsProjectsProgramsAccess",
        name: "Access Shared Projects, Programs & Clients"
    },
    {
        id: 2,
        shortcode:"syncCheckmartketAndZohoAccess",
        name: "Manually Re-Sync Checkmarket & Zoho Data"
    },
    {
        id: 3,
        shortcode:"previewClientsDashboardAccess",
        name: "Preview Clients' Dashboards"
    },
    {
        id: 4,
        shortcode:"exportReportsAccess",
        name: "Export Reports"
    },
    {
        id: 5,
        shortcode:"uploadDownloadCustomReportAccess",
        name: "Upload & Download Custom Reports"
    },
    {
        id: 6,
        shortcode:"uploadKeyImpactAnalysisAccess",
        name: "Upload Key Impact Analysis"
    },
    {
        id: 7,
        shortcode:"orderLogAccess",
        name: "Access Order Logs"
    }

    //Projects
    // {
    //     id: 1,
    //     name: 'View Projects',
    //     shortcode: "view_projects"
    // },
    // {
    //     id: 2,
    //     name: 'Create Project',
    //     shortcode: "create_project"
    // },
    // {
    //     id: 3,
    //     name: 'Update Project',
    //     shortcode: "update_project"
    // },
    // //Programs
    // {
    //     id: 4,
    //     name: 'View Programs',
    //     shortcode: "view_programs"
    // },
    // {
    //     id: 5,
    //     name: 'Create Program',
    //     shortcode: "create_program"
    // },
    // {
    //     id: 6,
    //     name: 'Update Program',
    //     shortcode: "update_program"
    // },
    // //Organizations
    // {
    //     id: 7,
    //     name: 'View Participating Organizations',
    //     shortcode: "view_orgs"
    // },
    // {
    //     id: 8,
    //     name: 'Manage Report Access for Organisations',
    //     shortcode: "manage_report_orgs"
    // },

    // {
    //     id: 9,
    //     name: "Preview Client Dashboard",
    //     shortcode: "preview_client_dashboard"
    // },
    // {
    //     id: 10,
    //     name: "Pull Data from Checkmarket",
    //     shortcode: "get_checkmarket"
    // },
    // {
    //     id: 11,
    //     name: "Manually Resync Data with Checkmarket",
    //     shortcode: "resync_checkmarket"
    // },
    // {
    //     id: 12,
    //     name: "Export Reports",
    //     shortcode: "export_reports"
    // },
    // {
    //     id: 13,
    //     name: "Export Reports",
    //     shortcode: "export_reports"
    // },
    // {
    //     id: 14,
    //     name: "Upload Custom Reports for Clients",
    //     shortcode: "upload_report_clients"
    // },
    // {
    //     id: 15,
    //     name: "Edit Best Companies List",
    //     shortcode: "update_best_companies"
    // },
    // {
    //     id: 16,
    //     name: "Send Email Notifications in bulk or to individual company",
    //     shortcode: "send_email_companies"
    // },
    // {
    //     id: 17,
    //     name: "Track sent emails",
    //     shortcode: "get_sent_emails_companies"
    // },
    // {
    //     id: 18,
    //     name: "Client communication via email and live chat",
    //     shortcode: "client_communication"
    // },
    // {
    //     id: 19,
    //     name: "View Users",
    //     shortcode: "view_users"
    // },
    // {
    //     id: 20,
    //     name: "Add User",
    //     shortcode: "create_user"
    // },
    // {
    //     id: 21,
    //     name: "Edit User Info",
    //     shortcode: "edit_user"
    // },
    // {
    //     id: 22,
    //     name: "Delete User",
    //     shortcode: "add_user"
    // },
    // {
    //     id: 23,
    //     name: "Create new User Role",
    //     shortcode: "create_role"
    // },
    // {
    //     id: 24,
    //     name: "Change existing User Role permissions",
    //     shortcode: "update_role_permissions"
    // },
    // {
    //     id: 25,
    //     name: "View Order Log",
    //     shortcode: "view_logs"
    // },
    // {
    //     id: 26,
    //     name: "Change product pricing",
    //     shortcode: "update_pricing"
    // },
    // {
    //     id: 27,
    //     name: "Upload Key Impact Analysis",
    //     shortcode: "keyImpact"
    // },
    // {
    //     id: 28,
    //     name: "Upload Key Driver Data",
    //     shortcode: "keyDriver"
    // }
];

export const randomPermissions = (size = 5) => {
    let perms = Permissions.slice(0), i = Permissions.length, min = i - size, temp, index;
    while(i-- > min) {
        index = Math.floor((i + 1) * Math.random());
        temp = perms[index];
        perms[index] = perms[i];
        perms[i] = temp;
    }
    return perms.slice(min);
}

export const mappedPermission = Permissions.map(i => ({
    ...i,
    label: i.name
}))
