import { useState, useEffect } from 'react'
import { Box, Button,LinearProgress, TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody, Modal, Typography, IconButton } from '@mui/material'
import ReportsHeader from '../components/reports-header'
import { Close } from '@mui/icons-material';
import { useSelector, useDispatch } from "react-redux";
import AppAction from 'redux/modules/app/actions';
import LinkButton from '@mui/material/Link'

import dayjs from 'dayjs'
import DLButton from './components/download-file';
import UploadService from "services/fileUploadService";
import LoadingButton from '@mui/lab/LoadingButton'
import WhiteTextField from 'components/form-utilities/white-text-field'

const MyCustomReports = () => {
	const { http } = global.services
	const dispatch = useDispatch();
	const [showModal, setShow] = useState(false);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [progressUploads, setProgress] = useState([]);
	const [selectedReport, setSelectedReport] = useState({})
	const [removeItem, setRemoveItem] = useState(null);
	const [removing, setRemoving] = useState(false);
	const [uploading, setUploading] = useState(false);
	const { user, asAdmin, admin } = useSelector(({user}) => user);
	const {selectedProgramId, clearcache} = useSelector(({ app }) => app);
	const { organizationProgram, projectId, organizationId } = user.userData ? user.userData : user.user;
	const programId = selectedProgramId || organizationProgram[0]?.programId?._id;
	const [data, setData] = useState([])
	const [errors, setErrors] = useState({
		reportTitle: null,
		reportDescription: null,
	});
	const [ vars, setVars ] = useState({
		reportTitle: "",
		reportDescription: "",
	});
	const openModal = () => setShow(!showModal);

	const handleClose = () => {
		setShow(false)
		setSelectedFiles([])
		setSelectedReport({})
		setProgress([])
	};

	const download = {
		data: null,
		filters: [],
		types: ['none']
	}

	useEffect(()=>{
		getCustomReport()
	}, [])

	const getCustomReport = async () => {
    const query = { selectedProgramId: programId };
    if(clearcache) {
      query['clearCache'] = clearcache
    }
		try {
			const { data } = await http.get( `/client/getCustomReport`, query );
			setData(data.data)
		} catch (e) {
			setData([])
		}
	}

	const selectFiles = ({target}) => {
    const files = Array.from(target.files);
   	setUploading(false)
		setSelectedFiles(files)
		setProgress(files.map(i => 0));
	}

  const handleForm = ({target}) => {
		setVars({
			...vars,
			[target.name]: target.value
		})
	}

	const addFileToReport = (item) => {
		setShow(true);
		setSelectedReport(item)
		setVars({
			reportTitle: item.ReportTitle || "",
			reportDescription: item.ReportDescription || ""
		})
	}

	const removeReport = async () => {
		setRemoving(true)
		try {
			const { data } = await http.delete(`/admin/customReport/${removeItem._id}`, null, {
				headers: {
					"Authorization": `Bearer ${admin.accessToken}`
				}
			});
      dispatch( AppAction.setAlert(`Custom report removed!`) )
			getCustomReport();
			setRemoveItem(null)
			setRemoving(false)
		} catch(e) {

		}
	}

	const upload = (file, idx) => {
		let addQuery = {}
		if(selectedReport._id) {
			addQuery.reportId = selectedReport._id 
		}

    return UploadService.upload(file, (event) => {
      const data = Math.round((100 * event.loaded) / event.total);
      setProgress( progressUploads.map((i,index) => index == idx ? data : i) )
    }, '/admin/uploadCustomReport', {
    	programId,
    	projectId:projectId._id,
    	orgProgramId: organizationProgram.find(i=>i.programId?._id == programId)?._id, //'625e801045867e3046c3daa3',//
    	organizationId: organizationId._id, //'625e800c45867e3046c3da9b' //
    	...vars,
    	...addQuery
    }, 'customReports')
    .catch(() => {});
  };

	const uploadFiles = (e) => {
		e.preventDefault();
		const uploadPromises = selectedFiles.map(upload);
		setUploading(true)

    Promise.all(uploadPromises)
    	.then(({data})=>{
    		getCustomReport()
    		setSelectedFiles([])
    		setShow(false)
    	})
	}
	let adminControl = {}
	
	if(
			admin &&
			(
				admin?.user?.roleId?.role === 'admin' ||  
				admin?.user?.roleId?.permissions?.find(i => i === 'uploadDownloadCustomReportAccess')
			)
		)
		adminControl = { onClickUpload: openModal }

	return (
		<div id="custom-reporting-component">
			<ReportsHeader pageName='Custom Reports' download={download} {...adminControl} />
			<p className="text-sm text-gray-30 mt-4">In addition to the standard reporting package offered, more in depth reporting is available. <br/> For more information about advanced reporting, please contact <LinkButton href="mailto:SurveyPro@workforcerg.com">SurveyPro@workforcerg.com</LinkButton></p>
			<Box className="mt-5">
				<TableContainer component={Paper} sx={{ borderRadius: '0'}}>
					<Table>
					  <TableHead sx={{background:'rgba(22, 38, 48, 0.25)'}}>
						  <TableRow>
						    <TableCell className='font-bold'>Report Name</TableCell>
						    <TableCell>Description</TableCell>
						    <TableCell>Upload Date</TableCell>
						    {/*<TableCell>Status</TableCell>*/}
						    <TableCell className="flex justify-end">Action</TableCell>
						  </TableRow>
						</TableHead>
						<TableBody>
							{
								data.length > 0 ? data.map((i,k)=> <ReportRow item={i} key={k} addFileToReport={addFileToReport} asAdmin={asAdmin} removeReport={(item)=>setRemoveItem(item)} />)
								: <TableRow>
									<TableCell>{
										asAdmin ? 'No Requested Report' :
										<p className="text-gray-400"><span className='font-bold'>Note:</span> Your custom report(s) will be available for download here.
										</p>
									}</TableCell>
								</TableRow>
							}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>

	    <Modal
			  open={showModal}
			  onClose={handleClose}
			  aria-labelledby="resort-title"
			  aria-describedby="resort-description"
			>
				<form onSubmit={uploadFiles}>
			  <Box sx={{
				  position: 'absolute',
				  top: '50%',
				  left: '50%',
				  transform: 'translate(-50%, -50%)',
				  width: 490,
				  bgcolor: '#f5f5f5',
				  boxShadow: 24,
				  p: 4,
				}}>
				<Typography id="role-form"
					variant="h5"
					component="span"
					fontWeight="600"
					display="block"
					className="text-left my-6"
				>
					<IconButton
		        aria-label="close"
		        onClick={handleClose}
		        sx={{ position: 'absolute', right: 8, top: 8 }}
		      >
		        <Close />
		      	</IconButton>
					Upload Custom Reports
				</Typography>
				<Box className="mb-5">
					<WhiteTextField
						error={errors.reportTitle}
						className="w-full mb-6 white-field"
						variant="outlined"
						placeholder="Title"
						value={ vars.reportTitle }
						name="reportTitle"
						onChange={ handleForm }
						autoComplete="off"
						required
					/>
					<WhiteTextField
						error={errors.reportDescription}
						className="w-full mb-6 white-field"
						variant="outlined"
						placeholder="Description"
						value={ vars.reportDescription }
						name="reportDescription"
						onChange={ handleForm }
						autoComplete="off"
						required
					/>
				</Box>

				<Box className="mb-5">
	        <label className="btn btn-default p-0">
	        	<input type="file" multiple onChange={selectFiles} />
	        </label>
				</Box>
				<Box className="mb-5">
					{
						selectedFiles.map((i,index) => <div className="mb-2" key={index}>
		         <span>{i.fileName}</span>
		         {
		         	uploading &&
			         <div className="progress">
			          <LinearProgress
			           variant="determinate"
			           value={progressUploads[index] || 0}
			          />
			          {progressUploads[index] || 0}%
			         </div>
		         }
		        </div>)
					}
				</Box>

				<Box className="flex justify-between">
					<Button
						variant="text"
						onClick={handleClose}
						className="normal-case rounded-none w-1/3"
					>
						Close
					</Button>
			    <LoadingButton
			    	type="submit"
			      variant="contained"
			      disabled={!selectedFiles}
			      color="error"
			      className="normal-case rounded-none w-1/3"
			     >
			      Upload
			    </LoadingButton>
					</Box>
				</Box>
				</form>
			</Modal>

		{/* Remove Modal */}
		<Modal
			  open={removeItem != null}
			  onClose={() => setRemoveItem(null)}
			  aria-labelledby="resort-title"
			  aria-describedby="resort-description"
			>
			  <Box sx={{
				  position: 'absolute',
				  top: '50%',
				  left: '50%',
				  transform: 'translate(-50%, -50%)',
				  width: 490,
				  bgcolor: '#f5f5f5',
				  boxShadow: 24,
				  p: 4,
				}}>
				<Typography id="role-form"
					variant="h5"
					component="span"
					fontWeight="600"
					display="block"
					className="text-left my-6"
				>
					<IconButton
		        aria-label="close"
		        onClick={() => setRemoveItem(null)}
		        sx={{ position: 'absolute', right: 8, top: 8 }}
		      >
		        <Close />
		      	</IconButton>
					Are you sure to remove this report?
				</Typography>
				<Typography id="user-form-title"
					variant="p"
					display="block"
					className="text-center my-9"
				>
					{`Any attached files to ${removeItem?.ReportTitle} will be removed.`}
				</Typography>
				<Box className="flex justify-between">
					<Button
						variant="text"
						onClick={() => setRemoveItem(null)}
						className="normal-case rounded-none w-1/3"
					>
						Close
					</Button>
			    <LoadingButton
			    	type="button"
			      variant="contained"
			      color="error"
			      className="normal-case rounded-none w-1/3"
			      onClick={removeReport}
			      loading={removing}
			     >
			      Remove
			    </LoadingButton>
					</Box>
				</Box>
			</Modal>
		</div>
	)
}

const ReportRow = ({ item, addFileToReport, removeReport, asAdmin = false }) => {
	const onClick = () => addFileToReport(item)
	const onRemove = () => removeReport(item)
	return <TableRow>
		<TableCell>{item.ReportTitle}</TableCell>
		<TableCell><p>{item.ReportDescription}</p></TableCell>
		<TableCell>{dayjs(item.createAt).format('DD/MM/YYYY')}</TableCell>
		{
			item.reportFormats.length > 0 ? 
			<TableCell className="flex justify-end">
				{
					asAdmin && 
					<>
					<Button variant="outlined"
			      sx={{
			        color: "#000",
			        borderColor: "#d3d3d3",
			        fontSize: "14px",
			        borderRadius: "0 !important",
			        marginRight: '15px'
			      }}
			      onClick={onRemove}
			      >Remove</Button>
					<Button variant="outlined"
			      sx={{
			        color: "#000",
			        borderColor: "#d3d3d3",
			        fontSize: "14px",
			        borderRadius: "0 !important",
			        marginRight: '15px'
			      }}
			      onClick={onClick}
			      >Add file</Button>
			    </>
				}
				<DLButton downloadables={item.reportFormats} />
		    </TableCell> : 
			<TableCell className="flex justify-end">Ask a Question</TableCell>
		}
	</TableRow>
}

export default MyCustomReports
