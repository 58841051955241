const UsersSVG = () => {
	return (
		<svg className="stroke-current" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M15.2222 17.0003V15.2225C15.2222 14.2796 14.8476 13.3752 14.1808 12.7084C13.514 12.0416 12.6097 11.667 11.6667 11.667H4.55556C3.61256 11.667 2.70819 12.0416 2.0414 12.7084C1.3746 13.3752 1 14.2796 1 15.2225V17.0003" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M8.11122 8.11111C10.0749 8.11111 11.6668 6.51923 11.6668 4.55556C11.6668 2.59188 10.0749 1 8.11122 1C6.14754 1 4.55566 2.59188 4.55566 4.55556C4.55566 6.51923 6.14754 8.11111 8.11122 8.11111Z" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M20.5556 17V15.2222C20.555 14.4344 20.2928 13.6691 19.8101 13.0465C19.3275 12.4239 18.6517 11.9792 17.8889 11.7822" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M14.3333 1.11523C15.0981 1.31106 15.776 1.75586 16.26 2.37951C16.7441 3.00316 17.0069 3.77019 17.0069 4.55968C17.0069 5.34916 16.7441 6.11619 16.26 6.73985C15.776 7.3635 15.0981 7.8083 14.3333 8.00412" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>

	)
}

export default UsersSVG