import { useState } from "react"
import TwoFATypeForm from "./components/2fa-type"
import AuthForm from "./components/auth-form"
import ClientAuthForm from "./components/client-auth-form"

import { 
  Switch,
  Route
} from 'react-router-dom'
import './index.css'

const Login = () => {
	const [ state, setState ] = useState({
		section: 'auth-form',
		type: null,
		authResponse: null
	})

	return (
		<div className="w-full">
			{
				state.section === 'auth-form' && (
				<Switch>
					<Route exact path="/login" render={()=><ClientAuthForm />} />
					<Route exact path="/admin-login" render={()=> <AuthForm onSubmit={(data) => setState(prevState => ({ ...prevState, authResponse: data, section: 'auth-type' })) }/>}/>
				</Switch>
				)
			}
			
			{/* 
				This will be changed because we will no longer be needed because after login we the login form will be replcaed with the 2fa form and NOT choose what kind of 2fa is going to be used.
			*/}
			{
				state.section === 'auth-type' && (
					<TwoFATypeForm
						value={ state.type?.value }
						onSubmit={(data) => setState(prevState => ({ ...prevState, type: data, section: 'otp-form' })) }
					/>
				)
			}
			
		</div>
	)

}

export default Login