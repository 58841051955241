import React from "react";
import StackedBarChart from "components/charts/StackedBarChart";
import { Box, Typography } from "@mui/material";
import DownloadButton from "components/charts/DownloadButton";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  root: {
    background: "#fff",
    boxShadow: "0 0 2px rgb(0 0 0 / 70%)",
    padding: "25px 25px",
    borderRadius: "5px",
    marginTop: "25px",
  },
});

const HorizontalBarChatSummary = (props) => {
  const ref = React.useRef(null);
  const classes = useStyles();

  return (
    <div className="relative">
      <div className="absolute top-5 right-5 z-10">
        <DownloadButton targetRef={ref} name={props.sourceData?.label} />
      </div>
      <Box className={classes.root} ref={ref}>
        <div className="flex justify-between mb-5">
          <Typography className="text-sm font-medium capitalize">
            {props.sourceData?.label}
          </Typography>
        </div>

        <ul className="flex justify-end mb-4">
          {props.data[0].metaConfig.fields.map((field, fieldIndex) => (
            <li
              className="pl-4 pr-4 text-xs flex items-center"
              key={`horizontal-chart-legend-${fieldIndex}`}
            >
              <p
                className="rounded-full mr-1"
                style={{
                  background: field.color,
				  width: "8px",
				  height: "8px",
                }}
              ></p>{" "}
              <p className="text-gray-30 capitalize">{field.label}</p>
            </li>
          ))}
        </ul>
        {props.data.length &&
          props.data.map((data, index) => (
            <div style={{ width: "100%" }} key={index}>
              <StackedBarChart
                key={index}
                data={data.data}
                metaConfig={data.metaConfig}
              />
            </div>
          ))}
      </Box>
    </div>
  );
};

export default HorizontalBarChatSummary;
