import {combineReducers, createStore} from 'redux'

import UserReducer from './modules/user/reducer'
import AppReducer from './modules/app/reducer'
import CartReducer from './modules/cart/reducer'
import ReportReducer from './modules/reports/reducer'

const allReducers = combineReducers({
  user: UserReducer,
  app: AppReducer,
  cart: CartReducer,
  report: ReportReducer
})

const store = createStore(allReducers)
export default store