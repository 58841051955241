export const SET_BOUGHT_REPORTS = 'SET_BOUGHT_REPORTS'
export const setBoughtReports = (reports = []) => {
  return {
    type: SET_BOUGHT_REPORTS,
    payload: reports,
  };
}

export const SET_AVAILABLE_REPORTS = 'SET_AVAILABLE_REPORTS'
export const setAvailableReports = (reports = []) => {
  return {
    type: SET_AVAILABLE_REPORTS,
    payload: reports,
  };
}

export const SET_RESYNC_INFO = 'SET_RESYNC_INFO'
export const setResyncInfo = (resyncing = true, resynced = false) => {
  return {
    type: SET_RESYNC_INFO,
    payload: { resyncing, resynced }
  };
}

export const SET_EV_FILTER = 'SET_EV_FILTER'
export const setEVFilter = (filter = null) => {
  return {
    type: SET_EV_FILTER,
    payload: filter
  };
}
