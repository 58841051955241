import { useHistory } from "react-router"

const BreadcrumbComponent = ({
	items
}) => {

	const history = useHistory()

	return (
		<div className="flex flex-row items-center flex-wrap">
			{
				items.map((item, index) => {
					return (
						<div key={`breadcrumb-${index}`} className="flex">
							<p
								className={ `text-gray-40 cursor-pointer ${ (index + 1) != items.length ? 'opacity-70' : '' }` }
								onClick={ () => (index + 1) != items.length ? history.push(item.path) : null }
							>
								{ item.label }
							</p>
							{
								(index + 1) != items.length ? <p className="text-gray-40 opacity-70 px-2">|</p> : null
							}
						</div>
					)
				})
			}
		</div>
	)

}

export default BreadcrumbComponent