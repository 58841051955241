import React from 'react'

import { Link } from '@mui/material'
import { useParams } from 'react-router-dom'

import ViewUsersContent from './content'

import BreadcrumbComponent from '../../../../../../../components/breadcrumb'

const ViewUsers = () => {

	const { role } = useParams()

	const navs = [
		{ label: 'Role Permissions', path: '/admin/role-permissions' },
		{ label: `View ${role} Users`, path: '/admin/role-permissions/#' }
	]

	return (
		<div>
			<BreadcrumbComponent items={navs} />

			<div className='py-8'>
				<h1 className='font-bold text-2xl'>Role Permissions</h1>
			</div>

			<Link class="flex items-center mb-8" href="/admin/role-permissions">
				<i className='bx bx-chevron-left text-2xl text-black'></i>
				Back to All Roles
			</Link>

			<div className='pb-4'>
				<h1 className='capitalize font-bold text-xl'>{role} Sales</h1>
			</div>

			<ViewUsersContent />
		</div>
	)
}

export default ViewUsers