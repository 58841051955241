import React from "react";
import Typography from "@mui/material/Typography";

const Legend = ({ text, color }) => {
  return (
    <Typography className="flex justify-start items-center gap-2">
      <div className="w-2 h-2 rounded" style={{ backgroundColor: color }}></div>
      <p className="text-sm">{text}</p>
    </Typography>
  );
};

export default Legend;
