import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom'
import { useMemo } from "react"

export default function useReportCheck (access = null) {
	const {pathname} = useLocation();
	const { items, resync } = useSelector(({cart}) => cart)
 	const { user } = useSelector(({ user }) => user);
	const {selectedProgramId, clearcache} = useSelector(({ app }) => app);
	const { items: reportItems, store, ...report} = useSelector(({report}) => report)

	const { organizationProgram } = user.userData ? user.userData : user.user;
	const selectOrgProgram = organizationProgram.find(i => i.programId?._id == selectedProgramId) || organizationProgram[0];
	const targetItem = store.find(i => i.redirectTo === pathname || i.access === access);
	
	const feeString =  targetItem?.forPayment + "_Fee";

	let accessList = {} // This part of the code is only triggered for checkout
	if(targetItem && selectOrgProgram) {
		if(targetItem.accessGroup?.length > 0) {
			targetItem.accessGroup?.forEach(i => {
				accessList[i] = "Yes" // selectOrgProgram[i]
			})
		} else if(targetItem.access) {
			accessList[targetItem.access] = 'Yes' //selectOrgProgram[targetItem.access]
		}
	}
	return useMemo(() => {
		return {
			onCart: items.find(i => i.redirectTo === pathname) != null,
			isPurchased: (selectOrgProgram[access] || '').trim().toLowerCase() === 'yes',
			price: selectOrgProgram[feeString] || selectOrgProgram?.programId[feeString] || null,
			targetItem,
			accessList
		}
	}, [ pathname, items, reportItems, selectOrgProgram ]);
}
