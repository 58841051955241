import { useState } from 'react'
import ClickAwayListener from '@mui/material/ClickAwayListener'
import Popper from '@mui/material/Popper'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'
import { makeStyles } from '@material-ui/styles'


const FilterDropdowns = ({
	options = [],
	name = '',
	onChange = null,
	questionId = null,
	placeholder = '',
	value = ''
}) => {
	const [ showMenu, setShowMenu ] = useState(false)
	const [ state, setState ] = useState({
		menuElement: null
	})

	return (
		<div
			className={`relative filter-dropdown-${name} ${showMenu ? 'border border-black-0' : ''}`}
			onClick={(event) => {
				if (!showMenu) {
					setState(prevState => ({ ...prevState, menuElement: event.currentTarget }))
					setShowMenu(true)
				}
			}}
		>
			<div
				className={`flex flex-row items-center bg-white px-5 py-2 space-x-7 cursor-pointer`}
			>
				<span className='text-sm'>{ value || placeholder }</span>
				<i className='bx bx-chevron-down'></i>
			</div>
			<Popper
				open={ showMenu }
				anchorEl={ state.menuElement }
				// role={undefined}
				transition
				disablePortal
				style={{ position: 'absolute', zIndex: 100, transform: 'translate(0px, 45px)', width: '100%' }}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin: 'left'
						}}
					>
						<Paper variant="outlined" style={{ borderRadius: 0, width: 'fit-content'}}>
							<ClickAwayListener onClickAway={ () => setShowMenu(false) }>
								<MenuList id="split-button-menu">
									{
										options.map((option, index) => (
											<MenuItem
												className='text-sm text-black'
												key={`${name}-${index}`}
												onClick={(event) => {
													setShowMenu(false)
													if (onChange)
														onChange({ data: option, index: index, questionId })
												}}
											>
												<span className="pr-10">{ option.label }</span>
											</MenuItem>
										))
									}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
	)

}

export default FilterDropdowns