import React from 'react';
import { useHistory } from 'react-router';
import {Typography, Box, Grid, InputLabel, MenuItem, Select, List, ListItem, Button, Pagination, Breadcrumbs, Link, Input, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, TablePagination, Paper, TableSortLabel,
    Modal
} from '@mui/material';
import { FunnelSVG, ArrowNextSVG, EditSVG, DeleteSVG } from "components/svg-components";
import GenericLoader from 'components/loaders/generic'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import dayjs from 'dayjs'
import useGenerateList from './component/useGenerateList';
import getSymbolFromCurrency from 'currency-symbol-map';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';

const externalData = {
	borders: [ 'border-red-0', 'border-yellow-0', 'border-green-0', 'border-purple-0', 'border-blue-0' ],
	lastBorderIndexUsed: 0
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#f5f5f5',
  boxShadow: 24,
  p: 4,
};

function DetailModal ({ item, onClose }) {
    const { access, filter } = useGenerateList(item?.keys ?? {})
    console.log('access filter', access, filter)
    console.log(item)

    const currentCurrency = (item)=>{ 
      try{ 
        console.log(item)
          const { Currency } = item?.programId || 'US'; 
          if(Currency === 'CAD'){
              return 'CA$';
          }
          return getSymbolFromCurrency(Currency); 
      }catch(e){
          console.error(`Something went wrong with the corrency: ${e}`);
      }
  }

    return <Modal
    open={item !== null}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <Typography variant="h6" component="h2" fontWeight="600" marginBottom="30px" textAlign="center">
        Purchase Information
      </Typography>
      { access.length > 0 && 
        <Typography
          component="h3"
          fontSize="14px"
          marginBottom="20px"
         > 
          {/* <span className="text-black ml-2">
            {access.map(i =>i.title).join(",")}
          </span> */}
          <div>
              <div className='flex justify-between'>
                <div className="text-gray-400">Reports</div>
                <div className="text-gray-400">Price</div>
              </div>
              <div className='flex justify-between'>
                {item.isPaid ? <div style={{color:`green`}}>{item.itemTitle}</div> : <div style={{color:`red`}}> {item.itemTitle}</div>}
                <div >{currentCurrency(item) || '$'} 
                  {
                    item?.paymentMethod === 'Invoice' && item?.keys?.EV_Access ?
                    Number(item.amount).toFixed(2) 
                    :
                    (item.amount/100).toFixed(2)
                  }
                  </div>
              </div>
            
              { item.itemTitle !== access[0]?.title &&
                <>
                  <div className='flex justify-between'>
                  <div className="text-gray-400">Access Bought:</div>
                  </div>
                    <div>
                    {
                      access.map((itm,index)=>{
                        return(
                          <div key={index} className='flex justify-between'>
                            <div>{itm.title}</div>
                          </div>
                        )
                      })
                      }
                  </div>
                </>
              }
          </div>
        </Typography>
      }
      {
        filter &&
        <Typography
          component="h3"
          fontSize="14px"
          marginBottom="20px"
         >
          <span className="text-gray-400">Filter Bought</span>
          <span className="text-black ml-2">{filter}</span>
        </Typography>
      }
    </Box>
  </Modal>
}

const OrderLogPage = () => {
    const { http } = global.services

	const history = useHistory()
	const itemsPerPage = 10;
	const [total, setTotal] = React.useState(0);
	const [page, setPage] = React.useState(1);
	const [displayed, setDisplayed] = React.useState(itemsPerPage);
	const [noOfPages, setNoOfPages] = React.useState(
		Math.ceil(total / itemsPerPage)
	);
	const [name, setName] = React.useState('');
	const [foundRows, setFoundRows] = React.useState([]);
    const [oldRows, setOldRows] = React.useState([]);
    const [oldCount, setOldCount] = React.useState(0);
	const [filterBy, setFilterBy] = React.useState(10);
	const [sortBy, setSortBy] = React.useState('createAt');

    const [loading, setLoading] = React.useState(false);
    const [selected, showItem] = React.useState(null);

    React.useEffect(() => {
        loadData()
    }, [page, sortBy]) // Sort and 
    
    const loadData = async () => {
        setLoading(true);
        try{
        const { data } = await http.get(`admin/order/log?page=${page}&per_page=${itemsPerPage}&sortBy=${sortBy}`);
        const rows = data.data || [];
        const count = data.pagination?.total_documents || rows.length;
        setFoundRows(rows);
        setOldRows(rows);
        setTotal(count);
        setOldCount(count);
        setDisplayed(((page - 1) * itemsPerPage) + rows.length);
        setNoOfPages(Math.ceil(count / itemsPerPage))
        // console.log('huuh?', rows, count)
        setLoading(false)
        } catch(err){
            setLoading(false)
            console.log(err);
            return;
        }
    }

	const handleBorderColor = (count) => {

		let borderIndex = 0
		while (borderIndex === externalData.lastBorderIndexUsed)
			borderIndex = parseInt(Math.random() * ((externalData.borders.length - 1) - 0) + 0)

		externalData.lastBorderIndexUsed = borderIndex

		return externalData.borders[borderIndex]

	}
	
	const handleChange = (event, value) => {
		setPage(value);
		// setDisplayed(value * itemsPerPage);
	};

	const handleFilterBy = event => {
		setFilterBy(event.target.value);
	};

	const handleSortBy = event => {
		setSortBy(event.target.value);
	};


	const filter = (e) => {
	    const keyword = e.target.value;

	    if (keyword !== '') {

	      const results = oldRows.filter((row) => {
	        // return row.itemTitle.toLowerCase().startsWith(keyword.toLowerCase());
          return row.itemTitle.toLowerCase().includes(keyword.toLowerCase()) ||
                  row.organizationId?.Account_Name.toLowerCase().includes(keyword.toLowerCase()) ||
                  row.programId?.Name.toLowerCase().includes(keyword.toLowerCase())
	      });
	      setFoundRows(results);

	      setTotal(results.length);
        if(results.length <= itemsPerPage)
	      	setDisplayed(results.length);
	      else
	      	setDisplayed(itemsPerPage);

	    } else {
	    	setTotal(oldCount);
	      	setFoundRows(oldRows);
          setDisplayed(itemsPerPage);
	    }

	    setName(keyword);
	  };

	return (
  <Box className="orders-container admin_content-wrapper">
   {/* <Breadcrumbs aria-label="breadcrumb">
    <Link href="#" underline="hover" color="inherit">
     Order Log
    </Link>
   </Breadcrumbs> */}
   <div>
    <Typography
     component="h1"
     fontSize="30px"
     fontWeight="700"
     marginBottom="30px"
    >
     Order Log
    </Typography>
   </div>

   <Typography
    component="h2"
    fontSize="22px"
    lineHeight="33px"
    fontWeight="700"
    marginBottom="30px"
   >
    Reports
   </Typography>

   <Stack
    spacing={5}
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    paddingRight="30px"
   >
    <Box className="flex" alignItems="center">
     <Select
      value={sortBy}
      onChange={handleSortBy}
      sx={{ maxHeight: "40px", minHeight: "unset", background: "#fff", fontSize: '14px' }}
     >
      <MenuItem value="createAt">Sort by Date</MenuItem>
      <MenuItem value="paymentMethod">Sort by Payment Method</MenuItem>
      <MenuItem value="organizationId.Account_Name:asc">Client Sort by Ascending</MenuItem>
      <MenuItem value="organizationId.Account_Name:desc">Client Sort by Descending</MenuItem>
     </Select>
    </Box>
    <Input
     type="search"
     placeholder="Product Search"
     value={name}
     onChange={filter}
     className="input search-list"
    />
   </Stack>
   {
    loading ? <Box><GenericLoader /></Box>
    : <div>
    <TableContainer component={Paper}>
     <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead sx={{ backgroundColor: "#eaebeb" }}>
       <TableRow>
        <TableCell>Order Date</TableCell>
        <TableCell>Product</TableCell>
        <TableCell>Payment Method</TableCell>
        <TableCell> Client
          {/* <Box className="flex items-center">
            Client
            <Box>
              <div onClick={()=>clientAscDecs('organizationId.Account_Name:asc')} className='-mb-3 cursor-pointer text-red hover:text-[#eb3f3b]'> 
                <ArrowDropUpIcon/>
              </div>
              <div onClick={()=>clientAscDecs('organizationId.Account_Name:desc')} className='cursor-pointer hover:text-[#eb3f3b]'>
                <ArrowDropDownIcon/>
              </div>
            </Box>
          </Box> */}
        </TableCell>
        <TableCell>Program</TableCell>
        <TableCell>Stripe Status</TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
        {/*.slice((page - 1) * itemsPerPage, page * itemsPerPage)*/}
       {foundRows
        .map((row) => (
         <TableRow
          key={row._id}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          hover
          className=""
         >
          <TableCell component="th" scope="row">
            {dayjs(row.createAt).format('MM/DD/YYYY')}
          </TableCell>
          <TableCell className="cursor-pointer" onClick={() => showItem(row)}>{row.itemTitle} {row.keys?.EV_Sorting_Filter ? `(${row.keys?.EV_Sorting_Filter})` : ''}</TableCell>
          <TableCell>{row.paymentMethod === 'Bank Transfer' ? 'Invoice' : row.paymentMethod}</TableCell>
          <TableCell>{row.organizationId?.Alias_Company_Name || row.organizationId?.Account_Name}</TableCell>
          <TableCell>{row.programId?.Name}</TableCell>
          <TableCell>{row.isPaid ? <div style={{color:`green`}}>Success</div> : <div style={{color:`red`}}>Unsuccessful</div>}</TableCell>
         </TableRow>
        ))}
      </TableBody>
     </Table>
    </TableContainer>
    <Grid container justifyContent="space-between" marginTop="30px">
     <Grid item>
      {displayed <= foundRows.length ? (
       <Typography>
        {displayed} out of {total}
       </Typography>
      ) : (
       <Typography>
        {foundRows.length} out of {total}
       </Typography>
      )}
     </Grid>
     <Grid item>
      <Pagination
       count={noOfPages}
       page={page}
       onChange={handleChange}
       defaultPage={1}
       color="primary"
       size="large"
       className="pagination"
      />
     </Grid>
    </Grid>
   </div>
   }
   <DetailModal item={selected} onClose={() => showItem(null)}/>
  </Box>
 );

}


export default OrderLogPage
