import { useState, useEffect } from 'react';
import {Box, Button, Typography, IconButton, Modal, Select, MenuItem, Alert} from '@mui/material';
import { Close } from '@mui/icons-material';
import { DeleteSVG } from "components/svg-components";
import WhiteTextField from 'components/form-utilities/white-text-field'
import TextField from '@mui/material/TextField'

import UserAction from 'redux/modules/user/actions'
import { useDispatch, useSelector } from "react-redux"
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import QRCode from 'qrcode';

const useStyles = makeStyles({
	select: {
		maxHeight: "40px",
		minHeight: "unset",
	 	border: 0,
		borderRadius: 0,
		width: "100%",
		background: "#fff",
		fontSize: "14px",
		'& fieldset': {
			border:0
		}
	}
})

export default function Verify2FA({form, setForm = ()=>{}, ...props}) {
	const { http } = global.services
	const genUser = useSelector(({user}) => user.user)
	const { user } = genUser
	const style = {
	  position: 'absolute',
	  top: '50%',
	  left: '50%',
	  transform: 'translate(-50%, -50%)',
	  width: 400,
	  bgcolor: '#f5f5f5',
	  boxShadow: 24,
	  p: 4,
	};
	const [ vars, setVars ] = useState('');
	const [errors, setErrors] = useState({
		response: null
	});
	const [success, setSuccess] = useState(false);
	const [qrCodeDataUrl, setQRCodeDataUrl] = useState(null);
	const [isLodaing, setIsLoading] = useState(false);

	useEffect(()=>{
		if(success)
			setTimeout(()=>setSuccess(false), 2000);
	}, [success]);
	
	const handleSubmit = async (e) => {
		setIsLoading(true);
		setErrors({
			response: null,
		})
		e.preventDefault();
        const data = {
            'token': vars.toString()
          };
          
          const config = {
            headers: {
              'Content-Type': 'application/json'
            }
          };
		try {
			const response = await http.post('user/management/validate2fa', data, config)
            if(response.data.verified){
                setSuccess(true);
				setIsLoading(false);
				closeHandler();
            }else if(!response.data.verified){ 
                setErrors({...errors, response: "OTP verification failed. Please try again"})
                setTimeout(()=>setErrors({...errors, response: null}), 3000);
				setIsLoading(false);
            }
			
            console.log(response);
		} catch (e) {
			setErrors({...errors, response: "Something's wrong. Check again later"})
            setTimeout(()=>setErrors({...errors, response: null}), 2000);
			setIsLoading(false);
			return;
		}

	}
	const handleForm = ({target}) => {
		setVars(target.value)
	}


	const generateQR2FA = async(e) => {
		e.stopPropagation();
		e.preventDefault();
		try {
			const response = await http.post('user/management/register2fa')
			// console.log(user, { ...genuser, mfaPreference: fa, ...vars, firstName: vars.fullName })
			console.log(response);
			const {data} = response;
			QRCode.toDataURL(data?.otpauth_url, function (err, dataUrl) {
				if (err) {
				  console.error(err);
				  return;
				}
				setQRCodeDataUrl(dataUrl);
			  });
		} catch (e) {
			setErrors({...errors, response: "Something's wrong. Check again later"})
			return;
		}
	}

    const closeHandler = ()=>{
        setQRCodeDataUrl(null);
        setVars('');
        setForm(false)
    }

	const handleAlert = (e) => {
		if(user['2faVerified']){
			const result = window.confirm("NOTE: The old 2FA code will stop working after this action.");
			if (result) {
				e.stopPropagation();
				e.preventDefault();
				generateQR2FA(e);
			} else {
				e.stopPropagation();
				e.preventDefault();
				console.log('close.')
			}
		}else{
			generateQR2FA(e);
		}
	  };

	return (<Modal
	  open={form}
	  onClose={()=>closeHandler()}
	  aria-labelledby="role-delete-title"
	  aria-describedby="role-delete-description"
	>
	  <form onSubmit={handleSubmit}>
	  <Box sx={style}>
		<Typography id="role-form"
			variant="h6"
			component="span"
			fontWeight="600"
			display="block"
			className="text-center mt-6"
		>
			<IconButton
		    aria-label="close"
		    onClick={()=>closeHandler()}
		    sx={{ position: 'absolute', right: 8, top: 8 }}
		  >
		    <Close />
		  </IconButton>
		  {`${user['2faVerified'] ? 'Change 2FA' : 'Enable 2FA' }`}
		</Typography>
        <Box className="flex flex-col">
			<div className='w-full flex justify-center'>
				{qrCodeDataUrl && <img src={qrCodeDataUrl} alt="QR Code"  style={{width:'70%'}}/>}
			</div>
			
			<Button
				variant="contained"
				type="submit"
				color="primary"
				className="normal-case rounded-none"
				sx={{ margin: "10px 0"}}
				onClick={handleAlert}
			>
				Generate QR Code
			</Button>
		</Box>
		<Box>
		<WhiteTextField
			error={errors.otp}
			id="otp"
			className="w-full my-2 white-field text-xs"
			variant="outlined"
			placeholder="Enter OTP"
			value={ vars }
			name="otp"
			onChange={ handleForm }
			autoComplete="off"
            type="number"
		/>
		
		{
			errors.response && <Box className="mb-2">
			<Alert variant="filled" severity="error">
				{ errors.response }
			</Alert>
			</Box>
		}
		{
			success && <Box className="mb-2">
			<Alert variant="filled" severity="success">
				OTP Verified!
			</Alert>
			</Box>
		}
		</Box>
		<Box className="flex flex-col">
			<Button
				variant="contained"
				type="submit"
				color="error"
				className="normal-case rounded-none"
				sx={{ margin: "10px 0"}}
				disabled = {vars.length < 6 || isLodaing}
			>
				Verify 2FA
			</Button>
			<Button
				variant="text"
				onClick={()=>closeHandler()}
				className="normal-case rounded-none block text-center"
			>
				Close
			</Button>
		</Box>
	  </Box>
	  </form>
	</Modal>)
}