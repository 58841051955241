import { HomeSVG, PaperSVG, PaperBagSVG } from "components/svg-components"

const routes = {
	"items": [
		{
			"id": "dashboard",
			"title": "Dashboard",
			"icon": <HomeSVG />,
			"path": "/dashboard",
			"sections": [],
			"dependent_paths": []
		},
		{
			"id": "my-reports",
			"title": "My Reports",
			"icon": <PaperSVG />,
			"sections": [
				{
					"id": "basic-package-section",
					"title": "BASIC PACKAGE",
					"items": [
						{ 
							"id": "workforce-feedback-results",
							"title": "Workforce Feedback Results",
							"access": "WFR_Access",
							// "path": "/workforce-feedback-results",
							"sections": [],
							"subitems": [
								{
									"id": 'employee-response-breakdown',
									"path": "/employee-response-breakdown",
									"title": "Employee Response Breakdown"
								},
								{
									"id": 'detailed-results',
									"path": "/detailed-results",
									"title": "Detailed Results"
								},
								{
									"id": 'response-patterns',
									"path": "/response-patterns",
									"title": "Response Patterns"
								},
								{
									"id": 'annual-trends',
									"path": "/annual-trends",
									"title": "Annual Trends",
								}
							], 
							"dependent_paths": []
						},
						{ 
							"id": "employee-verbatims",
							"title": "Employee Verbatims",
							"path": "/employee-verbatims",
							"access": "EV_Access",
							"sections": [],
							"dependent_paths": [] 
						},
						{ 
							"id": "workforce-benchmark-comparisons",
							"title": "Workforce Benchmark Comparisons",
							"access": "WBC_Access",
							// "path": "/workforce-benchmark-comparisons",
							"sections": [],
							"subitems": [
								{
									"id": '/workforce-benchmark-comparisons',
									"path": "/workforce-benchmark-comparisons",
									"title": "Benchmark Data"
								},
								{
									"id": 'comparison-data',
									"path": "/comparison-data",
									"title": "Comparison Data"
								},
							]

						},
						{ 
							"id": "benefits-and-best-practices",
							"title": "Benefits & Best Practices",
							"path": "/benefits-and-best-practices",
							"access": "BBP_Access",
							"sections": [],
							"dependent_paths": []
						}
					]
				},
				{
					"id": "additional-reports-section",
					"title": "ADDITIONAL REPORTS",
					"items": [
						{
							"id": 'response-detail',
							"path": "/response-detail",
							"title": "Response Detail",
							"access": "RD_Access",
							"sections": [],
							"dependent_paths": []
						},
						{
							"id": "key-impact-analysis",
							"title": "Key Impact Analysis",
							"path": "/key-impact-analysis",
							"access": "KIA_Access",
							"sections": [],
							"dependent_paths": []
						},
						{
							"id": "my-custom-reports",
							"title": "Custom Reports",
							"path": "/my-reports",
							"access": 'CR_Access',
							"sections": [],
							"dependent_paths": []
						}
					]
				}
			],
			"dependent_paths": []
		},
		{
			"id": "reports-store",
			"title": "Reports Store",
			"icon": <PaperBagSVG />,
			"path": "/reports-store",
			"sections": [],
			"dependent_paths": [
				'/reports/custom-reporting'
			]
		}
	]
}

export default routes
