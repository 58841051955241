import { useState } from "react"
import TwoFATypeForm from "./components/2fa-type"
import AuthForm from "./components/auth-form"


const AdminLogin = (props) => {

	const [ state, setState ] = useState({
		section: props.section,
		type: null,
		authResponse: null,
	})
	return (
		<div className="w-full">

			{
				state.section === 'auth-form' && (
					<AuthForm
						value={ state.type?.value }
						onSubmit={(data) => setState(prevState => ({ ...prevState, authResponse: data, section: 'auth-type' })) }
					/>
				)
			}
			
			{/* 
				This will be changed because we will no longer be needed because after login we the login form will be replcaed with the 2fa form and NOT choose what kind of 2fa is going to be used.
			*/}
			{
				state.section === 'otp-form' && (
					<TwoFATypeForm
						value={ state.type?.value }
						onSubmit={(data) => setState(prevState => ({ ...prevState, type: data, section: 'otp-form' })) }
					/>
				)
			}
			
		</div>
	)

}

export default AdminLogin