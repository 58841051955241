import React, { useState } from "react"
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import SaveIcon from '@mui/icons-material/Save'
import { Typography } from "@mui/material"
import UserAction from 'redux/modules/user/actions'
import GrayTextField from 'components/form-utilities/gray-text-field'
import { makeStyles } from "@material-ui/styles"
import { useHistory } from "react-router"

const useStyles = makeStyles({
	heading:{
		textAlign:'center',
		fontWeight:'bold',
		fontSize:'32px',
		color:'#fff',
		marginBottom:'10px',
	},
	p:{
		fontSize:'16px',
		color:'#fff',
		width:'350px',
		padding:'25px',
		textAlign:'center',
	},
	btn:{
		borderColor:'#C6411F !important',
		borderRadius: '0 !important',
		color:"#fff !important",
		width:'100% !important',
		"&:hover":{
			backgroundColor: '#F75626 !important',
		}
	}
})
const ForgotPassword = () => {
	const classes = useStyles();
	const history = useHistory();
	const { http } = global.services
	const [ state, setState ] = useState({
		username:'',
		submitting: false,
		usernameError:false,
		error: (history.location.state) ? history.location.state.error : null
	})
	const handleSubmission = async (event = null, forAdmin = false) => {
		if (event){
			event.preventDefault()
			if(state.username) {
				let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
				if ( regex.test(state.username) ) {
					forAdmin = true
				}
				const payload = forAdmin ? { email: state.username } : { username: state.username }
				try {
					const response = await http.post('/user/forgot-password', payload)
					const responseData = response.data.data
					if(responseData) {
						history.push({
							pathname: '/verify-otp',
							state: {
								key:responseData.key,
								username:state.username
							}
						})
					}
				} catch (error) {
					const response = error.response;
					setState(prev => ({ ...prev, usernameError: true }))
					console.log('Authentication Error: ', response);
					const defaultErrorMsg = 'An unknown error occured. Please refresh the page and try again.'
					if (response) {
						if (response.status === 403 || response.status === 400 || response.status === 404) {
							setState(prev => ({ ...prev, error: response.data.message }))
						}
					}
				}
			}else{
				setState(prev => ({ ...prev, error: 'Email / Username Field Required' }))
			}
		}
	}
	return (
		<div>
			{
				state.error && (
					<div className="mb-10">
						<Alert severity="error">
							{ state.error }
						</Alert>
					</div>
				)
			}
			<Typography variant="h4" className={ classes.heading}>
				Forgot Password
			</Typography>
			<p className={classes.p}>
				Please enter your email. We will send you a verification code to reset your password shortly.
			</p>
			<form onSubmit={ (event) => handleSubmission(event) }>
				
				<div className="input-group mb-6">
					<Typography
						component="h4"
						fontSize="14px"
						marginBottom="10px"
						color="#fff"
				    >
						Email
					</Typography>
					<GrayTextField
						id="email-outlined-basic"
						className="w-full"
						variant="outlined"
						placeholder="Enter Email"
						onChange={ (event) => setState(prev => ({ ...prev, username: event.target.value, error: null })) }
						autoComplete="off"
						error={state.usernameError}
					/>
				</div>

				<div className="flex flex-row">
					<Button
						type="submit"
						className={classes.btn}
						variant="outlined"
						size="large"
					>
						Continue
					</Button>
				</div>
			</form>
			<p className={classes.p} style={{ color: 'rgba(255,255,255, 0.6)' }}>
				I remember the password.
				<a className="ml-2" style={{color:'#FFF', cursor: 'pointer'}} onClick={() => history.push('/login')}>Log In</a>
			</p>
		</div>
	)

}

export default ForgotPassword