import React, { useEffect } from 'react'

import {
	InputLabel, MenuItem, Breadcrumbs, Select, Box, Grid, Button, Modal, Typography, TextField, FormControl, useFormControl, Stack,
	Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper, Input, Pagination,
	IconButton, FormGroup, FormControlLabel, Autocomplete, Alert
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { FunnelSVG, DeleteSVG } from "components/svg-components";
import { RolesData } from '../constants/data'
import { Permissions } from '../constants/permissions'
import WhiteTextField from 'components/form-utilities/white-text-field'
import Switch from 'components/form-utilities/switch-field'
import GenericLoader from 'components/loaders/generic'

const boxStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 490,
  bgcolor: '#f5f5f5',
  boxShadow: 24,
  p: 4,
}
const Content = () => {
	const { http } = global.services
	const [open, setOpen] = React.useState(false);
	const [openForm, setOpenForm] = React.useState(false);
	const [openPermission, setOpenPermission] = React.useState(false);
	const [target, setTarget] = React.useState(null);
	const [roleName, setRoleName] = React.useState("");
	const [loading, setLoading] = React.useState(false);

	const handleOpen = (target) => {
		setOpen(true)
		setTarget(target)
	}
	const handleClose = () => {
		setOpen(false)
	}
	const handlePermOpen = (target = null) => {
		if(target?.name?.toLowerCase() == 'admin')
			target = null;

		setOpenForm(true)
		setTarget(target)
		setRoleName(target?.role || "")
		getPermissions(target)
	}
	const handlePermClose = () => {
		setOpenForm(false)
		setTarget(null)
	}
	const [permissions, setPermissions] = React.useState([]);
	const handlePermissions = ({target}, item) => {
		setPermissions(target.checked ? 
			[item.shortcode, ...permissions] : 
			permissions.filter(i => i !== item.shortcode)
		)
	};

	const [changeRole, setChangeRole] = React.useState(null);
	const handleRole = ({ target }) => setChangeRole(target.value)

	const [filterBy, setFilterBy] = React.useState('date');
	const handleFilterBy = event => {
		setFilterBy(event.target.value);
	};

	const [sortBy, setSortBy] = React.useState('Date');
	const handleSortBy = event => {
		setSortBy(event.target.value);
	};

	const itemsPerPage = 5;
	const [total, setTotal] = React.useState(0);
	const [page, setPage] = React.useState(1);
	const [displayed, setDisplayed] = React.useState(itemsPerPage);
	const [noOfPages, setPages] = React.useState(
		Math.ceil(total / itemsPerPage)
	);
	const [foundRows, setFoundRows] = React.useState([]);
	const [alert, setAlert] = React.useState(null);

	useEffect(()=>{
		initData()
	}, []);

	useEffect(() => {
		if(alert)
			setTimeout(()=>{
				setAlert(null)
			}, 2000)
	}, [alert])

	const initData = async () => {
		try{
		setLoading(true);
		const { data } = await http.get('/admin/getroles');
		const { roleData } = data;
		setRoleData(roleData)
		setLoading(false)
		} catch(err){
			setLoading(false)
			console.log(err);
			return;
		}
	}

	const setRoleData = (roleData = []) => {
		setFoundRows(roleData)
		setTotal(roleData.length)
		setPages( Math.ceil(roleData.length / itemsPerPage));
	}

	const getPermissions = async (target) => {
		if(!target){
			setPermissions([])
			return;
		}
		try{
		const { data } = await http.get('/admin/getpermissions/' + target._id);
		const { roleData } = data;
		setPermissions(roleData.permissions || [])
		} catch(err){
			console.log(err);
			return;
		}
	}

	const handleUpdateRole = async (e) => {
		e.preventDefault();
		const reqData = { roleName, permissions }

		if(!target){
			try {
				const { data } = await http.post('/admin/addrole', reqData);
				const { roleData } = data;
				setRoleData( roleData )
				setAlert('Role Created!')
			} catch (e) {
				setAlert('Error!')
			}

			return;
		}
		try{
		const { data } = await http.put('/admin/updaterole', {...reqData, roleId: target?._id } );
		const { roleData } = data;
		// setRoleData( roleData )
		initData();

		setAlert('Role Updated!')
		} catch(err){
			console.log(err);
			setAlert('Error!', err)
			return;
		}
	}

	const handleRemoveRole = async (e) => {
		e.preventDefault();
		if(!target){
			setAlert('Could not delete empty role!')
			return;
		}
		try{
		const { data } = await http.delete('/admin/deleterole', {
			roleId: target?._id
		});
		initData();
		handleClose();
		} catch(err){
			console.log(err);
			handleClose();
			return;
		}
	}

	const handleChange = (event, value) => {
		setPage(value);
		setDisplayed(value * itemsPerPage);
	};

	return (
		<div className="users-wrapper">
			{/* Table here*/}
			<Box>
				<Button
					variant="contained"
					onClick={()=>handlePermOpen(null)}
					color="error"
					className="normal-case rounded-none"
					sx={{ marginBottom: "40px", minWidth: "180px" }}
				>
					+ Add New Role
				</Button>
				<Box className="users-lists_wrapper">
				{/*
					<Stack
						spacing={2}
						direction="row"
						className="filter-wrapper"
						marginBottom="20px"
						justifyContent="space-between"
						paddingRight='30px'
					>
						<Box className="flex" alignItems="center">
							<Select
								value={filterBy}
								onChange={handleFilterBy}
								sx={{
									maxHeight: "40px",
									minHeight: "unset",
									background: "#fff",
									fontSize: "14px",
								}}
							>
								<MenuItem value="date">Sort By Date</MenuItem>
								<MenuItem value="roleName">Sort By Name</MenuItem>
							</Select>
						</Box>
					</Stack>*/}
					{
						loading ? <Box><GenericLoader /></Box>
						: <Box>
						<TableContainer component={Paper}>
							<Table sx={{ minWidth: 650 }} aria-label="simple table">
								<TableHead sx={{ backgroundColor: "#eaebeb" }}>
									<TableRow>
										<TableCell>Role</TableCell>
										<TableCell>Users</TableCell>
										<TableCell align="right">Actions</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{foundRows
										.slice((page - 1) * itemsPerPage, page * itemsPerPage)
										.map((row, key) => (
											<TableRow
												key={row._id}
												sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
											>
												<TableCell component="th" scope="row">
													{row.role}
												</TableCell>
												<TableCell>{row.userCount}</TableCell>
												<TableCell align="right">
													{row.role?.toLowerCase() !== 'admin' && <Link className="mr-4" sx={{color:'#F2403B !important'}} onClick={()=>handlePermOpen(row)}>Edit</Link>}
													{/*	<Link className={row.name !== 'Admin' ? 'mr-4' : ''} href={`/admin/role-permissions/${row.role}/users`}>View Users</Link>*/}																									
												</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
						<Grid container justifyContent="space-between" marginTop="30px">
							<Grid item>
								{displayed <= foundRows.length ? (
									<Typography>
										{displayed} out of {total}
									</Typography>
								) : (
									<Typography>
										{foundRows.length} out of {total}
									</Typography>
								)}
							</Grid>
							<Grid item>
								<Pagination
									count={noOfPages}
									page={page}
									onChange={handleChange}
									defaultPage={1}
									color="primary"
									size="large"
									className="pagination"
								/>
							</Grid>
						</Grid>
					</Box>
					}
				</Box>
			</Box >
			{/* New/Update Role Form*/}
			<Modal
			  open={openForm}
			  onClose={()=>setOpenForm(false)}
			  aria-labelledby="role-delete-title"
			  aria-describedby="role-delete-description"
			>
			  <Box sx={boxStyle}>
				<Typography id="role-form"
					variant="h6"
					component="span"
					fontWeight="600"
					display="block"
					className="text-center mt-6"
				>
					<IconButton
			        aria-label="close"
			        onClick={()=>setOpenForm(false)}
			        sx={{ position: 'absolute', right: 8, top: 8 }}
			      >
			        <Close />
			      </IconButton>
				  {target ? 'Update': 'Add'} Role
				</Typography>
				<WhiteTextField
					error={null}
					id="email-outlined-basic"
					className="w-full my-5"
					variant="outlined"
					placeholder="Enter Role Name"
					value={ roleName }
					onChange={ (event) => setRoleName(event.target.value ) }
					autoComplete="off"
					required
				/>
				<Box className="mb-5 p-0">
					{
						Permissions.length > 0 ? Permissions.map((i, key)=> <FormControl key={"picked-"+key} className="flex h-10 mb-6 flex-row items-center justify-between">
							<Typography component='span' sx={{ fontSize: '14px', lineHeight: '21px', color: '#1D2124'}} htmlFor={'switch-name-'+ i.id}>{i.name}</Typography>
							<Switch className="" checked={permissions.find(p => p === i.shortcode) != null} onChange={(e)=>handlePermissions(e,i)} name={'switch-name-'+i.id} />
						</FormControl>) :
						<Typography id="role-delete-description" sx={{ mt: 2 }}>
						  Add some permissions for this role.
						</Typography>
					}
				</Box>
				{
					alert !== null &&
					<div className='mt-5'>
						<Alert variant="filled" severity="success">
							{ alert }
						</Alert>
					</div>
				}
				<Box className="mb-5">
					{
						(target!=null && target?.name?.toString().toLowerCase() !== 'admin') && 
						<Link sx={{color:'#F2403B !important', display: 'flex', cursor: 'pointer'}} onClick={()=>handleOpen(target)}>
							<svg style={{ marginRight: '10px'}} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
								<path d="M2.25 4.5H3.75H15.75" stroke="#F3403C" strokeLinecap="square" strokeLinejoin="round"/>
								<path d="M6 4.5V3C6 2.60218 6.15804 2.22064 6.43934 1.93934C6.72064 1.65804 7.10218 1.5 7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5M14.25 4.5V15V16.5H12.75H5.25H3.75V15V4.5H14.25Z" stroke="#F3403C" strokeLinecap="square"/>
								<path d="M7.5 8.25V12.75" stroke="#F3403C" strokeLinecap="square" strokeLinejoin="round"/>
								<path d="M10.5 8.25V12.75" stroke="#F3403C" strokeLinecap="square" strokeLinejoin="round"/>
							</svg>
						Delete Role</Link>
					}
				</Box>
				<Box className="flex flex-col">
					<Button
						variant="contained"
						onClick={handleUpdateRole}
						color="error"
						className="normal-case rounded-none"
						sx={{ margin: "15px 0"}}
					>
						{target ? 'Save Changes' : 'Create Role'}
					</Button>
					<Button
						variant="text"
						onClick={()=>setOpenForm(false)}
						className="normal-case rounded-none block text-center"
					>
						Cancel
					</Button>
				</Box>
			  </Box>
			</Modal>

			{/* Delete form */}
			<Modal
			  open={open}
			  onClose={handleClose}
			  aria-labelledby="role-delete-title"
			  aria-describedby="role-delete-description"
			>
			  <Box sx={boxStyle}>
				<Typography id="role-delete-title"
					variant="h6"
					component="span"
					fontWeight="600"
					display="block"
					className="text-center mt-6"
				>
					<IconButton
			        aria-label="close"
			        onClick={handleClose}
			        sx={{ position: 'absolute', right: 8, top: 8 }}
			      >
			        <Close />
			      </IconButton>
			      {
			      	target?.userCount > 0 ? "This role can't be deleted" : "Delete Role"
 			      }
				</Typography>
				<Typography id="role-delete-description" 
					variant="body1"
					component="p"
					fontWeight="normal"
					className="text-center my-2"
					sx={{ mt: 2 }}>
				  {
			      	target?.userCount > 0 ? <>This role can't be deleted because <br/> people are assigned to it.</> : "Are you sure?"
 			      }
				</Typography>
				{
					target?.userCount > 0 ? 
					<Box className="flex">
						<Button
							variant="contained"
							onClick={handleClose}
							color="error"
							className="normal-case w-full rounded-none"
							sx={{ margin: "15px 0"}}
						>
						Ok
						</Button>
					</Box>
					:<Box className="flex">
						<Button
							variant="text"
							onClick={handleClose}
							className="normal-case w-1/2 rounded-none block text-center"
						>
							Cancel
						</Button>
						<Button
							variant="contained"
							onClick={handleRemoveRole}
							color="error"
							className="normal-case w-1/2 rounded-none"
							sx={{ margin: "15px 0"}}
						>
							Delete
						</Button>
					</Box>
				}
			  </Box>
			</Modal>

			{/* Edit permission here */}
			{/*<Modal
			  open={openPermission}
			  onClose={handlePermClose}
			  aria-labelledby="modal-edit-title"
			  aria-describedby="modal-edit-description"
			>
			  <Box sx={{
				  position: 'absolute',
				  top: '50%',
				  left: '50%',
				  transform: 'translate(-50%, -50%)',
				  width: 500,
				  bgcolor: '#f5f5f5',
				  boxShadow: 24,
				  p: 4,
				}}>
				<Typography id="role-delete-title"
					variant="h6"
					component="span"
					fontWeight="600"
					display="block"
					className="text-center mt-6"
				>
				  <IconButton
				    aria-label="close"
				    onClick={handlePermClose}
				    sx={{ position: 'absolute', right: 8, top: 8 }}
				  >
				    <Close />
				  </IconButton>
				  Edit Permissions
				</Typography>
				<Box className="mb-2 h-96 overflow-y-scroll">
					{
						Permissions.map(i=> <FormControl key={"switch-"+i.id} className="flex h-10 flex-row items-center justify-between">
							<Typography component='span' htmlFor={'switch-name-'+ i.id}>{i.name}</Typography>
							<DeleteSVG />
						</FormControl>)
					}
				</Box>
				<Box className="flex flex-col mb-2">
					<Button
						variant="outlined"
						onClick={handlePermClose}
						className="normal-case rounded-none"
						sx={{ margin: "15px 0", border: "1px solid #D3D3D3", boxSizing: "border-box", color: "#1D2124"}}
					>
						Add Permission 
					</Button>
				</Box>
				<Box className="flex flex-col">
					<Button
						variant="contained"
						onClick={handlePermClose}
						color="error"
						className="normal-case rounded-none"
						sx={{ margin: "15px 0"}}
					>
						Save Changes
					</Button>
					<Button
						variant="text"
						onClick={handlePermClose}
						className="normal-case rounded-none block text-center"
					>
						Cancel
					</Button>
				</Box>
			  </Box>
			</Modal>*/}
		</div >
	)
}

export default Content