import { Fragment, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router"
import { Link as DomLink } from 'react-router-dom';
import UserAction from 'redux/modules/user/actions'
import AppAction from 'redux/modules/app/actions'

import { ClickAwayListener, Popper, Grow, Paper, MenuList, MenuItem, Link} from '@mui/material'
import EditProfileModal from './subcomponent/edit-profile'
import EditPasswordModal from './subcomponent/edit-password'
import Verify2FA from './subcomponent/verify-2fa';

const NavbarUserMenu = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { clearcache } = useSelector(({app})=> app)
	const { user, admin } = useSelector(({user})=> user)
	const [ showMenu, setShowMenu ] = useState(false)
	//personal information modal and form
	const [ infoModal, showInfoModal] = useState(false)
	const [ passModal, showPassModal] = useState(false)
	const [ verify2FAModal, showVerify2FAModal] = useState(false)

	const isClient = user.user?.role == 'client';
	const userLogout = () => {
		dispatch(UserAction.toggleUser())
		dispatch(UserAction.setUser(''))
		const role = (user.user) ? user.user.role : user.userData.role;

		if(['admin', 'sales', 'operations', 'oprations'].includes( role ) || admin != null) {
			history.push('/admin-login')
		} else {
			history.push('/login')
		}
	}

	const getInitial = () => {
		const _user = user.user || user.userData;
		const name = _user.username || _user.fullName 
		return name[0]?.toUpperCase();
	}

	const toggleClearCache = () => dispatch( AppAction.setClearCache(!clearcache))

	return (
		<Fragment>
		<div
			className="relative"
			onClick={(event) => {
				if (!showMenu) {
					setShowMenu(true)
				}
			}}
		>
			{
				false ? 
				<img
					src="https://www.w3schools.com/howto/img_avatar2.png"
					id="navbar-user-avatar"
					className="rounded-full cursor-pointer"
					alt="navbar-user-avatar"
				/> :
				<div id="navbar-user-avatar"
					className="rounded-full cursor-pointer flex items-center justify-center">
					<span className="text-white">{getInitial()}</span>
				</div>
			}
			<Popper
				open={ showMenu }
				anchorEl={ null }
				style={{ position: 'absolute', top: '3rem', right: 0, left: 'unset', zIndex:99 }}
				transition
				disablePortal
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom' ? 'center top' : 'center bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={ () => setShowMenu(false) }>
								<MenuList id="split-button-menu">
									{
										!isClient ? <>
										<MenuItem>
											<Link className="pr-10 text-sm" type={'button'} onClick={()=>showInfoModal(true)}>Edit Profile Information</Link>
										</MenuItem>
										<MenuItem>
											<Link className="pr-10 text-sm" type={'button'} onClick={()=>showPassModal(true)}>Change Password</Link>
										</MenuItem>
										<MenuItem>
											<Link className="pr-10 text-sm" type={'button'} onClick={()=>showVerify2FAModal(true)}>Verify 2FA</Link>
										</MenuItem>
										<MenuItem>
											<DomLink className='text-sm' to='/help'>Help</DomLink>
										</MenuItem>
										</> : <MenuItem onClick={toggleClearCache}>
											<span className='text-sm'>{clearcache ? 'Disable Clear Cache': 'Use Clear Cache'} </span>
										</MenuItem>
									}
									<MenuItem onClick={userLogout}>
										<span className='text-sm'>Logout</span>
									</MenuItem>
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>
		</div>
		<EditProfileModal form={infoModal} setForm={showInfoModal} />
		{
			user.role != 'client' && <EditPasswordModal form={passModal} setForm={showPassModal} />
		}
		{
			user.role != 'client' && <Verify2FA form={verify2FAModal} setForm={showVerify2FAModal} />
		}
		</Fragment>
	)

}

export default NavbarUserMenu