import React, { useState } from "react"
import { Box, InputLabel, FormControl, Select, MenuItem, Button } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    filter_section:{
        padding: '20px 15px',
				background: '#fff',
    },
    formControl:{
        position:'relative',
    },
	input_label:{
        position:'absolute',
        top:"-6px",
				fontSize: '14px'
    }

});

const FilterFormComponent = () => {
    const classes = useStyles();
    const lengthOfServices = [
		{
			value:'',
			label:'Five years to less than ten years',
		},
		{
			value:'',
			label:'Less than one year',
		},
		{
			value:'',
			label:'One year to less than two years',
		},
		{
			value:'',
			label:'Ten years or more',
		},
		{
			value:'',
			label:'Two years to less than five years',
		}
	];
    const generations = [
        {
            value:"",
            label:"Generation X  (Born 1965 to 1980)"
        },
        {
            value:"",
            label:"Generation Z (Born after 1997)"
        },
        {
            value:"",
            label:"The Silent Generation (Born 1928 to 1945)"
        },
        {
            value:"",
            label:"The Millennial Generation (Born 1981 to 1997)"
        }
    ]
    const ethnics = [
        {
            value:"",
            label:"Asia"
        },
        {
            value:"",
            label:"Pacific Islander"
        },
        {
            value:"",
            label:"Black or African-American"
        },
        {
            value:"",
            label:"Hispanic or Latino"
        }
    ]
    const jobs = [
        {
            value:"",
            label:"Administrative/Clerical"
        },
        {
            value:"",
            label:"Executive/Partner"
        },
        {
            value:"",
            label:"Manager/Supervisor"
        },
        {
            value:"",
            label:"Other"
        }
    ]
    const departments = [
        {
            value:"",
            label:"Customer Service/Care/Support"
        },
        {
            value:"",
            label:"Finance/Accounting"
        },
        {
            value:"",
            label:"Human Resources"
        },
        {
            value:"",
            label:"Information Technology"
        }
    ]
    return(
        <React.Fragment>
            <Box sx={{ mt:2, mb:2}} className={classes.filter_section}>
				<form>
					<div className="flex flex-wrap">
						<Box sx={{ width: 350, margin:'7px 5px' }}>
							<FormControl fullWidth className={classes.formControl}>
								<InputLabel className={classes.input_label}>Length of Service</InputLabel>
								<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value=""
								label="Age"
								size="small"
								>
								{
									(lengthOfServices && lengthOfServices.length) ? lengthOfServices.map((item, index) =>(
										<MenuItem className='text-sm' value={item.value} key={index}>{item.label}</MenuItem>
									)):null
								}
								</Select>
							</FormControl>
						</Box>
                        <Box sx={{ width: 350, margin:'7px 5px' }}>
							<FormControl fullWidth className={classes.formControl}>
								<InputLabel className={classes.input_label}>Generations</InputLabel>
								<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value=""
								label="Age"
								size="small"
								>
								{
									(generations && generations.length) ? generations.map((item, index) =>(
										<MenuItem value={item.value} key={index}>{item.label}</MenuItem>
									)):null
								}
								</Select>
							</FormControl>
						</Box>
                        <Box sx={{ width: 350, margin:'7px 5px' }}>
							<FormControl fullWidth className={classes.formControl}>
								<InputLabel className={classes.input_label}>Gender</InputLabel>
								<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value=""
								label="Age"
								size="small"
								>
								<MenuItem value="">Female</MenuItem>
								<MenuItem value="">Male</MenuItem>
								</Select>
							</FormControl>
						</Box>
                        <Box sx={{ width: 350, margin:'7px 5px' }}>
							<FormControl fullWidth className={classes.formControl}>
								<InputLabel className={classes.input_label}>Ethnic Background</InputLabel>
								<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value=""
								label="Age"
								size="small"
								>
								{
									(ethnics && ethnics.length) ? ethnics.map((item, index) =>(
										<MenuItem value={item.value} key={index}>{item.label}</MenuItem>
									)):null
								}
								</Select>
							</FormControl>
						</Box>
                        <Box sx={{ width: 350, margin:'7px 5px' }}>
							<FormControl fullWidth className={classes.formControl}>
								<InputLabel className={classes.input_label}>Status</InputLabel>
								<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value=""
								label="Age"
								size="small"
								>
								<MenuItem value="">Full-Time</MenuItem>
								<MenuItem value="">Part-Time</MenuItem>
								</Select>
							</FormControl>
						</Box>
                        <Box sx={{ width: 350, margin:'7px 5px' }}>
							<FormControl fullWidth className={classes.formControl}>
								<InputLabel className={classes.input_label}>Job Role</InputLabel>
								<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value=""
								label="Age"
								size="small"
								>
								{
									(jobs && jobs.length) ? jobs.map((item, index) =>(
										<MenuItem value={item.value} key={index}>{item.label}</MenuItem>
									)):null
								}
								</Select>
							</FormControl>
						</Box>
                        <Box sx={{ width: 350, margin:'7px 5px' }}>
							<FormControl fullWidth className={classes.formControl}>
								<InputLabel className={classes.input_label}>Departments</InputLabel>
								<Select
								labelId="demo-simple-select-label"
								id="demo-simple-select"
								value=""
								label="Age"
								size="small"
								>
								{
									(departments && departments.length) ? departments.map((item, index) =>(
										<MenuItem value={item.value} key={index}>{item.label}</MenuItem>
									)):null
								}
								</Select>
							</FormControl>
						</Box>
					</div>
                    <div className="flex justify-end mt-2 mr-3">
                        <Button variant="contained" className="">Apply Filters</Button>
                        <Button variant="outlined" className="ml-2">Clear</Button>
                    </div>
				</form>
			</Box>
        </React.Fragment>
    )
}

export default FilterFormComponent;