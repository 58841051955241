import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={60}
    height={60}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={30} cy={30} r={30} fill="#F5F5F5" />
    <path
      d="M37.549 22.25h-1.523a9.667 9.667 0 1 0-9.352 12.083h10.875a6.042 6.042 0 1 0 0-12.083Z"
      fill="#3F7CFB"
    />
    <path d="M21.799 29.167v14c0 1.66 4 3 9 3s9-1.34 9-3v-14" fill="#FFC955" />
    <path
      d="M21.799 29.167h18v14c0 1.66-4 3-9 3s-9-1.34-9-3v-14Z"
      stroke="#F5F5F5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M39.799 36.167c0 1.66-4 3-9 3s-9-1.34-9-3v-6.5h18v6.5Z"
      fill="#FFC955"
      stroke="#F5F5F5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M30.799 32.167c4.97 0 9-1.343 9-3s-4.03-3-9-3-9 1.343-9 3 4.03 3 9 3Z"
      fill="#FFC955"
      stroke="#F5F5F5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgComponent
