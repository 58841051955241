import Group125 from 'assets/images/temp/Group 125.png'
import Group124 from 'assets/images/temp/Group 124.png'
import { ADD_TO_CART, REMOVE_FROM_CART, CLEAR_CART,
	ADD_FILTER, REMOVE_FILTER,
	ADD_RESYNC,
	TOGGLE_CART
 } from './actions'
const initialState = {
	items: [],
	filters: [],
	resync: false,
	openCart: false
}

const reducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case TOGGLE_CART:
			return {
				...state,
				openCart: payload
			}
		case ADD_TO_CART:
			return {
				...state,
				items: state.items.find(x => x.forPayment == payload.forPayment) ? state.items : [payload, ...state.items]
			}
		case REMOVE_FROM_CART: 
			return {
				...state,
				items: state.items.filter(x => x.forPayment !== payload.forPayment)
			}
		case ADD_FILTER:
			return {
				...state,
				filters: [ payload ]
			}
		case REMOVE_FILTER: 
			return {
				...state,
				filters: state.filters.filter(x => x.id !== payload.id)
			}
		case ADD_RESYNC:
			return {
				...state,
				resync: payload
			}
		case CLEAR_CART:
			return {
				...state,
				items: [],
				filters: [],
				resync: false
			}
		default:
			return state;
	}
}

export default reducer