import { useState, useEffect } from 'react';
import {Box, Button, Typography, IconButton, Modal, Select, MenuItem, InputAdornment, Alert} from '@mui/material';
import { Close, Visibility, VisibilityOff } from '@mui/icons-material';
import { DeleteSVG } from "components/svg-components";
import WhiteTextField from 'components/form-utilities/white-text-field'
import { useDispatch, useSelector } from "react-redux"

export default function EditPassword({form, setForm = ()=>{}, ...props}) {
	const { http } = global.services
	const { user } = useSelector(({user}) => user.user)

	const style = {
	  position: 'absolute',
	  top: '50%',
	  left: '50%',
	  transform: 'translate(-50%, -50%)',
	  width: 400,
	  bgcolor: '#f5f5f5',
	  boxShadow: 24,
	  p: 4,
	};
	const [cred, setCred] = useState({
		key: null,
		otp: null,
	})
	const [ vars, setVars ] = useState({
		oldpassword: "",
		password: "",
		confirmPassword: ""
	});
	const [showPass, setShowPass] = useState({
		oldpassword: false,
		password: false,
		confirmPassword: false
	})
	const [success, setSuccess] = useState(false);
	const [errors, setErrors] = useState(null);
	useEffect(()=>{
		if(form && cred.key == null && cred.otp == null) {
			initFP();
		}
	}, [form]);

	// This is wrong sort of. This sends otp to users
	useEffect(()=>{
		if(success)
			setTimeout(()=>setSuccess(false), 2000);
	}, [success]);

	const initFP = async () => {
		if(user.email) {
			const { data } = await http.post('user/forgot-password', { email: user.email })
			setCred(data.data)
		}
	}

	const handleSubmit = async (e) => {
		e.preventDefault();
		if(vars.password != vars.confirmPassword) {
			setErrors("Password does not match")
			return;
		}

		const { data } = await http.put('user/forgot-password', { ...cred, password: vars.password })
		setSuccess(true);
	}


	const handleForm = ({target}) => {
		setErrors(null)
		setVars({ ...vars, [target.name]: target.value })
	}

	return (<Modal
	  open={form}
	  onClose={()=>setForm(false)}
	  aria-labelledby="role-delete-title"
	  aria-describedby="role-delete-description"
	>
		<form onSubmit={handleSubmit}>
	  <Box sx={style}>
		<Typography id="role-form"
			variant="h6"
			component="span"
			fontWeight="600"
			display="block"
			className="text-center mt-6"
		>
			<IconButton
		    aria-label="close"
		    onClick={()=>setForm(false)}
		    sx={{ position: 'absolute', right: 8, top: 8 }}
		  >
		    <Close />
		  </IconButton>
		Edit Password
		</Typography>
		<Box>
			<WhiteTextField
				error={errors}
				id="email-outlined-basic"
				className="w-full my-5 white-field"
				variant="outlined"
				placeholder="Enter Old Password"
				value={ vars.oldpassword }
				type={showPass.oldpassword ? "text" : "password"}
				name="oldpassword"
				onChange={ handleForm }
				autoComplete="off"
				required
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
				        <IconButton
				          aria-label="toggle password visibility"
				          onClick={()=>setShowPass({...showPass, oldpassword: !showPass.oldpassword })}
				        >
				          {showPass.oldpassword ? <Visibility /> : <VisibilityOff />}
				        </IconButton>
				      </InputAdornment>
					)
				}}
			/>
			<WhiteTextField
				error={errors}
				id="email-outlined-basic"
				className="w-full mb-5 white-field"
				variant="outlined"
				placeholder="Enter New Password"
				value={ vars.password }
				type={showPass.password ? "text" : "password"}
				name="password"
				onChange={ handleForm }
				autoComplete="off"
				required
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
				        <IconButton
				          aria-label="toggle password visibility"
				          onClick={()=>setShowPass({...showPass, password: !showPass.password })}
				        >
				          {showPass.password ? <Visibility /> : <VisibilityOff />}
				        </IconButton>
				      </InputAdornment>
					)
				}}
			/>
			<WhiteTextField
				error={errors}
				id="email-outlined-basic"
				className="w-full mb-5 white-field"
				variant="outlined"
				placeholder="Enter Confirmed Password"
				value={ vars.confirmPassword }
				type={showPass.confirmPassword ? "text" : "password"}
				name="confirmPassword"
				onChange={ handleForm }
				autoComplete="off"
				required
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
				        <IconButton
				          aria-label="toggle password visibility"
				          onClick={()=>setShowPass({...showPass, confirmPassword: !showPass.confirmPassword })}
				        >
				          {showPass.confirmPassword ? <Visibility /> : <VisibilityOff />}
				        </IconButton>
				      </InputAdornment>
					)
				}}
			/>
		</Box>
		{
			errors && <Box className="mb-2">
			<Alert variant="filled" severity="error">
				{ errors }
			</Alert>
			</Box>
		}
		{
			success && <Box className="mb-2">
			<Alert variant="filled" severity="success">
				Password has been reset!
			</Alert>
			</Box>
		}
		<Box className="flex flex-col">
			<Button
				variant="contained"
				onClick={handleSubmit}
				color="error"
				className="normal-case rounded-none"
				sx={{ margin: "15px 0"}}
			>
				Save Changes
			</Button>
			<Button
				variant="text"
				onClick={()=>setForm(false)}
				className="normal-case rounded-none block text-center"
			>
				Cancel
			</Button>
		</Box>
	  </Box>
		</form>
	</Modal>)
}