import { useState, useRef, useEffect } from "react"
import { 
	Box, 
	Button, 
	MenuItem, 
	Popper, 
	Grow, 
	Paper, 
	ClickAwayListener, 
	MenuList } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from "react-router";
const useStyles = makeStyles({
	root: {
		boxShadow: "0 0 0 rgb(0 0 0 / 70%)",
	}
})

const MenuFilter = ({ item, onClick }) => {
	const handleClick = (e) => onClick(item)
	return <MenuItem
		className='text-sm'
		onClick={handleClick}
	>
		<span className="pr-10">{item?.title}</span>
	</MenuItem>
}

export default function ReportPageDropdown ({ pages = [] }) {
	const [showMenu, setShowMenu] = useState(false)
	const [selected, setSelected] = useState(null)
	const history = useHistory();
	const classes = useStyles();
	const ref = useRef();

	const handleMenu = (open = false) => setShowMenu(open)

	useEffect(() => {
		const { pathname } = history.location;
	    const page = pages.find(i => i.path == pathname)
	    if(page) setSelected(page)
	}, [])

	const handleClick = (item) => {
		setShowMenu(false)
		setSelected(item)
		if(item.path) {
			history.push(item.path);
		}
	}

	if(pages.length == 0)
		return '';

	return <div
		className={`relative filter-dropdown bg-white`}
		ref={ref}
		onClick={(event) => {
			if (!showMenu) {
				setShowMenu(true)
			}
		}}
		style={{minWidth: 200, maxWidth: 400}}
	>
		<div
			className={`flex flex-row justify-between items-center px-5 py-2 space-x-7 cursor-pointer`}
		>
			<span className='text-sm'>{selected?.title || `Select Report`}</span>
			<i className='bx bx-chevron-down'></i>
		</div>
		<Popper
			open={showMenu}
			anchorEl={ref?.current}
			transition
			disablePortal
			style={{ position: 'absolute', zIndex: 100, transform: 'translate(0px, 45px)', width: '100%' }}
		>
			{({ TransitionProps, placement }) => (
				<Grow
					{...TransitionProps}
					style={{
						transformOrigin: 'left'
					}}
				>
					<Paper variant="outlined" style={{ borderRadius: 0, width: 'fit-content' }}>
						<ClickAwayListener onClickAway={() => setShowMenu(false)}>
							<MenuList id="split-button-menu" className={`${classes.root}`}>
								{
									pages.length > 0 && 
									pages.map(i => <MenuFilter key={i.id} item={i} onClick={handleClick} />)
								}
							</MenuList>
						</ClickAwayListener>
					</Paper>
				</Grow>
			)}
		</Popper>
	</div>
}
