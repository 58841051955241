import React from 'react'
import { useHistory } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { Button } from '@mui/material'
import ReportItemLarge from './report-item-large'
import ReportGridItem from './report-grid-item'
import ReportFDD from './report-fdd'
import ReportSortedVerbatim from './report-sorted-verbatim'
import ReportResync from './report-resync'

import { addToCart } from 'redux/modules/cart/actions';


const ReportStoreList = ({
	showGoToStoreLink = false
}) => {
	const { store, pending, fdd } = useSelector(({report}) => report )
	const { user: mainUser} = useSelector(({user}) => user)
	const { selectedProgramId } = useSelector(({ app }) => app);
	const { userData } = mainUser || {}
	const organizationProgram = userData?.organizationProgram || [];
	const projectId = userData?.projectId || {};
	const programSelected = organizationProgram?.find(i => i?.programId?._id == selectedProgramId) || organizationProgram[0]
	const programId = programSelected?.programId || {};

  const hasPaidFDD = programSelected?.FDD_Payment_Details !== null;
  const recentlyBought = programSelected?.FDD_Payment_Type !== null;
  const hasReport = ['WFR_Access', 'EV_Access', 'WBC_Access', 'BBP_Access'].find(i => (programSelected[i] || '').trim().toLowerCase() === 'yes');
  const fdd_fee = programSelected?.FDD_Fee || 0;

	const check = (access = "") => (programSelected[access] || '').toLowerCase() !== 'yes';
	const isYes = (access = "") => (programSelected[access] || '').toLowerCase() === 'yes';
		
	const filtered = React.useMemo(()=>{
		if(!programSelected)
			return [];
		return store
			.filter(i=>!i.hideStore) // Hide basic items
			//Hide if accessable already // works for single reports
			.filter(i => i.access && check(i.access))
			//Hide if at least bought
			.filter(i => i.paymentType != null && programSelected[i.paymentType] == null)
			// If this is a group report check if one is active. //Depreciated but continue
			.filter(i => i.accessGroup ? !i.accessGroup?.find(isYes) : true)
			// Attach if accessible
			.map(i=>{
				const priceString = i.forPayment + "_Fee"; //(i.access || "").replace('Access', 'Fee'); The right part is applicable only for ungrouped reports.
				return {
					...i,
					isPurchased: i.paymentDetail != null && programSelected[i.paymentDetail],
					amount: programId[priceString] || null,
				}
			})
	}, [ programSelected ]);

	const dispatch = useDispatch()
	const history = useHistory()
	//Depreciated?
	const handleAddToCart = (item) => {
		dispatch(addToCart(item))
	}

	const renderItemOption = (item) => {

		if (item.comingSoonAt)
			return (
				<div className='flex flex-row items-center justify-end'>
					<span className='mt-2 text-sm'>Available in { item.comingSoonAt }</span>
				</div>
			) 

		if (item.isPurchased)
			return (
				<div className='flex flex-row items-center justify-end'>
					<span className='text-red-10 cursor-pointer text-sm' onClick={ () => history.push(item.redirectTo) }>More Info</span>
					<i className='bx bx-chevron-right text-xl'/>
				</div>
			)

		return (
			<div className='flex flex-row items-center justify-start'>
				<Button
					variant="contained"
					type="submit"
					color="error"
					className="normal-case rounded-none px-5"
					onClick={()=>handleAddToCart(item)}
				>
					Add to Cart
				</Button>
				<Button
					variant="text"
					className="normal-case rounded-none mx-5 px-5 text-black-0 text-center"
					onClick={ () => history.push(item.redirectTo) }
				>
					Demo
				</Button>
			</div>
		)
	}

	return (
		<React.Fragment>

			<div className='flex flex-row items-center mb-8'>
				<div className='flex-1'>
					<p className='font-bold text-black-0 text-2xl'>Reports Store</p>
				</div>
				{
					showGoToStoreLink && (
						<div className='flex flex-row items-center justify-end cursor-pointer text-red-10' onClick={ () => history.push(`/reports-store`) }>
							<span className=''>View Reports Store</span>
							<i className='bx bx-chevron-right ml-2 text-2xl'></i>
						</div>
					)
				}
			</div>

			<div className="grid grid-cols-4 gap-5">
				{ !(hasPaidFDD || hasReport || recentlyBought) && <ReportFDD item={{...fdd, amount: fdd_fee }} classGrid='col-span-4'/>
				}
				<ReportSortedVerbatim classGrid='col-span-2'/>
				{
					filtered
						.map((item, index) => <ReportGridItem item={item} classGrid={item.id == 0 ? 'col-span-4': 'col-span-2'} index={index} key={`store-item-${index}`} />)
				}
				<ReportResync classGrid='col-span-2'/>
				{
					pending.map((item, index) => (
						<div key={`store-pending-item-${index}`} className={`w-full h-full flex flex-col bg-white p-5 relative`}>
							<img src={item.img} className="w-12 h-12 rounded-full mb-2"/>
							<h2 className='text-md text-black-0 font-bold line-clamp-2 h-12 mt-4 mb-3'>{ item.title }</h2>

							<div className='mb-5 mr-5 bottom-0 right-0 absolute'>
							{ renderItemOption(item) }
							</div>
						</div>
					))
				}
			</div>
		</React.Fragment>
	)

}

export default ReportStoreList
