import React from 'react'
import { Typography } from '@mui/material';
import {useStyles} from './cardStyle.js';
import StackGrid from "react-stack-grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";


export default function Card ({title = '', classes = {}, onChange , data = [] }) {
	return (<Accordion className="m-3" onChange={onChange}>
      <AccordionSummary
       aria-controls="panel1a-content"
       expandIcon={<ExpandMoreIcon color="white"/>}
       sx={{
        background: "linear-gradient(to left, #EC413D, #FF683A)",
        color: "#fff",
        '& .MuiAccordionSummary-expandIconWrapper': {
            borderRadius: '100px',
            background: '#ffffff35',
        },
        '&.Mui-expanded': {
            minHeight: '48px',
        },
        '& .MuiAccordionSummary-content.Mui-expanded': {
            margin: '12px 0 !important'
        }
        }}
      >
       <Typography className="text-sm font-bold mx-auto">{title}</Typography>
      </AccordionSummary>
      <AccordionDetails className="pl-5">
       <ul className={classes.ul}>
       {
    	  data.map((i,k)=>(<li className="flex flew-row my-4" key={'question-'+k}>
	         <span className="font-bold text-sm w-10 w-10">{i.value || i.Count}</span>
	         <span className="text-sm">{i.label || i.Caption}</span>
	        </li>))
       }
       </ul>
      </AccordionDetails>
     </Accordion>)
}