import React from "react";
import { Button, Avatar } from "@mui/material";
import { UploadFile  } from '@mui/icons-material';

export default function DeleteButton ({ onClick=()=>{}, deleteText="Delete Report" }) {
  return (
    <Button
      variant="contained"
      color="error"
      sx={{
        fontSize: "14px",
        borderRadius: "0 !important",
      }}
      onClick={onClick}
    >
      {deleteText}
    </Button>
  );
};
