import React from 'react'

import BreadcrumbComponent from '../../../../../components/breadcrumb'

import Content from './pages/content'

const EmailPage = () => {

	const navs = [
        {label: 'Email', path: '/admin/email'},
    ]

	return (
		<div>
			<BreadcrumbComponent items={navs}/>

			<div className='py-8'>
				<h1 className='font-bold text-2xl'>Email</h1>
			</div>

			<div className='pb-8'>
				<h1 className='font-bold text-2xl'>Campaigns</h1>
			</div>

			<Content />
		</div>
	)

}

export default EmailPage