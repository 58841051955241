import React from "react";
import { Menu, MenuItem, Button } from "@mui/material";
import Fade from "@mui/material/Fade";

const DownloadCustomFile = ({ downloadables = [] }) => {
  // take data, filter by filters, and prompt user to choose type of download
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => setAnchorEl(event.currentTarget);
  const handleClose = () => setAnchorEl(null)

  const handleMenuItemClick = (item) => {
    const dlFile = document.createElement("a");
    dlFile.download = item.filename;
    dlFile.href = item.fileUrl;
    dlFile.target = "_blank";
    dlFile.click();
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        variant="contained"
        sx={{width: '140px'}}
        className='rounded-none py-2 px-4 text-sm bg-red-0'
        onClick={handleClick}
      >
        Download
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {
          downloadables.map(i => <MenuFile key={i._id} file={i} handleClick={handleMenuItemClick}/>)
        }
      </Menu>
    </>
  );
};

const MenuFile = ({ file = {}, handleClick = () => {} }) => {
  console.log(file)
  const handleMenuItemClick = () => handleClick(file)
  const pop = file.fileName?.split('.').pop().toUpperCase();
  return <MenuItem onClick={handleMenuItemClick}>
    {pop}
  </MenuItem>
}

export default DownloadCustomFile;
