import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Button } from "@mui/material";
import Alert from "@mui/material/Alert";
import Skeleton from "@mui/material/Skeleton";
import IconSortingPurpleBG from "assets/images/icons/icon-sorting-purple-bg.png";
import ReportsHeader from "../../../components/reports-header";
import { useSelector } from "react-redux";
import PurchaseFilter from "../../components/purchase-filter";
import Filters from "../../components/filters";
import { useLocation, useHistory } from 'react-router-dom'

const EmployeeVerbatimsDetails = () => {
  const download = {
    data: "",
    types: ["XLS", "PDF"],
  };
  const location = useLocation();
  const history = useHistory();
  const { http } = global.services;
  const routeParams = useParams();
  const [loading, setLoading] = useState(false);
  const [filterData, setFilterData] = useState({});
  const [state, setState] = useState({
    // filterData: {},
    loading: true,
    loaded: false,
    responses: [],
    questionDetails: null,
  });
  const [dataError, setDataError] = useState("");
  const [isPurchaseFilter, setIsPursechaseFilter] = useState(false);
  const [loadedWithFilters, setLoadedFilter] = useState(location.search != "" ? true : false);
  const [questionId, setFilterId ] = useState(null);
  // const { EVFilter } = useSelector(({report}) => report)
  const {user, admin} = useSelector(({user}) => user);
  const { selectedProgramId, clearcache } = useSelector(({ app }) => app);

  const { organizationProgram } = user.userData ? user.userData : user.user;
  const selectOrgProgram =
    organizationProgram?.find((i) => i.programId?._id == selectedProgramId) ||
    organizationProgram[0];
  const programId = selectedProgramId || selectOrgProgram?.programId?._id;
  const EVFilter = selectOrgProgram?.EV_Sorting_Filter || null;
  const EVFilterDate = selectOrgProgram?.EV_Sorting_Payment_Details || null;
  //Will always run once per mount
  useEffect(() => {
    if(location.search != "") {
      // handleApplyFilter(new Event('Initiate'))
    } else {
      initializations(); // will run before filtered
    }
  }, [selectedProgramId]);
  // Will always run per change of data.
  useEffect(() => {
    if(loadedWithFilters && Object.keys(filterData).length === 0) {
      initializations()
      setLoadedFilter(false)
    }
  }, [loadedWithFilters, filterData]);

  const initializations = async () => {
    let query = {};
    var { organizationProgram } = user.userData ? user.userData : user.user;
    setLoading(true)
    let params = {
      questionId: routeParams.slug,
      selectedProgramId: programId,
    };
    if (clearcache) params.clearCache = clearcache;
    if ( (selectOrgProgram["EV_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) params.isDummy = true;
    const paramQuery = new URLSearchParams(params).toString();
    if (filterData !== null) {
      query.queryFilter = { ...filterData };
    }
    let response;
    try {
      response = await http.post(
        `client/getOpenResponsesAnswers?${paramQuery}`,
        { ...query }
      );
    } catch (err) {
      setLoading(false)
      setDataError(
        err.response.data?.message ||
          `Failed with status: ${err.response.status} ${err.response.statusText}`
      );
      return;
    }
    setLoading(false)
    setState((prevState) => ({
      ...prevState,
      loaded: true,
      responses: response.data.data.respondentData,
      questionDetails: response.data.data.queryQuestion,
    }));
    setDataError("");
  };

  const handleApplyFilter = (event) => {
    event.preventDefault();
    if (!state.loaded) {
      initializations(); //rerunning with filters
      setLoadedFilter(true);
    }
  };

  const onChangeFilter = (data) => {
    setFilterData(data)
    setState((prevState) => ({ ...prevState, loaded: false }));
  }

  return (
    <div id="open-response-cap-details">
      <ReportsHeader
        download={download}
        pageName="Question Details"
        verbatimResults
      />
        <div className="mt-8">
          {(EVFilter && EVFilterDate) && (
            <div className="my-10">
              <Filters
                setFilterId={setFilterId}
                runFilters={handleApplyFilter}
                onChange={onChangeFilter}
              />
            </div>
          )}

      { loading ? (
        <div className="mt-8">
          <Skeleton animation="wave" height={100} />

          <Skeleton animation="wave" height={50} />
          <Skeleton animation="wave" height={50} />
          <Skeleton animation="wave" height={50} />
          <Skeleton animation="wave" height={50} />
          <Skeleton animation="wave" height={50} />
        </div>
        ) : 
          dataError ? (<div className="mt-5">
              <Alert variant="filled" severity="error">
                {dataError}
              </Alert>
            </div>
          ) : (
            <>
              <div className="p-5 bg-white border-l-4 border-red-0">
                <p className="font-bold">{state.questionDetails?.caption}</p>
              </div>
              <div className="px-5 bg-white">
                {state.responses.map((answer, index) => (
                  <div
                    key={`answer-${index}`}
                    className="border-b border-gray-50 px-3 py-5 flex flex-row"
                  >
                    <p className="font-bold text-xl italic mr-3 text-gray-40">
                      "
                    </p>
                    <p className="text-gray-40">{answer.responses.Value}</p>
                  </div>
                ))}
              </div>
            </>
          )
        }
        </div>
    </div>
  );
};

export default EmployeeVerbatimsDetails;
