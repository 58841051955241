import { SET_BOUGHT_REPORTS, SET_AVAILABLE_REPORTS, SET_RESYNC_INFO, SET_EV_FILTER } from './actions'
import { checkIsUk } from 'helpers/checkIsUK'

import ReportStoreImg1 from 'assets/images/icons/Icon1.png'
import ReportStoreImg2 from 'assets/images/icons/Icon2.png'
import ReportStoreImg3 from 'assets/images/icons/Icon3.png'
import ReportStoreImg4 from 'assets/images/icons/Icon4.png'
import ReportStoreImg5 from 'assets/images/icons/Icon5.png'
import ReportStoreImg6 from 'assets/images/icons/Icon6.png'
import ReportStoreImg7 from 'assets/images/icons/Icon7.png'
import ReportStoreImg8 from 'assets/images/icons/Icon8.png'
import ReportStoreImg15 from 'assets/images/icons/Icon15.png'

const initialState = {
	items: [],
	fdd: {
		id: 0,
		title: 'Employee Feedback Data Dashboard',
		description: ()=> (<>This report provides the percentage of winning and non-winning {checkIsUk() ? 'organisations' : 'organizations'} that offer various employee benefits and workplace practices.</>),
		img: ReportStoreImg15,
		amount: 1000,
		access: 'FDD_Access',
		accessGroup: [
			'WFR_Access',
			'EV_Access',
			'WBC_Access',
			'BBP_Access',
		],
		isPurchased: false,
		paymentDetail: 'FDD_Payment_Details',
		paymentType: 'FDD_Payment_Type',
		forPayment: 'FDD',
		comingSoonAt: null,
		redirectTo: '/reports-sample',
		demo: true
	},
	store: [
	{
		id: 1,
		title: 'Workforce Feedback Results',
		description: 'This quantitative report reflects the perceptions of respondents to each question on the employee survey, both system-wide and segmented within various demographics of the population.',
		img: ReportStoreImg1,
		amount: 1000,
		isPurchased: false,
		access: 'WFR_Access',
		paymentDetail: 'FDD_Payment_Details',
		paymentType: 'FDD_Payment_Type',
		forPayment: 'FDD',
		hideStore: true,
		comingSoonAt: null,
		redirectTo: '/employee-response-breakdown',
		demo: true
	},
	{
		id: 2,
		title: 'Employee Verbatims',
		description: 'Any responses to open-ended survey questions are contained in this report.',
		img: ReportStoreImg2,
		amount: 1000,
		access: 'EV_Access',
		paymentDetail: 'FDD_Payment_Details',
		paymentType: 'FDD_Payment_Type',
		forPayment: 'FDD',
		isPurchased: false,
		hideStore: true,
		comingSoonAt: null,
		redirectTo: '/employee-verbatims',
		demo: true
	},
	{
		id: 3,
		title: 'Workforce Benchmark Comparisons',
		description: ()=>(<>This report averages the percentage of employees’ positive responses to each survey question from all participating {checkIsUk() ? 'organisations' : 'organizations'}, presented in aggregate by all competitors that did and did not make the list.</>),
		img: ReportStoreImg3,
		amount: 1000,
		access: 'WBC_Access',
		paymentDetail: 'FDD_Payment_Details',
		paymentType: 'FDD_Payment_Type',
		forPayment: 'FDD',
		isPurchased: false,
		hideStore: true,
		comingSoonAt: null,
		redirectTo: '/workforce-benchmark-comparisons',
		demo: true
	},
	{
		id: 4,
		title: 'Benefits & Best Practices',
		description: ()=>(<>This report provides the percentage of winning and non-winning {checkIsUk() ? 'organisations' : 'organizations'} that offer various employee benefits and workplace practices.</>),
		img: ReportStoreImg4,
		amount: 1000,
		access: 'BBP_Access',
		paymentDetail: 'FDD_Payment_Details',
		paymentType: 'FDD_Payment_Type',
		forPayment: 'FDD',
		isPurchased: false,
		hideStore: true,
		comingSoonAt: null,
		redirectTo: '/benefits-and-best-practices',
		demo: true
	},
	{
		id: 5,
		title: 'Key Impact Analysis',
		// description: 'huuh?',
		description: () => (<>
					This report identifies key motivators of employee engagement within your unique population. This information is vital to knowing what workplace attributes are most important to retain your top talent and drive high productivity among all staff.
					<br /><br />
					<span className='font-semibold'>We recommend a minimum of 100 employee responses to ensure valuable reporting.</span>
					<br /><br />
					<span className='font-italic'>Note: Report will be available within 7 to 10 business days of payment receipt.</span>
				</>),
		img: ReportStoreImg5,
		amount: 1000,
		access: 'KIA_Access',
		paymentDetail: 'KIA_Payment_Details',
		paymentType: 'KIA_Payment_Type',
		forPayment: 'KIA',
		isPurchased: false,
		comingSoonAt: null,
		redirectTo: '/key-impact-analysis',
		demo: true
	},
	{
		id: 6,
		title: 'Response Detail Report',
		// description: 'huuh?',
		description: () => (<>
					This in-depth report reflects, by each survey question and for each demographic, the percentage of responses distributed across the entire 6-point scale.
					<br /><br />
					<span className='font-semibold'>We recommend a minimum of 50 employee responses to ensure valuable reporting.</span>
					<br /><br />
					<span className='font-italic'>Note: Upon successful processing of a credit card payment, you’ll receive instant access to this report.</span>
				</>),
		img: ReportStoreImg6,
		amount: 1000,
		access: 'RD_Access',
		paymentDetail: 'RD_Payment_Details',
		paymentType: 'RD_Payment_Type',
		forPayment: 'RD',
		isPurchased: false,
		comingSoonAt: null,
		redirectTo: '/response-detail',
		demo: true
	},
	{
		id: 7,
		title: 'Custom Reports',
		description: () => <>In addition to the standard reporting package offered, more in depth reporting is available. For more information about advanced reporting, please contact <a href="mailto:SurveyPro@workforcerg.com">SurveyPro@workforcerg.com</a></>,
		img: ReportStoreImg8,
		amount: null,
		access: 'CR_Access',
		paymentDetail: 'CR_Payment_Details',
		paymentType: 'CR_Payment_Type',
		forPayment: 'CR',
		isPurchased: false,
		comingSoonAt: null,
		redirectTo: '/custom-reporting'
	}],
	pending: [
	// {
	// 	id: 8,
	// 	title: 'National, Industry-Specific, and Custom Benchmarks',
	// 	description: 'In addition to the two Benchmark Reports offered in the standard Feedback Report Package, reports by industry or custom geography may also be available.',
	// 	img: ReportStoreImg7,
	// 	amount: 1000,
	// 	isPurchased: false,
	// 	comingSoonAt: 2023,
	// 	redirectTo: '/dashboard'
	// }
	// {
	// 	id: 9,
	// 	title: 'Sorted Employee Verbatims',
	// 	description: 'Sorting the employees’ open-ended responses by a demographic will allow you to better identify where the comments originated.',
	// 	img: ReportStoreImg8,
	// 	amount: 1000,
	// 	isPurchased: false,
	// 	comingSoonAt: 2023,
	// 	redirectTo: '/dashboard'
	// }
	],
	resynced: false,
	resyncing: false,
	EVFilter: null,
}

const reducer = (state = initialState, {type, payload}) => {
	switch (type) {
		case SET_BOUGHT_REPORTS:
			return {
				...state,
				items: [...state.items, ...payload]
			}
		case SET_AVAILABLE_REPORTS:
			return {
				...state,
				store: payload
			}
		case SET_RESYNC_INFO:
			return {
				...state,
				...payload
			}
		case SET_EV_FILTER: 
			return {
				...state,
				EVFilter: payload
			}
		default:
			return state;
	}
}

export default reducer
