export const MailData = [
    {
        id: 1,
        name: 'Campaign Name',
        status: 'active',
        total_subscriber: 247,
    },
    {
        id: 2,
        name: 'Campaign Name',
        status: 'active',
        total_subscriber: 247,
    },
    {
        id: 3,
        name: 'Campaign Name',
        status: 'active',
        total_subscriber: 247,
    },
    {
        id: 4,
        name: 'Campaign Name',
        status: 'planning',
        total_subscriber: 247,
    },
    {
        id: 5,
        name: 'Campaign Name',
        status: 'expired',
        total_subscriber: 247,
    },
]