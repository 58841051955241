import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={60}
    height={60}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={30} cy={30} r={30} fill="#F5F5F5" />
    <path
      d="M24.557 24.166c.029-.213.043-.432.043-.666 0-.227-.014-.453-.05-.666l1.438-1.119c.128-.099.163-.29.085-.432l-1.36-2.352c-.085-.155-.262-.205-.418-.155l-1.693.68a5.004 5.004 0 0 0-1.147-.666l-.255-1.8a.343.343 0 0 0-.34-.29h-2.72c-.17 0-.305.12-.333.29l-.255 1.8c-.418.17-.8.404-1.148.666l-1.692-.68a.338.338 0 0 0-.418.155l-1.353 2.352c-.085.149-.057.333.085.432l1.438 1.12a4.147 4.147 0 0 0-.064.665c0 .22.014.453.05.666l-1.438 1.12a.348.348 0 0 0-.085.431l1.36 2.352a.346.346 0 0 0 .417.156l1.693-.68c.355.269.73.496 1.148.666l.255 1.799c.035.17.17.29.34.29h2.72a.33.33 0 0 0 .333-.29l.255-1.8c.418-.17.8-.396 1.147-.665l1.693.68c.156.056.333 0 .418-.156l1.36-2.352c.085-.156.05-.333-.085-.432l-1.424-1.119ZM19.5 26.05a2.557 2.557 0 0 1-2.55-2.55 2.557 2.557 0 0 1 2.55-2.55 2.557 2.557 0 0 1 2.55 2.55 2.557 2.557 0 0 1-2.55 2.55Z"
      fill="#9C5EFB"
    />
    <path
      d="M38.95 40.783c.033-.25.05-.508.05-.783a4.81 4.81 0 0 0-.058-.783l1.691-1.317a.409.409 0 0 0 .1-.508l-1.6-2.767a.407.407 0 0 0-.491-.183l-1.992.8a5.884 5.884 0 0 0-1.35-.784l-.3-2.116a.403.403 0 0 0-.4-.342h-3.2c-.2 0-.358.142-.392.342l-.3 2.116c-.491.2-.941.475-1.35.784l-1.991-.8a.398.398 0 0 0-.492.183l-1.592 2.767c-.1.175-.066.391.1.508l1.692 1.317c-.042.25-.075.525-.075.783s.017.533.058.783L25.367 42.1a.409.409 0 0 0-.1.508l1.6 2.767c.1.183.308.242.491.183l1.992-.8c.417.317.858.584 1.35.784l.3 2.116c.042.2.2.342.4.342h3.2c.2 0 .367-.142.392-.342l.3-2.116c.491-.2.941-.467 1.35-.784l1.991.8c.184.067.392 0 .492-.183l1.6-2.767c.1-.183.058-.391-.1-.508l-1.675-1.317ZM33 43c-1.65 0-3-1.35-3-3s1.35-3 3-3 3 1.35 3 3-1.35 3-3 3Z"
      fill="#FFC955"
    />
    <path
      d="M45.557 20.166c.029-.213.043-.432.043-.666 0-.227-.014-.453-.05-.666l1.438-1.119c.128-.099.163-.29.085-.432l-1.36-2.352c-.085-.155-.262-.205-.418-.155l-1.693.68a5.004 5.004 0 0 0-1.147-.666l-.255-1.8a.343.343 0 0 0-.34-.29h-2.72c-.17 0-.305.12-.333.29l-.255 1.8c-.418.17-.8.404-1.148.666l-1.692-.68a.338.338 0 0 0-.418.155l-1.353 2.352c-.085.149-.057.333.085.432l1.438 1.12a4.147 4.147 0 0 0-.064.665c0 .22.014.453.05.666l-1.438 1.12a.348.348 0 0 0-.085.431l1.36 2.352c.085.156.262.205.418.156l1.692-.68c.355.269.73.496 1.148.666l.255 1.799c.035.17.17.29.34.29h2.72a.33.33 0 0 0 .333-.29l.255-1.8c.418-.17.8-.396 1.147-.665l1.693.68c.156.056.333 0 .418-.156l1.36-2.352c.085-.156.05-.333-.085-.432l-1.424-1.119ZM40.5 22.05a2.557 2.557 0 0 1-2.55-2.55 2.557 2.557 0 0 1 2.55-2.55 2.557 2.557 0 0 1 2.55 2.55 2.557 2.557 0 0 1-2.55 2.55Z"
      fill="#00A46A"
    />
  </svg>
)

export default SvgComponent
