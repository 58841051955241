export default function ArrowNextSVG(){
	return (
		<svg
		    xmlns="http://www.w3.org/2000/svg"
		    width={8}
		    height={14}
		    fill="none"
		  >
		    <path
		      d="M1.25 12.5 6.75 7l-5.5-5.5"
		      stroke="#162630"
		      strokeWidth={1.5}
		      strokeLinecap="square"
		    />
		</svg>
	)
}