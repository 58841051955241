import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Box, Typography, Button, Paper, Divider } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import GreeenIcon from "assets/images/icons/Icongreen.png";
import RedIcon from "assets/images/icons/Iconred.png";
import ReportsHeader from "../../components/reports-header";
import ReportDescription from "../../components/report-description";

import CarouselSummary from "../components/carousel-summary";
import CategoryCarousel from "./components/category-carousel";
import GroupedBarGraphTable from "./components/grouped-bar-chart-table";

import { makeStyles } from "@material-ui/core/styles";
import DownloadIcon from "assets/images/icons/download.png";
import { Avatar } from "@mui/material";
import GenericFilter from "./components/comparison-filter";
import TableBarChart from "../components/table-bar-chart";
import SectionLoading from "components/loaders/SectionLoading";
import { useSelector, useDispatch } from "react-redux";
import AppAction from "redux/modules/app/actions";
import useAccessible from 'components/hooks/useSideBarAccessible';
import PageDropdown from "../../components/page-dropdown";

const useStyles = makeStyles({
  heading: {
    width: "200px",
    flexWrap: "wrap",
    whiteSpace: "normal !important",
  },
  p: {
    position: "relative",
  },
  dot: {
    position: "absolute",
    top: "8px",
  },
});

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const pages = [{
  "id": '/workforce-benchmark-comparisons',
  "path": "/workforce-benchmark-comparisons",
  "title": "Benchmark Data"
},{
  "id": 'comparison-data',
  "path": "/comparison-data",
  "title": "Comparison Data"
}]

const WorkforceComparisonsData = () => {
  const { http } = global.services;
  const classes = useStyles();
  const history = useHistory();
  const isAvailable = useAccessible('WBC_Access');
  const {user, admin} = useSelector(({user}) => user);
  const { selectedProgramId, timeEstimate, clearcache } = useSelector(({ app }) => app);
  const { organizationProgram } = user.userData ? user.userData : user.user;
  const selectOrgProgram = organizationProgram.find(i => i.programId?._id == selectedProgramId) || organizationProgram[0];
  const programId = selectedProgramId || selectOrgProgram?.programId?._id;

  const [selectedCategory, selectCategory] = useState(null);
  const [sectionLoadingVisible, setSectionLoadingVisible] = useState(false);
  const [compareDataSet, setCompareDataSet] = useState({
    loaded: false,
    data: [],
  });

  const [categoriesQuestions, setTable] = useState({
    loaded: false,
    data: [],
  });

  const [state, setFilter] = useState({ filter: null, loaded: false });
  const [loadedWithFilters, setLoaded] = useState(false);

  useEffect(() => {
    if (loadedWithFilters && state.filter == null) {
      reloadMain(); //rerunning with filters
      setTable({ ...categoriesQuestions, loaded: false }); // hide old table first
      setLoaded(false); //make sure this runs once if triggered
    }
  }, [loadedWithFilters, state]);

  useEffect(() => {
    reloadMain();
  }, [selectedProgramId]);

  useEffect(() => {
    if (selectedCategory) {
      loadCategoryQuestions();
    }
  }, [selectedCategory]);

  const reloadMain = () => {
    setSectionLoadingVisible(true);
    loadCompareDataSet();
  };

  const categoryMap = {
    AllYes: "All Winners",
    AllNo: "All Non-Winners",
    LargeYes: "Large Winners",
    LargeNo: "Large Non-Winners",
    MediumYes: "Medium Winners",
    MediumNo: "Medium Non-Winners",
    SmallYes: "Small Winners",
    SmallNo: "Small Non-Winners",
    MajorYes: "Major Winners",
    MajorNo: "Major Non-Winners"
  };

  const loadCompareDataSet = async () => {
    let query = {};
    let params = {
      selectedProgramId: programId
    }
    if(clearcache) params.clearCache = clearcache;
    if( (selectOrgProgram["WBC_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) params.isDummy = true;
    const paramsString = new URLSearchParams(params).toString()
    setCompareDataSet({ loaded: false, data: [] });
    if (state.filter) query.selectedCategoryOption = state.filter;
    try {
      const { data } = await http.post(
        `/client/employeeSectionComparisonWithMeReport?${paramsString}`,
        query
      );
      const newData = (data.data?.categoryResponse || []).map((i) => {
        return {
          name: i.category,
          data: [
            {
              data: {
                data: [
                  {
                    field: "Your Results",
                    value: (i.currentOrg / 100),
                  },
                ],
                metaConfig: {
                  colors: ["#9C5EFB"],
                  fields: [{ field: "Your Results", title: "Your Results" }],
                },
              },
            },
            {
              data: {
                data: [
                  {
                    field:
                      categoryMap[state.filter] ||
                      "All Winners",
                    value: (i.otherOrg / 100),
                  },
                ],
                metaConfig: {
                  colors: ["#00A46A"],
                  fields: [
                    {
                      field:
                        categoryMap[state.filter] ||
                        "All Winners",
                      title:
                        categoryMap[state.filter] ||
                        "All Winners",
                    },
                  ],
                },
              },
            },
          ],
        };
      });
      setCompareDataSet({ loaded: true, data: newData });
      setSectionLoadingVisible(false);
    } catch (err) {
      console.log(err);
      setSectionLoadingVisible(false);
      setCompareDataSet({ loaded: true, data: [] });
      return;
    }
  };

  const loadCategoryQuestions = async () => {
    let query = {};
    var { organizationProgram } = user.userData ? user.userData : user.user;
    let params = {
      selectedProgramId: selectedProgramId || organizationProgram[0]?.programId?._id
    }
    if(clearcache) params.clearCache = clearcache;
    if((selectOrgProgram["WBC_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) params.isDummy = true;
    const paramsString = new URLSearchParams(params).toString();

    setSectionLoadingVisible(true)
    setTable({ loaded: false, data: [] });
    try {
      if (selectedCategory) query.category = selectedCategory;
      if (state.filter) query.selectedCategoryOption = state.filter;
      const { data } = await http.post(
        `/client/employeeSectionQuestionsComparisonWithMeReport?${paramsString}`,
        query
      );
      const newSet = (data.data?.questionResponse || []).map((i) => {
        return {
          label: i.question,
          "Your Results": i.currentOrg,
          "All your employees that made the list": i.otherOrg,
        };
      });
      setTable({ loaded: true, data: newSet });
      setSectionLoadingVisible(false);
    } catch (err) {
      console.log(err);
      setTable({ loaded: false, data: [] });
      setSectionLoadingVisible(false);
      return;
    }
  };

  const download = {
    data: "",
    filters: state.filter,
    types: ["JPEG", "PDF", "SVG", "XLS"],
  };

  //Button action here.
  const handleApplyFilter = (event) => {
    event.preventDefault();
    reloadMain(); //rerunning with filters
    setTable({ ...categoriesQuestions, loaded: false }); // hide old table first
    setFilter({ ...state, loaded: true });
    setLoaded(true);
  };
  const filterOnChange = (data = null) => {
    setFilter((prevState) => ({ ...prevState, filter: data, loaded: false }));
  };

  const handleClick = (newCategory) =>
    selectCategory(newCategory !== selectedCategory ? newCategory : null);
  return (
    <React.Fragment>
      {
        /* SECTION LOADING */
        sectionLoadingVisible && <SectionLoading />
      }
      <ReportsHeader download={download} accessGroup="WBC_Access" benchmark pageName="Comparison Data" />

      {/*<ReportDescription /> Disable temporarily*/}
      {
        !isAvailable && <div className="mt-4"><PageDropdown pages={pages} /></div>
      }

      <GenericFilter access={"WBC_Access"} onChange={filterOnChange} runFilters={handleApplyFilter} />
      {compareDataSet.loaded && (
        <CategoryCarousel
          reports={compareDataSet.data}
          selectedCategory={selectedCategory}
          onClick={handleClick}
        />
      )}
      {/* <GroupedBarGraphTable />  */}
      {/* data = {} */}
      {categoriesQuestions.loaded && selectedCategory != null && (
        <TableBarChart
          name={selectedCategory}
          data={categoriesQuestions.data}
          label={categoryMap[state.filter]}
        />
      )}
    </React.Fragment>
  );
};

export default WorkforceComparisonsData;
