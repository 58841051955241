import React, { useState, useEffect } from "react"
import { Button, Select, MenuItem } from '@mui/material'
import FilterDropdowns from "../generic-filter/filter-dropdown"
import { useSelector } from "react-redux";

// Since it's only used on RD then use RD_Access at the moment

const MainFilterComponent = ({
	onChange = null,
	runFilters = (e)=>{},
	setFilterFormat = null
}) => {

	const { http } = global.services
	const [loaded, setLoaded] = useState(false);
	const [ selected, setSelected ] = useState(null)
  const {user, admin} = useSelector(({user}) => user);
	const selectedProgramId = useSelector(({app})=> app.selectedProgramId)
	const [ filters, setFilters ] = useState([]);
  var { organizationProgram } = (user.userData) ? user.userData : user.user;
  const selectOrgProgram = organizationProgram.find(i => i.programId?._id == selectedProgramId) || organizationProgram[0];
  const programId = selectedProgramId || selectOrgProgram?.programId?._id;

	useEffect(() => {
		initialization()
		setLoaded(true);
	}, [])

	useEffect(()=>{
		if (onChange) {
			//Setup changes.
			onChange(selected);
		}
	},[selected])
	//Created once.
	const initialization = async () => {
		let query = {};
		if(organizationProgram) query.selectedProgramId = programId;
    if((selectOrgProgram["RD_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) query.isDummy = true;

		let response;
		try {
			response = await http.get('client/fetchSurveyFilter', {...query})
			if (response.status !== 200 || !response.data?.success) {
				throw response.data || new Error(`Failed with status: ${response.status} ${response.statusText}`);
			}
		} catch(err){
			console.log(err)
			return;
		}
		const filterData = response.data?.data?.map(({QuestionId, filterLabel, type, filterOption}) => ({ QuestionId, filterLabel, type, value: QuestionId, label: filterLabel, filterOption}))
		if(filterData.length > 0) {
			setSelected(filterData[0].value);
			setFilterFormat(filterData[0])
		}
		setFilters(filterData)
	}

	const handleChange = (e) => {
		setSelected(e.target.value)
		setFilterFormat(filters.find((x)=> x.QuestionId == e.target.value))
	}

	return (
		<>
			<div className='flex flex-row items-center flex-wrap mt-5 ml-2'>
				<div className='flex flex-row items-center space-x-1 mr-5 mb-5'>
					<i className='bx bx-filter-alt text-md'></i>
					<span className='text-sm'>Select Filter:</span>
				</div>

				<Select
		          value={selected}
		          onChange={handleChange}
		        >
        		{
					filters.map((filter, index) => (
						<MenuItem key={`filter-dropdown-${index}`} value={filter.QuestionId}>{filter.filterLabel}</MenuItem>
					))
				}
		        </Select>
			</div>
		</>
	)

}

export default MainFilterComponent
