import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AppAction from 'redux/modules/app/actions';
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";

import DashboardMyReports from "./components/my-reports";
import DashboardHomeSummary from "./components/summary";
import FDDModal from "./components/fdd-modal";

import ReportStoreItemList from "../reports-store/components/reports-store-list";
import ReSortModal from "components/charts/ReSortModal";
import Download from "../components/download";
import { API_ENDPOINT } from "config/api";
import downloadFile from "helpers/downloadFile";
import SectionLoading from "components/loaders/SectionLoading";

const DashboardHome = () => {
  const { http } = global.services;
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const { selectedProgramId, timeEstimate, clearcache, fddmodal } = useSelector(({ app }) => app);

  const organizationProgram = user.userData?.organizationProgram || [];
  const programSelected = organizationProgram?.find(i => i?.programId?._id == selectedProgramId) || organizationProgram[0]

  const hasPaidFDD = programSelected?.FDD_Payment_Details !== null;
  const hasReport = programSelected ? ['WFR_Access', 'EV_Access', 'WBC_Access', 'BBP_Access'].find(i => (programSelected[i] || '').trim().toLowerCase() === 'yes') : false;

  const [filters, setFilters] = useState([]);
  const [sectionLoading, setSectionLoading] = useState(false);
  const [state, setState] = useState({
    summary: null,
  });

  useEffect(() => {
    initialization();
  }, [selectedProgramId]);

  const handleCloseFDDModal = () =>dispatch(AppAction.toggleFDDModal(false));
  const initialization = async () => {
    let query = {};
    setSectionLoading(true);
    var { organizationProgram, organizationId } = user.userData
      ? user.userData
      : user.user;
    let organizationprogramId = user?.userData?.organizationprogramId || null;
    let dealId = user?.userData?.dealId || null;
    let defaultProgramId = null;
    if(organizationprogramId || dealId){
      defaultProgramId = organizationProgram.find(item => item._id === organizationprogramId);
      if(!defaultProgramId?.programId?._id){
        defaultProgramId = organizationProgram.find(item => item?.DealId === dealId);
      }
    }
    console.log("===============home=================")
    // console.log(defaultProgramId)
    if (organizationProgram || selectedProgramId) {
      query.selectedProgramId = selectedProgramId || defaultProgramId?.programId?._id || organizationProgram[0]?.programId?._id;
    }
    if(clearcache) query.clearCache = clearcache

    try {
      //averagePercentOfAgreement data
      const { data } = await http.get(`/client/averagePercentageOfAgreement`, {
        ...query,
      });

      //Survey responseRates double the time
      const resData = await http.get(`/client/surveyResponseRate`, {
        ...query,
      });

      let surveyData = resData?.data?.data;
      // const validColors = ['#00A46A', '#FFC955', '#F2403B', '#3F7CFB']
      // console.log('response', response)
      const radialChartValue = {
        data: [],
        metaConfig: {
          valueKey: "value",
          colors: [],
        },
      };
      const percentage = data?.data?.percentage ?? 0;

      radialChartValue.data.push({
        label: "Survey average",
        value: (percentage / 100).toFixed(2),
      });
      radialChartValue.metaConfig.colors.push("#8C60F3");

      // radialChartValue.data.push({
      // 	label: 'National average (coming in 2023)',
      // 	value:  (percent / 100).toFixed(2)
      // })
      // radialChartValue.metaConfig.colors.push('#C4C4C4')

      // set.forEach((item, k) => {
      // 	radialChartValue.data.push({
      // 		label: item.ResponseCaption, value: (item.percent / 100).toFixed(2)
      // 	})

      // 	radialChartValue.metaConfig.colors.push(item.colorCode)
      // })

      setSectionLoading(false);

      dispatch(AppAction.setTimeEstimate( surveyData?.completedSurvey > 1 ? Math.floor(surveyData?.completedSurvey / 100) : 1 ))

      setState((prevState) => ({
        ...prevState,
        summary: {
          startDate: data?.data?.StartDate,
          endDate: data?.data?.EndDate,
          radialChartData: radialChartValue,
          nationalityCount: surveyData?.National_EEs ? surveyData?.National_EEs : (surveyData?.Total_Number_of_National_EEs || 0),
          eligibilityCount: surveyData?.Program_EEs ? surveyData?.Program_EEs : (surveyData?.Total_Number_of_Program_EEs || 0),
          sendSurvey: surveyData?.sendSurvey || 0,
          surveyCompleted: surveyData?.completedSurvey,
          responseRatePercentage: surveyData?.responseRate,
        },
      }));

    } catch (err) {
      console.log(err);
      return;
    }
  };

  const downloadFullReport = () => {
    const { organizationProgram } = user.userData ? user.userData : user.user;
    let downloadable = false;
    let query = {
      selectedProgramId: selectedProgramId || organizationProgram[0]?.programId?._id,
    }
    if(clearcache) query.clearCache = clearcache

    const queryString = new URLSearchParams(query).toString();
    setTimeout(()=> {
      if(!downloadable) {
        dispatch( AppAction.setAlert(`Compiling report. Estimated download time is ${timeEstimate} minute${timeEstimate > 1 ? "s" :''}`) )
      }
    }, [3000])

    setState((prevState) => ({ ...prevState, isDownloading: true }));
    downloadFile(
      `${API_ENDPOINT}/client/generateHeatMap?${queryString}`,
      undefined,
      "post"
    ).then((response)=>{
      const { headers } = response;
      const contentType = headers.get('content-type');
      if(contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then(({data}) => {
          const { signedUrl } = data.data
          const { pathname } = new URL(signedUrl);
          const dlFile = document.createElement("a");
          dlFile.download = pathname.replace('/', '');
          dlFile.href = signedUrl;
          dlFile.target = "_blank";
          dlFile.click();
        })
      }
    })
      .catch((err) => {
        console.log(err);
        return;
      })
      .finally(() =>{
        downloadable = true;
        setState((prevState) => ({ ...prevState, isDownloading: false }))
      });
  };

  let downloadConfigs = [];

  if(hasPaidFDD) {
    downloadConfigs.push({ action: downloadFullReport });
  };

  return (
    <div>
      <div className="flex justify-between">
        <p className="text-3xl font-bold text-gray-0 mt-4 mb-5">
          Welcome,{" "}
          { user.userData?.organizationId?.Alias_Company_Name || 
            user.userData?.organizationId?.Account_Name ||
            user.userData?.fullName}
          !
        </p>
        <div className="content-buttons flex align-center items-center">
         <ReSortModal />
         <div className="ml-3">
            <Download
              data={""}
              filters={filters}
              types={["XLS", "PDF", "JPEG", "SVG"]}
              downloadConfigs={downloadConfigs}
            />
          </div>
        </div>
        {
          /* SECTION LOADING */
          state.isDownloading && <SectionLoading />
        }
      </div>

      {state.summary && !sectionLoading ? (
        <DashboardHomeSummary summaryData={state.summary} notPaidFDD={(!(hasPaidFDD || hasReport))}/>
      ) : (
        <SectionLoading />
      )}

      <DashboardMyReports />
      
      { !(hasPaidFDD || hasReport) &&
        <FDDModal open={fddmodal} onClose={handleCloseFDDModal}/>
      }
    </div>
  );
};

export default DashboardHome;
