import React, { useState, useEffect } from 'react'
import { Alert, Typography, Breadcrumbs, Box, Grid, Link, Button, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Select, MenuItem, Input, Pagination, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import dayjs from 'dayjs'

import { useHistory, useParams } from "react-router";
// import { Link } from "react-router-dom";
import useUnload from 'services/useUnload'

import UserAction from 'redux/modules/user/actions'
import AppAction from 'redux/modules/app/actions'
import GenericLoader from 'components/loaders/generic'
import ResyncModal from '../component/resync-modal'
import { useDispatch } from "react-redux"

const ProgramDetails = () => {
  const { http } = global.services
	const history = useHistory()
  const dispatch = useDispatch();
  const {page: projectId, programId } = useParams()
  const [project, setProject] = useState({})
  const [program, setProgram] = useState({})
  const lastPage = history.location.pathname.split("/")[3]
	const itemsPerPage = 5;
	const [total, setTotal] = useState(0);
	const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [otherInfo, setInfo] = useState({

  });
  const [resyncStatus, setResyncStatus] = useState({
    status: 'idle',
    modal: false,
  });

  // useUnload(e => {
  //   e.preventDefault();
  //   if(resyncStatus.status === 'on-going') {
  //     // alert('something');
  //     // const exit = confirm('Are you sure you want to leave?');
  //     // if (exit) window.close();
  //   }
  // });

  const [sortBy, setSortBy] = useState('Date');
	const [displayed, setDisplayed] = useState(itemsPerPage);

	const [noOfPages, setNoOfPages] = useState(
		Math.ceil(total / itemsPerPage)
	);

	const [name, setName] = useState('');
	const [foundRows, setFoundRows] = useState([]);
  const [oldRows, setOldRows] = useState([]);

  useEffect(()=>{
    loadDetails()
    loadOrganizations()
  },[projectId])

  const loadDetails = async () => {
    const { data } = await http.get(`admin/getProgramById/${programId}`);
    const { program, ...othersettings } = data.data
    // const filteredProgram = programLists.data?.find(i => i._id == programId);
    setProgram(program || {})
    setProject(program?.Project || {})
    setInfo(othersettings);
  }

  const loadOrganizations = async () => {
    setLoading(true);
    try {
      const { data } = await http.get(`admin/getOrganizations`, {
        programId
      });
      const {success, message} = data;
      if(success) {
        setFoundRows(data?.data);
        setOldRows(data?.data);
        setTotal(data?.data?.length || 1);
        setDisplayed(itemsPerPage);
        setNoOfPages(Math.ceil(data?.data.length / itemsPerPage))
      }
    } catch(e) {
      const { message } = e?.response?.data || {} 
      setError(message || 'Data not found')
    }
    setLoading(false);
  }

	const handleChange = (event, value) => {
		setPage(value);
		setDisplayed(value * itemsPerPage);
	};

	const handleSortBy = event => {
		setSortBy(event.target.value);
    let sorted = [];

    switch(event.target.value) {
      case 'Date':
      sorted = oldRows.sort((a,b) => {
        var dA = a.orgPrograms[0]?.orgs?.Created_Time != null ? dayjs(a.orgPrograms[0]?.orgs?.Created_Time) : null;
        var dB = b.orgPrograms[0]?.orgs?.Created_Time != null ? dayjs(b.orgPrograms[0]?.orgs?.Created_Time) : null;
        if(dA && dB)
          return dB.diff( dA );
        else if (dB)
          return 0;
        return -1;
      } );
      break;

      case 'Name':
      sorted = oldRows.sort((a, b) => {
        const aName = a.Alias_Company_Name || a.Account_Name;
        const bName = b.Alias_Company_Name || b.Account_Name;
        return  aName > bName ? 1 : (bName > aName ? -1 : 0);
      } );
      break;

      case 'Surveys':
      sorted = oldRows.sort((a, b) => (a.orgPrograms[0]?.orgs?.Surveys_Sent || 0) - (b.orgPrograms[0]?.orgs?.Surveys_Sent || 0));
      break;
    }
    // console.log('huuh?', foundRows, sorted)
    // setOldRows(foundRows);
    setFoundRows(sorted);

	};

	const filter = (e) => {
    const keyword = e.target.value;
    if (keyword !== '') {
      const results = oldRows.filter((org) => {
        if(org.Alias_Company_Name)
          return org.Alias_Company_Name?.toLowerCase().startsWith(keyword.toLowerCase()); 
        return org.Account_Name?.toLowerCase().startsWith(keyword.toLowerCase());
      });
      setFoundRows(results);
      setTotal(results.length);
      var newDisplayed = results.length <= itemsPerPage ? results.length : itemsPerPage;
      setDisplayed(newDisplayed);
      setNoOfPages(Math.ceil(results.length / newDisplayed))
    } else {
    	setTotal(oldRows.length);
    	setFoundRows(oldRows);
    }
    setName(keyword);
  };

  const handleResyncAll = async () => {
    setResyncStatus({
      status: 'on-going',
      modal: true,
    });

    try {
      const payload = { programId }
      const { data } = await http.post('/webhook/massResyncByProgram', payload);
      console.log('data', data);
      if(data?.success) {
        setResyncStatus({ status: 'done', modal: false });
        return;
      }
        
      throw new Error('');
    } catch {
      setError("Resync could not complete, please try again")
      setResyncStatus({ status: 'idle', modal: false });
    }
  }

  const handleCloseResyncModal = () => setResyncStatus({ ...resyncStatus, modal: false });

  const handleViewDashboard = async (row) => {
    const { users, orgPrograms } = row
    const payload = {
        username: users[0]?.username,
    }

    try {
        const response = await http.post('/user/login?skipLastLogin=true', payload)
        const responseData = response.data.data
        await dispatch(UserAction.toggleUser(true));
        await dispatch(UserAction.setUser({...responseData, user: responseData.userData }))

        dispatch( AppAction.selectProgram(orgPrograms[0]?.orgs?.programId[0]?._id) );

        history.push('/dashboard')
    } catch (error) {
        console.log('error?', error)
    }
  }

  const categoryCounts = otherInfo.categoriesInfo?.categoryCounts || {};

	return (
  <Box className="projects-container">
    {
      project?.name != null &&
     <Breadcrumbs aria-label="breadcrumb" separator="|">
      <Typography
       className="cursor-pointer"
       onClick={() => {
        history.push(`/admin/projects/${projectId}`);
       }}
      >
       {project?.name || projectId}
      </Typography>
      <Typography color="text.primary">{program?.Name}</Typography>
     </Breadcrumbs>
    }
   <Box>
    <Typography
     component="h1"
     fontSize="30px"
     fontWeight="700"
    >
     {program?.Name}
    </Typography>
   </Box>
  <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Typography
        component="h3"
        fontSize="16px"
        lineHeight="33px"
        fontWeight="700"
       >
        Program Details
       </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Box className="flex">
        <Box sx={{ width: "33.33%" }}>
          <Typography
            component="h3"
            fontSize="14px"
            marginBottom="20px"
           >
            <span className="text-gray-400">
            Program ID:
            </span>
            <span className="text-black ml-2">
              {program.id}
            </span>
          </Typography>
          <Typography
            component="h3"
            fontSize="14px"
            marginBottom="20px"
           >
            <span className="text-gray-400">
            Program Year:
            </span>
            <span className="text-black ml-2">
              {program?.Name?.split(" ").pop()}
            </span>
          </Typography>
          <Typography
            component="h3"
            fontSize="14px"
            marginBottom="20px"
           >
            <span className="text-gray-400">
            Affiliated Project:
            </span>
            <span className="text-black ml-2">
              {project?.name}
            </span>
          </Typography>
          <Typography
            component="h3"
            fontSize="14px"
            marginBottom="20px"
           >
            <span className="text-gray-400">
            Date Created:
            </span>
            <span className="text-black ml-2">
              {dayjs(program.createAt).format('MM/DD/YYYY')}
            </span>
          </Typography>
        </Box>
        <Box sx={{ width: "33.33%" }}>
          <Typography
            component="h3"
            fontSize="14px"
            marginBottom="20px"
           >
            <span className="text-gray-400">
            Employee Survey ID:
            </span>
            <span className="text-black ml-2">
              {program.Employee_Survey_ID}
            </span>
          </Typography>
          <Typography
            component="h3"
            fontSize="14px"
            marginBottom="20px"
           >
            <span className="text-gray-400">
            Employer Survey ID:
            </span>
            <span className="text-black ml-2">
              {program.Employer_Survey_ID}
            </span>
          </Typography>
          <Typography
            component="h3"
            fontSize="14px"
            marginBottom="20px"
           >
            <span className="text-gray-400">
            Number of Organizations:
            </span>
            <span className="text-black ml-2">
              {otherInfo.numberOfOrgs || 0}
            </span>
          </Typography>
          <Typography
            component="h3"
            fontSize="14px"
            marginBottom="20px"
           >
            <span className="text-gray-400">
            Employer Survey Count:
            </span>
            <span className="text-black ml-2">
              {otherInfo.employerSurveyCount || 0}
            </span>
          </Typography>
          <Typography
            component="h3"
            fontSize="14px"
            marginBottom="20px"
           >
            <span className="text-gray-400">
            Employee Survey Count:
            </span>
            <span className="text-black ml-2">
              {otherInfo.employeeSurveyCount || 0}
            </span>
          </Typography>
        </Box>
        <Box sx={{ width: "33.33%" }}>
          <Typography
            component="h3"
            fontSize="14px"
            marginBottom="20px"
           >
            <span className="text-gray-400">
            Winners Count:
            </span>
            <span otherInfo="text-black ml-2">
              {otherInfo.categoriesInfo?.winnersCount || 0}
            </span>
          </Typography>
          <Typography
            component="h3"
            fontSize="14px"
            marginBottom="20px"
           >
            <span className="text-gray-400">
            Non-winners Count:
            </span>
            <span className="text-black ml-2">
              {otherInfo.categoriesInfo?.nonWinnersCount || 0}
            </span>
          </Typography>
          {
            Object.keys(categoryCounts)
            .map(i => <Typography
              key={i}
              component="h3"
              fontSize="14px"
              marginBottom="20px"
             >
              <span className="text-gray-400">
              {i}:
              </span>
              <span className="text-black ml-2">
                {categoryCounts[i] || 0}
              </span>
            </Typography>)
          }
        </Box>
      </Box>
    </AccordionDetails>
  </Accordion>

  <Box className="project-wrapper">
    <Box className="programs" marginTop="30px">
     <Typography
      component="h3"
      fontSize="16px"
      lineHeight="33px"
      fontWeight="700"
      marginBottom="30px"
     >
      Organization
     </Typography>
    </Box>
    {
      error &&
      <div className="mb-5">
        <Alert variant="filled" severity="error">
          {error}
        </Alert>
      </div>
    }
    <Stack
     spacing={5}
     direction="row"
     className="filter-wrapper"
     marginBottom="20px"
     justifyContent="space-between"
     paddingRight="30px"
    >
      <Box className="flex align-center" alignItems="center">
        <Select
         value={sortBy}
         onChange={handleSortBy}
         sx={{
          maxHeight: "40px",
          minHeight: "unset",
          background: "#fff",
          fontSize: "14px",
         }}
        >
         <MenuItem value="Date">Sort by Date</MenuItem>
         <MenuItem value="Name">Sort by Name</MenuItem>
         <MenuItem value="Surveys">Sort by Surveys Sent</MenuItem>
        </Select>
        <Button
          variant="contained"
          onClick={handleResyncAll}
          color="error"
          className="normal-case ml-2 rounded-none"
          sx={{ minWidth: "120px" }}
          disabled={ resyncStatus?.status !== 'idle'}
        >
        { resyncStatus?.status === 'idle' && 'Re-Sync All Deals' }
        { resyncStatus?.status === 'on-going' && 'On-going Re-Sync' }
        { resyncStatus?.status === 'done' && 'Re-Sync Completed' }
        </Button>
      </Box>
     <Input
      type="search"
      placeholder="Search Clients"
      value={name}
      onChange={filter}
      className="input search-list"
     />
    </Stack>



    {
      loading ? <GenericLoader />
      : <Box>
     <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
       <TableHead sx={{ backgroundColor: "#eaebeb" }}>
        <TableRow>
         <TableCell>Organization</TableCell>
         <TableCell>Date Added</TableCell>
         <TableCell>Current Stage</TableCell>
         <TableCell>Last Time Synced</TableCell>
         <TableCell>No. of Surveys Sent</TableCell>
         <TableCell align="right">Actions</TableCell>
        </TableRow>
       </TableHead>
       <TableBody>
        {foundRows
         .slice((page - 1) * itemsPerPage, page * itemsPerPage)
         .map((row, k) => (
          <TableRow
           key={`${row._id}-${k}`}
           sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          >
           <TableCell component="th" scope="row">
            {row.Alias_Company_Name || row.Account_Name}
           </TableCell>
           <TableCell>{dayjs(row.orgPrograms[0]?.orgs?.Created_Time).format('MM/DD/YYYY')}</TableCell>
           <TableCell>{row.orgPrograms[0]?.orgs?.Stage}</TableCell>
            <TableCell>{row.orgPrograms[0]?.orgs?.Last_time_deal_synced ? dayjs(row.orgPrograms[0]?.orgs?.Last_time_deal_synced).format('MM/DD/YYYY') : null}</TableCell>
           <TableCell component="th" scope="row">
            {row.orgPrograms[0]?.orgs?.Surveys_Sent || 0}
           </TableCell>
           <TableCell align="right">
           {
            row.users?.length > 0 ? 
            <Link type="button" className="text-red-10 flex items-center justify-end cursor-pointer" onClick={()=>handleViewDashboard(row)}>
             View Dashboard <i className='bx bx-chevron-right text-xl'/>
            </Link> : <p className="text-red-0">No users</p>
           }
           </TableCell>
          </TableRow>
         ))}
       </TableBody>
      </Table>
     </TableContainer>
     <Grid container justifyContent="space-between" marginTop="30px">
      <Grid item>
       {displayed <= foundRows.length ? (
        <Typography>
         {displayed} out of {total}
        </Typography>
       ) : (
        <Typography>
         {foundRows.length} out of {total}
        </Typography>
       )}
      </Grid>
      <Grid item>
       <Pagination
        count={noOfPages}
        page={page}
        onChange={handleChange}
        defaultPage={1}
        color="primary"
        size="large"
        className="pagination"
       />
      </Grid>
     </Grid>
    </Box>
    }
   </Box>
    <ResyncModal 
      open={resyncStatus?.modal} 
      status={resyncStatus?.status} 
    />
  </Box>
 );

}

export default ProgramDetails
