import React, { useState, useEffect, useRef } from "react";
import ReportsHeader from "../components/reports-header";
import {
	Box,
	Typography,
	Modal,
	Button,
	IconButton,
	LinearProgress,
} from "@mui/material";
import BubblePackGraph from "./component/BubblePackGraph";
import sampleData from "./component/sampleData";

import { useSelector } from "react-redux";
import SectionLoading from "components/loaders/SectionLoading";
import { toPng } from "html-to-image";
import UploadService from "services/fileUploadService";
import LoadingButton from "@mui/lab/LoadingButton";
import generatePdf from "helpers/generateKeyImpactPdf";
import ReportDescription from "../components/report-description";

import { Close } from "@mui/icons-material";

const colors = ["#FEC12F", "#E7E7E7", "#8C60F3", "#52AF79", "#FA5B5A"];
const fontColor = ["#000000", "#000000", "#FFFFFF", "#FFFFFF", "#FFFFFF"];

const KeyImpactAnalysis = () => {
	const [checkAnalysis, setAnalysis] = useState({});
	const { http } = global.services;

	const [showModal, setShow] = useState(false);
	const [selectedFiles, setSelectedFiles] = useState([]);
	const [progressUploads, setProgress] = useState([]);

	const { user, admin } = useSelector(({ user }) => user);
	const {selectedProgramId, clearcache} = useSelector(({ app }) => app);
	const [loading, setLoading] = useState(false);
	const [loaded, setLoaded] = useState(false);
	const [removing, setRemoving] = useState(false);
	const [uploading, setUploading] = useState(false);
	const [keyAnalysisData, setData] = useState(sampleData);
	const [kiaID, setKIAID] = useState(null);
	const [deleteModal, setDeleteModal] = useState(false);
	const [processing, setProcessing] = useState(false);
	const [winWidth, setWinWidth] = useState(window.innerWidth);
	const [winHeight, setWinHeight] = useState(window.innerHeight);
	const [sample, setSample] = useState(null);

	useEffect(() => {
		// handleResize should set state for winWidth and winHeight

		const handleResize = () => {
			setWinWidth(window.innerWidth);
			setWinHeight(window.innerHeight);
		};
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	});
	const bubbleRef = useRef();

	const { organizationProgram, organizationId, projectId } = user.userData
		? user.userData
		: user.user;
	const selectOrgProgram = organizationProgram.find(i => i.programId?._id == selectedProgramId) || organizationProgram[0];
  const hasReport = ['KIA_Access'].find(i => (selectOrgProgram[i] || '').trim().toLowerCase() === 'yes');
	const programId = selectedProgramId || selectOrgProgram?.programId?._id;

	useEffect(() => {
		loadData();
	}, []);

	const openModal = () => setShow(!showModal);
	const handleClose = () => {
		setShow(false);
		setSelectedFiles([]);
		setProgress([]);
	};

	const selectFiles = ({ target }) => {
		const files = Array.from(target.files);
		setUploading(false)
		setSelectedFiles(files);
		setProgress(files.map((i) => 0));
	};

	const upload = (file, idx) => {
		return UploadService.upload(
			file,
			(event) => {
				const data = Math.round((100 * event.loaded) / event.total);
				setProgress(
					progressUploads.map((i, index) => (index == idx ? data : i))
				);
			},
			`/admin/uploadKeyImpactAnalysis?programId=${programId}&projectId=${
				projectId._id
			}&orgProgramId=${
				organizationProgram.find((i) => i.programId?._id == programId)?._id
			}&orgId=${organizationId._id}`,
			{},
			"files"
		).catch(() => {});
	};

	const deleteKIA = async () => {
		setProcessing(true);
		try {
			const { data } = await http.delete(
				`/admin/KeyImpactAnalysis/${kiaID}`,
				null,
				{
					headers: {
						Authorization: `Bearer ${admin.accessToken}`,
					},
				}
			);
			setLoading(false);
			setData(sampleData);
			setDeleteModal(false);
			setKIAID(null);
		} catch (e) {}
		setProcessing(false);
	};

	const uploadFiles = (e) => {
		e.preventDefault();

		const uploadPromises = selectedFiles.map(upload);
		setProcessing(true);
		setUploading(true)
		Promise.all(uploadPromises)
			.then(({ data }) => {
				loadData();
			})
			.then(() => {
				setProcessing(false);
				setShow(false);
			});
	};

	const loadData = async () => {
		setLoading(true);
		let query = {};
		if (organizationProgram) {
			query.selectedProgramId =
				selectedProgramId || organizationProgram[0]?.programId?._id;
		}
		if(clearcache) query.clearCache = clearcache
     	if((selectOrgProgram["KIA_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) query.isDummy = true;

		try {
			const { data } = await http.get("/client/getKeyImpactAnalysis", {
				...query,
			});
			if(data?.data?.data) {
				setSample(data?.data?.data?.signedUrl);
				return;
			} else setSample(null);

			const report = data.data?.report?.map(({ key, label, value }, ind) => ({
				name: label,
				value,
				desc: key,
				color: colors[ind % colors.length],
				fontColor: fontColor[ind % fontColor.length],
			}));
			setLoaded(true);
			setLoading(false);
			setData(report);
			setKIAID(data.data?._id || null);
		} catch (e) {}
	};
	const handleClick = (data) => setAnalysis(data);

	const download = {
		data: null,
		filters: [],
		types: ["pdf"],
	};

	const downloadSample = () => {
		if(!sample) return;
		const { pathname } = new URL(sample);
    const dlFile = document.createElement("a");
    dlFile.download = pathname.replace('/', '');
    dlFile.href = sample;
    dlFile.target = "_blank";
    dlFile.click();
	}

	const downloadConfigs = [
		{
			action: async () => {
				if(sample) {
					downloadSample();
					return;
				}

				if (kiaID == null) {
					alert("Wait data to load");
					return;
				}

				toPng(bubbleRef.current, { cacheBust: true }).then((pngData) =>
					generatePdf(keyAnalysisData, pngData)
				);
			},
			context: "Download PDF",
		},
	];

	const KIAAvailable = (admin && 
			(	
				admin?.user?.roleId?.role == 'admin' || 
				admin?.user?.roleId?.permissions?.find(i => i === 'uploadKeyImpactAnalysisAccess')
			)
		)
		? kiaID == null
			? {
					uploadText: "Upload KIA XLS",
					onClickUpload: openModal,
			  }
			: {
					deleteText: "Delete KIA",
					onClickDelete: () => setDeleteModal(true),
			  }
		: {};

	return (
		<React.Fragment>
			<ReportsHeader
				pageName="Key Impact Analysis"
				{...KIAAvailable}
				download={download}
				accessGroup="KIA_Access"
				downloadConfigs={loaded ? downloadConfigs : [{ action: downloadSample, context: "Download Sample" }]}
			/>

			<ReportDescription description="This report identifies key motivators of employee engagement within your unique population. This information is vital to knowing what workplace attributes are most important to retain your top talent and drive high productivity among all staff." />

			{(sample && hasReport) && <p className="my-4 font-bold text-gray-40">Please wait for your KIA report to be uploaded by our personnel.</p>}

			<div className="graph-container flex justify-center">
				<div ref={bubbleRef}>
					<BubblePackGraph
						data={keyAnalysisData}
						callback={handleClick}
						width={winWidth < 1440 ? winWidth : winWidth * 0.7}
						height={winHeight < 1300 ? winHeight : winHeight * 0.63}
					/>
					{!loaded && <p className="my-4 text-center">No Available data</p>}
				</div>
			</div>

			{/* <Box
				sx={{
					display: "grid",
					gridTemplateColumns: "repeat(3, 33.33%)",
					marginTop: "20px",
				}}
			>
				{icons.map((i) => (
					<div
						key={i.id}
						className="flex items-center my-4 cursor-pointer"
						onClick={() => setAnalysis(i)}
					>
						<i.svg className={"mr-3"} /> {i.name}
					</div>
				))}
			</Box> */}

			<Modal
				open={checkAnalysis.id != null || checkAnalysis.name != null}
				onClose={() => setAnalysis({})}
				aria-labelledby="resort-title"
				aria-describedby="resort-description"
			>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: 490,
						bgcolor: "#f5f5f5",
						boxShadow: 24,
						p: 4,
					}}
				>
					<Typography
						id="role-form"
						variant="h5"
						component="span"
						fontWeight="600"
						display="block"
						className="text-center mt-6"
					>
						<IconButton
							aria-label="close"
							onClick={() => setAnalysis({})}
							sx={{ position: "absolute", right: 8, top: 8 }}
						>
							<Close />
						</IconButton>
						{checkAnalysis.name}
					</Typography>
					<Typography
						id="user-form-title"
						variant="p"
						display="block"
						className="text-center my-9"
					>
						{checkAnalysis.desc ||
							"This is a question. Lorem ipsum sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."}
						{" - "}
						<span className="text-red-10">
							<span className="font-bold">
								{(checkAnalysis.value * 100).toFixed(2)}%
							</span>{" "}
							of contribution
						</span>
						.
					</Typography>
					<Button
						variant="contained"
						color="error"
						onClick={() => setAnalysis({})}
						className="normal-case rounded-none w-full"
					>
						Close
					</Button>
				</Box>
			</Modal>
			{/* Delete modal*/}
			<Modal
				open={deleteModal}
				onClose={() => setDeleteModal(false)}
				aria-labelledby="resort-title"
				aria-describedby="resort-description"
			>
				<Box
					sx={{
						position: "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: 490,
						bgcolor: "#f5f5f5",
						boxShadow: 24,
						p: 4,
					}}
				>
					<Typography
						id="role-form"
						variant="h5"
						component="span"
						fontWeight="600"
						display="block"
						className="text-center mt-6"
					>
						<IconButton
							aria-label="close"
							onClick={() => setDeleteModal(false)}
							sx={{ position: "absolute", right: 8, top: 8 }}
						>
							<Close />
						</IconButton>
						Are you Sure?
					</Typography>
					<Typography
						id="user-form-title"
						variant="p"
						component="span"
						display="block"
						className="text-center my-9"
					>
						This will make "Key Impact Analysis" report <br /> unavailable for
						this organization unless you <br /> re-upload the original XLS file.
					</Typography>
					<Box className="flex justify-between">
						<Button
							variant="text"
							onClick={() => setDeleteModal(false)}
							className="normal-case rounded-none w-1/2"
						>
							Cancel
						</Button>
						<LoadingButton
							type="button"
							variant="contained"
							disabled={kiaID == null}
							color="error"
							className="normal-case rounded-none w-1/2"
							loading={processing}
							onClick={deleteKIA}
						>
							Delete KIA
						</LoadingButton>
					</Box>
				</Box>
			</Modal>

			<Modal
				open={showModal}
				onClose={handleClose}
				aria-labelledby="resort-title"
				aria-describedby="resort-description"
			>
				<form onSubmit={uploadFiles}>
					<Box
						sx={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							width: 490,
							bgcolor: "#f5f5f5",
							boxShadow: 24,
							p: 4,
						}}
					>
						<Typography
							id="role-form"
							variant="h5"
							component="span"
							fontWeight="600"
							display="block"
							className="text-left my-6"
						>
							<IconButton
								aria-label="close"
								onClick={handleClose}
								sx={{ position: "absolute", right: 8, top: 8 }}
							>
								<Close />
							</IconButton>
							Upload KIA XLS
						</Typography>

						<Box className="mb-5">
							<label className="btn btn-default p-0">
								<input type="file" onChange={selectFiles} />
							</label>
						</Box>
						<Box className="mb-5">
							{selectedFiles.map((i, index) => (
								<div className="mb-2" key={index}>
									<span>{i.fileName}</span>
									{
										uploading &&
										<div className="progress">
											<LinearProgress
												variant="determinate"
												value={progressUploads[index] || 0}
											/>
											{progressUploads[index] || 0}%
										</div>
									}
								</div>
							))}
						</Box>

						<Box className="flex justify-between">
							<Button
								variant="text"
								onClick={handleClose}
								className="normal-case rounded-none w-1/3"
							>
								Close
							</Button>
							<LoadingButton
								type="submit"
								variant="contained"
								disabled={!selectedFiles}
								color="error"
								className="normal-case rounded-none w-1/3"
								loading={processing}
							>
								Upload
							</LoadingButton>
						</Box>
					</Box>
				</form>
			</Modal>
		</React.Fragment>
	);
};

export default KeyImpactAnalysis;
