import React from "react"
import { Box, Typography, Button,
	Table, TableBody, TableRow, TableCell,
	List, ListItem, ListItemText
} from "@mui/material"
import { useSelector, useDispatch } from 'react-redux';
import ReportItem from '../components/report-item';
import { removeFromCart, removeFilter, clearCart, addResync } from 'redux/modules/cart/actions';
import { setResyncInfo } from 'redux/modules/reports/actions';

import { Link } from "react-router-dom"
import './cart.css' 
import { currentCurrency } from "helpers/checkCurrency";
// Depreciated
const Cart = () => {
	const dispatch = useDispatch();
	const { items, resync } = useSelector(({cart}) => cart)
	const { items: reportItems, filters: reportFilters, ...report} = useSelector(({report}) => report)

	const handleDelete = (item) => {
		dispatch(removeFromCart(item))
	}
	const handleFilterDelete = (item) => {
		dispatch(removeFilter(item))
	}
	const resetAll = () => {
		dispatch(clearCart())
		dispatch(setResyncInfo(false, false))
	}
	const revertSync = () => {
		dispatch(addResync( false ))
		dispatch(setResyncInfo(false, false))
	}
	const getTotal = () => items.reduce((a,b) => a+b.amount , 0) + (resync ? 100 : 0)
	return (
		<React.Fragment>
			<Box sx={{ display: 'flex' }}>
				<Typography variant="h5" sx={{ my:2 , fontWeight:'bold'}}>Cart</Typography>
			</Box>
			{/* Item count and reports store button */}
			<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '20px' }}>
				<p className="text-sm">{items.length ?? 0} items</p>

				<Button href={"/reports-store"} variant="outlined" sx={{ color: '#1D2124', borderColor:'#d3d3d3', fontSize:'14px', textTransform: 'unset', borderRadius: '0'}}>
	        Go to Reports Store
	      </Button>
			</Box>
			{/* Cart contents */}
			<Box sx={{ display: 'flex', color: '#fff', marginBottom: '50px'}}>
				{/*Cart Items*/}
				<Box sx={{ display: 'flex', flexDirection:'column', backgroundColor:'#fff', width: '900px', marginRight:'20px', padding:"20px"}}>
					<Table className="cart-table" sx={{marginBottom: "5px"}}>
						<TableBody>
							{
								items.map((i,k) => <TableRow
				          key={k}
				          sx={{ border: 0 }}
				        >
				        	<TableCell component="th" scope="row">
				          	<img src={i.img}/>
				          </TableCell>
				          <TableCell>{i.name || i.title}</TableCell>
				          <TableCell>{i.comments}</TableCell>
				          <TableCell align="right"><span className="font-bold">{currentCurrency()} {i.amount}</span></TableCell>
				          {/* <TableCell align="right"><span className="font-bold">$ {i.amount}</span></TableCell> */}

				          <TableCell align="right" onClick={()=>handleDelete(i)}>
				          	Delete
				          </TableCell>
				        </TableRow>)
							}
							{
								items.map((i,k) => <TableRow
					          key={k}
					          sx={{ border: 0 }}
					        >
					        	<TableCell component="th" scope="row">
					          	{/*<img src={i.img}/>*/}
					          </TableCell>
					          <TableCell>{i.filterLabel}</TableCell>
					          <TableCell>Employee Verbatim Filter</TableCell>
					          <TableCell align="right"><span className="font-bold">{currentCurrency()} {i.amount}</span></TableCell>
					          {/* <TableCell align="right"><span className="font-bold">$ {i.amount}</span></TableCell> */}

					          <TableCell align="right" onClick={()=>handleFilterDelete(i)}>
					          	Delete
					          </TableCell>
					        </TableRow>)
								}
							{
								resync && <TableRow
				          sx={{ border: 0 }}
				        >
				        	<TableCell component="th" scope="row">
				          	{/*<img src={i.img}/>*/}
				          </TableCell>
				          <TableCell>Resync Reports</TableCell>
				          <TableCell></TableCell>
				          <TableCell align="right"><span className="font-bold">{currentCurrency()} 100</span></TableCell>
				          {/* <TableCell align="right"><span className="font-bold">$ 100</span></TableCell> */}
				          <TableCell align="right" onClick={revertSync}>
				          	Delete
				          </TableCell>
				        </TableRow>
							}
						</TableBody>
					</Table>
					<Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Button variant="text" sx={{ color: '#000', marginRight: '10px'}} onClick={resetAll}>
				        Remove
				    </Button>
				    <Button variant="text" sx={{ color: '#000'}}>
				        Save for Later
				    </Button>
					</Box>
				</Box>

			  {/* Cart Details*/}
				<Box sx={{ backgroundColor: "#162630", flexGrow: '1', padding:'30px'}}>
					<Typography variant="h6" sx={{ my:2 , fontWeight:'bold'}}>Summary</Typography>
					<List className="cart-list" dense={true}>
						{
							items.map((i,k)=>
								<ListItem key={k} sx={{display:'flex', justifyContent:'space-between', margin: '10px 0'}}>
									<span className="title">{i.name || i.title}</span>
									<span>{currentCurrency()} {i.amount}</span>
									{/* <span>$ {i.amount}</span> */}
								</ListItem>)
						}
						{
							resync && <ListItem sx={{display:'flex', justifyContent:'space-between', margin: '10px 0'}}>
									<span className="title">Resync Reports</span>
									<span>{currentCurrency()} 100</span>
								</ListItem>
						}
						<ListItem sx={{display:'flex', justifyContent:'space-between', margin: '25px 0'}}>
							<span>Total</span>
							<span>{currentCurrency()} {getTotal()}</span>
						</ListItem>
					</List>

			    <Button
			      variant="contained"
			      color="error"
			      className="normal-case rounded-none w-full"
			      component={Link}
			      to="/checkout"
			     >
			      Go To Checkout
			    </Button>

				</Box>
			</Box>

			<Box sx={{ marginBottom: '50px'}}>
				<Typography variant="h6" sx={{ my:2 , fontWeight:'bold'}}>With This Reports Also Buy</Typography>

				<Box className="report-lists" sx={{ display: 'flex'}}>
					{
						reportItems && reportItems.map((i,k)=> <ReportItem key={k} item={i}/>)
					}
				</Box>
			</Box>

		</React.Fragment>
	)
}

export default Cart