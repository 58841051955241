import React, { useCallback, useRef } from "react";
import { useEffect, useState } from "react";
import { Button } from "@mui/material";
import DownloadButton from "components/charts/DownloadButton";
import Swiper from "react-id-swiper";
import "swiper/swiper.min.css";
import * as htmlToImage from "html-to-image";
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from "html-to-image";
import PieChart from "components/charts/PieChart";
import { pieChartData } from "components/charts/mock-data/charts-data";
import BreadcrumbComponent from "components/breadcrumb";
import GenericFilter from "components/filters/generic-filter/index";
import Skeleton from "@mui/material/Skeleton";
import { useSelector } from "react-redux";

const MeanScoreReport = () => {
  const { http } = global.services;
  const user = useSelector((state) => state.user.user);
  const selectedProgramId = useSelector(({ app }) => app.selectedProgramId);

  const [state, setState] = useState({
    showCharts: false,
    showPieChartData: false,
    showDetailedData: false,
    loadingDetailedData: false,
    detailedData: null,
    currentlySelectedPieChart: null,
    filterData: null,
    pieChartValue: [],
  });

  useEffect(() => {
    setTimeout(() => {
      setState((prevState) => ({ ...prevState, showCharts: true }));
    }, 4000);
    initialization();
  }, []);

  const initialization = async () => {
    let query = {};
    var { organizationProgram } = user.userData ? user.userData : user.user;

    if (organizationProgram) {
      query.selectedProgramId =
        selectedProgramId || organizationProgram[0]?.programId?._id;
    }
    let response;
    try {
      response = await http.post("client/employeeMeanScoreBySection", {
        ...query,
      });
      if (response.status !== 200 || !response.data?.success) {
        throw response.data || new Error(`Failed with status: ${response.status} ${response.statusText}`);
      }
    } catch (err) {
      console.log(err);
      return;
    }

    let formattedData = [];
    let dataFormat = [];
    let colorsList = null;
    let objectData = {};

    for (let counter = 0; counter < response.data.data.length; counter++) {
      for (let key in response.data.data[counter]) {
        let objectData = {
          label: key,
          rawData: response.data.data[counter],
          metaConfig: {
            centerText: "",
            colors: colorsList || [],
            fieldKey: "field",
            fields: [],
            hideLabels: false,
            showLargest: false,
            valueKey: "value",
          },
        };
        let data = {};
        for (
          let dataCounter = 0;
          dataCounter < response.data.data[counter][key].length;
          dataCounter++
        ) {
          data[response.data.data[counter][key][dataCounter].ResponseCaption] =
            response.data.data[counter][key][dataCounter].percent;
          if (
            response.data.data[counter][key][dataCounter].percent != undefined
          ) {
            objectData.metaConfig.fields.push({
              title:
                response.data.data[counter][key][dataCounter].ResponseCaption,
              field:
                response.data.data[counter][key][dataCounter].ResponseCaption,
            });
            if (!colorsList) {
              let colorExists = true;
              let colorGenerated =
                "#" + Math.floor(Math.random() * 16777215).toString(16);
              while (colorExists) {
                if (!objectData.metaConfig.colors.includes(colorGenerated)) {
                  colorExists = false;
                  objectData.metaConfig.colors.push(colorGenerated);
                } else {
                  colorGenerated =
                    "#" + Math.floor(Math.random() * 16777215).toString(16);
                }
              }
            }
          }
        }
        dataFormat.push(data);
        objectData["data"] = data;
        colorsList = objectData.metaConfig.colors;
        objectData.metaConfig.colors = colorsList;
        formattedData.push(objectData);
      }
    }
    setState((prevState) => ({
      ...prevState,
      pieChartValue: formattedData,
      showPieChartData: true,
    }));
  };

  const handleOnPieChartSelect = async (data) => {
    const title = data.label;
    const dataList = data.rawData[data.label];
    let questionRange = null;
    for (let counter = 0; counter < dataList.length; counter++) {
      if (dataList[counter].questionRange) {
        questionRange = dataList[counter].questionRange;
        break;
      }
    }
    if (questionRange) {
      setState((prevState) => ({
        ...prevState,
        showDetailedData: true,
        loadingDetailedData: true,
        currentlySelectedPieChart: data,
      }));
      let response;
      try {
        response = await http.post(`client/employeeMeanScoreByQuestions`, {
          questionRange: questionRange,
        });
        console.log("Response:", response);
        if (response.status !== 200 || !response.data?.success) {
          throw response.data || new Error(`Failed with status: ${response.status} ${response.statusText}`);
        }
      } catch (err) {
        console.log(err);
        return;
      }

      const detailedSummaryData = [];
      const detailedSummaryDataObject = {
        label: "",
        dataLabel: [],
        dataValues: [],
      };
      let fieldKeysToUse = [];
      let fieldKeysValues = [];
      for (let counter = 0; counter < response.data.data.length; counter++) {
        const dataObject = { label: response.data.data[counter].question };
        if (response.data.data[counter].responses != undefined) {
          for (
            let responseCounter = 0;
            responseCounter < response.data.data[counter].responses.length;
            responseCounter++
          ) {
            const responseCaption =
              response.data.data[counter].responses[responseCounter]
                .ResponseCaption;
            const percent =
              response.data.data[counter].responses[responseCounter].percent;

            if (!fieldKeysToUse.includes(responseCaption)) {
              fieldKeysToUse.push(responseCaption);
            }
            dataObject[responseCaption] =
              response.data.data[counter].responses[responseCounter].percent;
          }

          fieldKeysValues.push(dataObject);
        }
        detailedSummaryDataObject.label = title;
        detailedSummaryDataObject.dataLabel = fieldKeysToUse;
        detailedSummaryDataObject.dataValues = fieldKeysValues;
      }
      setState((prevState) => ({
        ...prevState,
        detailedData: detailedSummaryDataObject,
        showDetailedData: true,
        loadingDetailedData: false,
      }));
    }
  };
  return (
    <div id="survey-score-reviews">
      <BreadcrumbComponent
        items={[
          { label: "Reports Store", path: "/reports-store" },
          { label: "Mean Scoring Report", path: "/mean-scoring" },
        ]}
      />

      <div className="flex items-center flex-col lg:flex-row xl:flex-row 2xl:flex-row space-y-5 lg:space-y-0 xl:space-y-0 2xl:space-y-0 mt-8">
        <div className="flex-1">
          <p className="dashboard-page-title text-gray-40 my-3">
            Mean Scoring Report
          </p>
        </div>
        <div className="flex-1 text-right">
          {/* <Button variant="outlined" className="download-btn">
            Download Report
          </Button> */}
          {/* can download: XLS, PDF, JPEG, SVG */}
        </div>
      </div>

      <GenericFilter
        onChange={(data) =>
          setState((prevState) => ({ ...prevState, filterData: data }))
        }
      />

      <div className="w-full mt-7 overflow-hidden">
        <Swiper slidesPerView={3}>
          {state.pieChartValue.map((chart, index) => (
            <div
              key={`pie-chart-card-${index}`}
              className="chart-card relative"
              onClick={() => handleOnPieChartSelect(chart)}
            >
              <GraphItem
                key={index}
                showCharts={state.showCharts}
                index={index}
                chart={chart}
                handleOnPieChartSelect={handleOnPieChartSelect}
              />
            </div>
          ))}
        </Swiper>
      </div>

      {state.detailedData && (
        <div className="my-20 relative bg-white p-5">
          <table className="table-fixed">
            <tbody>
              <tr>
                <th className="question-header-column">
                  <p className="font-bold capitalize">
                    {state.detailedData.label}
                  </p>
                </th>
                {state.detailedData.dataLabel.map((label, index) => (
                  <th
                    className="align-bottom vertical-header result-column"
                    key={index}
                  >
                    <div className="vertical-table-header py-10">
                      <div className="w-2 h-2 bg-purple-0 rounded-full" />
                      <p className="text-sm font-normal">{label}</p>
                    </div>
                  </th>
                ))}
              </tr>
            </tbody>
          </table>
          <table className="table-fixed mt-48">
            <tbody>
              {state.detailedData.dataValues.map((data, index) => (
                <tr className="border-t border-gray-50" key={index}>
                  <td className="question-column value-col">
                    <p className="text-sm">{data.label}</p>
                  </td>
                  <td className="result-column text-left value-col">
                    <p className="text-sm">{data.Agree}</p>
                  </td>
                  <td className="result-column text-left value-col">
                    <p className="text-sm">{data.Disagree}</p>
                  </td>
                  <td className="result-column text-left value-col">
                    <p className="text-sm">{data.Neutral}</p>
                  </td>
                  <td className="result-column text-left value-col">
                    <p className="text-sm">{data["Strongly Agree"]}</p>
                  </td>
                  <td className="result-column text-left value-col">
                    <p className="text-sm">{data["Strongly Disagree"]}</p>
                  </td>
                  <td className="result-column text-left value-col">
                    <p className="text-sm">
                      {!data["N/A"] ? "null" : data["N/A"]}
                    </p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="flex-1 mt-5 text-right">
        {/* <Button variant="outlined" className="download-btn">
          Download Report
        </Button> */}
      </div>
    </div>
  );
};

const GraphItem = ({ chart, index, handleOnPieChartSelect, showCharts }) => {
  const ref = useRef(null);

  return (
    <React.Fragment>
      <div className="absolute top-3 right-12 z-10">
        <DownloadButton targetRef={ref} name={chart.label} />
      </div>
      <div className="chart-carousel-card bg-white" ref={ref}>
        <div className="flex flex-row items-center p-4">
          <p className="flex-1 font-bold ml-2 capitalize">{chart.label}</p>
        </div>
        <div className="chart-container">
          {showCharts && (
            <PieChart data={chart.data} metaConfig={chart.metaConfig} />
          )}
        </div>

        <div className="flex flex-row flex-wrap items-center px-7 pb-2">
          {chart.metaConfig.fields.map((field, ind) => (
            <div
              className="w-1/2 flex flex-row items-center mb-3 space-x-2"
              key={ind}
            >
              <div
                className="rounded-full h-2 w-2"
                style={{ backgroundColor: chart.metaConfig.colors[ind] }}
              />
              <span className="text-sm text-gray-30">{field.title}</span>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
};

export default MeanScoreReport;
