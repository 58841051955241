import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';
import { useSelector } from "react-redux";
import { checkIsUk } from 'helpers/checkIsUK';

const AlertModal = (props) => {
  const user = useSelector((state) => state.user.user);
  const { projectId } = user.userData ? user.userData : user.user;
  const isUK = projectId?.Project_Abbreviation?.includes("UK");
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: '#f5f5f5',
    boxShadow: 24,
    p: 4,
  };
  const { open = 'no-record' } = props
  const getTitle = () => {
    switch(open) {
      case 'purchase':
        return 'Purchase Required';
      case 'admin-maintenance':
        return 'Maintenance';
      case 'admin':
      case 'no-record': 
      case 'no-fee':
      default:
        return 'No Available Records';
    }
  }

  const getDesc = () => {
    switch(open) {
      case 'purchase':
        return <>Uh oh! We noticed you didn’t purchase last year’s Feedback Data Dashboard. Please purchase it for the previous year's program in the Reports Store. If you need help, please write to <a className="text-red-10" href="mailto:answers@workforcerg.com">answers@workforcerg.com</a></>
      case 'admin':
        return 'This feature is coming soon so stay tuned!'
      case 'admin-maintenance':
        return 'This feature is in maintenance.'
      case 'no-fee': 
        return `We do not have the data necessary to compile this report. This is due to your ${checkIsUk() ? 'organisation' : 'organization'} either not participating in the previous year’s program or not opting to purchase the Feedback Data Dashboard at that time.`
      case 'no-record': 
      default:
        return `Our records show that your ${checkIsUk() ? 'organisation' : 'organization'} did not participate last year. We do not have the data necessary to compile this report.`
    } 
  }
  console.log('huuh?', open);
  return (
    <Modal
      {...props}
      open={!!open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h6" component="h2" fontWeight="600" marginBottom="30px" textAlign="center">
          {getTitle()}
        </Typography>
        <Typography component="p" textAlign="center" marginBottom="30px">
          {getDesc()}
        </Typography>
      </Box>
    </Modal>
  )
}

export default AlertModal
