import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContentText,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { color } from '@mui/system';

import FormDialog from '../../../../../components/form-dialog'
import SelectDialogContent from './select-dialog-content'

import DataIcon from '../../../../../assets/svg/data'
import ExportIcon from '../../../../../assets/svg/export'
import CustomReportIcon from '../../../../../assets/svg/custom-report'
import KeyDriverIcon from '../../../../../assets/svg/key-driver'
import EditListIcon from '../../../../../assets/svg/edit-list'

const items = [
    {
        icon: <DataIcon />,
        name: 'Pull or Resync Data From Checkmarket',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Turpis in eu mi bibendum neque egestas.',
        edit: false,
    },
    {
        icon: <ExportIcon />,
        name: 'Export Report',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Turpis in eu mi bibendum neque egestas.',
        edit: false,
    },
    {
        icon: <CustomReportIcon />,
        name: 'Upload Custom Reports for Clients',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Turpis in eu mi bibendum neque egestas.',
        edit: false,
    },
    {
        icon: <KeyDriverIcon />,
        name: 'Upload Key Driver Data',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Turpis in eu mi bibendum neque egestas.',
        edit: false,
    },
    {
        icon: <EditListIcon />,
        name: 'Edit Best Companies List',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Turpis in eu mi bibendum neque egestas.',
        edit: true,
    }
]

function Modal({ title, show, onCloseModal }) {
    return (
        <Dialog
            fullWidth
            open={show}
            aria-labelledby="responsive-dialog-title"
            aria-describedby="responsive-dialog-description"
        >
            <FormDialog title={title} onClose={() => onCloseModal()}>
                <SelectDialogContent />
            </FormDialog>
        </Dialog>
    )
}

function Card({ data = {}, onClick }) {
    const BootstrapButton = styled(Button)({
        color: 'red',
        boxShadow: 'none',
        textTransform: 'none',
        fontSize: 14,
        lineHeight: 1.5,
        padding: '6px 0',
        fontFamily: [
            '-apple-system',
            'BlinkMacSystemFont',
            '"Segoe UI"',
            'Roboto',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif',
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(','),
        '&:hover': {
            background: 'none',
            boxShadow: 'none',
        },
        '&:active': {
            background: 'none',
            boxShadow: 'none',
        },
        '&:focus': {
            background: 'none',
            // boxShadow: '0 0 0 0.2rem rgba(0,123,255,.5)',
        },
    });

    return (
        <>
            <div className='py-4'>
                {data.icon}
            </div>

            <h3 className='font-semibold text-lg'>{data.name}</h3>

            <p className='font-normal text-sm text-gray-500 pt-4'>
                {data.description}
            </p>

            <div className="py-8">
                <div className='absolute bottom-4'>
                    {data.edit ? (
                        <Link className="flex items-center text-red-500 text-sm" to="/admin/data/edit">
                            Edit List
                            <i className='bx bx-chevron-right text-2xl text-black'></i>
                        </Link>
                    ) : (
                        <BootstrapButton onClick={() => onClick(data)}>
                            Select Client
                            <i className='bx bx-chevron-right text-2xl text-black'></i>
                        </BootstrapButton>
                    )}
                </div>
            </div>
        </>
    )
}

const Content = () => {
    const [showDialog, setShowDialog] = useState(false);
    const [details, setDetails] = useState({});

    const handleClick = (param) => {
        setDetails(param)
        handleDialog();
    }

    const handleDialog = () => {
        setShowDialog(!showDialog);
    }

    return (
        <>
            <Modal
                show={showDialog}
                title={details.name}
                onCloseModal={handleDialog}
            />

            <div className='w-full flex flex-wrap grid gap-4 grid-cols-3 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3'>
                {items.map((item, index) => (
                    <div className='relative bg-white w-full sm:w-full md:w-96 lg:w-80 xl:w-96 p-4'>
                        <Card
                            key={index}
                            data={item}
                            onClick={handleClick}
                        />
                    </div>
                ))}
            </div>
        </>
    )
}

export default React.memo(Content)