import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router';
import { Button, List, ListItem }from '@mui/material';
import { useDispatch } from 'react-redux'

import AppActions from "redux/modules/app/actions"
import { WorkforceLogo } from 'components/svg-components/index.js'
import NavbarUserMenu from './header/user-menu'

const DashboardHeaderAdmin = () => {

	const dispatch = useDispatch()

	useEffect(() => {
		window.addEventListener('resize', () => {
			const width = window.innerWidth
			if (width > 1439) {
				dispatch(AppActions.setSideMenuDrawerShow(false))
			}
		});

		return () => {
			window.removeEventListener('resize', () => {})
		}
	}, [])

	return (
		<React.Fragment>
			<div id="content-layout-header" className="content-horizontal-padding">

				<div className="flex-1 flex md:hidden lg:hidden xl:hidden 2xl:hidden flex-row items-center">		
					<span className="svg-w-32">
						<WorkforceLogo />
					</span>
				</div>

				<div className="flex-1 flex flex-row items-center justify-end space-x-10">

					<div className="hidden md:block lg:block xl:block 2x:block">
						<NavbarUserMenu />
					</div>

					<div className="lg:hidden xl:hidden 2x:hidden" style={{ marginLeft: '0px' }}>
						<Button
							variant="text"
							onClick={() => {
								dispatch(AppActions.setSideMenuDrawerShow(true))
							}}
						>
							<i className='bx bx-menu text-3xl cursor-pointer'></i>
						</Button>
					</div>
				</div>

			</div>
		</React.Fragment>
	)

}

export default DashboardHeaderAdmin