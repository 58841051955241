import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Button, Box, Typography, Paper, Grid } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ReportsHeader from "../../components/reports-header";
import SectionLoading from "components/loaders/SectionLoading";
import DemoHeatMap from "assets/images/Sample Heatmap.xlsx";
import ReportDescription from "../../components/report-description";
import { useSelector, useDispatch } from "react-redux";
import AppAction from 'redux/modules/app/actions';
import { API_ENDPOINT } from "config/api";
import downloadFile from "helpers/downloadFile";
import HeatmapChart from "components/charts/HeatmapChart";
import { heatmapChartMock } from "components/charts/mock-data/charts-data";
import Alert from "@mui/material/Alert";
import Skeleton from "@mui/material/Skeleton";
import LoadingButton from "@mui/lab/LoadingButton";

import useAccessible from 'components/hooks/useSideBarAccessible';
import PageDropdown from "../../components/page-dropdown";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.primary,
}));

const pages = [{
  "id": 'employee-response-breakdown',
  "path": "/employee-response-breakdown",
  "title": "Employee Response Breakdown"
},
{
  "id": 'detailed-results',
  "path": "/detailed-results",
  "title": "Detailed Results"
},
{
  "id": 'response-patterns',
  "path": "/response-patterns",
  "title": "Response Patterns"
},
{
  "id": 'annual-trends',
  "path": "/annual-trends",
  "title": "Annual Trends"
}];

const ResponsePatterns = () => {
  const { http } = global.services;
  const dispatch = useDispatch();
  const isAvailable = useAccessible('WFR_Access');
  const {user, admin} = useSelector(({user}) => user);
  const {selectedProgramId, timeEstimate, clearcache} = useSelector(({ app }) => app);
  var { organizationProgram } = user.userData ? user.userData : user.user;
  const programSelected = organizationProgram?.find(i => i?.programId?._id == selectedProgramId) || organizationProgram[0]
  const programId = selectedProgramId || programSelected?.programId?._id;
  const [heatMapData, setHeatMapData] = useState([]);
  const [dataError, setDataError] = useState();
  const [sectionLoadingVisible, setSectionLoadingVisible] = useState(false);
  const [upperLimitPos, setUpperLimitPos] = useState("80");
  const [lowerLimitPos, setLowerLimitPos] = useState("70");
  const [upperLimitNeg, setUpperLimitNeg] = useState("10");
  const [upperPosColor, setUpperPosColor] = useState("selected");
  const [lowerPosColor, setLowerPosColor] = useState("selected");
  const [upperNegColor, setUpperNegColor] = useState("selected");
  const [previewLoading, setPreviewLoading] = useState(false);
  const [isPreviewGenerated, setIsPreviewGenerated] = useState(false);
  const [legendPercentage, setLegendPercentage] = useState({})
  const colorHash = {
    blue: "#3F7CFB",
    red: "#F3403C",
    green: "#00A46A",
    gray: "#F3F4F5",
    undefined: "transparent",
  };

  // const limits = [
  //   {
  //     limit: upperLimitNeg,
  //     color: upperNegColor === "selected" ? "#3F7CFB" : null,
  //   },
  //   {
  //     limit: lowerLimitPos,
  //     color: lowerPosColor === "selected" ? "#F3403C" : null,
  //   },
  //   {
  //     limit: upperLimitPos,
  //     color: upperPosColor === "selected" ? "#00A46A" : null,
  //   },
  // ];

  // const heatmapChartMockData = heatmapChartMock(limits, "#F3F4F5");

  const handleOnInputChange = (e) => {
    const { target } = e;

    const { name, value } = target;
    setIsPreviewGenerated(false);
    switch (name) {
      case "upperLimitPos":
        setUpperLimitPos(value);
        break;
      case "lowerLimitPos":
        setLowerLimitPos(value);
        break;
      case "upperLimitNeg":
        setUpperLimitNeg(value);
        break;
      case "upperPosColor":
        setUpperPosColor(value);
        break;
      case "lowerPosColor":
        setLowerPosColor(value);
        break;
      case "upperNegColor":
        setUpperNegColor(value);
        break;
      default:
    }
  };

  const handleOnClick = () => {
    setSectionLoadingVisible(true);
    var downloadable = false;
    setTimeout(()=> {
      if(!downloadable) {
        dispatch( AppAction.setAlert(`Compiling report. Estimated download time is ${timeEstimate} minute${timeEstimate > 1 ? "s" :''}`) )
      }
    }, [3000])
    let params = {
      selectedProgramId: programId,
      upperLimitPos: upperLimitPos,
      lowerLimitPos: lowerLimitPos,
      upperLimitNeg: upperLimitNeg
    }
    if( (programSelected["WFR_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) params.isDummy = true;
    if(clearcache) params.clearCache = clearcache;
    let queryParams = new URLSearchParams(params).toString();
    downloadFile(
      `${API_ENDPOINT}/client/generateHeatMap?${queryParams}`,
      undefined,
      "get"
    ).then((response)=>{
      const { headers } = response;
      const contentType = headers.get('content-type');
      if(contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then(({data}) => {
          const { signedUrl } = data.data
          const { pathname } = new URL(signedUrl);
          const dlFile = document.createElement("a");
          dlFile.download = pathname.replace('/', '');
          dlFile.href = signedUrl;
          dlFile.target = "_blank";
          dlFile.click();
        })
      }
    })
      .catch((err) => {
        console.log(err);
        return;
      })
      .finally(() => setSectionLoadingVisible(false));

    // if you need the graph. add it here.
  };

  const handlePreview = () => {
    fetchHeatMapPreview();
    setIsPreviewGenerated(true);
  };

  const fetchHeatMapPreview = async () => {
    setPreviewLoading(true);
    let query = {
      selectedProgramId: programId,
      upperLimitPos,
      lowerLimitPos,
      upperLimitNeg,
      isPreview: true,
    };
    if((programSelected["WFR_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) query.isDummy = true;
    if(clearcache) query.clearCache = clearcache;
    let response;
    try {
      response = await http.get(`/client/generateHeatMap`, query);
      if (response.status !== 200 || !response.data?.success) {
        setDataError(
          "An error occured while loading content. Please refresh the page and try again."
        );
        throw response.data || new Error(`Failed with status: ${response.status} ${response.statusText}`);
      }
    } catch (err) {
      setDataError(
        "An error occured while loading content. Please refresh the page and try again."
      );
      console.log(err);
      return;
    } finally {
      setPreviewLoading(false);
    }

    const {
      data: { data },
    } = response;
    const{ heatmapPreview, percentage} = data
    const dataMap = new Map(heatmapPreview.map((d) => [`${d.row}-${d.col}`, d]));
    setLegendPercentage(percentage)
    const tData = Array(7)
      .fill(null)
      .map((_, rowN) =>
        Array(54)
          .fill(null)
          .map((_, colN) => {
            const d = dataMap.get(`${rowN + 1}-${colN + 1}`);
            return {
              color: colorHash[d?.color],
            };
          })
      );
    setHeatMapData(tData);
    // console.log(heatmapChartMock);
  };

  return (
    <React.Fragment>
      {
        /* SECTION LOADING */
        sectionLoadingVisible && <SectionLoading />
      }
      <ReportsHeader
        download={{ types: ["none"] }}
        pageName="Response Patterns"
        accessGroup="WFR_Access"
        workforce
      />

      <ReportDescription description="Set custom parameters for high and low survey scores to generate a color-coded report highlighting workplace strengths and opportunities for improvement." />

      {
        !isAvailable && <div className="mt-4"><PageDropdown pages={pages} /></div>
      }

      <div className="response-section mt-8">
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={6}>
              <Item className="p-4 h-full">
                <Typography variant="h6" className="font-semibold">
                  Positive Response
                </Typography>
                <p className="mt-3 mb-2 text-gray-30">Agreement</p>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <p className="text-gray-30">Input Upper Limit</p>
                    <input
                      className="ml-4 p-1"
                      name="upperLimitPos"
                      onChange={handleOnInputChange}
                      placeholder={upperLimitPos}
                      value={upperLimitPos}
                      style={{ border: "1px solid #ccc", width: "50px" }}
                    />
                    <p className="ml-1">%</p>
                  </div>
                  <div className="flex items-center mt-2">
                    <p className="text-gray-30 mr-2">Select Color</p>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="upperPosColor"
                        name="upperPosColor"
                        defaultValue={upperPosColor}
                        onChange={handleOnInputChange}
                      >
                        <FormControlLabel
                          value="selected"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "#00A46A",
                                "&.Mui-checked": {
                                  color: "#00A46A",
                                },
                              }}
                            />
                          }
                          label="Green"
                          size="small"
                        />

                        <FormControlLabel
                          value="none"
                          control={<Radio size="small" />}
                          label="None"
                          size="small"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
                <div className="flex items-center justify-between">
                  <div className="flex">
                    <p className="text-gray-30">Input Lower Limit</p>
                    <input
                      className="ml-4 p-1"
                      name="lowerLimitPos"
                      onChange={handleOnInputChange}
                      placeholder={lowerLimitPos}
                      value={lowerLimitPos}
                      style={{ border: "1px solid #ccc", width: "50px" }}
                    />
                    <p className="ml-1 text-gray-30">%</p>
                  </div>
                  <div className="flex items-center">
                    <p className="mr-2 text-gray-30">Select Color</p>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="lowerPosColor"
                        name="lowerPosColor"
                        defaultValue={lowerPosColor}
                        onChange={handleOnInputChange}
                      >
                        <FormControlLabel
                          value="selected"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "#F3403C",
                                "&.Mui-checked": {
                                  color: "#F3403C",
                                },
                              }}
                            />
                          }
                          label="Red"
                          size="small"
                        />
                        <FormControlLabel
                          value="none"
                          control={<Radio size="small" />}
                          label="None"
                          size="small"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </Item>
            </Grid>
            <Grid item xs={6} md={6}>
              <Item className="p-4 h-full">
                <Typography variant="h6" className="font-semibold">
                  Negative Response
                </Typography>
                <p className="mt-3 mb-2 text-gray-30">Disagreement</p>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <p className="text-gray-30">Input Upper Limit</p>
                    <input
                      className="ml-4 p-1"
                      name="upperLimitNeg"
                      onChange={handleOnInputChange}
                      placeholder={upperLimitNeg}
                      value={upperLimitNeg}
                      style={{ border: "1px solid #ccc", width: "50px" }}
                    />
                    <p className="ml-1">%</p>
                  </div>
                  <div className="flex items-center">
                    <p className="mr-2 text-gray-30">Select Color</p>
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="upperNegColor"
                        name="upperNegColor"
                        defaultValue={upperNegColor}
                        onChange={handleOnInputChange}
                      >
                        <FormControlLabel
                          value="selected"
                          control={
                            <Radio
                              size="small"
                              sx={{
                                color: "#3F7CFB",
                                "&.Mui-checked": {
                                  color: "#3F7CFB",
                                },
                              }}
                            />
                          }
                          label="Blue"
                          size="small"
                        />
                        <FormControlLabel
                          value="none"
                          control={<Radio size="small" />}
                          label="None"
                          size="small"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                </div>
              </Item>
            </Grid>
          </Grid>
          
{/*            isPreviewGenerated &&
           <div
            className="p-7 mt-10"
            style={{
              backgroundColor: "#fff",
            }}
          >
            <HeatmapChart
              data={heatMapData}
              metaConfig={{
                rowSize: 54,
                colSize: 7,
              }}
            />
          </div> */}
          

          <div className="flex place-content-end items-center my-8">
            {isPreviewGenerated &&
              !dataError &&
              !previewLoading &&
              [
                {
                  percentage: legendPercentage?.bluePercentage,
                  color: colorHash.blue,
                },
                {
                  percentage: legendPercentage?.redPercentage,
                  color: colorHash.red,
                },
                {
                  percentage: legendPercentage?.greenPercentage,
                  color: colorHash.green,
                },
              ].map(({ percentage, color }, key) => (
                <div className="flex items-center mr-6 gap-2" key={`box-${key}`}>
                  <div
                    style={{
                      width: "16px",
                      height: "16px",
                      backgroundColor: color,
                    }}
                  />

                  <Typography component="p" className="text-sm" fontSize="14px">
                    {percentage}%
                  </Typography>
                </div>
              ))}
            {!isPreviewGenerated ? (
              <Button
                type="submit"
                onClick={handlePreview}
                variant="contained"
                className="ml-4 hover:bg-red-600"
                sx={{
                  background: "#F3403C",
                }}
              >
                Preview the report
              </Button>
            ) : (
              <LoadingButton
                loading={previewLoading}
                disabled={previewLoading}
                type="submit"
                onClick={handleOnClick}
                variant="contained"
                className="ml-4 hover:bg-red-600"
                sx={{
                  background: "#F3403C",
                }}
              >
                Download the report
              </LoadingButton>
            )}
          </div>
        </Box>
      </div>
    </React.Fragment>
  );
};

export default ResponsePatterns;
