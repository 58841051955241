import React from 'react'
import { Link } from 'react-router-dom';

import BreadcrumbComponent from '../../../../../components/breadcrumb'

const EditList = ({ name }) => {

    const navs = [
        {label: 'Data', path: '/admin/data'},
        {label: `Edit Best Companies List`, path: '/admin/data/edit'},
    ]

    return (
       <div>
           <BreadcrumbComponent items={navs}/>

            <div className='py-8'>
				<h1 className='font-bold text-2xl'>Data</h1>
			</div>

            <Link className="flex items-center" to="/admin/data">
                <i className='bx bx-chevron-left text-2xl text-black'></i>
                Back to All Data
            </Link>

            <div className='py-8'>
				<h1 className='font-bold text-2xl'>Best Companies List</h1>
			</div>
       </div> 
    )
}

export default EditList