import { useSelector } from "react-redux";
import { useLocation } from 'react-router-dom'
import { useMemo } from "react"

export default function useSideBarAccessible (access = null) {
	const mainUser = useSelector(({user}) => user.user)
	const { userData, user } = mainUser || {}
	const selectedProgramId = useSelector(({ app }) => app.selectedProgramId);

	const role = user?.role || userData?.role;
	const organizationProgram = userData?.organizationProgram || [];
	const projectId = userData?.projectId || {};
	const programSelected = organizationProgram?.find(i => i?.programId?._id == selectedProgramId) || organizationProgram[0]
	
	return useMemo(() => {
		if(role !== 'client' || !userData || !access || !programSelected )
			return true;

		if(!programSelected[access])
			return false;

		return programSelected[access].toLowerCase() === 'yes';
		
	}, [userData, role, programSelected])
}
