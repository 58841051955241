import * as React from "react"

const ExportSVG = (props) => (
  <svg
    width={60}
    height={60}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={30} cy={30} r={30} fill="#F5F5F5" />
    <path fill="#9C5EFB" d="M18 17h18v24H18z" />
    <path
      fill="#F5F5F5"
      d="M22 22h10v1H22zM22 25h10v1H22zM22 28h10v1H22zM22 31h10v1H22zM22 34h10v1H22z"
    />
    <path fill="#00A46A" d="M31 40h14v2H31z" />
    <path
      d="m41 32-3-3-3 3"
      stroke="#FFC955"
      strokeWidth={2}
      strokeLinecap="square"
    />
    <path
      d="M38 30v7"
      stroke="#FFC955"
      strokeWidth={2}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
)

export default ExportSVG
