import React, { useRef } from "react";
import DownloadButton from "components/charts/DownloadButton"
import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';
import Typography from "@mui/material/Typography";
import { makeStyles } from '@material-ui/core/styles';
import RadialBarChart from "components/charts/RadialBarChart"
import { annualEmpty } from '../constant/empty-report'

const useStyles = makeStyles({
	root: {
		margin: '5px 10px 35px',
		background: '#fff',
		padding: '10px 25px',
		boxShadow: "0 0 2px rgb(0 0 0 / 70%)",
	},
	chart_item: {
		margin: '0px 15px'
	}
});

const dummyData = [
	{
		data: [
			{
				year: '2022',
				value: 1 - Math.random().toFixed(2),
				metaConfig: { 
					valueKey: "value",
					colors: ['#8C60F3']
				}
			},
			{
				year: '2023',
				value: 1 - Math.random().toFixed(2),
				metaConfig: { 
					valueKey: "value",
					colors: ['#52AF79']
				}
			}
		]
	}
];

const CarouselSurveyAverage = ({onSlideChange, reports = dummyData }) => {
	const responsive = {
		superLargeDesktop: {
		  breakpoint: { max: 4000, min: 3000 },
		  items: 1
		},
		desktop: {
		  breakpoint: { max: 3000, min: 1024 },
		  items: 1
		},
		tablet: {
		  breakpoint: { max: 1024, min: 464 },
		  items: 1
		},
		mobile: {
		  breakpoint: { max: 464, min: 0 },
		  items: 1
		}
	  };
	const classes = useStyles();
	return (
		<React.Fragment>
			<Carousel
				responsive={responsive}
				arrows={true}
				showDots={false}
				afterChange={(nextSlide, { currentSlide }) => onSlideChange ? onSlideChange(currentSlide) : null}
			>
				{
					reports.map((report, reportIndex) => (
						<GraphItem key={`report-wrapper-${reportIndex}`} report={report} classes={classes} />
					))
				}
			</Carousel>
		</React.Fragment>
	)
}

const GraphItem = ({ reportIndex, classes, report }) => {
	const ref = useRef()
	return (<div className={classes.root + ' relative'}>
		<div className="absolute top-5 right-5 z-10">
			<DownloadButton targetRef={ref} name={report.name} />
		</div>
		<div ref={ref} className="bg-white">
			<div className="flex items-center justify-center my-2">
        <Typography variant="h5" className="text-2xl">Survey Average</Typography>
      </div>
			<div className="chart flex justify-center">
				{
					report.data.map(({year, metaConfig, value}, reportDataIndex) => (
						<div key={`report-chart-wrapper-${reportIndex}-${reportDataIndex}`} className={`pb-8 flex-1 ${classes.chart_item} ${reportDataIndex == 0 ? 'border-r' : ''}`}>
							<div style={{ height: '30vh', width: '100%' }} className="relative pt-4 pb-4 annual-trends-carousel-chart-item">
								<RadialBarChart
		            	data={[{label: year, value }]}
		            	metaConfig={metaConfig}
		            />
							</div>
							<p className="text-center text-gray-30 text-base mt-5 font-medium">{year || '20XX'}</p>
						</div>
					))
				}
			</div>
		</div>
	</div >)
}

export default CarouselSurveyAverage;
