import * as React from "react"

const CustomReportSvg = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={60}
    height={60}
    fill="none"
    {...props}
  >
    <circle cx={30} cy={30} r={30} fill="#F5F5F5" />
    <circle cx={32} cy={22} r={6} fill="#FFC955" />
    <path d="M44 41a12 12 0 0 0-24 0h24Z" fill="#FFC955" />
    <path fill="#9C5EFB" d="M14 27h12v17H14z" />
    <path
      fill="#fff"
      d="M16 30h8v1h-8zM16 33h8v1h-8zM16 36h8v1h-8zM16 39h8v1h-8z"
    />
  </svg>
)

export default CustomReportSvg
