import { useState, useEffect,useRef } from 'react'
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"

import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Typography from '@mui/material/Typography'
import SaveIcon from '@mui/icons-material/Save'

import UserAction from 'redux/modules/user/actions'
import GrayTextField from 'components/form-utilities/gray-text-field'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	forgetPass:{
		fontSize: '14px',
		lineHeight: '100%',
		margin: '0 !important',
		padding: '0 !important',
		color: '#fff',
		textTransform: 'none !important'
	},
	login:{
		backgroundColor: '#F3403C !important',
		width: '100%',
		borderRadius: '0 !important',
		textTransform: 'none !important',
		boxShadow: 'none !important'
	}

})
 
const AuthForm = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const history = useHistory()
	const { http } = global.services
	const [ state, setState ] = useState({
		username: '',
		password: '',
		submitting: false,
		usernameError:false,
		passwordError:false,
		error: null
	});

	const formRef = useRef(null);

	const handleSubmission = async (event) => {
		console.log(event)
		if (event)
			event.preventDefault()
		if(state.username && state.password){
			setState(prevState => ({ ...prevState, submitting: true, error: null, usernameError: false, passwordError: false }))

			try {
				const response = await http.post( '/user/management/login' , {
				email: state.username,
				password: state.password
			} )
				const responseData = response.data.data
				if(responseData['2faVerified']){
					history.push({
						pathname: '/admin/2FA',
						state: {
							key: responseData.key,
							password: state.password,
							email: state.username,
							userId:responseData.userId
						}
					})
				}else{
					const responseOTP = await http.put('/user/management/login', {
						enteredOtp: '123456', // if the 2fa is false
						userId: responseData?.userId
					})
					const responseDataOTP = responseOTP.data.data
					dispatch(UserAction.setUser(responseDataOTP))
					history.push('/admin/projects')
				}
			} catch (error) {
				const response = error.response;
				console.log('Authentication Error: ', response);
				const defaultErrorMsg = 'An unknown error occured. Please refresh the page and try again.'
				const stateToReplace = state

				if (error?.response) {
					stateToReplace.submitting = false
					stateToReplace.error = { title: 'Unknown Error', message: defaultErrorMsg }
					stateToReplace.usernameError = true
					stateToReplace.passwordError = true

					if (error.response.status === 403 || error.response.status === 400 || error.response.status === 404) {
						stateToReplace.error = { title: 'Invalid Credentials', message: 'Username or Password Incorrect' }
					}
				}

				setState(stateToReplace)
			}
		}
	}

	useEffect(() => {
		const handleKeyPress = (event) => {
			if (event.key === 'Enter') { 
			event.preventDefault(); // Prevent default form submission
        	formRef.current.click();
			}
		  };

		document.addEventListener('keypress', handleKeyPress);
		return () => {
		  document.removeEventListener('keypress', handleKeyPress);
		};
	  }, []);


	return (
		<form className="w-1/2" onSubmit={handleSubmission}>

			{
				state.error && (
					<div className="mb-10">
						<Alert severity="error">
							<AlertTitle>{ state.error?.title }</AlertTitle>
							{ state.error?.message }
						</Alert>
					</div>
				)
			}
			<Typography
				component="h1"
				fontSize="30px"
				fontWeight="700"
				marginBottom="30px"
				color="#fff"
		    >
				Log In
			</Typography>

			<div className="input-group mb-5">
				<Typography
					component="h4"
					fontSize="14px"
					marginBottom="10px"
					color="#fff"
			    >
					Email
				</Typography>
				<GrayTextField
					id="email-outlined-basic"
					className="w-full rounded-none"
					variant="outlined"
					placeholder="Enter your Email"
					onChange={ (event) => setState(prev => ({ ...prev, username: event.target.value })) }
					autoComplete="off"
					error={state.usernameError}
					// onKeyDown={handleKeyPress}
				/>
			</div>

			<div className="input-group mb-5">
				<Typography
					component="h4"
					fontSize="14px"
					marginBottom="10px"
					color="#fff"
			    >
					Password
				</Typography>
				<GrayTextField
					type="password"
					id="password-outlined-basic"
					className="w-full rounded-none"
					variant="outlined"
					placeholder="Enter your Password"
					onChange={ (event) => setState(prev => ({ ...prev, password: event.target.value })) }
					autoComplete="off"
					error={state.passwordError}
				/>
			</div>

			<div className="flex flex-row-reverse mb-5">
				<Button
					variant="text"
					color={'white'}
					size={'large'}
					className={classes.forgetPass}
					onClick={() => history.push('/forgot-password')}
				>
					Forgot Password?
				</Button>
			</div>

			<div className="flex flex-row">
				<LoadingButton
					ref={formRef}
					variant="contained"
					className={classes.login}
					onClick={(e) => handleSubmission(e) }
					size={'large'}
					loadingPosition="start"
					startIcon={<></>}
					loading={ state.submitting }
				>
					<span className={ state.submitting ? 'pl-5' : '' }>
						{ state.submitting ? 'Authenticating' : 'Log In' }
					</span>
				</LoadingButton>

				
			</div>
		</form>
	)

}

export default AuthForm