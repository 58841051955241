import React, { useState, useEffect } from "react"
import { Button } from '@mui/material'
import FilterDropdowns from "./filter-dropdown"
import { useSelector } from "react-redux";

const GenericFilterComponent = ({
	onChange = null,
	access = null,
	runFilters = (e)=>{},
}) => {

	const { http } = global.services
	const [loaded, setLoaded] = useState(false);
	const [ selected, setSelected ] = useState([])
  const {user, admin} = useSelector(({user}) => user);
	const selectedProgramId = useSelector(({app})=> app.selectedProgramId)

	const [ state, setState ] = useState({
		selectedFilterOptions: [],
		filters: [
			// {
			// 	name: 'length_of_service',
			// 	placeholder: 'Length of Service',
			// 	value: null,
			// 	options:[
			// 		{ label: '1 Year', value: '1_year', selected: false },
			// 		{ label: '2 Years', value: '2_years', selected: false },
			// 		{ label: '3 Years', value: '3_years', selected: false },
			// 		{ label: '4 Years', value: '4_years', selected: false },
			// 	]
			// },
			// {
			// 	name: 'generation',
			// 	placeholder: 'Generation',
			// 	value: null,
			// 	options:[
			// 		{ label: '1st Generation', value: '1st_generation', selected: false },
			// 		{ label: '2nd Generation', value: '2nd_generation', selected: false },
			// 		{ label: '3rd Generation', value: '3rd_generation', selected: false },
			// 		{ label: '4th Generation', value: '4th_generation', selected: false },
			// 	]
			// },
			// {
			// 	name: 'gender',
			// 	placeholder: 'Gender',
			// 	value: null,
			// 	options:[
			// 		{ label: 'Male', value: 'male', selected: false },
			// 		{ label: 'Female', value: 'female', selected: false }
			// 	]
			// },
			// {
			// 	name: 'ethnic_background',
			// 	placeholder: 'Ethnic Background',
			// 	value: null,
			// 	options:[
			// 		{ label: 'Western', value: 'western', selected: false },
			// 		{ label: 'Eastern', value: 'eastern', selected: false }
			// 	]
			// },
			// {
			// 	name: 'status',
			// 	placeholder: 'Status',
			// 	value: null,
			// 	options:[
			// 		{ label: 'Single', value: 'single', selected: false },
			// 		{ label: 'Married', value: 'married', selected: false }
			// 	]
			// },
			// {
			// 	name: 'job_role',
			// 	placeholder: 'Job Role',
			// 	value: null,
			// 	options:[
			// 		{ label: 'Developer', value: 'developer', selected: false },
			// 		{ label: 'HR', value: 'hr', selected: false }
			// 	]
			// },
			// {
			// 	name: 'department',
			// 	placeholder: 'Department',
			// 	value: null,
			// 	options:[
			// 		{ label: 'R & D', value: 'r_and_d', selected: false },
			// 		{ label: 'Administration', value: 'administrator', selected: false }
			// 	]
			// }
		]
		//filters: []
	})

	useEffect(() => {
		initialization()
		setLoaded(true);
	}, [])

	// useEffect(()=>{
	// 	if(loaded && selected.length == 0) {
	// 		setTimeout(()=>runFilters(new Event('trigger')), 1000);
	// 	}
	// }, [selected, loaded])

	useEffect(()=>{
		if (onChange) {
			const grabData = (obj)=>{
				if(Array.isArray(obj.value))
					return obj.value;
				if(typeof obj.value == 'string')
					return [obj.value];
				return [];
			}
			//Setup changes.
			let output = {};
			selected.map(({ data, questionId })=>{
				if(output[questionId] != null) {
					output[questionId] = [...output[questionId], ...grabData(data)]
				} else {
					output[questionId] = grabData(data);
				}
			})
			onChange(output)
		}
	},[selected])
	//Created once.
	const initialization = async () => {
		let query = {};
		var { organizationProgram } = (user.userData) ? user.userData : user.user;

		if(organizationProgram) {
	    const selectOrgProgram = organizationProgram.find(i => i.programId?._id == selectedProgramId) || organizationProgram[0];
			query.selectedProgramId = selectedProgramId || organizationProgram[0]?.programId?._id
		  if(access && (selectOrgProgram[access] || '').trim().toLowerCase() !== 'yes' && !admin) query.isDummy = true;
		}

		let response;
		try {
			response = await http.get('client/fetchSurveyFilter', {...query})
			if (response.status !== 200 || !response.data?.success) {
				throw response.data || new Error(`Failed with status: ${response.status} ${response.statusText}`);
			}
		} catch(err){
			console.log(err)
			return;
		}
		const filterData = []
		for (let counter = 0; counter < response.data.data.length; counter++) {
			let objectData = {
				name:response.data.data[counter].filterLabel,
				placeholder:response.data.data[counter].filterLabel,
				value: null,
				QuestionId: response.data.data[counter].QuestionId,
				options:[]
			}
			for(let key in response.data.data[counter].filterOption){
				if(response.data.data[counter].filterOption[key].length > 0) {
					objectData.options.push({
						label: key,
						value: response.data.data[counter].filterOption[key],
						selected:false
					})
				}
			}
			for(let dataCounter = 0; dataCounter < response.data.data[counter].filterOption.length; dataCounter++)
			{
				objectData.options.push({
					label: response.data.data[counter].filterOption[dataCounter].Caption,
					value: response.data.data[counter].filterOption[dataCounter].Caption,
					ResponseId:response.data.data[counter].filterOption[dataCounter].ResponseId,
					selected:false
				})
			}
			filterData.push(objectData)
		}
		setState(prevState => ({ ...prevState, filters: filterData }))
	}

	const handleFilterChange = (data, index, willSelect = true) => {
		const { filters, selectedFilterOptions } = state
		const filterOptionCode = `${index}-${data.index}`
		filters[index].options[data.index].selected = willSelect
		if (willSelect) {
			if (!selectedFilterOptions.includes(filterOptionCode)) {
				selectedFilterOptions.push(filterOptionCode)
				setSelected([ ...selected, { ...data, index: filterOptionCode }])
			} 
		} else {
			const selectedDataIndex = filterOptionCode.indexOf(filterOptionCode)
			selectedFilterOptions.splice(selectedDataIndex, 1)
			setSelected( selected.filter(i => i.index != filterOptionCode) )
		}
		setState(prevState => ({ ...prevState, filters: filters, selectedFilterOptions: selectedFilterOptions }))
	}

	const handleClearFilter = () => {
		const { filters } = state
		for (let filterCount = 0; filterCount < filters.length; filterCount++) {
			for (let optionCount = 0; optionCount < filters[filterCount].options.length; optionCount++) {
				filters[filterCount].options[optionCount].selected = false
			}
		}
		setSelected([])
		setState(prevState => ({ ...prevState, filters: filters, selectedFilterOptions: [] }))
	}

	return (
		<>
			<div className='flex flex-row items-center flex-wrap mt-5 ml-2'>
				<div className='flex flex-row items-center space-x-1 mr-5 mb-5'>
					<i className='bx bx-filter-alt text-md'></i>
					<span className='text-sm'>Filters:</span>
				</div>

				{
					state.filters.map((filter, index) => (
						<div key={`filter-dropdown-${index}`} className='mr-5 mb-5'>
							<FilterDropdowns
								key={`filter-dropdown-${index}`}
								name={filter.name}
								value={ filter.value?.label }
								questionId={filter.QuestionId}
								options={filter.options}
								placeholder={ filter.placeholder }
								onChange={ (data) => {
									handleFilterChange(data, index, true)
								} }
							/>
						</div>
					))
				}
			</div>

			<div className='flex flex-row flex-wrap'>
				{
					state.filters.map((filter, filterIndex) => (
						<div key={`filter-selected-${filterIndex}`} className='flex'>
							{
								filter.options.map((option, optionIndex) => (
									<React.Fragment key={`filter-${filterIndex}-${optionIndex}`}>
										{
											option.selected && (
												<div key={`option-${filterIndex}-${optionIndex}`} className='mr-5 mb-5 py-2 px-5 bg-gray-0 text-sm text-white flex flex-row items-center space-x-2'>
													<span>
														{ option.label }
													</span>
													<i className='bx bx-x text-lg cursor-pointer' onClick={() => handleFilterChange({ data: option, index: optionIndex }, filterIndex, false)}></i>
												</div>
											)
										}
									</React.Fragment>
								))
							}
						</div>
					))
				}
			</div>

			{
				state.selectedFilterOptions.length > 0 && (
					<div className=''>
						<Button variant="contained" color="error" className="px-14 rounded-none py-2" onClick={runFilters}>Apply</Button>
						<Button variant="text" color="error" className="px-10 py-2 text-black-0 capitalize" onClick={ () => handleClearFilter() }>Clear All Filters</Button>
					</div>
				)
			}
		</>
	)

}

export default GenericFilterComponent
