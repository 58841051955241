import { useState, useEffect } from 'react'
import Radio from '@mui/material/Radio'
import RadioGroup from '@mui/material/RadioGroup'
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel'

const types = [
	{ label: 'Email', value: 'email' },
	{ label: 'Username', value: 'username' }
]

const TwoFATypeForm = ({
	onSubmit,
	value = null
}) => {

	const [ state, setState ] = useState({
		type: types[0].value
	})
	
	useEffect(() => {

		if (value)
			setState(prevState => ({ ...prevState, type: value }))

	}, [value])

	const handleSubmission = () => {
		let typeData = null
		for (let counter = 0; counter < types.length; counter++) {
			if (types[counter].value === state.type) {
				typeData = types[counter]
				break
			}
		}

		onSubmit(typeData)
	}

	return (
		<div>

			<div className="mb-5">
				<p className="text-white">
					For greater protection of your data, choose the option with which we will set up two-factor verification for you in case you log in from a new device:
				</p>
			</div>

			<RadioGroup
        aria-label="auth-type"
        defaultValue={types[0].value}
        name="auth-type-radio-buttons-group"
				onChange={ (event) => setState(prevState => ({ ...prevState, type: event.target.value })) }
      >
        {
					types.map(type => (
						<FormControlLabel
							key={ `radio-button-${ type.value }` }
							value={ type.value }
							color={'primary'}
							sx={{ color: 'white' }}
							label={ type.label }
							control={
								<Radio sx={{ color: 'white' }} />
							}
							
						/>
					))
				}
      </RadioGroup>

			<div className="mt-5">
				<div className="border border-red-0 inline-block">
					<Button
						variant="text"
						classes={{ root: 'px-10 text-white' }}
						onClick={() => handleSubmission() }
					>
						Next
					</Button>
				</div>
			</div>
		</div>
	)

}

export default TwoFATypeForm