import {
	FolderSVG,
	WindowSVG,
	UserSVG,
	UsersSVG,
	ULListSVG,
	DatabaseSVG,
	EnvelopeSVG,
	UnlockSVG,
  CogSVG
} from "components/svg-components"

const routes = {
 items: [
  {
   id: "admin-projects",
   title: "Projects & Programs",
   icon: <FolderSVG />,
   path: "/admin/projects",
   sections: [],
   adminAccess: 'clientsProjectsProgramsAccess',
   forRole: ['operations', 'oprations', 'sales'],
  },
  // {
  //  id: "admin-clients",
  //  title: "Organizations",
  //  icon: <UserSVG />,
  //  path: "/admin/clients",
  //  sections: [],
  //  // adminAccess: '',
  //  forRole: ['operations', 'oprations', 'sales'],
  // },
  {
   id: "admin-users",
   title: "Portal Users",
   icon: <UsersSVG />,
   path: "/admin/users",
   sections: [],
   // adminAccess: '',
  },
  {
   id: "admin-order-log",
   title: "Order Log",
   icon: <ULListSVG />,
   path: "/admin/order-log",
   sections: [],
   adminAccess: 'orderLogAccess',
   forRole: ['operations', 'oprations', 'sales'],
  },
  {
    id: "admin-system-log",
    title: "Activity Log",
    icon: <CogSVG />,
    path: "/admin/system-log",
    sections: [],
  },
  {
   id: "admin-role-permissions",
   title: "Roles",
   icon: <UnlockSVG />,
   path: "/admin/role-permissions",
   sections: [],
   // adminAccess: '',
  },
  // {
  //  id: "admin-email",
  //  title: "Email",
  //  icon: <EnvelopeSVG />,
  //  path: "/admin/email",
  //  sections: [],
  // },
  // {
  //   id: "admin-settings",
  //   title: "Settings",
  //   icon: <CogSVG />,
  //   path: "/admin/settings",
  //   sections: [],
  //   forRole: ['operations', 'oprations', 'sales'],
  //  }
 ],
};

export default routes