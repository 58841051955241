export default function CopySVG(){
  return (  <svg
      xmlns="http://www.w3.org/2000/svg"
      width={17}
      height={17}
      fill="none"
    >
      <path d="M6.104 11.91H1V1h10.91v5.144" stroke="#000" />
      <path fill="#fff" stroke="#000" d="M6.591 6.592H16.5v9.909H6.591z" />
    </svg>
  )
}