import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import {
  Breadcrumbs,
  Button,
  Box,
  Typography,
  CircularProgress,
  Avatar,
  Skeleton,
} from "@mui/material";
import DownloadIcon from "assets/images/icons/download.png";
import BackIcon from "assets/images/icons/Vector.png";
import { Link } from "react-router-dom";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DownIcon from "assets/images/icons/down.png";
import ReportsHeader from "../components/reports-header";
import MainFilter from "components/filters/main-filter";
import ReportDescription from "../components/report-description";
import { useSelector, useDispatch } from "react-redux";
import SectionLoading from "components/loaders/SectionLoading";
import { API_ENDPOINT } from "config/api";
import downloadFile from "helpers/downloadFile";
import AppAction from "redux/modules/app/actions";

const ResponseDetail = () => {
  const { http } = global.services;
  const dispatch = useDispatch();

  const {user, admin} = useSelector(({user}) => user);
  const {selectedProgramId, timeEstimate, clearcache} = useSelector(({ app }) => app);
  const [loadQuestion, setLoadingQuestion] = useState(false);
  const [selectedFilter, setFilter] = useState(null);
  const [filterFormat, setFilterFormat] = useState(null);
  const [topics, setTopics] = useState([]);
  const [sectionLoadingVisible, setSectionLoadingVisible] = useState(false);
  const { organizationProgram, organizationId } = user.userData
    ? user.userData
    : user.user;
  const selectOrgProgram = organizationProgram.find(i => i.programId?._id == selectedProgramId) || organizationProgram[0];
  const programId = selectedProgramId || selectOrgProgram?.programId?._id;

  useEffect(() => {
    loadQuestions();
  }, []);

  const downloadReport = (isFilteredReport) => {
    const query = {
      selectedProgramId: programId
    }
    var downloadable = false;
    setTimeout(() => {
      if (!downloadable) {
        dispatch(
          AppAction.setAlert(
            `Compiling report. Estimated download time is ${timeEstimate} minute${
              timeEstimate > 1 ? "s" : ""
            }`
          )
        );
      }
    }, [3000]);
    let queryString = "";
    if (isFilteredReport) {
      let output = [];
      let data = {};
      if (Array.isArray(filterFormat?.filterOption)) {
        filterFormat?.filterOption?.map((data) => {
          output.push(data?.Caption);
        });
        data[filterFormat?.QuestionId] = output;
      } else if (typeof filterFormat?.filterOption == "object") {
        output = Object.values(filterFormat?.filterOption).flatMap((x) => x);
        data[filterFormat?.QuestionId] = output;
      }
      // queryString += `&queryFilter=${JSON.stringify(data)}`;
      query.queryFilter = JSON.stringify(data);
    }
    if(clearcache) query.clearCache = clearcache;
    if((selectOrgProgram["RD_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) query.isDummy = true;
    queryString = new URLSearchParams(query).toString();

    setSectionLoadingVisible(true);
    downloadFile(
      `${API_ENDPOINT}/client/generateHeatMapDetailed?${queryString}`,
      undefined,
      "get"
    )
      .then((response) => {
        const { headers } = response;
        const contentType = headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then(({ data }) => {
            const { signedUrl } = data.data;
            const { pathname } = new URL(signedUrl);
            const dlFile = document.createElement("a");
            dlFile.download = pathname.replace("/", "");
            dlFile.href = signedUrl;
            dlFile.target = "_blank";
            dlFile.click();
          });
        }
      })
      .catch((err) => {
        console.log(err);
        return;
      })
      .catch((err) => {
        console.log(err);
        return;
      })
      .finally(() => {
        downloadable = true
        setSectionLoadingVisible(false)
      });
  };

  const loadQuestions = async () => {
    setLoadingQuestion(true);
    let query = {};
    if (organizationProgram) {
      query.selectedProgramId =
        selectedProgramId || organizationProgram[0]?.programId?._id;
    }
    if(clearcache) query.clearCache = clearcache
    if((selectOrgProgram["RD_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) query.isDummy = true;
    try {
      const { data } = await http.get(
        "/client/responseDetailReportSectionQuestions",
        { ...query }
      );
      const setup = data.data
        ?.map((i) => {
          const key = Object.keys(i)[0];
          if (!key) return null;

          return {
            topic: key,
            data: i[key],
          };
        })
        .filter((i) => i);
      setTopics(setup);
    } catch (e) {}
    setLoadingQuestion(false);
  };

  const download = {
    data: null,
    filters: [],
    types: [],
  };
  const downloadConfigs = [
    {
      action: () => downloadReport(),
    },
    {
      context: "Download Filtered XLS",
      action: () => downloadReport(true),
    },
  ];


  return (
    <div className="response-detail-page">
      <ReportsHeader
      download={download}
      pageName="Response Detail"
      accessGroup={"RD_Access"}
      downloadConfigs={downloadConfigs}
      />
      {sectionLoadingVisible && <SectionLoading />}

      <ReportDescription
        description="This in-depth report reflects, by each survey question and for each demographic, the percentage of responses distributed across the entire 6-point scale."
      />

      <MainFilter onChange={setFilter} setFilterFormat={setFilterFormat} />
      {loadQuestion ? (
        <>
          <Skeleton
            animation="wave"
            height={100}
            style={{ marginBottom: "-2rem" }}
          />
          <Skeleton
            animation="wave"
            height={100}
            style={{ marginBottom: "-2rem" }}
          />
          <Skeleton
            animation="wave"
            height={100}
            style={{ marginBottom: "-2rem" }}
          />
          <Skeleton
            animation="wave"
            height={100}
            style={{ marginBottom: "-2rem" }}
          />
          <Skeleton
            animation="wave"
            height={100}
            style={{ marginBottom: "-2rem" }}
          />
        </>
      ) : (
        <div className="accordian-section mt-8">
          {topics.map((i, k) => (
            <RDAccordion
              http={http}
              key={"topic-" + k}
              topic={i}
              clearcache={clearcache}
              selectOrgProgram={selectOrgProgram}
              selectedProgramId={
                selectedProgramId || organizationProgram[0]?.programId?._id
              }
              selectedFilter={selectedFilter}
            />
          ))}
        </div>
      )}
    </div>
  );
};

const RDAccordion = ({
  http,
  selectedProgramId,
  selectedFilter,
  selectOrgProgram,
  clearcache,
  topic = {},
}) => {
  return (
    <Accordion className="mb-1">
      <AccordionSummary
        expandIcon={<ExpandMoreIcon sx={{ color: "#fff" }} />}
        aria-controls={`topic-` + topic.topic}
        id={`topic-` + topic.topic}
        sx={{
          background: "linear-gradient(to left, #EC413D, #FF683A)",
          color: "#fff",
        }}
      >
        <Typography>{topic.topic}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {selectedFilter != null &&
          topic.data?.map((i) => (
            <SUBAccordion
              key={`caption-${i.QuestionId}`}
              http={http}
              clearcache={clearcache}
              selectedProgramId={selectedProgramId}
              selectedFilter={selectedFilter}
              selectOrgProgram={selectOrgProgram}
              sub={i}
            />
          ))}
      </AccordionDetails>
    </Accordion>
  );
};

const SUBAccordion = ({
  http,
  selectedProgramId,
  selectOrgProgram,
  selectedFilter,
  clearcache,
  sub = {},
}) => {
  const [load, setLoad] = useState(false);
  const [subdata, setSub] = useState([]);
  const [isOpen, setOpen] = useState(false);
  const {admin} = useSelector(({user}) => user);

  useEffect(() => {
    // Since table only loads on open we check if it's open and rerun event handler on change of selected filter
    handleChange(new Event("Rerun check"), isOpen);
  }, [selectedFilter]);

  const handleChange = async (e, bool) => {
    setOpen(bool);
    if (bool) {
      let query = {};
      let params = { selectedProgramId, version: 1 };
      if(selectedFilter) {
        query = {
          QuestionId: sub.QuestionId,
          filterQuestion: selectedFilter,
        }
      }
      if( (selectOrgProgram["RD_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) params.isDummy = true;

      if(clearcache) params.clearCache = clearcache
      setLoad(true);
      try {
        const { data } = await http.post(
          "/client/responseDetailReportQuestionResult",
          {
            ...query
          },
          { params }
        );
        setSub(data.data);
      } catch (e) {}
      setLoad(false);
    }
  };

  return (
    <Accordion className="mb-1" onChange={handleChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        ria-controls={`caption-` + sub.QuestionId}
        id={`caption-` + sub.QuestionId}
      >
        <Typography>{sub.Caption}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {load ? <CircularProgress /> : <CustomTable results={subdata} />}
      </AccordionDetails>
    </Accordion>
  );
};

const CustomTable = ({ results = [] }) => {
  const [first, ...rest] = results;

  if (results.length == 0) return <div></div>;

  return (
    <div className="table-section mb-5">
      <table className="table-auto bg-white w-full py-1 mb-4">
        <thead className="bg-gray-200">
          <tr>
            {first.map((i, k) => (
              <th key={`header-` + k} className="text-xs w-1 pt-2  font-normal">
                {i}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rest.map((items, k) => (
            <CustomBodyRow
              key={`body-` + k}
              fontClass={k == rest.length - 1 ? "font-semibond" : "font-normal"}
              items={items}
            />
          ))}
        </tbody>
      </table>
    </div>
  );
};

const CustomBodyRow = ({ items = [], fontClass = "font-normal" }) => {
  return (
    <tr className="h-10">
      {items.map((i, k) => (
        <th
          key={`sub-` + k}
          className={`text-xs w-1 pt-2 ${fontClass} ${
            k == 0 ? "text-left" : "text-center"
          }`}
        >
          {/* // fcken bad response design man. */}
          {typeof i == "string"
            ? i
            : `${i.percentile || i.average} / ${i.respondentCount} respondent${
                i.respondentCount > 1 ? "s" : ""
              }`}
        </th>
      ))}
    </tr>
  );
};

export default ResponseDetail;
