import React, {useState, useEffect} from "react"
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import SaveIcon from '@mui/icons-material/Save'
import { Typography } from "@mui/material"
import UserAction from 'redux/modules/user/actions'
import GrayTextField from 'components/form-utilities/gray-text-field'
import { makeStyles } from "@material-ui/styles"
import { useHistory } from "react-router"

const useStyles = makeStyles({
	heading:{
		textAlign:'center',
		fontWeight:'700 !important',
		fontSize:'32px',
		color:'#fff',
		marginBottom:'10px',
	},
	p:{
		fontSize:'14px',
		color:'#fff',
		width:'350px',
		textAlign:'center',
        margin: '25px 0px'
	},
	btn:{
		borderColor:'#C6411F !important',
		color:"#fff !important",
		width:'100% !important',
		borderRadius: '0 !important',
		"&:hover":{
			backgroundColor: '#F75626 !important',
		}
	}
})
const OtpForm = () => {
	const classes = useStyles();
	const history = useHistory();
	const { http } = global.services
	const [seconds, setSeconds] = useState(30);
	const [key, setKey] = useState(history.location.state.key);
	const [username, setUsername] = useState(history.location.state.username);
	const [forAdmin, setForAdmin] = useState(false);
	const [ state, setState ] = useState({
		code:'',
		error: null
	})

	useEffect(() => {
		let myInterval = setInterval(() => {
			if (seconds > 0) {
			  setSeconds(seconds - 1)
			}
		}, 1000)
		  return () => {
			clearInterval(myInterval)
		  }
	})

	useEffect(() => {
		if(username){
			let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
			if ( regex.test(username) ) {
				setForAdmin(true)
			}
		}
	},[])
	
	const payload = forAdmin ? { email: username } : { username: username }

	const sendOtp = async(event = null) =>{
		event.preventDefault()
		try {
			const response = await http.post('/user/forgot-password', payload)
			const responseData = response.data.data
			if(responseData) {
				setKey(responseData.key)
				setSeconds(30)
			}
		} catch (error) {
			const response = error.response;
		}	
	}

	const handleSubmission = async (event = null) => {
		if (event){
			event.preventDefault()
			if(state.code) {
				history.push({
					pathname: '/reset-password',
					state: {
						key:key,
						code:state.code
					}
				})
			}else{
				setState(prev => ({ ...prev, error: 'field should not be empty' }))
			}
		}
	}

	return (
		<div>
			{
				state.error && (
					<div className="mb-10">
						<Alert severity="error">
							{ state.error }
						</Alert>
					</div>
				)
			}
			<Typography variant="h4" className={classes.heading}>
				Enter Code
			</Typography>
			<p className={classes.p}>
			Enter the 4 digit code that you received in your email.
			</p>
			<form onSubmit={ (event) => handleSubmission(event) }>
				<div className="input-group mb-5">
					<Typography
						component="h4"
						fontSize="14px"
						marginBottom="10px"
						color="#fff"
				    >
						Enter Code
					</Typography>
					<GrayTextField
						id="email-outlined-basic"
						className="w-full"
						placeholder="Enter Code"
						variant="outlined"
						onChange={ (event) => setState(prev => ({ ...prev, code: event.target.value, error: null })) }
						autoComplete="off"
					/>
				</div>
				<div className="flex item-center mb-5">
					<p style={{color:'red'}}>
						{
							seconds > 0 ? `00:${seconds} seconds`: <button style={{color:'#fff'}} className="text-sm" onClick={sendOtp}>Send Again</button>
						}</p>
				</div>
				<div className="inline-block flex flex-row space-x-5">
					<Button
						type="submit"
						className={classes.btn}
						variant="outlined"
						size="large"
					>
						Continue
					</Button>
				</div>
			</form>
			<p className={classes.p} style={{ color: 'rgba(255,255,255, 0.6)' }}>
				I remember the password.
				<a className="ml-2" style={{color:'#FFF', cursor: 'pointer'}} onClick={() => history.push('/login')}>Log In</a>
			</p>
		</div>
	)

}

export default OtpForm