import React, { useState, useEffect } from "react"
import { Button } from '@mui/material'
import { useSelector } from 'react-redux'
import FilterDropdowns from "components/filters/generic-filter/filter-dropdown"
import { useLocation } from 'react-router-dom'

const EVFilterComponent = ({
	onChange = null,
	setFilterId= ()=> {},
	runFilters = (e)=>{},
}) => {
	const location = useLocation();
	const { http } = global.services
	const [loaded, setLoaded] = useState(false);
	const [ selected, setSelected ] = useState([])
	const [ state, setState ] = useState({
		selectedFilterOptions: [],
		filters: [
			{
				name: 'job_role',
				placeholder: 'Job Role',
				value: null,
				options:[
					{ label: 'Operation Manager', value: 'Operation Manager', selected: false }
				]
			}
		]
	})

	const user = useSelector(({user}) => user.user);
	const {selectedProgramId, clearcache} = useSelector(({app})=> app)
	const { organizationProgram } = user.userData ? user.userData : user.user;
	const selectOrgProgram = organizationProgram?.find(i => i.programId?._id == selectedProgramId) || organizationProgram[0];
	const programId = selectedProgramId || selectOrgProgram?.programId?._id;
	const EVFilter = selectOrgProgram?.EV_Sorting_Filter || null;

	const loadFilter = async () => {
		let query = {};
		if(organizationProgram) query.selectedProgramId = programId;
		if(clearcache) query.clearCache = clearcache;
		const { data } = await http.get('client/fetchSurveyFilter', {...query})
		const boughtFilter = data.data?.find(i => i.filterLabel == EVFilter);
		if(boughtFilter) {
			setFilterId(boughtFilter?.QuestionId)
			let activeFilter = [];
			if(location.search != ''){
				const search = new URLSearchParams(location.search);
				activeFilter = search.get(boughtFilter?.QuestionId).split(',')
			}
			let options = [];
			if( Array.isArray(boughtFilter?.filterOption) ) {
				options = boughtFilter?.filterOption?.map(i => ({
					label: i.Caption,
					value: i.Caption,
					ResponseId: i.ResponseId,
					selected: activeFilter.length > 0 ? (activeFilter.indexOf(i.Caption) >= 0) : false
				}))
			} else {
				for( let key in boughtFilter?.filterOption) {
					options.push({
						label: key,
						value: boughtFilter?.filterOption[key],
						selected: activeFilter.length > 0 ? (activeFilter.indexOf(key) >= 0) : false
					})
				}
			}
			let selected = options.filter(i => i.selected).map((i, index)=> ({ 
				index: `0-${index}`, 
				data:i, 
				questionId: boughtFilter?.QuestionId, 
			}));
			setLoaded(true)
			setState({
				...state,
				filters: [{
					name: boughtFilter.filterLabel,
					placeholder: boughtFilter.filterLabel,
					value: null,
					QuestionId:  boughtFilter.QuestionId,
					options
				}],
				selectedFilterOptions: selected.map(i => i.index)
			})
			if(activeFilter.length > 0 && selected.length > 0) {
				setSelected(selected)
				runFilters(new Event('Triggered'))
			}
		}
	}

	useEffect(loadFilter, [selectedProgramId])

	useEffect(()=>{
		if (onChange) {
			const grabData = (obj)=>{
				if(Array.isArray(obj.value))
					return obj.value;
				if(typeof obj.value == 'string')
					return [obj.value];
				return [];
			}
			//Setup changes.
			let output = {};
			selected.map(({ data, questionId })=>{
				if(output[questionId] != null) {
					output[questionId] = [...output[questionId], ...grabData(data)]
				} else {
					output[questionId] = grabData(data);
				}
			})
			onChange(output)
		}
	},[selected])

	const handleFilterChange = (data, index, willSelect = true) => {
		const { filters, selectedFilterOptions } = state
		const filterOptionCode = `${index}-${data.index}`
		filters[index].options[data.index].selected = willSelect
		if (willSelect) {
			if (!selectedFilterOptions.includes(filterOptionCode)) {
				selectedFilterOptions.push(filterOptionCode)
				setSelected([ ...selected, { ...data, index: filterOptionCode }])
			} 
		} else {
			const selectedDataIndex = filterOptionCode.indexOf(filterOptionCode)
			selectedFilterOptions.splice(selectedDataIndex, 1)
			setSelected( selected.filter(i => i.index != filterOptionCode) )
		}
		setState(prevState => ({ ...prevState, filters: filters, selectedFilterOptions: selectedFilterOptions }))
	}

	const handleClearFilter = () => {
		const { filters } = state
		for (let filterCount = 0; filterCount < filters.length; filterCount++) {
			for (let optionCount = 0; optionCount < filters[filterCount].options.length; optionCount++) {
				filters[filterCount].options[optionCount].selected = false
			}
		}
		setSelected([])
		setState(prevState => ({ ...prevState, filters: filters, selectedFilterOptions: [] }))
	}

	if(!loaded) 
		return "";

	return (
		<>
			<div className='flex flex-row items-center flex-wrap mt-5 ml-2'>
				<div className='flex flex-row items-center space-x-1 mr-5 mb-5'>
					<i className='bx bx-filter-alt text-md'></i>
					<span className='text-sm'>Filters:</span>
				</div>

				{
					state.filters.map((filter, index) => (
						<div key={`filter-dropdown-${index}`} className='mr-5 mb-5'>
							<FilterDropdowns
								key={`filter-dropdown-${index}`}
								name={filter.name}
								value={ filter.value?.label }
								questionId={filter.QuestionId}
								options={filter.options}
								placeholder={ filter.placeholder }
								onChange={ (data) => {
									handleFilterChange(data, index, true)
								} }
							/>
						</div>
					))
				}
			</div>

			<div className='flex flex-row flex-wrap'>
				{
					state.filters.map((filter, filterIndex) => (
						<div key={`filter-selected-${filterIndex}`} className='flex'>
							{
								filter.options.map((option, optionIndex) => (
									<React.Fragment key={`filter-${filterIndex}-${optionIndex}`}>
										{
											option.selected && (
												<div key={`option-${filterIndex}-${optionIndex}`} className='mr-5 mb-5 py-2 px-5 bg-gray-0 text-sm text-white flex flex-row items-center space-x-2'>
													<span>
														{ option.label }
													</span>
													<i className='bx bx-x text-lg cursor-pointer' onClick={() => handleFilterChange({ data: option, index: optionIndex }, filterIndex, false)}></i>
												</div>
											)
										}
									</React.Fragment>
								))
							}
						</div>
					))
				}
			</div>

			{
				state.selectedFilterOptions.length > 0 && (
					<div className=''>
						<Button variant="contained" color="error" className="px-14 rounded-none py-2" onClick={runFilters}>Apply</Button>
						<Button variant="text" color="error" className="px-10 py-2 text-black-0 capitalize" onClick={ () => handleClearFilter() }>Clear All Filters</Button>
					</div>
				)
			}
		</>
	)

}

export default EVFilterComponent