import {
	SET_ALERT,
	SET_PAGE_LOADER,
	SET_CURRENT_ROUTE,
	SET_SIDE_MENU_DRAWER_SHOW,
	SET_CURRENT_PAGE_MOUNTED,
	SELECT_PROGRAM_ID,
	SET_HEADER_YEAR,
	VIEW_ORG_DASHBOARD,
	SET_TIME_ESTIMATE,
	USE_CLEAR_CACHE,
	TOGGLE_FDD_MODAL
} from './types'

const initialState = {
	alert: null,
	alertType: 'info',
	pageLoader: { title: '', message: '', show: false },
	currentRoute: null,
	showSideMenuDrawer: false,
	currentPageMounted: false,
	selectedProgramId: null,
	headerState: {
		year: 2022,
		place: 'New York'
	},
	program: null,
	timeEstimate: 1,
	organization: null,
	fddmodal: true,
	clearcache: JSON.parse(localStorage.getItem('clearcache')) || false 
};

const reducer = (state = initialState, {type, payload}) => {

	switch (type) {
		case SET_ALERT: 
			return { ...state, alert: payload.alert, alertType: payload.type }
		case SET_TIME_ESTIMATE:
			return { ...state, timeEstimate: !isNaN(payload) && payload > 0 ? payload : 1}

		case SET_PAGE_LOADER:
			let data =	{ title: 'Page Loading', message: 'The page is loading. Please wait...', show: initialState.pageLoader.show }
			if (payload?.title !== null && payload?.title !== undefined && payload?.message !== null && payload?.message !== undefined)
				data = { ...initialState, ...payload }
				
			return { ...state, pageLoader: data }

		case SET_CURRENT_ROUTE:
			return { ...state, currentRoute: payload }

		case SET_SIDE_MENU_DRAWER_SHOW:
			return { ...state, showSideMenuDrawer: payload }

		case SET_CURRENT_PAGE_MOUNTED:
			return { ...state, currentPageMounted: payload }

		case SET_HEADER_YEAR: 
			return {...state, headerState: {...state.headerState, ...payload } }

		case SELECT_PROGRAM_ID:
			localStorage.setItem('selectedProgramId', payload);
			return {...state, selectedProgramId: payload }
			
		case VIEW_ORG_DASHBOARD:
			return {...state, program: payload.program, organization: payload.organization }

		case USE_CLEAR_CACHE:
			localStorage.setItem('clearcache', payload);

			return {...state, clearcache: payload }

		case TOGGLE_FDD_MODAL:
			return {...state, fddmodal: payload }
			
		default:
			return state;

	}

}

export default reducer
