import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={60}
    height={60}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={30} cy={30} r={30} fill="#F5F5F5" />
    <path fill="#9C5EFB" d="M19 18h18v24H19z" />
    <path
      fill="#F5F5F5"
      d="M23 23h10v1H23zM23 26h10v1H23zM23 29h10v1H23zM23 32h10v1H23zM23 35h10v1H23z"
    />
    <path fill="#00A46A" d="M32 41h14v2H32z" />
    <path
      d="m42 33-3-3-3 3"
      stroke="#FFC955"
      strokeWidth={2}
      strokeLinecap="square"
    />
    <path
      d="M39 31v7"
      stroke="#FFC955"
      strokeWidth={2}
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgComponent
