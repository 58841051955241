import React from 'react'
import { Typography, Link } from "@mui/material"

const ReportDescription = ({ description, annualtrends }) => {
    return (
        <div className="w-1/2 my-8">
            <Typography component="p" fontSize="14px" marginBottom="30px">
                {description
                    ? description
                    : `Lorem ipsum dolor sit amet, consectetur adipiscing elit. In placerat gravida sed tellus quisque in at. Quam tincidunt nibh ornare adipiscing.`}
            </Typography>

            {
                annualtrends != null &&
                <Typography component="p" fontSize="14px" fontStyle="italic"  marginBottom="30px" >
                    NOTE: Workforce Research Group does not have access to reports from prior years of the competition.
                </Typography>
            }
        </div>
    )
}

export default ReportDescription