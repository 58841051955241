import { useState } from 'react'
import { useDispatch, useSelector } from "react-redux"

import ClickAwayListener from '@mui/material/ClickAwayListener'
import Popper from '@mui/material/Popper'
import Grow from '@mui/material/Grow'
import Paper from '@mui/material/Paper'
import MenuList from '@mui/material/MenuList'
import MenuItem from '@mui/material/MenuItem'

const years = [ 2022, 2021]



const YearDropdown = ({
	organizationProgram = [],
	year = null,
	onChange = null
}) => {

	const [ showMenu, setShowMenu ] = useState(false)
	const [ state, setState ] = useState({
		menuElement: null
	})

	const handleChange = (item) => {
		setShowMenu(false)
		if (onChange) onChange(item)
	}

	return (
		<div
			className="relative bg-white z-10"
			onClick={(event) => {
				if(organizationProgram.length == 1) return;
				if (!showMenu) {
					setState(prevState => ({ ...prevState, menuElement: event.currentTarget }))
					setShowMenu(true)
				}
			}}
		>
			<div
				id="year-dropdown"
				className="flex flex-row items-center bg-gray-10 px-5 space-x-7 cursor-pointer"
			>
				<span>{ year || 'Select Year' }</span>
				<i className='bx bx-chevron-down'></i>
			</div>
			<Popper
				open={ showMenu }
				anchorEl={ state.menuElement }
				// role={undefined}
				style={{ position: 'absolute', margin: '1000px' }}
				transition
				disablePortal
				placement={'bottom-start'}
			>
				{({ TransitionProps, placement }) => (
					<Grow
						{...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom' ? 'right top' : 'right bottom',
						}}
					>
						<Paper>
							<ClickAwayListener onClickAway={ () => setShowMenu(false) }>
								<MenuList id="split-button-menu">
									{
										organizationProgram.length > 0 ?
										organizationProgram.map((item) => (
											<CustomMenu
												key={`year-${item._id}`}
												onChange={handleChange}
												item={item}
											/>
										))
										: <MenuItem onClick={()=>setShowMenu(false)}>
											<span className="pr-10">Empty Data</span>
										</MenuItem>
									}
								</MenuList>
							</ClickAwayListener>
						</Paper>
					</Grow>
				)}
			</Popper>

		</div>
	)
}

const CustomMenu = ({ item, onChange = () => {} }) => {
	const handleClick = () => onChange(item)
	const [yearItem] = (item?.programId?.Name || "No_Program 2000").toString().match(/\d{4}/g);
	return <MenuItem onClick={handleClick}>
		<span className="pr-10">{ yearItem }: {item?.programId?.Name}</span>
	</MenuItem>
}

export default YearDropdown
