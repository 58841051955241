import React, {useState, useEffect} from 'react'
import { Typography } from '@mui/material';
import {useStyles} from './cardStyle.js';
// import StackGrid from "react-stack-grid";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoCard from "./card" 
import Grid from '@mui/material/Grid';
const DemographicCards = ({data = []}) => {
    const classes = useStyles();
    const [node, setNode] = useState(null);

    const [subData, splitData] = useState([]);
    const split = 3;

    useEffect(()=> {
      let walk = 0
      let _subData = []
      for (var i = 0; i < data.length; i++) {
        if(walk == 3) walk = 0;
        _subData[walk] = (_subData[walk] == null) ? [ data[i] ] : [ ..._subData[walk], data[i] ];
        walk++;
      }
      splitData(_subData)
    },  [data])
  // take demographics data
  // make each demographic category a card with category name at top of card
  // list each item in the demographic category with number of people that make up that item
  //Dont change
  const onChange = () => {}


  return (
   <React.Fragment>
    <Typography
     className="mt-8 mb-5 text-xl font-semibold"
     color="text.primary"
    >
     Total Number of Survey Responses by Demographic Category
    </Typography>
    <Grid container>
        {
            subData.map((x, ind) => <Grid item key={ind} xs={4}>
                {
                    x.map(i => <InfoCard key={i.QuestionId} title={i.categoryLabel} data={i.options} onChange={onChange} classes={classes} />)
                }
            </Grid>)
        }
    </Grid>
   </React.Fragment>
  );
}

export default DemographicCards