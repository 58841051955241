import React, { useCallback, useRef } from "react";
import ReactDOM from "react-dom";
// import DonutChart from "components/charts/DonutChart";
import "react-multi-carousel/lib/styles.css";
import MoreIcon from "assets/images/icons/morevertical.png";
import Carousel from "react-multi-carousel";
import { makeStyles } from "@material-ui/core/styles";
import DownloadButton from "components/charts/DownloadButton";
import PieChart from "components/charts/PieChart";

const useStyles = makeStyles({
  root: {
    boxShadow: "0 0 2px rgb(0 0 0 / 70%)",
    margin: "25px 10px 30px 10px",
    transition: "transform .5s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
  carousel_item: {},
});

const GraphItem = ({
  step,
  data = {},
  showChart,
  onClickDownload,
  onSelect,
}) => {
  const classes = useStyles();
  const ref = useRef(null);

  return (
    <div
      tabIndex={step}
      className={`${classes.root} pt-3 mb-16 relative bg-white`}
      onClick={() => (onSelect ? onSelect(data) : null)}
    >
      <div className="absolute top-4 right-4 z-10">
        <DownloadButton targetRef={ref} name={data.label} />
      </div>
      <div ref={ref} className="bg-white">
        <div
          style={{ height: "35vh", width: "100%" }}
          className="relative py-2"
        >
          <div className="flex px-2 justify-between">
            <div className="left pl-4">
              <h5 className="font-semibold text-sm capitalize">{data.label}</h5>
              <p className="text-xs decoration-gray-100 text-gray-30 mt-1 mb-2">
                Category Averages
              </p>
            </div>
          </div>
          {/* <DonutChart 
                    data={data.data || []}
                    metaConfig={data.metaConfig}
                /> */}
          {showChart && (
            <PieChart data={data.data} metaConfig={data.metaConfig} />
          )}
        </div>
        <div className="flex flex-row flex-wrap items-center pl-8 mt-8 pb-4 content-start">
          {data.metaConfig.fields.map((field, fieldIndex) => (
            <div
              key={"field-" + fieldIndex}
              className="w-1/2 flex flex-row items-center gap-x-1.5 mb-2"
            >
              <div
                className="w-3 h-3 rounded-full"
                style={{ backgroundColor: data.metaConfig.colors[fieldIndex] }}
              />
              <small>{field.title}</small>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const PieChartSummary = ({ data = [], onSelect, showChart = true }) => {
  // const dountChartRef = useRef([])

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

  return (
    <React.Fragment>
      <Carousel responsive={responsive} arrows={true} showDots={true}>
        {data.length &&
          data.map((d, index) => (
            <GraphItem
              key={"graph-dl-" + index}
              step={index}
              data={d}
              onSelect={onSelect}
              showChart={showChart}
            />
          ))}
      </Carousel>
    </React.Fragment>
  );
};
export default PieChartSummary;
