import React, { useEffect, useState } from "react";
import { Box, InputLabel, FormControl, Select, MenuItem } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";
import {
  pieChartData,
  stackedBarChartData,
  donutChartData,
} from "components/charts/mock-data/charts-data";
import { Button, Alert } from "@mui/material";
import PieChartSummary from "./components/PieChartSummary";
import HorizontalBarChatSummary from "./components/HorizontalBarChatSummary";
import ReportsHeader from "../../components/reports-header";
import FilterFormComponent from "./components/FilterFormComponent";
import DownloadIcon from "assets/images/icons/download.png";
import GenericFilter from "components/filters/generic-filter/index";
import Menu from "@mui/material/Menu";
import Fade from "@mui/material/Fade";
import ReportDescription from "../../components/report-description";
import { useSelector, useDispatch } from "react-redux";
import downloadFile from "helpers/downloadFile";
import changeLabel from "helpers/changeLabel";
import { API_ENDPOINT } from "config/api";
import SectionLoading from "components/loaders/SectionLoading";
import AppAction from 'redux/modules/app/actions';
import AlertModal from "../annual-trends/components/AlertModal";

import useAccessible from 'components/hooks/useSideBarAccessible';
import PageDropdown from "../../components/page-dropdown";

const pages = [{
  "id": 'employee-response-breakdown',
  "path": "/employee-response-breakdown",
  "title": "Employee Response Breakdown"
},
{
  "id": 'detailed-results',
  "path": "/detailed-results",
  "title": "Detailed Results"
},
{
  "id": 'response-patterns',
  "path": "/response-patterns",
  "title": "Response Patterns"
},
{
  "id": 'annual-trends',
  "path": "/annual-trends",
  "title": "Annual Trends"
}];

const sequence = ['Agree', 'Neutral', 'Disagree'];

const DetailedResults = () => {
  // This page shows each category's responses
  // It should show a pie chart for each category with the % of response answers / total number of questions
  // the bar graph should show the category, and the questions in the category, and the % of each response / total responses to that question
  // the filters should be drop down menus w one selectable option on each menu.
  // when a filter is selected, it should show the responses from respondent who meet that filter criteria
  // const [pieChatSummary, setPieChatSummary] = useState(pieChartData);
  // const [horizontalBarChat, setHorizontalBarChat] = useState(stackedBarChartData)
  // const [donutData, setDonutData] = useState(donutChartData)
  const dispatch = useDispatch();
  const isAvailable = useAccessible('WFR_Access');
  const {user, admin} = useSelector(({user}) => user);
  const {selectedProgramId, timeEstimate, clearcache } = useSelector(({ app }) => app);
  const { organizationProgram } = user.userData ? user.userData : user.user;
  const programSelected = organizationProgram?.find(i => i?.programId?._id == selectedProgramId) || organizationProgram[0]
  const programId = selectedProgramId || programSelected?.programId?._id;

  const [filters, setFilters] = useState([]);

  const [loadingGraph, setLoadingGraph] = useState(false);
  const [sectionLoadingVisible, setSectionLoadingVisible] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [alertModal, setModal] = useState(null);

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [state, setState] = useState({
    donutChartData: [],
    showDonutChartData: false,
    showDetailedChart: false,
    loadingDetailedChart: false,
    detailedChartData: null,
    horizontalBarChartData: [],
    currentlySelectedPieChart: null,
    filterData: {},
    loaded: false,
    message: "success", //default
  });
  const [loadedWithFilters, setLoadedFilter] = useState(false);

  const { http } = global.services;
  const download = {
    data: "data",
    filters: filters,
    types: ["JPEG", "PDF", "SVG"],
  };

  useEffect(() => {
    // if(!admin) {
    //   setModal('admin-maintenance');
    //   return;
    // }
    initialization();
  }, [selectedProgramId]);

  useEffect(() => {
    if (loadedWithFilters && Object.keys(state.filterData).length === 0) {
      initialization(); //safe to rerun since state filter is cleared.
      setLoadedFilter(false);
    }
  }, [loadedWithFilters, state.filterData]);

  const initialization = async () => {
    let params = {};
    const { organizationProgram } = user.userData ? user.userData : user.user;
    const programId = selectedProgramId || organizationProgram[0]?.programId?._id;
    const query = { selectedProgramId: programId, fullReport: "false" };
    if(clearcache) query.clearCache = clearcache;
    if((programSelected["WFR_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) query.isDummy = true;
    setLoadingGraph(true);
    if (Object.keys(state.filterData).length > 0)
      params["queryFilter"] = { ...state.filterData };

    let response;
    try {
      response = await http.post(
        "client/employeeResponseBreakdownBySection",
        params,
        {
          params: query,
        }
      );
    } catch (err) {
      console.log(err);
      return;
    }
    setLoadingGraph(false);

    let formattedData = [];
    let colorsList = null;

    for (let counter = 0; counter < response.data.data.length; counter++) {
      for (let key in response.data.data[counter]) {
        let objectData = {
          label: key,
          data: {},
          rawData: response.data.data[counter],
          metaConfig: {
            centerText: "",
            colors: [], //colorsList || ['#52AF79', '#8C60F3', '#C4C4C4', '#FEC12F', '#F2403B', '#162630'],
            fieldKey: "field",
            fields: [],
            hideLabels: false,
            showLargest: false,
            valueKey: "value",
          },
        };

        for (
          let dataCounter = 0;
          dataCounter < response.data.data[counter][key].length;
          dataCounter++
        ) {
          const responseCaption = response.data.data[counter][key][dataCounter].ResponseCaption
          if (
            response.data.data[counter][key][dataCounter].percent != undefined
          ) {
            objectData.data[
              responseCaption
            ] = response.data.data[counter][key][dataCounter].percent;
            // .push({
            // 	field: responseCaption,
            // 	value: response.data.data[counter][key][dataCounter].percent
            // })

            objectData.metaConfig.fields.push({
              title: changeLabel(responseCaption) //add true as second parameter to disable
                ,
              field:
                responseCaption,
            });

            objectData.metaConfig.colors.push(
              response.data.data[counter][key][dataCounter].colorCode
            );

            /*if (!colorsList) {
							let colorExists = true
							let colorGenerated = '#' + Math.floor(Math.random()*16777215).toString(16)
							while (colorExists) {
								if (!objectData.metaConfig.colors.includes(colorGenerated)) {
									colorExists = false
									objectData.metaConfig.colors.push(colorGenerated)
								} else {
									colorGenerated = '#' + Math.floor(Math.random()*16777215).toString(16)
								}
							}
						}*/
          }
        }

        colorsList = objectData.metaConfig.colors;
        //objectData.metaConfig.colors = colorsList
        formattedData.push(objectData);
      }
    }
    setState((prevState) => ({
      ...prevState,
      donutChartData: formattedData,
      loaded: true,
      message: response.data.message,
    }));
    setTimeout(() => {
      setState((prevState) => ({
        ...prevState,
        showDonutChartData: true,
      }));
    }, 400);
  };

  const handleOnPieChartSelect = async (data) => {
    const dataList = data.rawData[data.label];
    let questionRange = null;
    for (let counter = 0; counter < dataList.length; counter++) {
      if (dataList[counter].questionRange) {
        questionRange = dataList[counter].questionRange;
        break;
      }
    }

    if (questionRange) {
      setState((prevState) => ({
        ...prevState,
        showDetailedChart: true,
        loadingDetailedChart: true,
        currentlySelectedPieChart: data,
      }));
      let params = {
        questionRange: questionRange,
      };

      if (Object.keys(state.filterData).length > 0)
        params["queryFilter"] = { ...state.filterData };

      const { organizationProgram } = user.userData ? user.userData : user.user;
      const obj = {}
      if (organizationProgram) {
        obj.selectedProgramId = selectedProgramId || organizationProgram[0]?.programId?._id;
      }
      if(clearcache) obj.clearCache = clearcache
      if( (programSelected["WFR_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) obj.isDummy = true;
        
      const url = new URLSearchParams(obj).toString();
      let response;
      try {
        response = await http.post(
          `client/employeeResponseBreakdown${url !== "" ? "?" + url : ""}`,
          params
        );
      } catch (err) {
        console.log(err);
        return;
      }

      const fieldsMap = new Map();
      const outputData = response.data.data.map((row) =>
        row.responses.reduce(
          (dataRow, caption) => {
            const field = caption.ResponseCaption;
            dataRow[field] = caption.percent;
            dataRow.tooltipData[field] = caption.numberOfResponses;
            fieldsMap.set(field, {
              key: field,
              label: changeLabel(field),
              color: caption.colorCode,
            });
            return dataRow;
          },
          {
            label: row.question,
            tooltipData: {},
          }
        )
      );

      const detailedSummaryDataObject = {
        data: outputData.reverse(),
        metaConfig: {
          // colors: ['#52AF79', '#8C60F3', '#C4C4C4', '#FEC12F', '#F2403B', '#162630'],
          fields: ([...fieldsMap.values()]).sort((a, b) => sequence.indexOf(a.key) - sequence.indexOf(b.key)),
          tooltipKey: "tooltipData",
          groupKey: "group",
          labelKey: "label",
          valueKey: "value",
        },
      };

      // let fieldKeysToUse = [];
      // for (let counter = 0; counter < response.data.data.length; counter++) {
      //   const dataObject = {
      //     label: response.data.data[counter].question,
      //   };
      //   let tooltipData = {};
      //   for (
      //     let responseCounter = 0;
      //     responseCounter < response.data.data[counter].responses.length;
      //     responseCounter++
      //   ) {
      //     const responseCaption =
      //       response.data.data[counter].responses[responseCounter]
      //         .ResponseCaption;
      //     tooltipData[
      //       response.data.data[counter].responses[
      //         responseCounter
      //       ].ResponseCaption
      //     ] =
      //       response.data.data[counter].responses[
      //         responseCounter
      //       ].numberOfResponses;
      //     const colorCode =
      //       response.data.data[counter].responses[responseCounter].colorCode;
      //     if (!fieldKeysToUse.includes(responseCaption)) {
      //       fieldKeysToUse.push(responseCaption);
      //       detailedSummaryDataObject.metaConfig.colors.push(colorCode);
      //     }
      //     dataObject[responseCaption] =
      //       response.data.data[counter].responses[responseCounter].percent;
      //     // dataObject["responses"] = numberOfResponses;
      //     dataObject["tooltipData"] = tooltipData;
      //   }
      //   detailedSummaryDataObject.data.push(dataObject);
      // }

      // detailedSummaryDataObject.metaConfig.fields = fieldsToUse
      // for (let counter = 0; counter < fieldKeysToUse.length; counter++) {
      //   detailedSummaryDataObject.metaConfig.fields.push({
      //     key: fieldKeysToUse[counter],
      //     label: fieldKeysToUse[counter],
      //   });

      /*let colorExists = true
				let colorGenerated = '#' + Math.floor(Math.random()*16777215).toString(16)
				while (colorExists) {
					if (!detailedSummaryDataObject.metaConfig.colors.includes(colorGenerated)) {
						colorExists = false
						detailedSummaryDataObject.metaConfig.colors.push(colorGenerated)
					} else {
						colorGenerated = '#' + Math.floor(Math.random()*16777215).toString(16)
					}
				}*/
      // }

      // console.log('Detailed sUMMARY dATA oBJECT:', detailedSummaryDataObject)
      // detailedSummaryData.push(detailedSummaryDataObject);
      setState((prevState) => ({
        ...prevState,
        showDetailedChart: true,
        loadingDetailedChart: false,
        horizontalBarChartData: [detailedSummaryDataObject],
      }));
    }
  };

  const filterOnChange = (data) => {
    setState((prevState) => ({
      ...prevState,
      filterData: data,
      loaded: false,
    }));
  };

  const handleApplyFilter = (event) => {
    event.preventDefault();
    // console.log('Running logs');
    if (!state.loaded) {
      initialization(); //rerunning with filters
      if (state.currentlySelectedPieChart) {
        handleOnPieChartSelect(state.currentlySelectedPieChart);
      }
      setLoadedFilter(true);
    }
  };

  const downloadFullReport = () => {
    setSectionLoadingVisible(true);
    var downloadable = false;
    setTimeout(()=> {
      if(!downloadable) {
        dispatch( AppAction.setAlert(`Compiling report. Estimated download time is ${timeEstimate} minute${timeEstimate > 1 ? "s" :''}`) )
      }
    }, [3000])
    const params = {
      selectedProgramId: programId
    }
    if(clearcache) params.clearCache = clearcache
    if( (programSelected["WFR_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) params.isDummy = true;
    const paramsString = new URLSearchParams(params).toString()
    downloadFile(
      `${API_ENDPOINT}/client/generateHeatMap?${paramsString}`,
      undefined,
      "post"
    ).then((response)=>{
      const { headers } = response;
      const contentType = headers.get('content-type');
      if(contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then(({data}) => {
          const { signedUrl } = data.data
          const { pathname } = new URL(signedUrl);
          const dlFile = document.createElement("a");
          dlFile.download = pathname.replace('/', '');
          dlFile.href = signedUrl;
          dlFile.target = "_blank";
          dlFile.click();
        })
      }
    })
      .catch((err) => {
        console.log(err);
        return;
      })
      .finally(() => {
        downloadable = true;
        setSectionLoadingVisible(false)
      });
  };

  const downloadReport = (isFilteredReport) => {
    let params = {
      selectedProgramId: programId
    };

    if (isFilteredReport && Object.keys(state.filterData).length > 0) {
      params.queryFilter = JSON.stringify(state.filterData)
    }
    if(clearcache) params.clearCache = clearcache;
    if( (programSelected["WFR_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) params.isDummy = true;
    const url = new URLSearchParams(params).toString();
    setSectionLoadingVisible(true);
    var downloadable = false;
    setTimeout(()=> {
      if(!downloadable) {
        dispatch( AppAction.setAlert(`Compiling report. Estimated download time is ${timeEstimate} minute${timeEstimate > 1 ? "s" :''}`) )
      }
    }, [3000])
    downloadFile(
      `${API_ENDPOINT}/client/generateHeatMap?${url}`,
      undefined,
      "post"
    ).then((response)=>{
      const { headers } = response;
      const contentType = headers.get('content-type');
      if(contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then(({data}) => {
          const { signedUrl } = data.data
          const { pathname } = new URL(signedUrl);
          const dlFile = document.createElement("a");
          dlFile.download = pathname.replace('/', '');
          dlFile.href = signedUrl;
          dlFile.target = "_blank";
          dlFile.click();
        })
      }
    }).catch((err) => {
        console.log(err);
        return;
      })
      .finally(() => {
        downloadable = true;
        setSectionLoadingVisible(false)
      });
  };

  const downloadConfigs = [
    {
      action: downloadFullReport,
    }
  ];

  if (Object.keys(state.filterData).length > 0) {
    downloadConfigs.push({
      context: "Download Filtered XLS",
      action: () => downloadReport(true),
    })
  }
  
  return (
    <React.Fragment>
      {
        /* SECTION LOADING */
        sectionLoadingVisible && <SectionLoading />
      }
      <ReportsHeader
        download={download}
        pageName="Detailed Results"
        accessGroup="WFR_Access"
        workforce
        downloadConfigs={downloadConfigs}
      />
      <ReportDescription description="Filter survey feedback by various demographics within your respondent population and dig into employee perspectives of each of the nine focus areas of the workplace." />
      {
        !isAvailable && <div className="mt-4"><PageDropdown pages={pages} /></div>
      }

      <GenericFilter access={"WFR_Access"} onChange={filterOnChange} runFilters={handleApplyFilter} />
      {state.message != "success" && (
        <Alert variant="filled" className="mt-4 rounded-none" severity="info">
          {state.message}
        </Alert>
      )}
      <AlertModal open={alertModal} onClose={() => setModal(null)}/>

      {/* Carousel Chart */}
      {loadingGraph ? (
        <SectionLoading />
      ) : (
        <>
          {!!state.donutChartData.length && (
            <div className="carousel-section-detail-result-page">
              <PieChartSummary
                data={state.donutChartData}
                onSelect={(data) => handleOnPieChartSelect(data)}
                showChart={state.showDonutChartData}
              />
            </div>
          )}

          {state.showDetailedChart && state.message === "success" && (
            <>
              {state.loadingDetailedChart ? (
                <Skeleton animation="wave" height={500} />
              ) : (
                <div className="horizontal-bar">
                  <HorizontalBarChatSummary
                    data={state.horizontalBarChartData}
                    sourceData={state.currentlySelectedPieChart}
                  />
                </div>
              )}
            </>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default DetailedResults;
