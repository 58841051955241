
import getSymbolFromCurrency from 'currency-symbol-map';

export const currentCurrency = (currentCurrency)=>{ 
    try{
        let user = JSON.parse(localStorage.getItem('user'));  
        const { organizationProgram } = (user?.userData) ? user?.userData : user?.user;
        const selectOrgProgram = organizationProgram[0];
        const { Currency } = selectOrgProgram?.programId || null; 
        if(Currency === 'CAD'){
            return 'CA$';
        }
        return getSymbolFromCurrency(Currency); 
    }catch(e){
        console.error(`Something went wrong with the corrency: ${e}`);
    }
}