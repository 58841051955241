import { useHistory } from 'react-router'
import { Button, Link as DomLink } from '@mui/material'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { addToCart } from 'redux/modules/cart/actions';
import { currentCurrency } from 'helpers/checkCurrency';
import { checkIsUk } from 'helpers/checkIsUK';

const PackageReportList = () => {
	const history = useHistory()
	const handleClick = () => history.push('/reports-sample')

	return <ul className="default-list mb-4">
		<li>
			<p>
				<span className="list-title">Phone Call.</span> A 30-minute phone call with our Survey Specialist will help you go through the results and get answers to questions.
			</p>
		</li>
		<li>
			<p>
				<span className="list-title">Online Data Dashboard.</span> This is a place where you can get all your data in the ways you need it, including downloading it in charts and graphs.  
			</p>
		</li>
		<li>
			<p>
				<span className="list-title">Workforce Feedback Results.</span> This quantitative report reflects the perceptions of respondents to each question on the employee survey, both system-wide and segmented within various demographics of the population.
				<span className='view-report ml-4 text-red-10' onClick={()=>history.push('/employee-response-breakdown')}>View Report<i className='bx bx-chevron-right ml-2'></i></span>
			</p>
		</li>
		<li>
			<p>
				<span className="list-title">Employee Verbatims.</span> Any responses to open-ended survey questions are contained in this report.
				<span className='view-report ml-4 text-red-10' onClick={() => history.push('/employee-verbatims')}>View Report<i className='bx bx-chevron-right ml-2'></i></span>
			</p>
		</li>
		<li>
			<p>
				<span className="list-title">Workforce Benchmark Comparisons.</span> This report averages the percentage of employees’ positive responses to each survey question from all participating {checkIsUk() ? 'organisations' : 'organizations'}, presented in aggregate by all competitors that did and did not make the list.
				<span className='view-report ml-4 text-red-10' onClick={() => history.push('/workforce-benchmark-comparisons')}>View Report<i className='bx bx-chevron-right ml-2'></i></span>
			</p>
		</li>
		<li>
			<p>
				<span className="list-title">Benefits & Best Practices Report.</span> This report provides the percentage of winning and non-winning {checkIsUk() ? 'organisations' : 'organizations'} that offer various employee benefits and workplace practices.
				<span className='view-report ml-4 text-red-10' onClick={() => history.push('/benefits-and-best-practices')}>View Report<i className='bx bx-chevron-right ml-2'></i></span>
			</p>
		</li>
	</ul>
}

export default function ReportGridItem ({ classGrid, item, index, onAddCart=() => {} }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const user = useSelector(({user})=> user.user)
	// Unused
	const handleAddToCart = () => {
		onAddCart(item);
		dispatch(addToCart(item))
	}

	const renderItemOption = () => {
		if (item.comingSoonAt)
			return (
				<div className='flex flex-row items-center justify-end'>
					<span className='mt-2 text-sm'>Available in { item.comingSoonAt }</span>
				</div>
			) 

		return (
			<Link 
				className="text-red-10 flex items-center"
				to={item.redirectTo}
			>
			View Report
			<i className='bx bx-chevron-right ml-2 text-2xl'></i>
			</Link>
		)
	}

	const generateSubject = () => {
		const mainUser = user.user;

		var append = '';
		if(mainUser.CompanyName)
			append += mainUser?.CompanyName || '';
		if(mainUser.programs)
			append += (append != '' ? ', ': '') + mainUser.programs.map(i=>i.Name).join(', ');

		return append != '' ? append : mainUser.username;
	}


	return (<div className={`w-full h-full ${classGrid}`}>
		<div className='h-full bg-white p-5 flex flex-col'>
			<img src={item.img} className="w-12 h-12 rounded-full mb-2"/>
			<h2 className='text-md text-black-0 font-bold line-clamp-2 mt-4 mb-3'>{ item.title }</h2>
			{
				!item.isPurchased && item.amount != null && <p className="my-5">Price <span className="text-red-10">{currentCurrency()} {item.amount}</span></p>
				// !item.isPurchased && item.amount != null && <p className="my-5">Price <span className="text-red-10">$ {item.amount}</span></p>
			}
			{
				(item.amount == null && item.title == 'Custom Reports') &&
				<DomLink 
					className="text-red-10 my-5 block"
					href={`mailto:${user.salesUser?.email || 'SurveyPro@WorkforceRG.com'}?subject=Custom Report Request: ${generateSubject()}`}
				>
				Contact Sales
				</DomLink>
			}
			{
				item.id == 0 ? 
				<p className='mb-2 text-gray-30 text-sm'>
					This report identifies key motivators of employee engagement within your unique population. This information is vital to knowing what workplace attributes are most important. <span className=
				"font-bold">Here’s what you get</span>:
				</p> : 
				<p className='flex-grow mb-3 text-gray-30 text-sm'>
					{ typeof item.description == 'function' ? item.description() : item.description }
				</p>
			}
			{/* Bad placement. */}
			{ 
				item.id == 0 ? 
				<PackageReportList/> :
				<div className='mt-5'>
				{ renderItemOption() }
				</div>
			}
		</div>
	</div>)
}
