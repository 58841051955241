import React from 'react'
import { useSelector } from "react-redux"
import { useEffect } from "react"

import DashboardLayout from './dashboard/index'
import DefaultLayout from './default/index'
import TwoColumnLayout from './two-column/index'
import SingleColumnLayout from './single-column/index'

const Layout = ({ children }) => {

	const layout = useSelector((state) => state.app.currentRoute?.layout)
	const appState = useSelector((state) => state.app)
	
	useEffect(() => {
	}, [appState.pageLoader.show])

	if (!layout)
		return children

	const renderLayout = () => {

		if (!layout) {
			
			return null
		
		} else {
			
			if (layout === 'dashboard') {
				return (
					<DashboardLayout>
						{ children }
					</DashboardLayout>
				)
			}

			if (layout === 'default') {
				return (
					<DefaultLayout>
						{ children }
					</DefaultLayout>
				)
			}

			if (layout === 'two-column') {
				return (
					<TwoColumnLayout>
						{ children }
					</TwoColumnLayout>
				)
			}

			if (layout === 'single-column') {
				return (
					<SingleColumnLayout>
						{ children }
					</SingleColumnLayout>
				)
			}

		}

	}

	return (
		<>
			{ renderLayout() }
			<div
				className={`
					fixed inset-0 flex flex-col items-center justify-center bg-white bg-opacity-90 transition-all duration-200
					${ appState.pageLoader.show ? 'z-20 visible' : 'z-0 invisible' }
				`}
			>
				<p className="text-2xl font-bold mb-2">{ appState.pageLoader.title }</p>
				<p className="text-base">{ appState.pageLoader.message }</p>
			</div>
		</>
	)

}

export default Layout