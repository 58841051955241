export default [
  { name: 'Leadership and Planning', value: 42/218, color: '#aaa', fontColor: "#fff" },
  { name: 'Corporate Culture and Communications', value: 24/218, color: '#aaa', fontColor: "#fff" },
  { name: 'Role Satisfaction', value: 5/218, color: '#aaa', fontColor: "#fff" },
  { name: 'Work Environment', value: 24/218, color: '#aaa', fontColor: "#fff" },
  { name: 'Relationship with Supervisor', value: 27/218, color: '#aaa', fontColor: "#fff" },
  { name: 'Training, Development and Resources', value: 24/218, color: '#aaa', fontColor: "#fff" },
  { name: 'Overall Survey Average', value: 36/218, color: '#aaa', fontColor: "#fff" },
  { name: 'Pay and Benefits', value: 12/218, color: '#aaa', fontColor: "#fff" },
  { name: 'Overall Engagement', value: 24/218, color: '#aaa', fontColor: "#fff" },
];
