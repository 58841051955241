import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useSelector } from 'react-redux';
import ReportItemLarge from '../../reports-store/components/report-item-large'
 
const DashboardMyReports = () => {
	const { store } = useSelector(({ report })=> report)
	const { selectedProgramId } = useSelector(({ app }) => app);
	const { user: mainUser} = useSelector(({user}) => user)
	const { userData } = mainUser || {}
	const organizationProgram = userData?.organizationProgram || [];
	const projectId = userData?.projectId || {};
	const programSelected = organizationProgram?.find(i => i?.programId?._id == selectedProgramId) || organizationProgram[0]

	const filtered = useMemo(()=>{
		if(!programSelected)
			return [];
		
		return store.filter(i => i.access != null && (programSelected[i.access] || '').toLowerCase() == 'yes')
			.map(i=>({...i, isPurchased: true }))
	}, [ programSelected ]);

	if(filtered.length <= 0)
		return '';

	return (
		<div className="dashboard-reports-section">
			<h5 className='text-black-0 mb-2'>
				My Reports
			</h5>

			<div className="flex flex-row flex-wrap">
				{
					filtered.map((i, index) => <ReportItemLarge item={i} index={index} key={`store-item-${index}`}  />)
				}
			</div>
		</div>
	)

}

export default DashboardMyReports
