import React from "react"
import ReportsHeader from "../components/reports-header";

const Help = () => {
	const download = {
		data: null,
		filters: [],
		types: ['none']
	}

	// client should be able to change password, view orders, and.... more?


	return (
		<React.Fragment>
			<ReportsHeader pageName='Help' download={download} />
			<p className="text-sm text-gray-30 mt-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris nam metus, tellus fusce massa senectus. Risus lacus, scelerisque gravida fermentum, iaculis feugiat in cras pellentesque. Eget neque metus, viverra pellentesque.</p>
		</React.Fragment>
	)

}

export default Help