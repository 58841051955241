import { useState, useEffect, Fragment } from 'react'
import { useDispatch } from "react-redux"
import { useHistory } from 'react-router'
import { Typography } from '@mui/material'
import Button from '@mui/material/Button'
import OtpInput from "react-otp-input";
import UserAction from 'redux/modules/user/actions'
import LoadingButton from '@mui/lab/LoadingButton'


const TwoFATypeForm = (props) => {
	const dispatch = useDispatch()
	const history = useHistory();
	const { http } = global.services
	const [code, setCode] = useState("");
	const [key, setKey] = useState(history.location.state.key);
	const handleChange = (code) => setCode(code);
	const [ state, setState ] = useState({
		error: null
	})
	const [submitting, setSubmitting] = useState(false);
	const [resending, setResending] = useState(false);
	const [err, showErr ] = useState(false);
	useEffect(()=>{
		if(code.length === 6) {
			handleSubmit(new Event('submit'))
		}
	}, [code])

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			setSubmitting(true);
			const response = await http.put('/user/management/login', {
				key: key,
				enteredOtp: code,
				userId: history.location.state.userId
			})
			const responseData = response.data.data
			dispatch(UserAction.setUser(responseData))
			history.push('/admin/projects')
			console.log('Should have pushed to /admin/projects')
		} catch (error) {
			const defaultErrorMsg = 'An unknown error occurred. Please refresh the page and try again.'
			const stateToReplace = state

			setSubmitting(false)
			if (error?.response) {
				stateToReplace.submitting = false
				stateToReplace.error = { title: 'Unknown Error', message: defaultErrorMsg }

				if (error.response.status === 403 || error.response.status === 400)
					stateToReplace.error = { title: 'Invalid Credentials', message: 'You have provided invalid credentials. Please try again.' }
			}

			setState(stateToReplace)
			showErr(true);
			// console.log('Something here', called);
		}
	}

	const resendOtp = async (e) => {
		setResending( true );
		try {
			const response = await http.post( '/user/management/login' , {
				email: history.location?.state?.email,
				password: history.location?.state?.password
			} )
			const responseData = response.data.data
			setKey(responseData.key)
			setCode("")
			setState({ error:null })
			showErr(false)
		} catch (error) {
			const defaultErrorMsg = 'An unknown error occurred. Please refresh the page and try again.'
			const stateToReplace = state

			if (error?.response) {
				stateToReplace.submitting = false
				stateToReplace.error = { title: 'Unknown Error', message: defaultErrorMsg }

				if (error.response.status === 403 || error.response.status === 400)
					stateToReplace.error = { title: 'Invalid Credentials', message: 'You have provided invalid credentials. Please try again.' }
			}

			setState(stateToReplace)
		}
	}
	if(state.error)
		console.log('Should rerender');

	return (
		<div>
			<Typography variant="h4" sx={{ fontWeight:600, mb:2, color:"#ffffff"}}>Enter Code</Typography>
			<div className="mb-5">
				<p className="text-white">
					Enter the code sent to the specified phone number
				</p>
			</div>
			<form onSubmit={handleSubmit}>
			<OtpInput
				value={code}
				onChange={handleChange}
				numInputs={6}
				separator={<span style={{ width: "8px" }}></span>}
				isInputNum={true}
				shouldAutoFocus={true}
				inputStyle={{
				border: "1px solid transparent",
				borderRadius: "0px",
				width: "35px",
				height: "35px",
				fontSize: "12px",
				color: "#000",
				fontWeight: "400",
				caretColor: "blue"
				}}
				focusStyle={{
				border: "1px solid #CFD3DB",
				outline: "none"
				}}
			/>
			{
				err && <Fragment>
				<p className="text-red-0 my-4">
					{ state.error?.title }
				</p>
				{/* <LoadingButton
						variant="text"
						color={'white'}
						type="button"
						onClick={resendOtp}
						size={'large'}
						loadingPosition="start"
						startIcon={<></>}
						loading={ resending }
						sx={{mt:2, padding: 0}}
					>
						<span className={ resending ? 'pl-5' : '' }>
							{ resending ? 'resending' : 'Re-send CODE' }
						</span>
				</LoadingButton> */}
				</Fragment>
			}
			</form>
			
		</div>
	)

}

export default TwoFATypeForm