import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Skeleton from "@mui/material/Skeleton";

const useStyles = makeStyles({
  heading: {
    width: "200px",
    flexWrap: "wrap",
    whiteSpace: "normal !important",
  },
  header: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    "& p": {
      transform: "rotate(180deg)",
      textOrientation: "sideways",
      writingMode: "vertical-rl",
    },
    "& .span-color": {
      width: "8px !important",
      height: "8px !important",
      borderRadius: "16px !important",
      marginTop: "10px",
    },
  },
  spanBall: {},
  p: {
    position: "relative",
  },
  dot: {
    position: "absolute",
    top: "8px",
  },
});

export default function TableComponent({
  title = "",
  headers = [],
  rows = [],
  ...props
}) {
  const classes = useStyles();
  const [openedRows, setOpenedRows] = useState({});

  const toggleExpansion = async (row) => {
    setOpenedRows((_openedRows) => ({
      ..._openedRows,
      [row.id]: !_openedRows[row.id],
    }));
    // if (!open) {
    //   setNestedRowsData((nestedRows) => ({
    //     ...nestedRows,
    //     [row.id]: "loading",
    //   }));
    //   try {
    //     const nestedDataRows = await nestedDataFetcher(row);
    //     setNestedRowsData((nestedRows) => ({
    //       ...nestedRows,
    //       [row.id]: nestedDataRows,
    //     }));
    //   } catch (err) {
    //     setNestedRowsData((nestedRows) => ({
    //       ...nestedRows,
    //       [row.id]: undefined,
    //     }));
    //     console.log(err);
    //     return;
    //   }
    // } else {
    //   setNestedRowsData((nestedRows) => ({
    //     ...nestedRows,
    //     [row.id]: undefined,
    //   }));
    // }
  };

  return (
    <div className="my-10 relative bg-white p-5">
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <colgroup>
            <col width="40%" />
            {headers.map((i, k) => (
              <col key={"th-" + k} width="7.5%" />
            ))}
          </colgroup>
          <TableHead sx={{ backgroundColor: "#fff" }}>
            <TableRow>
              <TableCell className="grow-1 align-baseline font-bold">
                {title}
              </TableCell>
              {headers.map((header, idx) => (
                <TableCell key={"th-" + idx}>
                  <div className={classes.header}>
                    <p className="text-sm w-10 h-40">{header.title}<span className="inline-block">{header.subTitle}</span></p>
                    <span
                      className="span-color"
                      style={{ backgroundColor: header.color }}
                    ></span>
                  </div>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, idx) =>
              row.dataValues ? (
                <ItemRow key={"single-" + idx} classes={classes} row={row} />
              ) : (
                <ItemRowExpandable
                  key={"expandable-" + idx}
                  classes={classes}
                  row={row}
                  expand={headers.length + 1}
                  openedRows={openedRows}
                  // isLoading={nestedRowsData[row.id] === "loading"}
                  expansionToggled={toggleExpansion}
                />
              )
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
//Customize this to have collapsable animation if you want
const ItemRowExpandable = ({
  row,
  expand = 2,
  openedRows,
  classes,
  expansionToggled = null,
}) => {
  const open = openedRows[row.id];
  const rowData = row.nestedData;
  return (
    <>
      <TableRow>
        <TableCell colSpan={expand}>
          <div className="flex w-full justify-between items-center">
            <p className="font-bold">{row.title}</p>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => expansionToggled(row)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
      {/* isLoading ? (
        <TableRow>
          <TableCell colSpan={expand}>
            <Skeleton animation="wave" height={100} />
          </TableCell>
        </TableRow>
      ) : ( */}
      {open &&
        (row.nestingType === "DEEP_NEST"
          ? rowData.map((i, idx) => (
              <ItemRowExpandable
                key={"expandable-" + idx}
                classes={classes}
                row={i}
                expand={expand}
                openedRows={openedRows}
                // isLoading={nestedRowsData[row.id] === "loading"}
                expansionToggled={expansionToggled}
              />
            ))
          : rowData.map((i, idx) => (
              <ItemRow isNested={true} key={"subscore_" + idx} row={i} />
            )))}
      {/*<Collapse in={open} timeout="auto" unmountOnExit>
		</Collapse>*/}
    </>
  );
};

const ItemRow = ({ row, isNested, classes }) => {
  return (
    <TableRow>
      <TableCell className={isNested ? "opacity-75" : "font-bold"}>
        {row.title}
      </TableCell>
      {row.dataValues?.map((i, k) => (
        <TableCell key={"score_" + k} className="text-center">
          {i}
        </TableCell>
      ))}
    </TableRow>
  );
};
