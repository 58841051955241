export default function RotationRightSVG(){
	return (
		<svg
		    xmlns="http://www.w3.org/2000/svg"
		    width={14}
		    height={14}
		    fill="none"
		  >
		    <path
		      d="M13.417 2.334v3.5h-3.5"
		      stroke="#25282B"
		      strokeLinecap="round"
		      strokeLinejoin="round"
		    />
		    <path
		      d="M11.953 8.75a5.25 5.25 0 1 1-1.237-5.46L13 5.502"
		      stroke="#25282B"
		      strokeLinecap="square"
		      strokeLinejoin="round"
		    />
		</svg>
	)
}