import { useCallback, useEffect, useRef } from "react";
import { select as d3Select } from "d3-selection";
import { scaleLinear as d3ScaleLinear } from "d3-scale";
import { axisLeft, axisTop } from "d3-axis";
import { range as d3Range, rollup as d3Rollup, extent as d3Extent } from "d3-array";
import { stack as d3Stack, stackOrderNone, stackOffsetExpand } from "d3-shape";
// Only used in annual trends.
const GroupedHorizontalBarChart = (props) => {
  const { data, metaConfig } = props;
  const chartRef = useRef(null);
  const tooltipRef = useRef(null);

  const drawChart = useCallback(() => {
    if (chartRef.current) {
      const { width, height } = chartRef.current.getBoundingClientRect();
      const { types, valueKey, colors, labelKey, groupKey = 'group' } = metaConfig;
      const margin = {
        top: 10,
        right: 20,
        left: 310,
        bottom: 0,
      };
      let barHeight = 20,
        gapBetweenGroups = 15,
        spaceForLabels = 300,
        transformedData = data.flatMap((d) =>
          types.map((t) => ({
            value: d[valueKey][t.field],
            type: t.label,
            label: d[labelKey],
            group: t.field
          }))
        );

      const wrap = (text, width) => {
        text.each(function() {
          var text = d3Select(this),
              words = text.text().split(/\s+/).reverse(),
              word,
              line = [],
              lineNumber = 0,
              lineHeight = 1, // ems
              y = text.attr("y"),
              dy = parseFloat(text.attr("dy")),
              tspan = text.text(null).append("tspan").attr("x", -300).attr("y", 10).attr("dy", dy + "em");
          while (word = words.pop()) {
            line.push(word);
            tspan.text(line.join(" "));
            if (tspan.node().getComputedTextLength() > width) {
              line.pop();
              tspan.text(line.join(" "));
              line = [word];
              tspan = text.append("tspan").attr("x", -300).attr("y", 15).attr("dy", ++lineNumber * lineHeight + dy + "em").text(word);
            }
          }
        });
      }

      barHeight = metaConfig.barHeight || barHeight;
      spaceForLabels = metaConfig.spaceForLabels || spaceForLabels;
      //Trying to connect stacked bar modified chart.
      const zDomain = types.map((field) => field.field);
      const X = transformedData.map((d) => d[valueKey]);
      const Y = transformedData.map((d) => d[labelKey]);
      const Z = transformedData.map((d) => d[groupKey]);
      const I = d3Range(X.length);
      const series = d3Stack()
        .keys(zDomain)
        .value(([, I], z) => {
          return X[I.get(z)]
        })
        // .value(([, I], z) => X[I.get(z)])
        .order(stackOrderNone)
        .offset(stackOffsetExpand)(
          d3Rollup(
            I,
            ([i]) => i,
            (i) => Y[i],
            (i) => Z[i]
          )
        )
        .map((s) =>
          s.map((d) => Object.assign(d, { i: d.data[1].get(s.key) }))
        );

      const xDomain = d3Extent(series.flat(2));
      const groupHeight = barHeight * colors.length,
        xScale = d3ScaleLinear()
          .domain(xDomain)
          .range([spaceForLabels, width - spaceForLabels - 50]);

      const xAxis = axisTop(xScale).ticks((width - spaceForLabels) / 80, "%");

      if (chartRef.current) {
        const { colors } = metaConfig;
        const svg = d3Select(chartRef.current)
          .append("svg")
          .attr("width", width)
          .attr("height", height);
        var bar = svg
          .selectAll("g")
          .data(transformedData)
          .enter()
          .append("g")
          .attr("transform", (_d,i) => `translate(${spaceForLabels}, ${ i* barHeight + gapBetweenGroups * (0.5 + Math.floor(i / colors.length ) )})`)
          // .attr("transform", function (_d, i) {
          //   return (
          //     "translate(" +
          //     spaceForLabels +
          //     "," +
          //     (i * barHeight +
          //       gapBetweenGroups * (0.5 + Math.floor(i / colors.length))) +
          //     ")"
          //   );
          // });

        const onMouseOver = function () {
          d3Select(tooltipRef.current).style("opacity", 1);
        };

        const onMouseMove = function (ev, d) {
          const { layerX, layerY } = ev;
          d3Select(tooltipRef.current)
            .style("opacity", 1)
            .html(`${d.label}<br/>${d.type}: ${(d.value * 100).toFixed(0)}%`)
            .style("top", `${layerY}px`)
            .style("left", `${layerX + 15}px`);
        };

        const onMouseLeave = function () {
          d3Select(tooltipRef.current).style("opacity", 0);
        };
        bar
          .append("rect")
          .attr("fill", function (_d, i) {
            return colors[i % colors.length];
          })
          .attr("class", "bar")
          .attr("transform", () => `translate(${spaceForLabels}, 0)` )
          .attr("width", (x, a,b,c) => {
            return xScale(x.value) - xScale(0)
          })
          .attr("height", barHeight - 1)
          .on("mouseover", onMouseOver)
          .on("mousemove", onMouseMove)
          .on("mouseleave", onMouseLeave);
        // Add text label in bar
        bar
          .append("text")
          .attr("x", function ({ value }) {
            return xScale(value) + 10;
          })
          .attr("y", barHeight / 2)
          // .attr("fill", "black")
          .attr("stroke", "black")
          .attr("stroke-width", "0.02rem")
          .attr("dy", ".35em")
          .text(({ value }) => {
            // Negative value in response means that the question is not available for the year.
            if( value < 0 ) return 'No data available.';
            return `${(value * 100).toFixed(0)}%`;
          });

        bar
          .append("rect")
          .attr("class", "divider")
          .attr("transform", ()=>`translate(0, 5)`)
          .attr("width", width)
          .attr("height", (_,i) => i % colors.length === 1 ? 1 : 0 )
          .attr("x", -spaceForLabels)
          .attr("opacity", "0.6")
          .attr("y", 20);

        // Draw labels
        bar
          .append("text")
          .attr("class", "label")
          .attr("x", spaceForLabels)
          .attr("y", groupHeight / 2)
          .attr("dy", ".35em")
          .text(function (_, i) {
            if (i % colors.length === 0)
              return data[Math.floor(i / colors.length)]?.label;
            else return "";
          }).call(wrap, spaceForLabels*1.8);

        svg
          .attr('preserveAspectRatio', 'none')
          .append("g")
          .attr("transform", `translate(${spaceForLabels}, ${Y.length * (barHeight + 9)})`)
          .call(xAxis)
          .call((g) => g.select(".domain").remove())
          .call((g) => g.selectAll(".tick line").remove())
      }
    }
  }, [data, metaConfig]);

  const destroyChart = useCallback(() => {
    if (chartRef.current) {
      d3Select(chartRef.current).select("svg").remove();
    }
  }, []);

  const rebuildChart = useCallback(() => {
    destroyChart();
    drawChart();
  }, [destroyChart, drawChart]);

  useEffect(() => {
    drawChart();
    window.addEventListener("resize", rebuildChart);
    return () => {
      destroyChart();
      window.removeEventListener("resize", rebuildChart);
    };
  }, [drawChart, destroyChart, rebuildChart]);

  return (
    <div
      ref={chartRef}
      style={{ width: "100%", height: "100%", position: "relative" }}
    >
      <div ref={tooltipRef} className="tooltip"></div>
    </div>
  );
};

export default GroupedHorizontalBarChart;
