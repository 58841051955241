const WindowSVG = () => {
	return (
		<svg className="stroke-current" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M7.22222 1H1V7.22222H7.22222V1Z" strokeLinecap="round"/>
			<path d="M17.0001 1H10.7778V7.22222H17.0001V1Z" strokeLinecap="round"/>
			<path d="M17.0001 10.7773H10.7778V16.9996H17.0001V10.7773Z" strokeLinecap="round"/>
			<path d="M7.22222 10.7773H1V16.9996H7.22222V10.7773Z" strokeLinecap="round"/>
		</svg>
	)
}

export default WindowSVG