export const annualEmpty = [
    {
        data: [
            {
                field: 'Neutral',
                value: 12
            },
            {
                field: 'Agree',
                value: 60
            },
            {
                field: 'Disagree',
                value: 28
            }
        ],
        metaConfig: {
            centerText: "2021",
            colors: [
                '#DCDCDC',
                '#AFAFAF',
                '#C4C4C4',
            ],
            fieldKey: "field",
            valueKey: "value",
            fields: [
                { field: 'Agree', title: 'Agree' },
                { field: 'Neutral', title: 'Neutral' },
                { field: 'Disagree', title: 'Disagree' },
            ]
        },
        year: 2021
    },
    {
        data: [
            {
                field: 'Neutral',
                value: 12
            },
            {
                field: 'Agree',
                value: 50
            },
            {
                field: 'Disagree',
                value: 38
            }
        ],
        metaConfig: {
            centerText: "2022",
            colors: [
                '#DCDCDC',
                '#AFAFAF',
                '#C4C4C4',
            ],
            fieldKey: "field",
            valueKey: "value",
            fields: [
                { field: 'Agree', title: 'Agree' },
                { field: 'Neutral', title: 'Neutral' },
                { field: 'Disagree', title: 'Disagree' },
            ]
        },
        year: 2022
    }
]