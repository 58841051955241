import React, { useState, useEffect, useRef } from 'react';
import DownloadIcon from 'assets/images/icons/download.png';
import { makeStyles } from '@material-ui/core/styles';
import GroupedHorizontalBarChart from 'components/charts/GroupedHorizontalBarChart';
import DownloadButton from "components/charts/DownloadButton"
import { groupedBarChartData } from 'components/charts/mock-data/charts-data';
import { Button } from "@mui/material"

const useStyles = makeStyles({
    root:{
        margin:'25px 10px',
        background:'#fff',
        padding:'25px 25px',
        position: 'relative',
        boxShadow: "0 0 2px rgb(0 0 0 / 70%)",
    },
    heading:{
        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
    },
    h4:{
        fontSize:'16px',
        fontWeight:'bold',
        color:'#000'
    }
});

const toggleLabel = [
    {
        label: 'Agreement',
        key: 'Agree'
    },
    {
        label: 'Neutral',
        key: 'Neutral'
    },
    {
        label: 'Disagreement',
        key: 'Disagree'
    }
]

const sampleMC = {
  labelKey: "label",
  valueKey: "value",
  types: [
    { field: "2022", label: 2022 },
    { field: "2023", label: 2023 },
  ],
  colors: ["#8C60F3", "#FEC12F"],
}
// Old structure
const sampleData = [{
    questionId: 1,
    label: 'Question 1',
    valueSet: {
        2022: {
            Agreement: 5,
            Disagreement: 12,
            Neutral: 32,
        },
        2023: {
            Agreement: 70,
            Disagreement: 24,
            Neutral: 52,
        },
    },
}];

const GroupedBarChartSummary = ({ initialData = sampleData, metaConfig = sampleMC, report = {} }) => {
    const classes = useStyles();
    const [selected, setSelected] = useState('Agreement');
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const graphRef = useRef();
    // const
    useEffect(()=>{
        updateGraph(selected)
    },[initialData])

    const updateGraph = (selected) => {
        setError(null)
        const select = initialData.map(i => {
            let value = {}
            Object.keys(i.valueSet).map(x => {
                value[x] = i.valueSet[x][selected] ? i.valueSet[x][selected] / 100 : 0
            })
            return {
                id: i.questionId,
                label: i.label,
                value
            }
        })
        if(!select || select.length <= 0) {
            setError('Could not complete the graph.')
            return;
        } 

        setData(select);
    }
    // const data = groupedBarChartData;
    const handleChange = ({ target }) => {
        setSelected(selected !== target?.value ? target?.value : null);
        updateGraph(target?.value);
    }
    // const handleChange = ({ target }) => setSelected( selected.includes(target.value) ? selected.filter(i => i != target.value): [ target.value, ...selected ])
    return (
        <React.Fragment>
            <div className={classes.root}>
                <div className="absolute top-4 right-4">
                    <DownloadButton targetRef={graphRef} name={report?.name || 'Unknown Category'} />
                </div>
                <div ref={graphRef} className="bg-white">
                    <div className={classes.heading}>
                        <h4 className={classes.h4}>{report?.name || 'Unknown Category'}</h4>
                    </div>
                    <ul className="flex list-style-none justify-end mt-2">
                        {
                            metaConfig?.types?.map((i, k)=> <li key={`label-${k}`} className='ml-2 mr-2 flex items-center'>
                            <p className="rounded-full h-2 w-2 mr-1" style={{ background: metaConfig?.colors[k] || 'gray'}}></p>
                            <p className='text-sm'>{i.field}</p>
                        </li>)
                        }
                    </ul>
                    <div className='flex items-center mt-2 mb-5'>
                        <label className='mr-4 text-sm'>Choose your Response Comparison: </label>
                    </div>
                    <div className='flex items-center mt-2 mb-5'>
                        {
                            toggleLabel.map(i=> 
                            <Button 
                                variant={selected != i.label? 'outlined' : 'contained'} 
                                key={i.key} 
                                color={'black'}
                                value={i.label}
                                className={`capitalize border border-black rounded-none mr-4 ${selected == i.label ? 'text-white':''}`}
                                onClick={handleChange}
                            >{i.label}</Button>)
                        }
                    </div>
                    {
                        error ? <p className="text-red-0">{error}</p>
                        : <div style={{ height:'70vh',  width:'100%', overflowX: 'scroll'}}>
                            <GroupedHorizontalBarChart 
                                data={data}
                                metaConfig={metaConfig}
                            />
                        </div>
                    }
                </div>
            </div>
            
        </React.Fragment>
    )
}

export default GroupedBarChartSummary;
