import { useMemo } from 'react';
import { useSelector } from "react-redux"

export default function useGenerateList(object = {}) {
  const { store } = useSelector(({ report })=> report);

  return useMemo(() => {
    const keys = Object.keys(object);
    const accessibles = keys.filter(i => i.search('Access'));

    return {
      access: store
        .filter(i => accessibles.includes(i.access))
        .map(({id, title, access}) => ({id, title, access})),
      filter: object?.EV_Sorting_Filter,
    }
  }, [object]);
};
