import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={17}
    height={18}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      opacity={0.5}
      stroke="#1D2124"
      strokeLinecap="square"
      strokeLinejoin="round"
    >
      <path d="M11.833 1.667 14.5 4.334 11.833 7" />
      <path d="M2.5 8.333V7a2.667 2.667 0 0 1 2.667-2.667h8.666M5.167 16.333 2.5 13.667 5.167 11" />
      <path d="M14.5 9.667V11a2.667 2.667 0 0 1-2.667 2.667H3.167" />
    </g>
  </svg>
)

export default SvgComponent
