const WorkforceLogo = () => {
	return (
		<svg viewBox="0 0 160 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clipPath="url(#clip0_388_196)">
			<path d="M32.6235 11.895H32.6124L20.439 24.7998H20.4556H23.9854L36.1532 11.895H32.6235Z" fill="url(#paint0_linear_388_196)"/>
			<path d="M34.6526 6.17395H34.6359H31.1117H31.0951L13.3574 24.8002H13.3741H16.8983H16.915L34.6526 6.17395Z" fill="url(#paint1_linear_388_196)"/>
			<path d="M7.75416 9.02637H4.22998L12.7347 18.014L14.519 16.1602L7.75416 9.02637Z" fill="url(#paint2_linear_388_196)" style={{ mixBlendMode: 'multiply' }}/>
			<path d="M11.9846 5.9975H8.46045L16.3204 14.2844L18.0991 12.4306L11.9846 5.9975Z" fill="#BB4412"/>
			<path d="M33.5798 0H30.0389L10.9339 19.8952L3.52418 12.0497H0L9.15509 21.7435L9.14397 21.7545L10.9172 23.6359L10.9227 23.6414L33.5909 0H33.5798Z" fill="#FF5625"/>
			<g style={{ mixBlendMode: 'multiply' }}>
			<path d="M12.7348 18.0136L14.5191 16.1598L13.6909 15.2936L11.9121 17.1419L12.7348 18.0136Z" fill="#7A2D0C"/>
			</g>
			<g style={{ mixBlendMode: 'multiply' }}>
			<path d="M16.3202 14.2842L18.099 12.4304L17.2763 11.5587L15.4976 13.4125L16.3202 14.2842Z" fill="#7A2D0C"/>
			</g>
			<path d="M39.8501 5.97516H42.7462L44.4693 11.5476L46.3759 5.94757H48.7384L50.645 11.5476L52.3682 5.97516H55.2142L51.879 15.6745H49.5221L47.5266 10.1572L45.5477 15.6745H43.1575L39.8501 5.97516Z" fill="#162630"/>
			<path d="M58.2266 10.8193V10.7918C58.2266 8.03314 60.5167 5.7821 63.5684 5.7821C66.6201 5.7821 68.8825 7.989 68.8825 10.7476C68.8825 13.5062 66.5923 15.7573 63.5406 15.7573C60.4889 15.7573 58.2266 13.5835 58.2266 10.8193ZM66.0865 10.8193V10.7918C66.0969 10.4535 66.0388 10.1166 65.9155 9.80102C65.7922 9.48547 65.6064 9.19772 65.3689 8.95483C65.1315 8.71194 64.8473 8.51888 64.5332 8.38708C64.2191 8.25529 63.8816 8.18746 63.5406 8.18762C63.2038 8.18661 62.8703 8.25327 62.5601 8.3836C62.2499 8.51392 61.9695 8.70521 61.7358 8.94595C61.5021 9.18668 61.3199 9.47188 61.2002 9.78436C61.0805 10.0968 61.0257 10.4301 61.0392 10.7642C61.0281 11.101 61.0852 11.4366 61.2072 11.7511C61.3292 12.0656 61.5137 12.3525 61.7496 12.5949C61.9855 12.8373 62.268 13.0302 62.5805 13.1621C62.8929 13.294 63.2289 13.3622 63.5684 13.3628C63.9037 13.3659 64.2362 13.3019 64.5461 13.1746C64.8559 13.0473 65.1367 12.8593 65.3718 12.6219C65.6068 12.3845 65.7912 12.1026 65.914 11.7929C66.0368 11.4832 66.0955 11.1521 66.0865 10.8193Z" fill="#162630"/>
			<path d="M73.1406 5.97522H77.821C79.3385 5.97522 80.3835 6.35591 81.045 7.01798C81.3422 7.30994 81.5741 7.66084 81.7254 8.04777C81.8768 8.4347 81.9442 8.84895 81.9233 9.2635V9.29108C81.9423 9.9341 81.7557 10.5666 81.3902 11.0978C81.0247 11.6291 80.4991 12.0318 79.8888 12.2483L82.2679 15.6083H79.0995L77.0817 12.6897H75.881V15.6083H73.1406V5.97522ZM77.6932 10.5987C78.6214 10.5987 79.1606 10.1573 79.1606 9.4566V9.42901C79.1606 8.66763 78.6048 8.28694 77.6765 8.28694H75.881V10.5987H77.6932Z" fill="#162630"/>
			<path d="M86.1592 5.97522H88.8996V9.88143L92.2737 5.97522H95.5255L91.779 10.1132L95.67 15.6304H92.3737L89.889 12.0497L88.8829 13.1532V15.6469H86.1592V5.97522Z" fill="#162630"/>
			<path d="M99.2773 5.97522H107.193V8.31453H102.018V9.9697H106.726V12.1766H102.046V15.6194H99.2773V5.97522Z" fill="#162630"/>
			<path d="M110.923 10.8193V10.7918C110.923 8.03314 113.213 5.7821 116.265 5.7821C119.316 5.7821 121.584 7.989 121.584 10.7476C121.584 13.5062 119.294 15.7573 116.237 15.7573C113.18 15.7573 110.923 13.5835 110.923 10.8193ZM118.783 10.8193V10.7918C118.793 10.4535 118.735 10.1166 118.612 9.80102C118.488 9.48547 118.303 9.19772 118.065 8.95483C117.828 8.71194 117.544 8.51888 117.229 8.38708C116.915 8.25529 116.578 8.18746 116.237 8.18762C115.9 8.18661 115.567 8.25327 115.256 8.3836C114.946 8.51392 114.666 8.70521 114.432 8.94595C114.198 9.18668 114.016 9.47188 113.896 9.78436C113.777 10.0968 113.722 10.4301 113.736 10.7642C113.725 11.1008 113.783 11.4361 113.905 11.7502C114.027 12.0644 114.212 12.351 114.448 12.5933C114.683 12.8355 114.966 13.0284 115.278 13.1605C115.59 13.2927 115.925 13.3615 116.265 13.3628C116.6 13.3659 116.933 13.3019 117.242 13.1746C117.552 13.0473 117.833 12.8593 118.068 12.6219C118.303 12.3845 118.487 12.1026 118.61 11.7929C118.733 11.4832 118.792 11.1521 118.783 10.8193Z" fill="#162630"/>
			<path d="M125.836 5.97522H130.517C132.029 5.97522 133.074 6.35591 133.741 7.01798C134.036 7.31104 134.266 7.66225 134.417 8.049C134.567 8.43576 134.634 8.84947 134.614 9.2635V9.29108C134.633 9.9341 134.446 10.5666 134.08 11.0978C133.715 11.6291 133.189 12.0318 132.579 12.2483L134.941 15.6083H131.773L129.778 12.6897H128.577V15.6083H125.836V5.97522ZM130.389 10.5987C131.323 10.5987 131.856 10.1573 131.856 9.4566V9.42901C131.856 8.66763 131.301 8.28694 130.372 8.28694H128.577V10.5987H130.389Z" fill="#162630"/>
			<path d="M138.488 10.8193V10.7917C138.486 10.1192 138.621 9.45329 138.885 8.83374C139.148 8.21419 139.535 7.65377 140.022 7.18604C140.508 6.71832 141.085 6.35289 141.718 6.11165C142.35 5.87041 143.025 5.75831 143.702 5.78207C144.516 5.74159 145.327 5.90419 146.061 6.25502C146.795 6.60584 147.429 7.1337 147.905 7.79034L145.837 9.34621C145.594 8.99934 145.271 8.71549 144.895 8.51864C144.518 8.32178 144.1 8.2177 143.674 8.21517C143.349 8.22275 143.028 8.29539 142.731 8.42877C142.435 8.56214 142.168 8.75351 141.948 8.99144C141.727 9.22937 141.557 9.50897 141.448 9.81355C141.339 10.1181 141.292 10.4414 141.312 10.7641C141.289 11.0886 141.333 11.4143 141.441 11.7215C141.549 12.0286 141.718 12.3109 141.939 12.5513C142.16 12.7916 142.428 12.985 142.726 13.1197C143.024 13.2544 143.347 13.3277 143.674 13.3352C144.113 13.3325 144.544 13.2248 144.931 13.0211C145.318 12.8175 145.65 12.5239 145.898 12.1655L147.96 13.5945C147.329 14.4781 146.434 15.1432 145.402 15.4965C144.371 15.8498 143.253 15.8735 142.207 15.5643C141.161 15.2552 140.239 14.6286 139.57 13.7727C138.901 12.9167 138.519 11.8743 138.477 10.7917L138.488 10.8193Z" fill="#162630"/>
			<path d="M151.973 5.97522H159.933V8.24281H154.686V9.70487H159.438V11.8069H154.686V13.3352H160V15.6083H151.973V5.97522Z" fill="#162630"/>
			<path d="M44.1188 23.6688L42.9793 22.0688H41.962V23.6688H41.1338V19.0674H43.2016C44.2689 19.0674 44.9192 19.6192 44.9192 20.524C44.9311 20.8504 44.8259 21.1704 44.6223 21.427C44.4187 21.6837 44.13 21.8603 43.8075 21.9254L45.0804 23.6688H44.1188ZM43.146 19.8068H41.962V21.3516H43.1516C43.7352 21.3516 44.1077 21.0481 44.1077 20.5792C44.1077 20.1102 43.7463 19.8012 43.146 19.8012V19.8068Z" fill="#162630"/>
			<path d="M50.6949 19.7902H48.0712V20.9654H50.3947V21.6881H48.0712V22.924H50.7283V23.6688H47.2485V19.0674H50.6949V19.7902Z" fill="#162630"/>
			<path d="M54.8025 20.9985C55.7864 21.2358 56.2922 21.5834 56.2922 22.3503C56.2922 23.222 55.6141 23.7296 54.6246 23.7296C53.912 23.7323 53.224 23.4706 52.6958 22.9958L53.185 22.4441C53.5766 22.823 54.0999 23.0383 54.6469 23.0454C55.1527 23.0454 55.4696 22.8137 55.4696 22.4606C55.4696 22.1075 55.2806 21.9475 54.4245 21.7489C53.4351 21.5116 52.8792 21.2248 52.8792 20.3696C52.8792 19.5144 53.5407 19.0289 54.4634 19.0289C55.071 19.0192 55.6622 19.2246 56.131 19.6082L55.6975 20.2151C55.3544 19.923 54.9255 19.7488 54.4746 19.7185C54.0021 19.7185 53.7186 19.9613 53.7186 20.2703C53.7186 20.5792 53.9187 20.7889 54.8025 20.9985Z" fill="#162630"/>
			<path d="M62.0125 19.7902H59.3943V20.9654H61.7012V21.6881H59.3777V22.924H62.0291V23.6688H58.5605V19.0674H61.9958L62.0125 19.7902Z" fill="#162630"/>
			<path d="M68.7715 23.669H67.9154L67.4429 22.5655H65.2528L64.7748 23.669H63.9243L65.9643 19.0345H66.7203L68.7715 23.669ZM66.3423 20L65.5474 21.8372H67.1428L66.3423 20Z" fill="#162630"/>
			<path d="M73.863 23.6688L72.7513 22.0633H71.7063V23.6688H70.8892V19.0674H72.957C74.0242 19.0674 74.6802 19.6192 74.6802 20.524C74.692 20.8504 74.5868 21.1704 74.3832 21.427C74.1796 21.6837 73.8909 21.8603 73.5684 21.9254L74.8191 23.6688H73.863ZM72.8903 19.8068H71.7063V21.3516H72.8958C73.4795 21.3516 73.8519 21.0481 73.8519 20.5792C73.8519 20.1102 73.4962 19.8012 72.8903 19.8012V19.8068Z" fill="#162630"/>
			<path d="M79.1551 23.7241C78.539 23.6907 77.9594 23.4232 77.5367 22.9769C77.114 22.5307 76.8805 21.9401 76.8848 21.3277C76.8891 20.7152 77.1308 20.1278 77.5597 19.6875C77.9887 19.2472 78.5719 18.9876 79.1885 18.9627C79.5242 18.9505 79.8588 19.0079 80.1709 19.1314C80.4829 19.255 80.7655 19.4418 81.0006 19.68L80.4781 20.2814C80.1369 19.9346 79.6712 19.7362 79.1829 19.7296C78.7893 19.7898 78.4304 19.9879 78.1711 20.2879C77.9119 20.588 77.7693 20.9702 77.7693 21.3655C77.7693 21.7608 77.9119 22.143 78.1711 22.4431C78.4304 22.7431 78.7893 22.9412 79.1829 23.0014C79.434 22.9994 79.6819 22.9454 79.9107 22.8428C80.1395 22.7402 80.3441 22.5912 80.5115 22.4055L81.034 22.9572C80.7973 23.2148 80.506 23.4171 80.1812 23.5497C79.8563 23.6823 79.5059 23.7419 79.1551 23.7241Z" fill="#162630"/>
			<path d="M84.0245 21.7268V23.6688H83.2129V19.0674H84.0245V20.9654H86.2479V19.0674H87.065V23.6688H86.2479V21.7268H84.0245Z" fill="#162630"/>
			<path d="M95.053 23.7242C94.7349 23.7382 94.4173 23.6865 94.1203 23.5724C93.8233 23.4582 93.5534 23.2842 93.3277 23.0612C93.102 22.8382 92.9254 22.5711 92.809 22.2769C92.6927 21.9826 92.6391 21.6676 92.6517 21.3518C92.6465 21.038 92.7046 20.7264 92.8227 20.4353C92.9408 20.1442 93.1164 19.8795 93.3391 19.6569C93.5619 19.4342 93.8274 19.2581 94.1198 19.1389C94.4123 19.0197 94.7258 18.9598 95.0419 18.9628C95.6951 18.932 96.3343 19.158 96.8207 19.5918L96.3093 20.1987C95.9569 19.8728 95.4901 19.6966 95.0086 19.7076C94.7995 19.7153 94.594 19.7646 94.4044 19.8525C94.2149 19.9405 94.0451 20.0654 93.9051 20.2198C93.7651 20.3742 93.6578 20.5549 93.5895 20.7513C93.5213 20.9476 93.4935 21.1556 93.5077 21.3628C93.4906 21.5765 93.5188 21.7915 93.5905 21.9937C93.6622 22.196 93.7759 22.3811 93.9241 22.537C94.0723 22.693 94.2519 22.8164 94.4511 22.8992C94.6503 22.9821 94.8649 23.0225 95.0808 23.018C95.4791 23.0216 95.8683 22.9 96.1926 22.6704V21.7987H95.053V21.098H97.0264V23.029C96.4758 23.4914 95.7741 23.7386 95.053 23.7242Z" fill="#162630"/>
			<path d="M102.368 23.6688L101.256 22.0633H100.233V23.6688H99.4219V19.0674H101.484C102.551 19.0674 103.207 19.6192 103.207 20.524C103.219 20.8504 103.114 21.1704 102.91 21.427C102.707 21.6837 102.418 21.8603 102.096 21.9254L103.346 23.6688H102.368ZM101.395 19.8068H100.206V21.3516H101.401C101.984 21.3516 102.351 21.0481 102.351 20.5792C102.351 20.1102 101.996 19.8012 101.395 19.8012V19.8068Z" fill="#162630"/>
			<path d="M107.715 23.7243C107.242 23.7189 106.78 23.5745 106.389 23.3093C105.998 23.0441 105.694 22.67 105.517 22.234C105.339 21.7981 105.296 21.3197 105.391 20.8592C105.487 20.3987 105.718 19.9766 106.055 19.646C106.392 19.3154 106.82 19.0912 107.285 19.0014C107.75 18.9116 108.231 18.9604 108.668 19.1416C109.106 19.3228 109.479 19.6283 109.742 20.0196C110.004 20.4109 110.144 20.8707 110.144 21.3409C110.151 21.6579 110.092 21.9729 109.972 22.2666C109.851 22.5604 109.672 22.8268 109.445 23.0498C109.218 23.2727 108.947 23.4474 108.65 23.5634C108.353 23.6793 108.035 23.7341 107.715 23.7243ZM107.715 19.7078C107.501 19.7081 107.29 19.7519 107.093 19.8366C106.897 19.9212 106.72 20.0449 106.574 20.2C106.427 20.3551 106.315 20.5383 106.242 20.7383C106.17 20.9383 106.14 21.1509 106.153 21.3629C106.142 21.5754 106.174 21.7878 106.248 21.9875C106.322 22.1872 106.436 22.37 106.583 22.5247C106.73 22.6794 106.907 22.803 107.104 22.8878C107.3 22.9726 107.512 23.0169 107.726 23.0181C107.938 23.0184 108.148 22.9759 108.343 22.8932C108.538 22.8105 108.713 22.6893 108.859 22.5371C109.006 22.3848 109.119 22.2047 109.193 22.0076C109.266 21.8105 109.299 21.6005 109.288 21.3905C109.3 21.1778 109.267 20.9651 109.194 20.7651C109.12 20.5651 109.006 20.382 108.859 20.2268C108.712 20.0717 108.535 19.9476 108.338 19.8622C108.142 19.7768 107.93 19.7318 107.715 19.7298V19.7078Z" fill="#162630"/>
			<path d="M116.348 21.6771C116.348 23.0509 115.57 23.7405 114.358 23.7405C113.146 23.7405 112.385 23.0509 112.385 21.7157V19.0674H113.196V21.6826C113.196 22.5378 113.641 22.9957 114.369 22.9957C115.097 22.9957 115.537 22.5599 115.537 21.7157V19.0674H116.348V21.6771Z" fill="#162630"/>
			<path d="M120.528 22.1902H119.606V23.6688H118.794V19.0674H120.623C121.701 19.0674 122.385 19.6688 122.385 20.6123C122.379 21.6495 121.545 22.1902 120.528 22.1902ZM120.528 19.8012H119.583V21.4564H120.528C121.145 21.4564 121.529 21.1254 121.529 20.6288C121.529 20.1323 121.179 19.8012 120.55 19.8012H120.528Z" fill="#162630"/>
			</g>
			<defs>
			<linearGradient id="paint0_linear_388_196" x1="20.4445" y1="18.3501" x2="36.1533" y2="18.3501" gradientUnits="userSpaceOnUse">
			<stop stopColor="#FF5B19"/>
			<stop offset="1" stopColor="#FD2630"/>
			</linearGradient>
			<linearGradient id="paint1_linear_388_196" x1="525.08" y1="529.012" x2="1340.9" y2="529.012" gradientUnits="userSpaceOnUse">
			<stop stopColor="#FF5B19"/>
			<stop offset="1" stopColor="#FD2630"/>
			</linearGradient>
			<linearGradient id="paint2_linear_388_196" x1="82.5297" y1="229.222" x2="282.446" y2="229.222" gradientUnits="userSpaceOnUse">
			<stop stopColor="#BB4412"/>
			<stop offset="1" stopColor="#FD2630"/>
			</linearGradient>
			<clipPath id="clip0_388_196">
			<rect width="160" height="24.8" fill="#162630"/>
			</clipPath>
			</defs>
		</svg>

	)
}

export default WorkforceLogo