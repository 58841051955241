import localstorage from 'services/localstorage';
import {
	SET_USER,
	TOGGLE_ROLE
} from './types'

const admin = JSON.parse(localStorage.getItem('admin'));

const initialState = {
	/** The localstorage key used on this section must be included on the "allowedKeys" variable on the localstorage service (localstorage.js). */
	user: JSON.parse(localStorage.getItem('user')),
	admin: admin || null,
	asAdmin: !admin ? false : true
};

const reducer = (state = initialState, {type, payload}) => {
	switch (type) {

		case SET_USER:

			if (!payload)
				localStorage.clear()
			else
				localStorage.setItem('user', JSON.stringify(payload));

			return {
				...state,
				user: payload
			}

		case TOGGLE_ROLE:
			let output = {};

			if (payload) {
				localStorage.setItem('admin', JSON.stringify(state.user));
				output = {
					admin: state.user,
					asAdmin: true
				}
			} else {
				const admin = localStorage.getItem('admin');
				if(admin) {
					localStorage.setItem('user', admin);
					output = {
						user: admin ? JSON.parse(admin) : state.admin,
						admin: null,
						asAdmin: false
					}
				}
				localStorage.removeItem('admin');
			}

			return {
				...state, ...output
			}

		default:
			return state;

	}

}

export default reducer