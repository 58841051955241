import React from 'react';
import { useHistory } from 'react-router';
import {Typography, Box, Grid, Pagination, Breadcrumbs, Link, Input, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, TablePagination, Paper, TableSortLabel,
    Modal, MenuItem, Select
} from '@mui/material';
import GenericLoader from 'components/loaders/generic'
import dayjs from 'dayjs'; 

const externalData = {
	borders: [ 'border-red-0', 'border-yellow-0', 'border-green-0', 'border-purple-0', 'border-blue-0' ],
	lastBorderIndexUsed: 0
}
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: '#f5f5f5',
  boxShadow: 24,
  p: 4,
};


function DetailModal ({ item, onClose }) {
  const convertTime = (time)=>{
    const date = new Date(time);
    const hours = date.getHours() % 12 || 12;
    const minutes = ('0' + date.getMinutes()).slice(-2);
    const ampm = date.getHours() >= 12 ? 'PM' : 'AM';
    return `${hours}:${minutes} ${ampm}`;
    
  }
  return <Modal
  open={item !== null}
  onClose={onClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography variant="h6" component="h2" fontWeight="600" marginBottom="30px" textAlign="center">
      Activity Information
    </Typography>
      {
        item && 
        <Typography
        component="h3"
        fontSize="14px"
        marginBottom="20px"
       >
        <span className="text-gray-400">Date:</span>
        <span className="text-black ml-2">
          {dayjs(item.createdAt).format('MM/DD/YYYY')}
        </span>
        
        <Box>
          <span className="text-gray-400">Time:</span>
            <span className="text-black ml-2">
              {convertTime(item.createdAt)}
            </span>
        </Box>

        <Box>
          <span className="text-gray-400">Status:</span>
            <span className="text-black ml-2" style={{color:`${item.status === 'Success' ? 'green' : 'red'}`}}>
              {item.status}
            </span>
        </Box>
        {
          item.status === 'Failure' &&
            <>
              {item?.errorMessage && 
                <Box>
                <span className="text-gray-400">Error Message:</span>
                  <span className="text-black ml-2">
                    {item.errorMessage}
                  </span>
                </Box>
              }

              <Box>
                <span className="text-gray-400">Error Type:</span>
                  <span className="text-black ml-2">
                    {item.errorType}
                  </span>
              </Box>

              <Box>
                <span className="text-gray-400">Error Steps To Resolve:</span>
                  <span className="text-black ml-2">
                    {item?.errorStepsToResolve}
                  </span>
              </Box>
            </>
        }
      </Typography>
      }
  </Box>
</Modal>
}

const SystemLogPage = () => {
    const { http } = global.services

	const history = useHistory()
	const itemsPerPage = 75;
	const [total, setTotal] = React.useState(0);
	const [page, setPage] = React.useState(1);
	const [displayed, setDisplayed] = React.useState(itemsPerPage);
	const [noOfPages, setNoOfPages] = React.useState(
		Math.ceil(total / itemsPerPage)
	);
	const [name, setName] = React.useState('');
	const [foundRows, setFoundRows] = React.useState([]);
  const [oldRows, setOldRows] = React.useState([]);
  const [oldCount, setOldCount] = React.useState(0);
	const [filterBy, setFilterBy] = React.useState(10);

    const [loading, setLoading] = React.useState(false);
    const [selected, showItem] = React.useState(null);
    const [sortBy, setSortBy] = React.useState('createAt');

    React.useEffect(() => {
        loadData()
    }, [page, sortBy]) // Sort and 
    
    const loadData = async () => {
        setLoading(true);
        try{
        const { data } = await http.get(`admin/system/log?page=${page}&per_page=${itemsPerPage}&limit=${itemsPerPage}&sortBy=${sortBy}`);
        const rows = data.data || [];
        const count = data?.totalCount || rows.length;
        setFoundRows(rows);
        setOldRows(rows);
        setTotal(count);
        // setOldCount(count);
        setDisplayed(((page - 1) * itemsPerPage) + rows.length);
        setNoOfPages(Math.ceil(count / itemsPerPage))
        // console.log('huuh?', rows, count)
        setLoading(false)
        } catch(err){
            setLoading(false)
            console.log(err);
            return;
        }
    }

	const handleBorderColor = (count) => {

		let borderIndex = 0
		while (borderIndex === externalData.lastBorderIndexUsed)
			borderIndex = parseInt(Math.random() * ((externalData.borders.length - 1) - 0) + 0)

		externalData.lastBorderIndexUsed = borderIndex

		return externalData.borders[borderIndex]

	}
	
	const handleChange = (event, value) => {
    console.log(value);
		setPage(value);
		// setDisplayed(value * itemsPerPage);
	};

	const handleFilterBy = event => {
		setFilterBy(event.target.value);
	};

	const filter = (e) => {
	    const keyword = e.target.value;
      
	    if (keyword !== '') { 
	      const results = oldRows.filter((row) => {
	        return row.description.toLowerCase().startsWith(keyword.toLowerCase());
	      });  
	      setFoundRows(results);

	      setTotal(results.length);
	      if(results.length <= itemsPerPage)
	      	setDisplayed(results.length);
	      else
	      	setDisplayed(itemsPerPage);

	    } else {
	    	setTotal(oldCount);
	      	setFoundRows(oldRows);
	    }

	    setName(keyword);
	  };

    const handleSortBy = event => {
      setSortBy(event.target.value);
    };

	return (
  <Box className="orders-container admin_content-wrapper">
   <Breadcrumbs aria-label="breadcrumb">
    <Link href="#" underline="hover" color="inherit">
     System Log
    </Link>
   </Breadcrumbs>
   <div>
    <Typography
     component="h1"
     fontSize="30px"
     fontWeight="700"
     marginBottom="30px"
    >
     System Log
    </Typography>
   </div>

   {/* <Stack
    spacing={5}
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    paddingRight="30px"
   >
    <Box className="flex" alignItems="center">
     <Select
      value={sortBy}
      onChange={handleSortBy}
      sx={{ maxHeight: "40px", minHeight: "unset", background: "#fff", fontSize: '14px' }}
     > 
      <MenuItem value="createAt">Sort by Date</MenuItem>
      <MenuItem value="program">Sort by Program</MenuItem>
      <MenuItem value="success">Sort by Success</MenuItem>
      <MenuItem value="failure">Sort by Failure</MenuItem>
      <MenuItem value="checkMarketSync">Sort by CheckMarket Sync</MenuItem>
     </Select>
    </Box>
    <Input
     type="search"
     placeholder="Client Search"
     value={name}
     onChange={filter}
     className="input search-list"
    />
   </Stack> */}


   <Stack
    spacing={5}
    direction="row"
    justifyContent="space-between"
    alignItems="center"
    paddingRight="30px"
   >
    <Box className="flex" alignItems="center">
    </Box>
    {/* <Input
     type="search"
     placeholder="Client Search"
     value={name}
     onChange={filter}
     className="input search-list"
    /> */}
   </Stack>
   {
    loading ? <Box><GenericLoader /></Box>
    : <div>
    <TableContainer component={Paper}>
     <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead sx={{ backgroundColor: "#eaebeb" }}>
       <TableRow>
        <TableCell>Date</TableCell>
        <TableCell>Description</TableCell>
        <TableCell>Status</TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
        {/*.slice((page - 1) * itemsPerPage, page * itemsPerPage)*/}
       {foundRows
        .map((row) => (
         <TableRow
          key={row._id}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
          hover
          className=""
         >
          <TableCell component="th" scope="row">
            {dayjs(row.createdAt).format('MM/DD/YYYY')}
          </TableCell>
          {/* <TableCell className="cursor-pointer" onClick={() => showItem(row)}>{row.itemTitle} {row.keys?.EV_Sorting_Filter ? `(${row.keys?.EV_Sorting_Filter})` : ''}</TableCell> */}
          <TableCell  className="cursor-pointer" onClick={() => showItem(row)}>{row.description}</TableCell>
          <TableCell>
            <span style={{color:`${row.status === 'Success' ? 'green' : 'red'}`}}>{row.status}</span></TableCell>
         </TableRow>
        ))}
      </TableBody>
     </Table>
    </TableContainer>
    <Grid container justifyContent="space-between" marginTop="30px">
     <Grid item>
      {displayed <= foundRows.length ? (
       <Typography>
        {displayed} out of {total}
       </Typography>
      ) : (
       <Typography>
        {foundRows.length} out of {total}
       </Typography>
      )}
     </Grid>
     <Grid item>
      <Pagination
       count={noOfPages}
       page={page}
       onChange={handleChange}
       defaultPage={1}
       color="primary"
       size="large"
       className="pagination"
      />
     </Grid>
    </Grid>
   </div>
   }
  <DetailModal item={selected} onClose={() => showItem(null)}/>
  </Box>
 );

}


export default SystemLogPage
