import { useState, useEffect } from 'react'
import {Typography, Breadcrumbs, Box, Grid, Button, Stack, 
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, 
  Paper, Select, MenuItem, Input, Pagination,
  Accordion, AccordionSummary, AccordionDetails 
} from '@mui/material';
import { FunnelSVG, ArrowNextSVG, EditSVG, DeleteSVG, ArrowPrevSVG } from "components/svg-components";
import GenericLoader from 'components/loaders/generic'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import BackIcon from "assets/images/icons/Vector.png";
import { useHistory, useParams, Link } from 'react-router-dom';
import dayjs from 'dayjs'

const ProjectDetails = () => {
  const { http } = global.services
  const history = useHistory()
  const projectId = useParams().page
  const [loaded, setLoaded] =  useState(false)
  const [details, setDetails] = useState({});
  const [sortBy, setSortBy] = useState('Date');

  const itemsPerPage = 5;
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [displayed, setDisplayed] = useState(itemsPerPage);
  const [noOfPages, setNoOfPages] = useState( 1 );
  const [name, setName] = useState('');
  const [foundRows, setFoundRows] = useState([]);
  const [oldRows, setOldRows] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(()=>{
    loadDetails()
    loadPrograms()
  },[projectId])

  const loadDetails = async () => {
    const { data } = await http.get(`admin/getprojects/${projectId}`);
    setDetails(data.data[0] || {})
  }

  const loadPrograms = async () => {
    try{
      setLoading(true);
      const { data } = await http.get('admin/getProgramsByProjectId', {projectId: projectId, expand: 'orgs'});
      const rows = (data.data || []).map(i => {
        return {
          ...i,
          number_of_clients: i.orgs?.length || 0
        }
      });
      setFoundRows(rows);
      setOldRows(rows);
      setTotal(rows.length);
      setDisplayed(itemsPerPage);
      setNoOfPages(Math.ceil(rows.length / itemsPerPage))
      setLoading(false);
      } catch(err){
        setLoading(false);
        console.log(err);
        return;
      }
  }
	
	const handleChange = (event, value) => {
		setPage(value);
		setDisplayed(value * itemsPerPage);
	};

	const handleSortBy = event => {
		setSortBy(event.target.value);
    let sorted = [];

    switch(event.target.value) {
        case 'Date':
        sorted = foundRows.sort((a,b) => dayjs(b.createAt).diff( dayjs(a.createAt) ) );
        break;

        case 'Name':
        sorted = foundRows.sort((a, b) => a.Name > b.Name ? 1 : (b.Name > a.Name ? -1 : 0) );
        break;

        case 'OrgCount':
        sorted = foundRows.sort((a, b) => a.number_of_clients - b.number_of_clients);
        break;
    }
    setFoundRows(sorted);
	};

	const filter = (e) => {
	    const keyword = e.target.value;

	    if (keyword !== '') {
	      const results = oldRows.filter((project) => {
	        return project.Name.toLowerCase().startsWith(keyword.toLowerCase());
	      });
	      setFoundRows(results);
	      setTotal(results.length);
	      if(results.length <= itemsPerPage)
	      	setDisplayed(results.length);
	      else
	      	setDisplayed(itemsPerPage);
	    } else {
	    	setTotal(oldRows.length);
      	setFoundRows(oldRows);
	    }

	    setName(keyword);
	  };

	return (
  <Box>
  {
    details?.Name &&
   <Breadcrumbs separator="|" aria-label="breadcrumb" marginBottom="20px">
    <Typography
     className="cursor-pointer"
     onClick={() => {
      history.push("/admin/projects");
     }}
     underline="hover"
     color="inherit"
    >
     Projects
    </Typography>
    <Typography underline="hover" color="text.primary">
     {details?.Name}
    </Typography>
   </Breadcrumbs>
  }

   <Typography component="h1" fontSize="30px" fontWeight="700" marginBottom="20px">
    {details?.Name}
   </Typography>

  <Accordion>
    <AccordionSummary
      expandIcon={<ExpandMoreIcon />}
      aria-controls="panel1a-content"
      id="panel1a-header"
    >
      <Typography
        component="h3"
        fontSize="16px"
        lineHeight="33px"
        fontWeight="700"
       >
        Project Details
       </Typography>
    </AccordionSummary>
    <AccordionDetails>
      <Box sx={{ marginBottom: "30px" }}>
        <Typography
          component="h3"
          fontSize="14px"
          marginBottom="20px"
         >
          <span className="text-gray-400">
          Project ID:
          </span>
          <span className="text-black ml-2">
            {details.id}
          </span>
        </Typography>
        <Typography
          component="h3"
          fontSize="14px"
          marginBottom="20px"
         >
          <span className="text-gray-400">
          Abbreviation:
          </span>
          <span className="text-black ml-2">
            {details.Project_Abbreviation}
          </span>
        </Typography>
        <Typography
          component="h3"
          fontSize="14px"
          marginBottom="20px"
         >
          <span className="text-gray-400">
          Date Created:
          </span>
          <span className="text-black ml-2">
            {dayjs(details.createAt).format('MM/DD/YYYY')}
          </span>
        </Typography>
      </Box>
    </AccordionDetails>
  </Accordion>

  <Box className="project-wrapper">
    <Box className="programs" marginTop="30px">
     <Typography
      component="h3"
      fontSize="16px"
      lineHeight="33px"
      fontWeight="700"
      marginBottom="30px"
     >
      Programs
     </Typography>
     {/*<Grid container spacing={2} marginBottom="30px">
      <Grid item>
       <Button className="btn_project-year active rounded-none">2020</Button>
      </Grid>
      <Grid item>
       <Button className="btn_project-year rounded-none">2021</Button>
      </Grid>
     </Grid>*/}
    </Box>

    <Stack
     spacing={5}
     direction="row"
     className="filter-wrapper"
     marginBottom="20px"
     justifyContent="space-between"
     paddingRight="30px"
    >
     <Box className="flex" alignItems="center">
      <Select
       value={sortBy}
       onChange={handleSortBy}
       sx={{
        maxHeight: "40px",
        minHeight: "unset",
        background: "#fff",
        fontSize: "14px",
       }}
      >
       <MenuItem value="Date">Sort by Date</MenuItem>
       <MenuItem value="Name">Sort by Name</MenuItem>
       <MenuItem value="OrgCount">Sort by Number of Organizations</MenuItem>
      </Select>
     </Box>
     <Input
      type="search"
      placeholder="Search Programs"
      value={name}
      onChange={filter}
      className="input search-list"
     />
    </Stack>
    {
      loading ? <GenericLoader />
      : <Box>
       <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
         <TableHead sx={{ backgroundColor: "#eaebeb" }}>
          <TableRow>
           <TableCell>Program Name</TableCell>
           <TableCell>Date of Creation</TableCell>
           <TableCell>Number of Organizations</TableCell>
           <TableCell align="right">Actions</TableCell>
          </TableRow>
         </TableHead>
         <TableBody>
          {foundRows
           .slice((page - 1) * itemsPerPage, page * itemsPerPage)
           .map((row) => (
            <TableRow
             key={row._id}
             sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
             <TableCell component="th" scope="row">
              {row.Name}
             </TableCell>
            <TableCell>{dayjs(row.createAt).format('MM/DD/YYYY')}{row.date}</TableCell>
             <TableCell>{row.number_of_clients}</TableCell>
             <TableCell align="right">
              <Link className="text-red-10 flex items-center justify-end"  to={`/admin/projects/${projectId}/programs/${row._id}`}>
               View Details <i className='bx bx-chevron-right text-xl'/>
              </Link>
             </TableCell>
            </TableRow>
           ))}
         </TableBody>
        </Table>
       </TableContainer>
       <Grid container justifyContent="space-between" marginTop="30px">
        <Grid item>
         {displayed <= foundRows.length ? (
          <Typography>
           {displayed} out of {total}
          </Typography>
         ) : (
          <Typography>
           {foundRows.length} out of {total}
          </Typography>
         )}
        </Grid>
        <Grid item>
         <Pagination
          count={noOfPages}
          page={page}
          onChange={handleChange}
          defaultPage={1}
          color="primary"
          size="large"
          className="pagination"
         />
        </Grid>
       </Grid>
      </Box>
    }
  </Box>
  </Box>
 );

}

export default ProjectDetails