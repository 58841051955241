import { useRef, useCallback } from 'react'
import RadialBarChart from "components/charts/RadialBarChart"
import { radialChartData } from "components/charts/mock-data/charts-data"
import DownloadButton from "components/charts/DownloadButton"
import dayjs from 'dayjs'
import customParseFormat from 'dayjs/plugin/customParseFormat'
import { useSelector } from "react-redux";
import { checkIsUk } from 'helpers/checkIsUK';
import { Link } from 'react-router-dom';
dayjs.extend(customParseFormat)

const dayFirstFormat = ['UK']

const DashboardHomeSummary = ({
	summaryData = null,
  notPaidFDD = null,
}) => {
  const user = useSelector((state) => state.user.user);
  const { projectId } = user.userData ? user.userData : user.user;
  const isUK = projectId?.Project_Abbreviation?.includes("UK")
  // const format = dayFirstFormat.includes(projectId?.Project_Abbreviation) ? 'DD.MM.YYYY' : 'MM.DD.YYYY';
  const format = checkIsUk() ? 'DD.MM.YYYY' : 'MM.DD.YYYY';

  const radChart = useRef(null)
  const nationality = useRef(null)
  const surveyed = useRef(null)
  const rate = useRef(null)
	return (
    <div className="grid overflow-hidden grid-cols-4 grid-rows-2 gap-6">
      <div className="row-start-1 row-span-2 col-start-1 col-span-2">
        <div className="bg-white h-full relative">
         <div className="absolute top-4 right-4">
           <DownloadButton targetRef={radChart} name="Employee Survey Response Information" />
         </div>
         <div className="p-7 h-full bg-white" ref={radChart}>
          <div className="flex flex-row items-center justify-between">
           <p className="text-md">Employee Survey Response Information</p>
          </div>

          <div className="flex flex-row mt-1">
           <div className="flex-1 flex flex-col justify-start space-y-2 pb-2 pt-10">
            {summaryData?.radialChartData?.data.map((legend, index) => (
             <div
              className="flex flex-row items-center space-x-5"
              key={`radial-chart-legend-${index}`}
             >
              <div
               className="min-h-3 min-w-3 rounded-full"
               style={{
                backgroundColor:
                 summaryData?.radialChartData.metaConfig.colors[index],
               }}
              />{" "}
              <span className="text-sm text-gray-30">{legend.label}</span>
             </div>
            ))}
           </div>
           <div style={{ height: "30vh", width: "60%" }} className='relative mt-6 mb-6'>
            <RadialBarChart
              notPaidFDD={notPaidFDD}
              data={summaryData?.radialChartData?.data}
              metaConfig={summaryData?.radialChartData?.metaConfig}
            />
            { notPaidFDD &&
              <div class="absolute inset-0 bg-black opacity-100 flex flex justify-center items-center -mt-6 -mb-6" style={{backdropFilter: 'blur(10px)'}}>                
                <div className='w-2/4 px-2 py-1 bg-red-400 rounded-sm'>
                   <Link className="text-white text-xs flex justify-center text-center" to="/reports-store">Click here to see your survey average</Link>
                </div>
              </div>
            }
           </div>
          </div>

          <div className="mt-0 text-gray-30 text-xs">
            <p>{checkIsUk() ? 'Programme' : 'Program'} Period: {dayjs(summaryData?.startDate).format(format)} - {dayjs(summaryData?.endDate).format(format)}</p>

           {/* <p>Survey Period: 01.07.2021 - 02.07.2021</p> */}
          </div>
         </div>
        </div>
      </div>

      <div className="relative">
        <div className="absolute top-4 right-4">
         <DownloadButton targetRef={nationality} name={`Number of Employees the ${checkIsUk() ? 'Organisation' : 'Organization'} has Nationally`} />
        </div>
        
        <div ref={nationality} className="bg-white border-l-4 border-blue-0 flex-1 p-6 h-full">
          <div className="flex flex-col justify-between h-full">
            <p className="text-sm">
             Number of Employees the <br/>{checkIsUk() ? 'Organisation' : 'Organization'} has Nationally
            </p>
           <p className="text-3xl font-medium">{summaryData?.nationalityCount || 0}</p>
          </div>
        </div>
      </div>
      
      <div className="relative">
        <div className="absolute top-4 right-4">
         <DownloadButton targetRef={surveyed} name={`Number of Employees the ${checkIsUk() ? 'Organisation' : 'Organization'} has Nationally`} />
        </div>
        <div ref={surveyed} className="bg-white border-l-4 border-red-0 flex-1 p-6 h-full">
          <div className="flex flex-col justify-between h-full">
            <p className="text-sm">Number of Employees <br/>Eligible to be Surveyed</p>
            
             <p className="text-3xl text-black-0 font-medium">
              {summaryData?.eligibilityCount || 0}
             </p>
          </div>
        </div>
      </div>
      
      <div className="row-start-2 col-span-2 relative">     
        <div className="absolute top-4 right-4">
         <DownloadButton targetRef={rate} name="Response Rate" />
        </div>
        <div ref={rate} className="bg-white h-full border-l-4 border-green-0 p-6">
          <div className="h-full pb-8 flex flex-col justify-between">
            <p className="text-md">Response Rate</p>

            <div className="mt-3 flex flex-row items-center">
             <div className="">
              <p className="text-gray-30 mb-2 text-sm">
               Surveys sent: {summaryData?.sendSurvey || 0}
              </p>
              <p className="text-gray-30 text-sm">
               Surveys completed: {summaryData?.surveyCompleted || 0}
              </p>
             </div>
             <div className="flex-1 flex flex-row justify-end items-end">
              <p className="text-5xl font-medium text-black-0">
               {summaryData?.responseRatePercentage || 0}%
              </p>
             </div>
            </div>
          </div>
         </div>
       </div>

    </div>
 );

}

export default DashboardHomeSummary
