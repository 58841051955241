import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { List, ListItem } from "@mui/material";
import Alert from "@mui/material/Alert";
import Skeleton from "@mui/material/Skeleton";
// import GenericFilter from "components/filters/generic-filter/index";
import Filters from "./components/filters";
import ReportsHeader from "../components/reports-header";
import PurchaseFilter from "./components/purchase-filter";
import ReportDescription from "../components/report-description";
import { useSelector, useDispatch } from "react-redux";
import { API_ENDPOINT } from "config/api";
import SectionLoading from "components/loaders/SectionLoading";
import downloadFile from "helpers/downloadFile";
import AppAction from 'redux/modules/app/actions';

const externalData = {
  borders: [
    "border-red-0",
    "border-yellow-0",
    "border-green-0",
    "border-purple-0",
    "border-blue-0",
  ],
  lastBorderIndexUsed: 0,
};

const EmployeeVerbatims = () => {
  const { http } = global.services;
  const history = useHistory();
  const dispatch = useDispatch();
  const {user, admin} = useSelector(({user}) => user);
  const {selectedProgramId, timeEstimate, clearcache} = useSelector(({ app }) => app);
  const [loadedWithFilters, setLoadedFilter] = useState(false);
  const [questionId, setFilterId ] = useState(null);
  const [allowFilter, setAllowFilter] = useState(false);

  const [state, setState] = useState({
    questions: [],
    filterData: [],
    loading: true,
    error: null,
    isDownloading: false,
  });
  const { organizationProgram } = user.userData ? user.userData : user.user;

  const selectOrgProgram = organizationProgram.find(i => i.programId?._id == selectedProgramId) || organizationProgram[0];
  const programId = selectedProgramId || selectOrgProgram?.programId?._id;
  const EVFilter = selectOrgProgram?.EV_Sorting_Filter || null;
  console.log('Ev filter', EVFilter);
  useEffect(() => {
    loadFilter();
    initialization();
    checkFilterIfAllowed();
  }, [selectedProgramId]);

  const checkFilterIfAllowed = () => {
    const { EV_Sorting_Filter, EV_Sorting_Payment_Details, EV_Sorting_Payment_Type  } = selectOrgProgram;
    setAllowFilter(EV_Sorting_Filter && EV_Sorting_Payment_Type && EV_Sorting_Payment_Details);
  };

  const loadFilter = async () => {
    let query = {};
    if(organizationProgram) query.selectedProgramId = programId;
    if(clearcache) query.clearCache = clearcache;
    const { data } = await http.get('client/fetchSurveyFilter', {...query})
    const boughtFilter = data.data?.find(i => i.filterLabel == EVFilter);
    if(boughtFilter) setFilterId(boughtFilter?.QuestionId);
  }

  const initialization = async () => {
    const query = { selectedProgramId: programId };
    if(clearcache) query.clearCache = clearcache
    if( (selectOrgProgram["EV_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) query.isDummy = true;

    let response;
    try {
      response = await http.get("client/getOpenResponsesQuestions", query);
      if (response.status !== 200 || !response.data?.success) {
        throw (
          response.data ||
          new Error(
            `Failed with status: ${response.status} ${response.statusText}`
          )
        );
      }
    } catch (err) {
      console.log(err);
      setState((prevState) => ({
        ...prevState,
        loading: false,
        error:
          "An error occured while loading content. Please refresh the page and try again.",
      }));
      return;
    }

    setState((prevState) => ({
      ...prevState,
      questions: response.data.data,
      loading: false,
      error: null,
    }));
  };

  const handleBorderColor = () => {
    let borderIndex = 0;
    while (borderIndex === externalData.lastBorderIndexUsed)
      borderIndex = parseInt(
        Math.random() * (externalData.borders.length - 1 - 0) + 0
      );

    externalData.lastBorderIndexUsed = borderIndex;

    return externalData.borders[borderIndex];
  };

  const download = {
    data: "",
    filters: [],
    types: ["XLS", "PDF"],
  };

  const purchase = () => {
    setState((prevState) => ({ ...prevState, purchase: true }));
  };

  const downloadFullReport = () => {
    setState((prevState) => ({ ...prevState, isDownloading: true }));
    let query = {
      selectedProgramId: selectedProgramId || organizationProgram[0]?.programId?._id,
    }
    if(clearcache) query.clearCache = clearcache
    if( (selectOrgProgram["EV_Access"] || '').trim().toLowerCase() !== 'yes' && !admin ) query.isDummy = true;
    let dlBody = {} 
    if (questionId) {
      // dlBody.queryFilter = state.filterData //I think this is more correct

      if(allowFilter) {
        dlBody.queryFilter = { questionId }
      }
    }
    const queryString = new URLSearchParams(query).toString();
    var downloadable = false;
    setTimeout(()=> {
      if(!downloadable) {
        dispatch( AppAction.setAlert(`Compiling report. Estimated download time is ${timeEstimate} minute${timeEstimate > 1 ? "s" :''}`) )
      }
    }, [3000])
    downloadFile(
      `${API_ENDPOINT}/client/getOpenResponsesAnswersReport?${queryString}`,
      dlBody,
      "post"
    ).then((response)=>{
      const { headers } = response;
      const contentType = headers.get('content-type');
      if(contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then(({data}) => {
          const { signedUrl } = data.data
          const { pathname } = new URL(signedUrl);
          const dlFile = document.createElement("a");
          dlFile.download = pathname.replace('/', '');
          dlFile.href = signedUrl;
          dlFile.target = "_blank";
          dlFile.click();
        })
      }
    })
      .catch((err) => {
        console.log(err);
        return;
      })
      .finally(() => {
        setState((prevState) => ({ ...prevState, isDownloading: false }))
        downloadable = true;
      });
  };

  const handleApplyFilter = (event) => {
    event.preventDefault();
    // console.log('Running logs');
    if (!state.loaded) {
      initialization(); //rerunning with filters
      setLoadedFilter(true);
    }
  };

  const downloadConfigs = [];

  if(EVFilter && questionId) {
    downloadConfigs.push({
      ...(allowFilter && {
        context: "Download Filtered XLS",
      }),
      action: downloadFullReport,
    })
  } else {
    downloadConfigs.push({
      action: downloadFullReport,
    })
  }

  const renderFilterToLinks = () => {
    const { filterData } = state
    const params = { ...filterData }
    if(Object.keys(state.filterData).length === 0) return "";
    return "?"+(new URLSearchParams(params)).toString()
  }
  return (
    <div className="open-response-cap">
      {state.isDownloading && <SectionLoading />}

      <ReportsHeader
        download={download}
        pageName="Employee Verbatims"
        accessGroup="EV_Access"
        downloadConfigs={downloadConfigs}
      />

      <ReportDescription description="Any responses to open-ended survey questions are contained in this report." />

      {state.loading ? (
        <>
          <Skeleton
            animation="wave"
            height={100}
            style={{ marginBottom: "-2rem" }}
          />
          <Skeleton
            animation="wave"
            height={100}
            style={{ marginBottom: "-2rem" }}
          />
          <Skeleton
            animation="wave"
            height={100}
            style={{ marginBottom: "-2rem" }}
          />
          <Skeleton
            animation="wave"
            height={100}
            style={{ marginBottom: "-2rem" }}
          />
          <Skeleton
            animation="wave"
            height={100}
            style={{ marginBottom: "-2rem" }}
          />
        </>
      ) : (
        <>
          {state.error ? (
            <div className="mt-5">
              <Alert variant="filled" severity="error">
                {state.error}
              </Alert>
            </div>
          ) : (
            <div className="list-item">

            { !EVFilter && <PurchaseFilter /> }
   {/*             
              EVFilter && <div className="my-10">
                  <Filters
                    setFilterId={setFilterId}
                    runFilters={handleApplyFilter} 
                    onChange={(data) =>
                      setState((prevState) => ({
                        ...prevState,
                        filterData: data,
                      }))
                    }
                  />
                </div>
              */}
              
              {state.questions.map((item, index) => (
                <List
                  key={`list-item-${index}`}
                  className={`list border-l-4 bg-white cursor-pointer ${handleBorderColor()}`}
                >
                  <ListItem
                    className="flex justify-between"
                    onClick={() =>
                      history.push(`/employee-verbatims/${item.id + renderFilterToLinks()}`)
                    }
                  >
                    {item.caption}
                    <i className="bx bx-chevron-right text text-2xl text-gray float-right"></i>
                  </ListItem>
                </List>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default EmployeeVerbatims;
