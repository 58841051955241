import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import { Button } from "@mui/material";
import DownloadIcon from "assets/images/icons/download.png";
import ReportsHeader from "../../components/reports-header";
import CarouselChart from "./components/CarouselChart";
import CarouselSurveyAverage from "./components/CarouselSurveyAverage";

import CarouselChartEmpty from "./components/CarouselChartEmpty";
import GroupedBarChartSummary from "./components/GroupedBarChartSummary";
import AlertModal from "./components/AlertModal";

import { groupedBarChartData } from "components/charts/mock-data/charts-data";
import ReportDescription from "../../components/report-description";
import { API_ENDPOINT } from "config/api";
import useAccessible from 'components/hooks/useSideBarAccessible';
import PageDropdown from "../../components/page-dropdown";
import AppAction from 'redux/modules/app/actions';
import downloadFile from "helpers/downloadFile";

const pages = [{
  "id": 'employee-response-breakdown',
  "path": "/employee-response-breakdown",
  "title": "Employee Response Breakdown"
},
{
  "id": 'detailed-results',
  "path": "/detailed-results",
  "title": "Detailed Results"
},
{
  "id": 'response-patterns',
  "path": "/response-patterns",
  "title": "Response Patterns"
},
{
  "id": 'annual-trends',
  "path": "/annual-trends",
  "title": "Annual Trends"
}];

const sortFields = ['Agree', 'Neutral', 'Disagree'];
const mappedFields = {
  'Agree': 'Agreement',
  'Neutral': 'Neutral',
  'Disagree': 'Disagreement',
}

const emptyResponse = [
    {
        "ResponseCaption": "Agree",
        "numberOfResponses": 0,
        "percentOfAgreement": 0,
        "colorCode": "#8C60F3",
        "percent": 0,
        "nullval": true,
        "percentage": null
    },
    {
        "numberOfResponses": 0,
        "ResponseCaption": "Disagree",
        "colorCode": "#FEC12F",
        "percent": 0,
        "nullval": true,
        "percentage": null
    },
    {
        "ResponseCaption": "Neutral",
        "percent": 0,
        "nullval": true,
        "numberOfResponses": 0,
        "colorCode": "#C4C4C4",
        "percentage": null
    }
];

const AnnualTrends = () => {
  // this page should be very similar to detailed-responses, but the ability to see 2 year's of data at once
  // user should be able to change the years of both sides of the page
  const { http } = global.services;
  const isAvailable = useAccessible('WFR_Access');
  const {user, admin} = useSelector(({user}) => user);
  const {selectedProgramId,timeEstimate, clearcache} = useSelector(({ app }) => app);
  const { organizationProgram } = user.userData ? user.userData : user.user;
  const programSelected = organizationProgram?.find(i => i?.programId?._id == selectedProgramId) || organizationProgram[0]
  const programId = selectedProgramId || programSelected?.programId?._id;
  const dispatch = useDispatch();
  const [alertModal, setModal] = useState(null);

  const { year = 2022, place = "New York" } = useSelector(
    ({ app }) => app.headerState
  );

  const [state, setState] = useState({
    reports: [],
    averages: [],
    empty: false,
    currentReportIndex: 0,
  });
  const [subGraphLoading, setLoading] = useState(false);
  const [currentReportIndex, setIndex] = useState(0);
  const [detailedReports, setReports] = useState({});
  const [barReport, setBarReport] = useState({
    metaConfig: {},
    initialData: [],
    loaded: false,
    error: null,
  });

  const [downloadStatus, setStatus] = useState({
    isDownloading: false
  });

  const download = {
    data: "data",
    filters: [],
    types: ["XLS"],
  };

  useEffect(() => {
    const { Program_Year, Program_Type } = programSelected?.programId || {};
    const { FDD_Payment_Type } = programSelected;
    // console.log('Program_Type: ', Program_Type);
    // console.log('FDD_Payment_Type: ', FDD_Payment_Type);
    /**
     * if Program_Type has work "no fee" set modal to no-fee
     */
    if(Program_Type && typeof Program_Type === 'string') {
      if((/^no\Wfee(\Wprogram)?$/gi).test(Program_Type) && !FDD_Payment_Type) {
        setState((prev) => ({ ...prev, empty: true}));
        setModal('no-fee');
        return true;
      }
    }
  
    if(!Program_Year) return false;
    const previousYear = +Program_Year - 1;
    const previousProgram = organizationProgram?.find(i => i?.programId?.Program_Year == previousYear);
    if(!previousProgram) {
      setState((prev) => ({ ...prev, empty: true}));
      setModal('no-record');
      return;
    } else if (previousProgram?.WFR_Access?.toLowerCase() !== 'yes') {
      setState((prev) => ({ ...prev, empty: true}));
      setModal('purchase');
      return;
    }

    loadFirstGraph()
    loadSecondGraph()
  }, [selectedProgramId]);

  useEffect(() => {
    if (
      currentReportIndex >= 0 &&
      state.reports?.length > 0 &&
      currentReportIndex <= state.reports?.length
    ) {
      loadThirdGraph();
    }
  }, [currentReportIndex, state.reports]);

  const loadFirstGraph = async () => {
    let query = {};
    var { organizationProgram } = user.userData ? user.userData : user.user;
    if (organizationProgram) query.selectedProgramId = selectedProgramId || organizationProgram[0]?.programId?._id;
    if((programSelected["WFR_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) query.isDummy = true;
    if(clearcache) query.clearCache = clearcache;

    setState({
      empty: false, 
      error: null, 
      reports: [], 
      averages: [],
      currentReportIndex: 0
    })
    let response;
    try {
      response = await http.get(
        `client/surveyResponseRateAnuualTrend`,
        { ...query }
      );

      const { data, success, message } = response.data
      if( data === null || success === false ) {
        setState((prev) => ({ ...prev, empty: true}));
        setModal('no-record');
        return;
      }
      
      const colors = ['#8C60F3', '#52AF79'];
      const [ yearset ] = data;
      const averages = [{
        data: Object.keys(yearset)
          .sort((prev, curr) => curr - prev)
          .map((i, k) => {
            let year = i;
            let yearValue = yearset[i];
            return {
              year,
              value: (yearValue / 100).toFixed(2),
              metaConfig: { 
                valueKey: "value",
                colors: [ colors[k] ]
              }
            }
          })
        }]
      setState( (prev) => ({ ...prev, averages }) )
    } catch (err) {
      console.log(err);
      return;
    }
  }

  const loadSecondGraph = async () => {
    let query = {};
    var { organizationProgram } = user.userData ? user.userData : user.user;
    if (organizationProgram) query.selectedProgramId = selectedProgramId || organizationProgram[0]?.programId?._id;
    if((programSelected["WFR_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) query.isDummy = true;
    if(clearcache) query.clearCache = clearcache;
    let response;
    try {
      response = await http.get(
        `client/employeeAnnualTrendsCategory`,
        { ...query }
      );

      if( typeof response?.data?.message == 'string') {
        // setState((prev) => ({ ...prev, empty: true }));
        return;
      }
      
    } catch (err) {
      console.log(err);
      return;
    }

    const { data } = response.data;
    const mapped = data.map(i => {
      const data = Object.keys(i) || []; //Always 0
      if(data.length <= 0) return null;
      const { category, ...categoryData } = i;
      // const category = i.category; //data[0];
      // const categoryData = i[category];
      if(!categoryData) return null;

      const yearVariables = Object.keys(categoryData); // array of years
      return {
        name: category?.category,
        data: yearVariables.map(year => {
          const { data: yearData, questionIds } = categoryData[year] ?? {};
          const sortedData = yearData.sort((a,b) => sortFields.indexOf(a.ResponseCaption) - sortFields.indexOf(b.ResponseCaption));

          return {
            data: sortedData.map(yData => ({ field: yData.ResponseCaption, value: yData.percentage, ...yData })),
            questionIds,
            metaConfig: {
              centerText: year,
              colors: sortedData.map(yData => yData.colorCode),
              fieldKey: "field",
              valueKey: "value",
              fields: [
                { field: 'Agree', title: 'Agreement' },
                { field: 'Neutral', title: 'Neutral' },
                { field: 'Disagree', title: 'Disagreement' }
              ]
            },
            year
          }
        })
      }
    }).filter(i => i); //clear null values
    
    setState((prevState) => ({
      ...prevState,
      reports: mapped,
      currentReportIndex: mapped.length == 0 ? -1 : 0,
    }));

  }

  const loadThirdGraph = async () => {
    let query = {};
    var { organizationProgram } = user.userData ? user.userData : user.user;
    let params = {
      selectedProgramId: selectedProgramId || organizationProgram[0]?.programId?._id,
    }
    // if (organizationProgram) query.selectedProgramId = selectedProgramId || organizationProgram[0]?.programId?._id;
    if((programSelected["WFR_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) query.isDummy = true;
    if(clearcache) params.clearCache = clearcache;
    
    setBarReport({ 
      metaConfig: {}, 
      initialData: [], 
      loaded: false, 
      error: null
    });

    const graphQuery = state.reports?.length > 0 ? state.reports[currentReportIndex] : null;
    let response;
    try {
      if(graphQuery) {
        const yearObject = graphQuery
          .data?.sort((prev, curr) => curr.year - prev.year)
          .reduce((reduced, item, index) => {
            const label = index == 0 ? 'curruntYear' : 'prevYear';
            return {
              ...reduced,
              [label]: item.questionIds,
            }
          }, {});

        query = {
          ...query,
          category: graphQuery.name,
          ...yearObject
        }
      } else throw new Error('Unable to load data');
      
      const queryString = new URLSearchParams(params).toString();

      response = await http.post(
        `client/employeeAnnualTrendsDetail?${queryString}`,
        { ...query }
      );

      const { data } = response.data;
      let types = [];
      
      let yearSet = data.map(({question, questionId, ...years}) => Object.keys(years)).flat();
      yearSet = [...new Set(yearSet)].sort((a,b) => b-a);

      const flatten = data.map((i, k) => {
        let { question, questionId, ...years } = i;
        // if(!question || yearSet.length !== 2)
        //   throw new Error('Invalid map of data');
        for(var k in years){
          if(!question) question = years[k].question;
          if(!questionId) questionId = years[k]?.questionId;
          else break;
        }
        if(types.length === 0) types = yearSet;
        return {
          label: question,
          questionId,
          valueSet: yearSet.reduce((reduced, yearData) => {
            let value = !!years[yearData] ? years[yearData].responses : emptyResponse;
            return {
              ...reduced,
              [yearData]: value.reduce((sub, item)=>{
                const field = mappedFields[item.ResponseCaption] || 'Neutral';
                return { ...sub, [field]: item.nullval ?  -1 : item.percentage }
              }, {})
              // End of YearData
            }
          }, {})
        }
      });
      let metaConfig = {
        labelKey: "label",
        valueKey: "value",
        types: types.map(i => ({ field: i, label: i })),
        colors: ["#8C60F3", "#52AF79"],
      };

      setBarReport({metaConfig, 
        initialData: flatten, 
        loaded: true});
      
    } catch (err) {
      console.log(err);
      return;
    }
  }

  const handleSlideChange = (index) => setIndex(index);

  const downloadClick = async () => {
    setStatus((prevState) => ({ ...prevState, isDownloading: true }));
    let query = {
      selectedProgramId: selectedProgramId || organizationProgram[0]?.programId?._id,
    }
    if(clearcache) query.clearCache = clearcache
    if( (programSelected["WFR_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) query.isDummy = true;
    let dlBody = {} 

    const queryString = new URLSearchParams(query).toString();
    var downloadable = false;
    setTimeout(()=> {
      if(!downloadable) {
        dispatch( AppAction.setAlert(`Compiling report. Estimated download time is ${timeEstimate} minute${timeEstimate > 1 ? "s" :''}`) )
      }
    }, [3000])
    downloadFile(
      `${API_ENDPOINT}/client/annualTrensReportDownload?${queryString}`,
      dlBody,
      "post"
    ).then((response)=>{
      const { headers } = response;
      const contentType = headers.get('content-type');
      if(contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then(({data}) => {
          const { signedUrl } = data.data
          const { pathname } = new URL(signedUrl);
          const dlFile = document.createElement("a");
          dlFile.download = pathname.replace('/', '');
          dlFile.href = signedUrl;
          dlFile.target = "_blank";
          dlFile.click();
        })
      }
    })
      .catch((err) => {
        console.log(err);
        return;
      })
      .finally(() => {
        setStatus((prevState) => ({ ...prevState, isDownloading: false }))
        downloadable = true;
      });
  }


  const downloadConfigs = [];
  
  if(!state.empty) {
    downloadConfigs.push({
      context: "Annual XLS Report",
      action: downloadClick,
    })
  }
  return (
    <React.Fragment>
      <ReportsHeader 
        download={download} 
        pageName="Annual Trends" 
        accessGroup="WFR_Access" 
        workforce 
        downloadConfigs={downloadConfigs}
        />

      <ReportDescription
        description="Compare current levels of workforce engagement and satisfaction with the scores from your previous employee survey."
        annualtrends
      />
      {
        !isAvailable && <div className="my-4"><PageDropdown pages={pages} /></div>
      }
      <AlertModal open={alertModal} onClose={() => setModal(null)}/>
      {state.empty ? (
        <CarouselChartEmpty />
      ) : (
        <>
          {
            state.averages?.length > 0 && <CarouselSurveyAverage reports={state.averages}/>
          }
          <CarouselChart
            reports={state.reports}
            onSlideChange={handleSlideChange}
          />
          {!barReport.loaded ? (
            <Skeleton animation="wave" height={500} />
          ) : (
            <GroupedBarChartSummary {...barReport} report={ state.reports?.length > 0 ? state.reports[currentReportIndex] : {} }/>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default AnnualTrends;
