import Skeleton from "@mui/material/Skeleton";

export default function GenericLoader() {
	return <>
      <Skeleton
        animation="wave"
        height={100}
        style={{ marginBottom: "-2rem" }}
      />
      <Skeleton
        animation="wave"
        height={100}
        style={{ marginBottom: "-2rem" }}
      />
      <Skeleton
        animation="wave"
        height={100}
        style={{ marginBottom: "-2rem" }}
      />
      <Skeleton
        animation="wave"
        height={100}
        style={{ marginBottom: "-2rem" }}
      />
      <Skeleton
        animation="wave"
        height={100}
        style={{ marginBottom: "-2rem" }}
      />
    </>
}