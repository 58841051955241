import React, { useState, useEffect } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route,
	Redirect
} from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import RoutesList from './routes-list'

import AppActions from "redux/modules/app/actions"
import UserActions from "redux/modules/user/actions"
import PageInitialization from "components/loaders/page-initialization"
import Layout from "layouts/index"

const externalData = {
	currentPageMountedSet: false
}

const RouteComponent = () => {

	const dispatch = useDispatch()
	const user = useSelector(state => state.user.user)
	// const { selectedProgramId } = useSelector((state) => state.app);
	// const currentPageMounted = useSelector(state => state.app.currentPageMounted)
	const routes = RoutesList
	const { localstorage } = global.services
	const [ state, setState ] = useState({
		initializing: true,
	})

	useEffect(async () => {
		
		/** Initializing of user data stored in local storage */
		const data = localstorage.get('user')
		const parsed = JSON.parse(data.value);
		await dispatch(UserActions.setUser(parsed || null))
		if(parsed?.userData && parsed?.userData?.organizationProgram?.length > 0){
			// const program = parsed?.userData?.organizationProgram?.slice(-1)[0];
			const organizationprogramId = parsed.userData?.organizationprogramId;
			let dealId = parsed?.userData?.dealId || null;
			let defaultProgramId = null; 
			if(organizationprogramId || dealId){
				defaultProgramId = parsed.userData?.organizationProgram.find(item => item._id === organizationprogramId);
				if(!defaultProgramId?.programId?._id){
					defaultProgramId = parsed.userData?.organizationProgram.find(item => item?.DealId === dealId);
				}
			}
			console.log("***********INDEX ROUTER*************");
			// const program = responseData.userData?.organizationProgram?.find(orgProg => orgProg['_id'] === organizationprogramId);
			if(parsed.userData?.organizationProgram){
				dispatch(AppActions.selectProgram(defaultProgramId?.programId?._id || parsed.userData?.organizationProgram[0]?.programId?._id));
			}
			
			// const program = parsed.userData?.organizationProgram?.find(orgProg => orgProg['_id'] === organizationprogramId);
			// const persistentProgId = localstorage.get('selectedProgramId')?.value;
			// if(program) dispatch( AppActions.selectProgram(persistentProgId || program.programId?._id) );
		}
		await setState({ ...state, initializing: false })
	}, [])


	

	const renderRoute = (route, routeIndex) => {

		if (routeIndex === 0 && !externalData.currentPageMountedSet) {
			dispatch(AppActions.setCurrentPageMounted(false))
		}

		let role = '';

		return <Route exact path={ route.path } key={`route-${route.path}`} render={ () => {
			const dashboardContentContainer = document.getElementById('dashboard-content-container')
			if (dashboardContentContainer)
				dashboardContentContainer.scrollTo({ top: 0, behavior: 'smooth' })

			if (user && typeof user.user !== 'undefined'){
				role = user.user.role;
			}else if(user && typeof user.userData !== 'undefined'){
				role = user.userData.role;
			}

			if (user && role === 'admin' && route.path === '/login') {
				return <Redirect key={`redirect-${route.path}`} to={`/admin/projects`} />
			}

			if (route.access?.guest && user) {
				return <Redirect key={`redirect-${route.path}`} to={`/dashboard`} />
			}

			if ((route.access?.client || route.access?.admin) && !user) {
				return <Redirect key={`redirect-${route.path}`} to={`/login`} />
			}

			externalData.currentPageMountedSet = true
			setTimeout(() => {
				dispatch(AppActions.setCurrentRoute(route))
				dispatch(AppActions.setCurrentPageMounted(true))
			}, 0)
			return route.component()
		}}/>
	}

	return (
		<React.Fragment>

			{
				state.initializing ? (
					<PageInitialization />
				) : (
					
					<Router>
						<Layout>
							<Switch>
								{
									routes.map((route, index) => {
										return renderRoute(route, index)
									})
								}
							</Switch>
						</Layout>
					</Router>
					
				)
			}

		</React.Fragment>
	)
}

export default RouteComponent
