import { useCallback, useEffect, useRef } from "react";
import { pie as d3Pie, arc as d3Arc } from "d3-shape";
import { select as d3Select } from "d3-selection";

const PieChart = (props) => {
  const { data, metaConfig } = props;

  const chartRef = useRef(null);

  const drawChart = useCallback(() => {
    if (chartRef.current) {
      const { width, height } = chartRef.current.getBoundingClientRect();
      const pieRadius = (Math.min(width, height) / 2) * 0.65;
      const labelWidth = pieRadius / 4,
        labelHeight = pieRadius / 4;

      const { fields, colors } = metaConfig;
      const transformedData = fields.map(({ field }) => ({
        field,
        value: data[field],
      }));
      const pie = d3Pie()
        .sort(null)
        .value((d) => d.value);
      const arc = d3Arc().outerRadius(pieRadius).innerRadius(0);
      const pieData = pie(transformedData);

      const svgG = d3Select(chartRef.current)
        .append("svg")
        .attr("height", height)
        .attr("width", width)
        .append("g")
        .attr("transform", `translate(${width / 2}, ${height / 2})`)
        .selectAll("slices")
        .data(pieData)
        .join("g");

      svgG
        .append("path")
        .attr("d", arc)
        .attr("fill", (d) => colors[d.index])
        .attr("stroke", "white")
        .attr("stroke-width", 2);
        //Line label
      svgG
        .selectAll("allPolylines")
        .data(pieData)
        .join("polyline")
        .attr('class',"poly-class")
        .style("fill", "none")
        .attr("stroke-width", 1)
        .attr("stroke", (d) => metaConfig.colors[d.index])
        .attr("points", (d) => {
          var posA = arc.centroid(d);
          var posB = posA.map((p) => p * 2.12);
          return [posA, posB];
        });
        //number label
      svgG
        .selectAll("allLabels")
        .data(pieData)
        .join("text")
        .text(({ value }) => `${(value*100).toFixed(0)}%`)
        .attr("transform", (d) => {
          var pos = arc.centroid(d).map((p, k) => p * (d.index % 2 == 0 ? 2.45 : 2.2));
          return `translate(${pos})`;
        })
        .style("text-anchor", "middle")
        .style("font-size", `${pieRadius * 0.12}px`);

      //Old label
      // const quadrantDiff = [
      //     [1, 0],
      //     [0, 0],
      //     [0, 1],
      //     [1, 1],
      //   ],
      //   quadrantDirections = [
      //     "bottom-left",
      //     "bottom-right",
      //     "top-right",
      //     "top-left",
      //   ];
      // d3Select(chartRef.current)
      //   .append("div")
      //   .attr("id", "labels-container")
      //   .selectAll(".label")
      //   .data(pieData)
      //   .join("span")
      //   .style("width", `${labelWidth}px`)
      //   .style("height", `${labelHeight}px`)
      //   .style("background", "#fefefe")
      //   .style("color", "black")
      //   .each(function (d) {
      //     d.angle = (5 * Math.PI - d.startAngle - d.endAngle) / 2;
      //     d.quadrant = Math.floor(d.angle / (Math.PI / 2));
      //   })
      //   .attr("class", (d) => `label ${quadrantDirections[d.quadrant % 4]}`)
      //   .style("top", function (d) {
      //     const y = Math.sin(d.angle) * pieRadius * 0.9;
      //     const [, yDiff] = quadrantDiff[d.quadrant % 4];
      //     return `${-y + (yDiff - 1) * labelHeight}px`;
      //   })
      //   .style("left", function (d) {
      //     const x = Math.cos(d.angle) * pieRadius * 0.9;
      //     const [xDiff] = quadrantDiff[d.quadrant % 4];
      //     return `${x + (xDiff - 1) * labelWidth}px`;
      //   })
      //   .style("font-size", `${pieRadius * 0.1}px`)
      //   .append("text")
      //   .attr("fill", "black")
      //   .text(
      //     (d) => 
      //       `${(d.value * 100).toFixed(0)}%`
      //       // `${(((d.endAngle - d.startAngle) / (2 * Math.PI)) * 100).toFixed(
      //       //   0
      //       // )}%`
      //   )
    }
  }, [data, metaConfig]);

  const destroyChart = useCallback(() => {
    if (chartRef.current) {
      d3Select(chartRef.current).select("svg").remove();
      d3Select(chartRef.current).select("div").remove();
    }
  }, []);

  const rebuildChart = useCallback(() => {
    destroyChart();
    drawChart();
  }, [destroyChart, drawChart]);

  useEffect(() => {
    drawChart();
    window.addEventListener("resize", rebuildChart);
    return () => {
      destroyChart();
      window.removeEventListener("resize", rebuildChart);
    };
  }, [drawChart, rebuildChart, destroyChart]);

  return (
    <div
      ref={chartRef}
      style={{ width: "100%", height: "100%", position: "relative" }}
    ></div>
  );
};

export default PieChart;
