import { useState, useEffect, useRef } from 'react';
import { Box, Typography, Breadcrumbs, Link, Stack, Paper, Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, InputLabel, Select, MenuItem, Input, 
		TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Pagination, LinearProgress, IconButton } from '@mui/material';
import { ExportSVG, ArrowPrevSVG, RotationRightSVG, LockSVG, ArrowNextSVG, CustomReportSvg, KeyImpactSvg, CloseSVG, CopySVG, PreviewDashboardSVG } from "components/svg-components";
import { styled } from '@mui/material/styles';
import { Close } from '@mui/icons-material';
import { useHistory, useParams } from 'react-router-dom';
import UploadService from "services/fileUploadService";
import dayjs from 'dayjs'

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(2.5),
	color: theme.palette.text.secondary
}));

const ClientDetails = () => {
    const { http } = global.services
	const history = useHistory()
    const { page } = useParams();
    const [details, setDetails] = useState({});

	const [resetPwd, setResetPwd] = useState(false);
	const resetPwdOpen = () => setResetPwd(true);
	const resetPwdClose = () => setResetPwd(false);

	const [generatePwd, setGeneratePwd] = useState(false);
	const generatePwdOpen = () => setGeneratePwd(true);
	const generatePwdClose = () => setGeneratePwd(false);

    const [uploadCR, setUploadCR] = useState(false);
    const uploadCROpen = () => setUploadCR(true);
    const uploadCRClose = () => setUploadCR(false);

    const [uploadKIA, setUploadKIA] = useState(false);
    const uploadKIAOpen = () => setUploadKIA(true);
    const uploadKIAClose = () => setUploadKIA(false);

/*upload*/
    const [selectedFiles, setSelectedFiles] = useState(undefined);
    const [progressInfos, setProgressInfos] = useState({ val: [] });
    const [message, setMessage] = useState([]);
    const [fileInfos, setFileInfos] = useState([]);
    const progressInfosRef = useRef(null);

    useEffect(() => {
        UploadService.getFiles().then((response) => {
          setFileInfos(response.data);
        });
        loadDetails()
    },[])

    const selectFiles = (event) => {
        setSelectedFiles(event.target.files);
        setProgressInfos({ val: [] });
    };

    const uploadFiles = () => {
        const files = Array.from(selectedFiles);

        let _progressInfos = files.map(file => ({ percentage: 0, fileName: file.name }));

        progressInfosRef.current = {
          val: _progressInfos,
        }

        const uploadPromises = files.map((file, i) => upload(i, file));

        Promise.all(uploadPromises)
            .then(() => UploadService.getFiles())
            .then((files) => {
                setFileInfos(files.data);
            });

        setMessage([]);
    };

    const uploadKIAFile = () => {
        
    }

    const upload = (idx, file) => {
        let _progressInfos = [...progressInfosRef.current.val];
        return UploadService.upload(file, (event) => {
          _progressInfos[idx].percentage = Math.round(
            (100 * event.loaded) / event.total
          );
          setProgressInfos({ val: _progressInfos });
        })
          .then(() => {
            setMessage((prevMessage) => ([
              ...prevMessage,
              "Uploaded the file successfully: " + file.name,
            ]));
          })
          .catch(() => {
            _progressInfos[idx].percentage = 0;
            setProgressInfos({ val: _progressInfos });

            setMessage((prevMessage) => ([
              ...prevMessage,
              "Could not upload the file: " + file.name,
            ]));
          });
      };

    /*end upload*/
    const loadDetails = async () => {
        const { data } = await http.get(`/admin/getOrganizations/${page}`)
        setDetails(data.data || {});
    }

	const [pwd, setPwd] = useState(null);
	const generateString = event => {
	    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=';
	    let charactersLength = characters.length;
	    let result = '';
	    for ( var i = 0; i < 10; i++ ) {
	      result += characters.charAt(Math.floor(Math.random() * 
	 charactersLength));
	   }
	   setPwd(result);
	}

    const previewOrg = () => {

    }

	return (
  <Box className="projects-container">
   <Breadcrumbs aria-label="breadcrumb" separator="|">
    <Typography
     onClick={() => {
      history.push("/admin/projects");
     }}
    >
     Clients
    </Typography>
    <Typography color="text.primary">
     { details.Alias_Company_Name || details.Account_Name}
    </Typography>
   </Breadcrumbs>
   <Box>
    <Typography
     component="h1"
     fontSize="30px"
     fontWeight="700"
     marginBottom="30px"
    >
     { details.Alias_Company_Name || details.Account_Name}
    </Typography>
   </Box>
   <Box className="project-wrapper">
    <Box className="project-details-wrapper" marginBottom="30px">
     <Typography
      component="h3"
      fontSize="16px"
      lineHeight="33px"
      fontWeight="700"
      marginBottom="30px"
     >
      Info
     </Typography>
     <Stack direction="row" spacing={2} marginBottom="10px">
      <Typography component="label">Email:</Typography>
      <Link href={`mailto:${details.Email_to_Send_Reporting_Website_Login_to}`} className="text-gray-40 no-underline">
       {details.Email_to_Send_Reporting_Website_Login_to}
      </Link>
     </Stack>
{/*     <Stack direction="row" spacing={2} marginBottom="10px">
      <Typography component="label">Password:</Typography>
      <Typography component="p" fontSize="22px" lineHeight="1">
       •••••••••
      </Typography>

      <Link
       href="#"
       className="text-gray-40"
       onClick={resetPwdOpen}
       underline="hover"
      >
       <Stack direction="row" spacing={2} alignItems="center">
        <RotationRightSVG />
        <Typography component="span" className="text-gray-40">
         Reset Password
        </Typography>
       </Stack>
      </Link>

      <Link
       href="#"
       className="text-gray-40"
       onClick={generatePwdOpen}
       underline="hover"
      >
       <Stack direction="row" spacing={2} alignItems="center">
        <LockSVG />
        <Typography component="span" className="text-gray-40">
         Generate New Password
        </Typography>
       </Stack>
      </Link>
     </Stack>*/}
     <Stack direction="row" spacing={2} marginBottom="10px">
      <Typography component="label">Location:</Typography>
      <Typography component="p">{details.Organization_City}</Typography>
     </Stack>
     <Stack direction="row" spacing={2} marginBottom="10px">
      <Typography component="label">Date Added:</Typography>
      <Typography component="p">{dayjs(details.Created_Time).format('MM/DD/YYYY')}</Typography>
     </Stack>
{/*     <Stack direction="row" spacing={2} marginBottom="10px">
      <Typography component="label">Number of Program:</Typography>
      <Typography component="p">00</Typography>
     </Stack>*/}
    </Box>
   </Box>

   <Box sx={{ width: "100%" }}>
     <Grid
      container
      rowSpacing={1}
      columnSpacing={{ xs: 1, sm: 2, md: 3 }}
      className="card-column"
     >
      <Grid item xs={6} md={4} className="grid-row" marginBottom="20px">
       <Item className="card-item report-section">
        <CustomReportSvg />
        <Typography
         component="h2"
         fontSize="16px"
         fontWeight="700"
         margin="10px 0"
         className="text-gray-40"
        >
         Custom Reports
        </Typography>
        <Typography component="p" fontSize="14px" marginBottom="30px">
         Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Typography>
        <Link className="link-tag text-red-0" onClick={uploadCROpen} href="#">
         <Stack direction="row" alignItems="center" spacing={2}>
          <Typography component="span">Upload Reports</Typography>
          <ArrowNextSVG />
         </Stack>
        </Link>
       </Item>
      </Grid>
      <Grid item xs={6} md={4} className="grid-row" marginBottom="20px">
       <Item className="card-item report-section">
        <KeyImpactSvg />
        <Typography
         component="h2"
         fontSize="16px"
         fontWeight="700"
         margin="10px 0"
         className="text-gray-40"
        >
         Key Impact Analysis
        </Typography>
        <Typography component="p" fontSize="14px" marginBottom="30px">
         Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Typography>
        <Link className="link-tag text-red-0" onClick={uploadKIAOpen} href="#">
         <Stack direction="row" alignItems="center" spacing={2}>
          <Typography component="span">Upload Reports</Typography>
          <ArrowNextSVG />
         </Stack>
        </Link>
       </Item>
      </Grid>
      <Grid item xs={6} md={4} className="grid-row" marginBottom="20px">
       <Item className="card-item report-section">
        <PreviewDashboardSVG />
        <Typography
         component="h2"
         fontSize="16px"
         fontWeight="700"
         margin="10px 0"
         className="text-gray-40"
        >
         Preview Dashboard
        </Typography>
        <Typography component="p" fontSize="14px" marginBottom="30px">
         Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Typography>
        <Link className="link-tag text-red-0" href="#">
         <Stack direction="row" alignItems="center" spacing={2}>
          <Typography component="span">Select Client</Typography>
          <ArrowNextSVG />
         </Stack>
        </Link>
       </Item>
      </Grid>
      <Grid item xs={6} md={4} className="grid-row" marginBottom="20px">
       <Item className="card-item report-section">
        <ExportSVG />
        <Typography
         component="h2"
         fontSize="16px"
         fontWeight="700"
         margin="10px 0"
         className="text-gray-40"
        >
         Export Report
        </Typography>
        <Typography component="p" fontSize="14px" marginBottom="30px">
         Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </Typography>
        <Link className="link-tag text-red-0" href="#">
         <Stack direction="row" alignItems="center" spacing={2}>
          <Typography component="span">Export Report</Typography>
          <ArrowNextSVG />
         </Stack>
        </Link>
       </Item>
      </Grid>
     </Grid>
    </Box>


   <Dialog
    aria-labelledby="upload custom reports"
    aria-describedby="upload custom reports"
    open={uploadCR}
    onClose={uploadCRClose}
    maxWidth="xs"
    fullWidth="true"
    className="modal_add-user"
   >
    <DialogTitle sx={{ marginTop: "15px" }}>
     <Typography
      variant="h6"
      component="span"
      fontWeight="600"
      display="block"
      className="text-center mt-6"
     >
      Upload Custom Reports
      <IconButton
        aria-label="close"
        onClick={uploadCRClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <Close />
      </IconButton>
     </Typography>
    </DialogTitle>
    <DialogContent sx={{ overflow: "hidden" }}>
     <DialogContentText>Information about formats, etc.</DialogContentText>

     <div>
      {progressInfos &&
       progressInfos.val.length > 0 &&
       progressInfos.val.map((progressInfo, index) => (
        <div className="mb-2" key={index}>
         <span>{progressInfo.fileName}</span>
         <div className="progress">
          <LinearProgress
           variant="determinate"
           value={progressInfo.percentage}
          />
          {progressInfo.percentage}%
         </div>
        </div>
       ))}

      <div className="row my-3">
       <div className="col-8">
        <label className="btn btn-default p-0">
         <input type="file" multiple onChange={selectFiles} />
        </label>
       </div>
      </div>

      {message.length > 0 && (
       <div className="alert alert-secondary" role="alert">
        <ul>
         {message.map((item, i) => {
          return <li key={i}>{item}</li>;
         })}
        </ul>
       </div>
      )}

      <div className="card">
       <div className="card-header">List of Files</div>
       <ul className="list-group list-group-flush">
        {fileInfos &&
         fileInfos.map((file, index) => (
          <li className="list-group-item" key={index}>
           <a href={file.url}>{file.name}</a>
          </li>
         ))}
       </ul>
      </div>
     </div>
    </DialogContent>
    <DialogActions
     sx={{
      justifyContent: "flex-start",
      flexWrap: "wrap",
      padding: "0 24px 30px",
     }}
    >
     <Button
      variant="contained"
      onClick={uploadFiles}
      disabled={!selectedFiles}
      sx={{ width: "100%" }}
      color="error"
      className="normal-case rounded-none"
     >
      Upload
     </Button>
     <Button
      onClick={uploadCRClose}
      sx={{ width: "100%" }}
      className="text-gray-40 normal-case"
     >
      Cancel
     </Button>
    </DialogActions>
   </Dialog>

   <Dialog
    aria-labelledby="upload custom reports"
    aria-describedby="upload custom reports"
    open={uploadKIA}
    onClose={uploadKIAClose}
    maxWidth="xs"
    fullWidth="true"
    className="modal_add-user"
   >
    <DialogTitle sx={{ marginTop: "15px" }}>
     <Typography
      variant="h6"
      component="span"
      fontWeight="600"
      display="block"
      className="text-center mt-6"
     >
      Upload Key Impact Analysis
      <IconButton
        aria-label="close"
        onClick={uploadKIAClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
      >
        <Close />
      </IconButton>
     </Typography>
    </DialogTitle>
    <DialogContent sx={{ overflow: "hidden" }}>
     <DialogContentText>Information about formats, etc.</DialogContentText>

     <input
      accept="image/*"
      style={{ display: "none" }}
      id="raised-button-file"
      multiple
      type="file"
     />
     <label htmlFor="raised-button-file">
      <Button variant="raised" component="span">
       Upload
      </Button>
     </label>
    </DialogContent>
    <DialogActions
     sx={{
      justifyContent: "flex-start",
      flexWrap: "wrap",
      padding: "0 24px 30px",
     }}
    >
     <Button
      variant="contained"
      sx={{ width: "100%" }}
      color="error"
      className="normal-case rounded-none"
     >
      Upload
     </Button>
     <Button
      onClick={uploadKIAClose}
      sx={{ width: "100%" }}
      className="text-gray-40 normal-case"
     >
      Cancel
     </Button>
    </DialogActions>
   </Dialog>

   <Dialog
    aria-labelledby="add-user"
    aria-describedby="form for adding a user"
    open={resetPwd}
    onClose={resetPwdClose}
    maxWidth="xs"
    fullWidth="true"
    className="modal_add-user"
   >
    <DialogTitle sx={{ marginTop: "15px" }}>
     <Typography
      variant="h6"
      component="span"
      fontWeight="600"
      display="block"
      className="text-center"
     >
      Reset Password
     </Typography>
    </DialogTitle>
    <DialogContent sx={{ overflow: "hidden" }}>
     <DialogContentText>Reset Password</DialogContentText>
    </DialogContent>
    <DialogActions
     sx={{ justifyContent: "flex-start", padding: "0 24px 30px" }}
    >
     <Button onClick={resetPwdClose} className="text-gray-40 capitalize">
      Cancel
     </Button>
    </DialogActions>
   </Dialog>

   <Dialog
    aria-labelledby="generate-password"
    aria-describedby="form for adding a user"
    open={generatePwd}
    onClose={generatePwdClose}
    maxWidth="xs"
    fullWidth="true"
    className="modal_generate-password rounded-none"
    background="#F5F5F5"
   >
    <DialogTitle sx={{ marginTop: "15px" }}>
     <Link
      href="#"
      onClick={generatePwdClose}
      sx={{ position: "absolute", right: "15px", top: "15px" }}
     >
      {" "}
      <CloseSVG />{" "}
     </Link>
     <Typography
      variant="h6"
      component="span"
      fontWeight="600"
      display="block"
      className="text-center"
     >
      Generate Password
     </Typography>
    </DialogTitle>
    <DialogContent sx={{ overflow: "hidden" }}>
     <DialogContentText textAlign="center">
      The new user will receive an invitation to the specified mail with a login
      link
     </DialogContentText>
    </DialogContent>
    <DialogActions
     sx={{ justifyContent: "center", flexWrap: "wrap", padding: "0 24px 30px" }}
    >
     <Button
      variant="contained"
      color="error"
      onClick={generateString}
      className="normal-case rounded-none"
     >
      Generate
     </Button>

     {pwd !== null && (
      <Box sx={{ width: "100%", marginTop: "50px" }}>
       <Typography fontSize="12px">New Password</Typography>
       <Box sx={{ position: "relative" }}>
        <TextField
         value={pwd}
         border="0"
         borderRadius="0"
         className="plain-textfield"
         fullWidth
        />
        <Box
         sx={{
          position: "absolute",
          right: "15px",
          top: "50%",
          transform: "translateY(-50%)",
          zIndex: "99",
          cursor: "pointer",
         }}
        >
         <CopySVG />
        </Box>
       </Box>
      </Box>
     )}
    </DialogActions>
   </Dialog>
  </Box>
 );
}

export default ClientDetails;
