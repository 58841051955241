import { useRef, useEffect, useState } from 'react'
import { Popper, ClickAwayListener, Paper, Grow, MenuList, MenuItem, Link, CircularProgress } from '@mui/material'
import { styled } from '@mui/system'
import { toPng, toJpeg, toSvg } from 'html-to-image'
import DownloadIcon from "assets/images/icons/download.png";

const options = [
	{
		label: 'Download as PNG',
		value: 'png'
	},{
		label: 'Download as SVG',
		value: 'svg'
	},{
		label: 'Download as JPG',
		value: 'jpg'
	}
];

const Root = styled('div')`
  font-size: 0.875rem;
  position: relative;
  display: inline-block;
  vertical-align: baseline;
  color: #000;
`;

const CustomDropDown = ({ target, name = 'download' }) => {
	const listboxRef = useRef(null);
	const [loading, setLoading] = useState(false);
	const [ listboxVisible, setListboxVisible ] = useState(false);
	useEffect(()=>{
		// console.log('Should call me please');
	},[listboxVisible])

	const onClick = (type) => {
		let output;
		setLoading(true);
		switch(type){
			case 'svg':
			output = toSvg(target.current, { cacheBust: true })
			break;
			case 'jpg':
			output = toJpeg(target.current, { cacheBust: true })
			break;
			case 'png':
			default:
			output = toPng(target.current, { cacheBust: true })
			break;
		}
		output.then((dataUrl) => {
      const link = document.createElement('a')
      link.download = name + '.' + type
      link.href = dataUrl
      link.click()
    })
    .catch((err) => {
      console.log(err)
    })
    .finally(()=>setLoading(false))
	}

	return (<Root
	  onMouseOver={() => setListboxVisible(true)}
    onMouseOut={() => setListboxVisible(false)}
    onFocus={() => setListboxVisible(true)}
    onBlur={() => setListboxVisible(false)}
   	onClick={()=>setListboxVisible(true)}
    ref={listboxRef}
	>
		{
			loading ? <CircularProgress size={20} /> :
			<img src={DownloadIcon} alt="..." />
		}
    <Popper
				open={ listboxVisible }
				anchorEl={ listboxRef.current }
				placement={'bottom-end'}
				style={{zIndex: 15}}
				transition
				disablePortal
			>
			{({ TransitionProps, placement }) => (
				<Grow {...TransitionProps}
						style={{
							transformOrigin:
								placement === 'bottom' ? 'center top' : 'center bottom',
						}}>
					<Paper sx={{width: 'fit-content'}}>
						<ClickAwayListener onClickAway={ () => setListboxVisible(false) }>
					    <MenuList style={{background:'#fff'}}>
					      {options.map((option) => (
					        <MenuItem key={option.value} onClick={()=>onClick(option.value)}>
					          <p>{option.label}</p>
					        </MenuItem>
					      ))}
					    </MenuList>
						</ClickAwayListener>
					</Paper>
				</Grow>
			)}
    </Popper>
	</Root>)
}

export default function DownloadGraph({ targetRef, name = 'download' }) {
	return <CustomDropDown target={targetRef} name={name}/>
}