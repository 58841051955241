import { styled } from '@mui/material/styles';
import { Box, Typography, Paper, Button, Grid } from "@mui/material"
import Carousel from 'react-multi-carousel';
import GreeenIcon from "assets/images/icons/Icongreen.png"
import RedIcon from "assets/images/icons/Iconred.png"
//Remove this later
const testData = [{
	id: 1,
	madeInList: 82,
	notMadeInList: 15,
	panelColor: '#3F7CFB'
},{
	id: 2,
	madeInList: 36,
	notMadeInList: 25,
	panelColor: '#FFC955'
},{
	id: 3,
	madeInList: 67,
	notMadeInList: 53,
	panelColor: '#00A46A'
},{
	id: 4,
	madeInList: 29,
	notMadeInList: 70,
	panelColor: '#162630'
}]

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(1),
	color: theme.palette.text.secondary,
    // margin: '25px 0px 30px 0px',
}));

const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4
    }
};

const GraphItem = ({
	title, id, madeToList, notMadeToList, panelColor, addClassName = ""
}) => {
  if(!madeToList && !notMadeToList)
    return "";
  
  if(madeToList <= 0 && notMadeToList <= 0)
    return "";

	return <Item
        className={`pl-4 pt-6 pb-4 ${addClassName}`}
        sx={{ borderLeft: `4px solid ${panelColor}` }}
       >
        <Typography className="text-sm mt-1 mb-1 text-gray-900">
         {title}
        </Typography>
        <Typography className="text-xs">Survey Average</Typography>
        <div className="list mt-2 mb-2 text-gray-900">
         <ul>
          {
            madeToList > 0 &&
          <li className="flex items-center text-center mt-4 mb-4">
           <div className="icon mr-3">
            <img src={GreeenIcon} alt="..." className="w-10 h-10" />
           </div>
           <p className="">
            Winners
            <span className="block text-left text-lg font-semibold">{madeToList}%</span>
           </p>
          </li>
          }
          {
            notMadeToList > 0 &&
            <li className="flex items-center text-center mt-4 mb-4">
             <div className="icon mr-3">
              <img src={RedIcon} alt="..." className="w-10 h-10" />
             </div>
             <p className="">
              Non-winners{" "}
              <span className="block text-lg font-semibold text-left">{notMadeToList}%</span>
             </p>
            </li>
          }
         </ul>
        </div>
       </Item>
}

export default function SurveyAverageCarousel ({data = testData}) {
  if( data.length <= 4)
    return <Grid container spacing={2}>
      {
        data.map((i, key) => <Grid item md={3} key={key} ><GraphItem {...i}/></Grid>)
      }
    </Grid>
	 
   return <Carousel
       swipeable={false}
       draggable={false}
       showDots={true}
       responsive={responsive}
       ssr={true} // means to render carousel on server-side.
       infinite={true}
       autoPlaySpeed={1000}
       keyBoardControl={true}
       customTransition="all .5"
       transitionDuration={500}
       containerClass="carousel-container"
       removeArrowOnDeviceType={["tablet", "mobile"]}
       dotListClass="custom-dot-list-style"
       itemClass="carousel-item-padding-40-px"
      >
        {
          data.map((i, key) => <GraphItem key={'graph-item' + key} addClassName={'mr-5'} {...i}/>)
        }
      </Carousel>
}