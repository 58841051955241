import React from 'react';
import { InputLabel, MenuItem, Breadcrumbs, Select, Box, Grid, Button, Modal, Typography, TextField, FormControl, useFormControl, Stack,
 Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper, Input, Pagination } from '@mui/material';
import UserForm from './component/user-form';
import { FunnelSVG } from "components/svg-components";
import GenericLoader from 'components/loaders/generic'
import dayjs from 'dayjs'
import './index.css'
import customParseFormat from 'dayjs/plugin/customParseFormat'
dayjs.extend(customParseFormat)

const UsersPage = () => {
    const { http } = global.services
	const [open, setOpen] = React.useState(false);
    const [target, setTarget] = React.useState(null);
    const [loading, setLoading] = React.useState(false);
	const handleOpen = (target = null) => {
        setTarget(target)
        setOpen(true)
    }
	const handleClose = (load = false) => {
        setOpen(false)
        setTarget(null)
        //Load
        console.log('loading load', load)
        if(load)
            loadData()
    };

	const [filterBy, setFilterBy] = React.useState('all');
	const handleFilterBy = event => {
		setFilterBy(event.target.value);
	};

	const [sortBy, setSortBy] = React.useState('Date');
	const handleSortBy = event => {
		setSortBy(event.target.value);
	};
    const [oldRows, setOldRows] = React.useState([]);

	const itemsPerPage = 5;
	const [total, setTotal] = React.useState(0);
	const [page, setPage] = React.useState(1);
	const [displayed, setDisplayed] = React.useState(itemsPerPage);

	const [noOfPages, setNoOfPages] = React.useState(
		0
	);

	const [name, setName] = React.useState('');
	const [foundRows, setFoundRows] = React.useState([]);

    React.useEffect(()=>{
        loadData();
    }, [])

    const loadData = async () => {
        try{
            setLoading(true);
        const { data } = await http.get('user/list', {expand: 'projects'});
        const rows = data.data || [];
        setFoundRows(rows);
        setOldRows(rows);
        setTotal(rows.length);
        setDisplayed(itemsPerPage);
        setNoOfPages(Math.ceil(rows.length / itemsPerPage))
        setLoading(false);
        } catch(err){
            setLoading(false);
            console.log(err);
            return;
        }
    }

	const filter = (e) => {
	    const keyword = e.target.value;
	    
        if (keyword !== '') {

	      const results = oldRows.filter((project) => {
	        // return project.fullName.toLowerCase().startsWith(keyword.toLowerCase());
            return (project.fullName.toLowerCase().includes(keyword.toLowerCase())) ||
                    (typeof project?.role === 'string' && project?.role.toLowerCase().includes(keyword.toLowerCase())) ||
                    (project?.projects.length > 0 && project?.projects.some(proj => proj?.Name.toLowerCase().includes(keyword.toLowerCase())));
	      });

	      setFoundRows(results);

	      setTotal(results.length);
	      if(results.length <= itemsPerPage)
	      	setDisplayed(results.length);
	      else
	      	setDisplayed(itemsPerPage);

	    } else {
	    	setTotal(oldRows.length);
	      	setFoundRows(oldRows);
            setDisplayed(itemsPerPage);
	    }

	    setName(keyword);
	};

	const handleChange = (event, value) => {
		setPage(value);
		setDisplayed(value * itemsPerPage);
	};

	return (
  <div className="users-wrapper">
{/*   <Breadcrumbs aria-label="breadcrumb">
    <Link href="#" underline="hover" color="inherit">
     Users
    </Link>
   </Breadcrumbs>*/}
   <div>
    <Typography
     component="h1"
     fontSize="30px"
     fontWeight="700"
     marginTop="30px"
     marginBottom="30px"
    >
     Users
    </Typography>
   </div>
   <Box>
    <Box className="flex justify-between">
        <Button
         variant="contained"
         onClick={()=>handleOpen(null)}
         color="error"
         className="normal-case rounded-none w-64"
         sx={{ marginBottom: "40px", minWidth: "180px" }}
        >
         + Add User
        </Button>
        <div className="w-80">
            <Input
           type="search"
           placeholder="User Search"
           value={name}
           onChange={filter}
           className="input search-list"
          />
        </div>
    </Box>
    <Box className="users-lists_wrapper">
     {/*<Stack
      spacing={2}
      direction="row"
      className="filter-wrapper"
      marginBottom="20px"
      justifyContent="space-between"
      paddingRight='30px'
     >
      <Box className="flex" alignItems="center">
       <Select
        value={filterBy}
        onChange={handleFilterBy}
        sx={{
         maxHeight: "40px",
         minHeight: "unset",
         background: "#fff",
         fontSize: "14px",
        }}
       >
        <MenuItem value="all">All Roles</MenuItem>
        <MenuItem value="admin">Admin</MenuItem>
        <MenuItem value="sales">Sales</MenuItem>
        <MenuItem value="operations">Operations</MenuItem>
       </Select>
      </Box>
      <Box className="flex" alignItems="center">
       <Select
        value={sortBy}
        onChange={handleSortBy}
        sx={{
         maxHeight: "40px",
         minHeight: "unset",
         background: "#fff",
         fontSize: "14px",
        }}
       >
        <MenuItem value="Date">Sort by Date</MenuItem>
        <MenuItem value="Username">Username</MenuItem>
       </Select>
      </Box>
      <Input
       type="search"
       placeholder="User Search"
       value={name}
       onChange={filter}
       className="input search-list"
      />
     </Stack>*/}
     { loading ? <Box><GenericLoader /></Box>
    : <Box>
      <TableContainer component={Paper} className="border-none">
       <Table sx={{ minWidth: 650, borderRadius: 0 }} aria-label="simple table">
        <TableHead sx={{ backgroundColor: "#eaebeb" }}>
         <TableRow>
          <TableCell>User Full Name</TableCell>
          <TableCell>Role</TableCell>
          <TableCell>Projects</TableCell>
          <TableCell>Date Created</TableCell>
          <TableCell align="right">Actions</TableCell>
         </TableRow>
        </TableHead>
        <TableBody>
         {foundRows
          .slice((page - 1) * itemsPerPage, page * itemsPerPage)
          .map((row, index) => (
           <TableRow
            key={row.fullName+ "-"+index}
            sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
           >
            <TableCell component="th" scope="row">
             {row.fullName}
            </TableCell>
            <TableCell>
             {row.role}
            </TableCell>
            <TableCell>
             {row.projects.length > 0 ? row.projects?.map(i=>i.Name).join(', ') : 'No project assigned'}
            </TableCell>
            <TableCell>{dayjs(row.createAt).format('MM/DD/YYYY')}</TableCell>
            <TableCell align="right">
             <Link className="text-red-0" onClick={()=>handleOpen(row)}>Edit</Link>
            </TableCell>
           </TableRow>
          ))}
        </TableBody>
       </Table>
      </TableContainer>
      <Grid container justifyContent="space-between" marginTop="30px">
       <Grid item>
        {displayed <= foundRows.length ? (
         <Typography>
          {displayed} out of {total}
         </Typography>
        ) : (
         <Typography>
          {foundRows.length} out of {total}
         </Typography>
        )}
       </Grid>
       <Grid item>
        <Pagination
         count={noOfPages}
         page={page}
         onChange={handleChange}
         defaultPage={1}
         color="primary"
         size="large"
         className="pagination"
        />
       </Grid>
      </Grid>
     </Box>
    }
    </Box>
   </Box>
   <UserForm form={open} closeForm={handleClose} user={target}/>
  </div>
 );

}

export default UsersPage