import { useState } from "react";
import { Button } from "@mui/material";
import DownloadIcon from "assets/images/icons/download.png";
import { Avatar } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Fade from "@mui/material/Fade";

const Download = ({ data, filters, types, downloadConfigs = [] }) => {
  // take data, filter by filters, and prompt user to choose type of download
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (config) => {
    if (config?.action) {
      config?.action();
    }
    handleClose();
  };

  if(downloadConfigs.length == 0)
    return ""; //Return nothing

  return (
    <>
      <Button
        variant="outlined"
        sx={{
          color: "#000",
          borderColor: "#d3d3d3",
          fontSize: "14px",
          borderRadius: "0 !important",
          marginLeft: '15px'
        }}
        onClick={handleClick}
      >
        <Avatar src={DownloadIcon} sx={{ width: 18, height: 18, mr: 1 }} />
        Download Report
      </Button>
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={open && downloadConfigs.length > 0}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {
          downloadConfigs.map((i, k) => <MenuItem key={k} onClick={()=>handleMenuItemClick(i)}>{ i.context || 'Download Full XLS'} </MenuItem>)
        }
{/*        <MenuItem onClick={() => handleMenuItemClick(0)}>
          Download Full XLS
        </MenuItem>*/}
      </Menu>
    </>
  );
};

export default Download;
