import * as React from "react"

const PreviewDashboardSVG = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={60}
    height={60}
    fill="none"
    {...props}
  >
    <circle cx={30} cy={30} r={30} fill="#F5F5F5" />
    <path fill="#00A46A" d="M18 18h25v18H18zM30 36h1v5h-1z" />
    <path fill="#00A46A" d="M27 41h7v-1h-7z" />
    <circle cx={42} cy={31} r={4} fill="#FFC955" />
    <path d="M50 43.668a8 8 0 0 0-16 0h16Z" fill="#FFC955" />
  </svg>
)

export default PreviewDashboardSVG
