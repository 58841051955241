import { useHistory } from 'react-router'
import { Button, Link as DomLink } from '@mui/material'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { addToCart } from 'redux/modules/cart/actions';
import { currentCurrency } from 'helpers/checkCurrency';

export default function ReportItemLarge ({ item, index, onAddCart=() => {} }) {
	const history = useHistory();
	const dispatch = useDispatch();
	const user = useSelector(({user})=> user.user)

	const handleAddToCart = () => {
		onAddCart(item);
		dispatch(addToCart(item))
	}

	const renderItemOption = () => {
		if (item.comingSoonAt)
			return (
				<div className='flex flex-row items-center justify-end'>
					<span className='mt-2 text-sm'>Available in { item.comingSoonAt }</span>
				</div>
			) 

		if (item.isPurchased)
			return (
				<Link 
					className="text-red-10 flex items-center"
					to={item.redirectTo}
				>
				View Report
				<i className='bx bx-chevron-right ml-2 text-2xl'></i>
				</Link>
			)

		return (
			<div className='flex flex-row items-center justify-start'>
				<Button
					variant="contained"
					type="submit"
					color="error"
					className="normal-case rounded-none px-5"
					onClick={handleAddToCart}
				>
					Add to Cart
				</Button>
				<Button
					variant="text"
					className="normal-case rounded-none mx-5 px-5 text-black-0 text-center"
					onClick={ () => history.push(item.redirectTo) }
				>
					Demo
				</Button>
			</div>
		)
	}

	const generateSubject = () => {
		const mainUser = user.user;

		var append = '';
		if(mainUser.CompanyName)
			append += mainUser?.CompanyName || '';
		if(mainUser.programs)
			append += (append != '' ? ', ': '') + mainUser.programs.map(i=>i.Name).join(', ');

		return append != '' ? append : mainUser.username;
	}


	return (<div className={`w-1/2 pb-5 ${ (index + 1) % 2 != 0 ? 'pr-5' : '' }`}>
		<div className='bg-white p-5'>
			<img src={item.img} className="w-12 h-12 rounded-full mb-2"/>
			<h2 className='text-md text-black-0 font-bold line-clamp-2 mt-4 mb-3'>{ item.title }</h2>
			{
				!item.isPurchased && item.amount != null && <p className="my-5 text-red-10">{currentCurrency()} {item.amount}</p>
				// !item.isPurchased && item.amount != null && <p className="my-5 text-red-10">$ {item.amount}</p>
			}
			{
				(item.amount == null && item.title == 'Custom Reports') &&
				<DomLink 
					className="text-red-10 my-5 block"
					href={`mailto:${user.salesUser?.email}?subject=Custom Report Request: ${generateSubject()}`}
				>
				Contact Sales
				</DomLink>
			}
			<p className='h-24 line-clamp-4 mb-3 text-gray-30 text-sm'>
				{ typeof item.description == 'function' ? item.description() : item.description }
			</p>

			<div className='mt-5'>
			{ renderItemOption() }
			</div>
		</div>
	</div>)
}