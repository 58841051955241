import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={17}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.5 10v2.667A1.334 1.334 0 0 1 13.167 14H3.833A1.334 1.334 0 0 1 2.5 12.667V10"
      stroke="#fff"
      strokeLinecap="square"
    />
    <path
      d="M5.167 6.667 8.5 10l3.333-3.333M8.5 9V1"
      stroke="#fff"
      strokeLinecap="square"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgComponent
