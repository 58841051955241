import axios from 'axios'
import { API_ENDPOINT } from 'config/api'
import LocalStorageService from 'services/localstorage'
import UserActions from 'redux/modules/user/actions'
import store from 'redux/index'

class HttpService {

	config (config, endpoint) {
		const { user } = store.getState();
		const getAccessToken = LocalStorageService.get('user')
		
		const data = user?.user || JSON.parse(getAccessToken.value);
		let token = null
		if (data != null) {}
			token = (data != null) ? data.accessToken : null

		if (!endpoint.includes('http://') && !endpoint.includes('https://'))
			config.baseURL = API_ENDPOINT

		if (!config.headers)
			config.headers = {}
		
		if (token && !config.headers.Authorization && !endpoint.includes('http://') && !endpoint.includes('https://'))
			config.headers.Authorization = `Bearer ${token}`

		return config;
	}

	/** Created this method handler for error to process necessary steps before returning reject response of the promise. */
	errorhandler (errorData) {
		// const getAccessToken = LocalStorageService.get('user')
		// const data = JSON.parse(getAccessToken.value);
		const { response } = errorData;

		if(response?.data?.error?.name === 'TokenExpiredError' || response?.data?.error?.name === 'JsonWebTokenError' || response?.data?.msg === 'Token is not Valid!') {
			console.log('logged out');
			store.dispatch( UserActions.setUser(null) )
			
			//apparently since the functions of this http class is covered in promises, 
			//the original callback/await instances are not called.
			// return axios.post('/user/refreshtoken', { refreshToken: data.refreshToken }, config)
			// 	.then((res) => {
			// 		const { token, refreshToken } = res.data
					
			// 		if (token) {
			// 			config.headers.Authorization = `Bearer ${token}`
			// 			LocalStorageService.set('user', JSON.stringify({ ...data, accessToken: token, refreshToken }))
			// 			return axios(config)
			// 		}
			// 	})
		}

		/**
		 * The following cases are the reason why this handler is created
		 * - Refreshing expired tokens.
		 */
		return errorData
	}

	get (endpoint, params, config = { headers: {} }) {
		return new Promise(async (resolve, reject) => {
			config.params = params;
			config = await this.config(config, endpoint)
			axios.get(endpoint, config)
				.then(response => {
					resolve(response)
				})
				.catch(err => reject(this.errorhandler(err)))
		})
	}

	post (endpoint, params, config = { headers: {} }) {
		return new Promise(async (resolve, reject) => {
			config = await this.config(config, endpoint)
			axios.post(endpoint, params, config)
				.then(response => resolve(response))
				.catch(err => reject(this.errorhandler(err)))
		})
	}

	put (endpoint, params, config = { headers: {} }) {
		return new Promise(async (resolve, reject) => {
			config = await this.config(config, endpoint)
			axios.put(endpoint, params, config)
				.then(response => resolve(response))
				.catch(err => reject(this.errorhandler(err)))
		})
	}

	patch (endpoint, params, config = { headers: {} }) {
		return new Promise(async (resolve, reject) => {
			config = await this.config(config, endpoint)
			axios.patch(endpoint, params, config)
				.then(response => resolve(response))
				.catch(err => reject(this.errorhandler(err)))
		})
	}

	delete (endpoint, data, config = { headers: {} }) {
		return new Promise(async (resolve, reject) => {
			config.data = data;
			config = await this.config(config, endpoint)
			axios.delete(endpoint, config)
				.then(response => resolve(response))
				.catch(err => reject(this.errorhandler(err)))
		})
	}

}

export default new HttpService()