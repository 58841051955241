import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import store from 'redux/index'
import HTTPService from 'services/http'
import localStorageService from 'services/localstorage'
import RouterService from 'services/router'

import App from 'App'
import reportWebVitals from 'reportWebVitals'

global.services = {
  http: HTTPService,
  localstorage: localStorageService,
  router: RouterService
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
