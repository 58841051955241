import * as React from 'react';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import {Typography, Box, Grid, InputLabel, MenuItem, Select, List, ListItem, Button, Pagination, Breadcrumbs, Input, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, TablePagination, Paper, TableSortLabel} from '@mui/material';
import { FunnelSVG, ArrowNextSVG, EditSVG, DeleteSVG } from "components/svg-components";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import dayjs from 'dayjs'
import GenericLoader from 'components/loaders/generic'

const externalData = {
	borders: [ 'border-red-0', 'border-yellow-0', 'border-green-0', 'border-purple-0', 'border-blue-0' ],
	lastBorderIndexUsed: 0
}

const ProjectsPage = () => {
    const { http } = global.services
	const history = useHistory()
    const itemsPerPage = 5;
	const [sortBy, setSortBy] = React.useState('Date');
    const [total, setTotal] = React.useState(0);
    const [page, setPage] = React.useState(1);
    const [displayed, setDisplayed] = React.useState(itemsPerPage);
    const [name, setName] = React.useState('');
    const [foundRows, setFoundRows] = React.useState([]);
    const [oldRows, setOldRows] = React.useState([]);
    const [noOfPages, setNoOfPages] = React.useState(
        Math.ceil(total / itemsPerPage)
    );
    const [loading, setLoading] = React.useState(true)

    React.useEffect(() => {
        loadData()
    }, []) // Sort and 
	
    const loadData = async () => {
        try{
            setLoading(true);
        const { data } = await http.get('admin/getprojects', {expand: 'programs'});
        const rows = data.data || [];
        setFoundRows(rows);
        setOldRows(rows);
        setTotal(rows.length);
        setDisplayed(itemsPerPage);
        setNoOfPages(Math.ceil(rows.length / itemsPerPage))
        setLoading(false)
        } catch(err){
            setLoading(false)
            console.log(err);
            return;
        }
    }
	const handleChange = (event, value) => {
		setPage(value);
		setDisplayed(value * itemsPerPage);
	};

	const handleSortBy = event => {
		setSortBy(event.target.value);
        let sorted = [];

        switch(event.target.value) {
            case 'Date':
            sorted = foundRows.sort((a,b) => dayjs(b.createAt).diff( dayjs(a.createAt) ) );
            break;

            case 'Name':
            sorted = foundRows.sort((a, b) => a.Name > b.Name ? 1 : (b.Name > a.Name ? -1 : 0) );
            break;

            case 'Programs':
            sorted = foundRows.sort((a, b) => a.Programs[0]?.Name > b.Programs[0]?.Name ? 1 : (b.Programs[0]?.Name > a.Programs[0]?.Name ? -1 : 0));
            break;
        }
        setFoundRows(sorted);
	};

	const filter = (e) => {
	    const keyword = e.target.value;

	    if (keyword !== '') {

	      const results = oldRows.filter((project) => {
	        return project.Name.toLowerCase().startsWith(keyword.toLowerCase());
	      });
	      setFoundRows(results);

	      setTotal(results.length);
	      if(results.length <= itemsPerPage)
	      	setDisplayed(results.length);
	      else
	      	setDisplayed(itemsPerPage);

	    } else {
	    	setTotal(oldRows.length);
	      	setFoundRows(oldRows);
	    }

	    setName(keyword);
	  };

	return (
  <Box className="projects-container">
   <Breadcrumbs separator="|" aria-label="breadcrumb">
    <Typography href="#" underline="hover" color="inherit">
     Projects & Programs
    </Typography>
   </Breadcrumbs>
   <Box>
    <Typography
     component="h1"
     fontSize="30px"
     fontWeight="700"
     marginBottom="30px"
    >
     Projects
    </Typography>
   </Box>
   <Stack
    spacing={5}
    direction="row"
    className="filter-wrapper"
    marginBottom="20px"
    justifyContent="space-between"
    paddingRight="30px"
   >
    <Box className="flex" alignItems="center">
     <Select
      value={sortBy}
      onChange={handleSortBy}
      sx={{
       maxHeight: "40px",
       minHeight: "unset",
       background: "#fff",
       fontSize: "14px",
      }}
     >
      <MenuItem value="Date">Sort by Date</MenuItem>
      <MenuItem value="Name">Sort by Name</MenuItem>
      <MenuItem value="Programs">Sort by Programs</MenuItem>
     </Select>
    </Box>
    <Input
     type="search"
     placeholder="Search Projects"
     value={name}
     onChange={filter}
     className="input search-list"
    />
   </Stack>
   {
    loading ? <Box><GenericLoader /></Box>
    :<Box>
    <TableContainer component={Paper}>
     <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead sx={{ backgroundColor: "#eaebeb" }}>
       <TableRow>
        <TableCell>Project Name</TableCell>
        <TableCell>Date of Creation</TableCell>
        <TableCell>Programs</TableCell>
        <TableCell align="right">Actions</TableCell>
       </TableRow>
      </TableHead>
      <TableBody>
       {foundRows
        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
        .map((row) => (
         <TableRow
          key={row._id}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
         >
          <TableCell component="th" scope="row">
           {row.Name}
          </TableCell>
          <TableCell>{dayjs(row.createAt).format('MM/DD/YYYY')}{row.date}</TableCell>
          <TableCell>{row.Programs?.map(i=>i.Name).join(', ') || 0}</TableCell>
          <TableCell align="right">
           <Link className="text-red-10 flex items-center justify-end" to={`/admin/projects/${row._id}`}>View Details <i className='bx bx-chevron-right text-xl'/></Link>
          </TableCell>
         </TableRow>
        ))}
      </TableBody>
     </Table>
    </TableContainer>
    <Grid container justifyContent="space-between" marginTop="30px">
     <Grid item>
      {displayed <= foundRows.length ? (
       <Typography>
        {displayed} out of {total}
       </Typography>
      ) : (
       <Typography>
        {foundRows.length} out of {total}
       </Typography>
      )}
     </Grid>
     <Grid item>
      <Pagination
       count={noOfPages}
       page={page}
       onChange={handleChange}
       defaultPage={1}
       color="primary"
       size="large"
       className="pagination"
      />
     </Grid>
    </Grid>
   </Box>
   }
  </Box>
 );

}

export default ProjectsPage