export const ADD_TO_CART = 'ADD_TO_CART'
//We can change default amount later
export const addToCart = (report, amount = 100) => {
  return {
    type: ADD_TO_CART,
    payload: {...report, amount },
  };
}

export const REMOVE_FROM_CART = 'REMOVE_FROM_CART'

export const removeFromCart = (report) => {
  return {
    type: REMOVE_FROM_CART,
    payload: report,
  };
}

export const ADD_RESYNC = 'ADD_RESYNC'

export const addResync = (resync = true) => {
  return {
    type: ADD_RESYNC,
    payload: resync,
  };
}

export const ADD_FILTER = 'ADD_FILTER'

export const addFilter = (filter) => {
  return {
    type: ADD_FILTER,
    payload: filter,
  };
}

export const REMOVE_FILTER = 'REMOVE_FILTER'

export const removeFilter = (filter) => {
  return {
    type: REMOVE_FILTER,
    payload: filter,
  };
}

export const CLEAR_CART = 'CLEAR_CART'
export const clearCart = () => {
	return {
		type: CLEAR_CART
	}
}

export const TOGGLE_CART = 'TOGGLE_CART'
export const toggleCart = (openCart = false) => {
  return {
    type: TOGGLE_CART,
    payload: openCart
  }
}