import { Fragment, useState, useEffect } from 'react';
import {Box, Button, Typography, IconButton, Modal, Select, MenuItem, Alert, Chip, OutlinedInput, TextField} from '@mui/material';
import { Close, Refresh, ErrorOutline } from '@mui/icons-material';
import { DeleteSVG } from "components/svg-components";
import WhiteTextField from 'components/form-utilities/white-text-field'
import LoadingButton from '@mui/lab/LoadingButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@mui/material/Autocomplete';
import CheckIcon from "@mui/icons-material/Check";

const sampleArray = [
    { id: 1, name: 'Alice' },
    { id: 2, name: 'Bob' },
    { id: 3, name: 'Charlie' },
    { id: 4, name: 'Diana' },
    { id: 5, name: 'Edward' }
];

// Pre-selected names
const preSelectedNames = [
    { id: 2, name: 'Bob' },
    { id: 4, name: 'Diana' }
];
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 490,
  bgcolor: '#f5f5f5',
  boxShadow: 24,
  p: 4,
}
const useStyles = makeStyles({
	select: {
		maxHeight: "40px",
		minHeight: "unset",
	  border: 0,
		borderRadius: 0,
		width: "100%",
		background: "#fff",
		fontSize: "14px",
		'& fieldset': {
			border:0
		}
	},
	texts: {
		fontSize: '14px',
		lineHeight: '21px',
		textAlign: 'center',
		color: '#1D2124'
	},
	chip: {
		borderRadius: '0 !important',
		'& .MuiChip-label, & .MuiChip-deleteIcon': {
			color: '#fff',
			backgroundColor: '#000',
		}
	}
})

export default function UserForm({form, closeForm = ()=>{}, user = null, ...props}) {
	const { http } = global.services
	const classes = useStyles();
	const [ vars, setVars ] = useState({
		fullName: user?.fullName || "", //SHould be full name
		email: user?.email || "",
		mobile: user?.mobile || "",
	});
	const [submitDisabled, setDisabled] = useState(true);
	const [errors, setErrors] = useState({
		fullName: null,
		email: null,
		mobile: null,
		response: null
	});
	//other modals
	const [formNotif, showFormNotif] = useState(false);
	const [resetPass, showResetPass] = useState(false);
	const [remove, showDelete] = useState(false);

	const [passReset, sendPassReset] = useState(false);
	//"Constant" arrays
	const [roleOptions, setRoleOpts] = useState([]);
	const [projectOptions, setProjOpts] = useState([]);

	const [role, setRole] = useState(null);
	const [projects, setProjects] = useState([]);
	//Processing
	const [sending, setSending] = useState(false)
	const [deleting, setDeleting] = useState(false)
	const [processing, setProcessing] = useState(false)
	const [tosubmit, addSubmit] = useState({});
	const [passwordResetStatus, setPasswordResetStatus] = useState({
		title: 'Password Reset',
		message: 'The new password has been emailed',
		success: true,
	});
	const [selectedNames, setSelectedNames] = useState([]);

	useEffect(()=>{
		if(form){
			setVars({
				fullName: user?.fullName || "", //SHould be full name
				email: user?.email || "",
				mobile: user?.mobile || "",
			})
			
			// const firstRole = roleOptions[0] || {}
			// setRole(firstRole?._id);
			sendPassReset(false)
			addSubmit({})
		}
	}, [form])

	useEffect(()=> {
		setDisabled( !(
				vars.fullName != "" &&
				vars.email != "" &&
				vars.mobile != "" )
		) 
	}, [vars])

	useEffect(() => {
		if(user) {
			const role = roleOptions.find(i => i.role === user?.role || user?.roleId === i._id);
			setRole( role?._id || null );
			setProjects( user?.projects || []);
		} else {
			setRole( null );
			setProjects([]);
		}
	},[user])
 
	useEffect(()=>{
		loadRoles()
		loadProjects()
		addSubmit({})
	}, [])

	const loadRoles = async () => {
		try{
		const { data }= await http.get('admin/getroles')
		const { roleData } = data;
		setRoleOpts(roleData);
		// return roleData;
		} catch(err){
			console.log(err);
			return;
		}
	} 
	const loadProjects = async () => {
		try{
		const { data } = await http.get('admin/getprojects')
		// const { roleData } = data;
		setProjOpts(data.data);
		} catch(err){
			console.log(err);
			return;
		}
	}

	const handleSubmit = async (e) => {
		setErrors({
			fullName: null,
			email: null,
			mobile: null,
			response: null
		})
		e.preventDefault();
		try {
			let res;
			if(user?._id != null) {
				res = await http.put('user/update/'+user?._id, {...tosubmit})
				// addSubmit({})
				// closeForm(true);
			} else {
				res = await http.post('user/create', {...tosubmit})
				showFormNotif(true);
				// addSubmit({})
				// closeForm(true);
			}
		} catch (e) {
			// setErrors({...errors, response: e?.response?.data?.message || e.message})
			setErrors({...errors, response: e.message})
		} finally{
			addSubmit({})
			closeForm(true);
		}

	}

	const handleForm = ({target}) => {
		setVars({
			...vars,
			[target.name]: target.value
		})
		addSubmit({...tosubmit, [target.name]: target.value})
	}

	const handleRole = ({target}) => {
		setRole(target.value)
		addSubmit({...tosubmit, roleId: target.value})
	}
	const handleProjects = (e, a) => {
		console.log(a)
		setProjects(a);
		addSubmit({...tosubmit, projects: a.map(i => i._id)})
	}

	const handlePasswordReset = async () => {
		if(!user)
			return;
		setSending(true)
		let response;
		try{
			response = await http.post('user/admin-reset-password', {
				userId: user._id
			})
			setPasswordResetStatus({
				title: 'Password Reset',
				message: 'The new password has been emailed',
				success: true,
			});
		} catch(err){
			console.log(err);
			setPasswordResetStatus({
				title: 'Something Went Wrong.',
				message: err.message || err.response.data.message,
				success: false
			});
		} finally{
			sendPassReset(true);
			setSending(false);
		}
	}

	const handleNotifButton = () => {
		showFormNotif(false)
		closeForm(true)
	}

	const deleteUser = async () => {
		setDeleting(true)
		try{
			await http.delete('user/delete/' + user._id)
		} catch(err){
			console.log(err);
		} finally{
		showDelete(false)
		setDeleting(false)
		handleNotifButton()
		}
	}

	const onCloseBtn = ()=>{
		setErrors({
			fullName: null,
			email: null,
			mobile: null,
			response: null
		});
		closeForm(false);
	}


	return (<Fragment>
		<Modal
	  open={form}
	  onClose={()=>onCloseBtn()}
	  aria-labelledby="user-form-title"
	  aria-describedby="user-form-description"
	>
	  <Box sx={style}>
	  	<form onSubmit={handleSubmit}>
		<Typography id="user-form-title"
			variant="h6"
			component="span"
			fontWeight="600"
			display="block"
			className="text-center mt-6"
		>
			<IconButton
		    aria-label="close"
		    onClick={()=>onCloseBtn()}
		    sx={{ position: 'absolute', right: 8, top: 8 }}
		  >
		    <Close />
		  </IconButton>
		{ user != null ? 'Edit User' : 'Add User' }
		</Typography>
		<WhiteTextField
			error={errors.fullName}
			id="email-outlined-basic"
			className="w-full my-9 white-field"
			variant="outlined"
			placeholder="Full Name"
			value={ vars.fullName }
			name="fullName"
			onChange={ handleForm }
			autoComplete="off"
			required
		/>
		<WhiteTextField
			error={errors.email}
			id="email-outlined-basic"
			className="w-full mb-9 white-field"
			variant="outlined"
			placeholder="Email"
			value={ vars.email }
			name="email"
			onChange={ handleForm }
			autoComplete="off"
			required
		/>
		<WhiteTextField
			error={errors.mobile}
			id="email-outlined-basic"
			className="w-full mb-9 white-field"
			variant="outlined"
			placeholder="Phone Number"
			value={ vars.mobile }
			name="mobile"
			onChange={ handleForm }
			autoComplete="off"
			required
		/>
		<Select
		  value={role || user?.roleId}
		  onChange={handleRole}
		  className={`${classes.select} mb-9`}
		  placeholder="Set role of User"
		  IconComponent={KeyboardArrowDownIcon}
		  required
		 >
		  {
		  	roleOptions.map(i => <MenuItem key={"role-"+i._id} value={i._id} name={i.role}>{i.role}</MenuItem>)
		  }
		</Select>

		{/* <Autocomplete 
			options={projectOptions.map(i=> ({...i, label:i.Name, value: i._id}))}
      		className={`${classes.select} mb-9`}
			value={projects}
			onChange={handleProjects}
			disableCloseOnSelect
			renderTags={(tagValue, getTagProps)=>
				tagValue.map((opt, ind) => (
					<Chip label={opt.Name} 
					sx={{
						borderRadius: '0 !important', 
						backgroundColor: '#000',
						'& .MuiChip-label, & .MuiChip-deleteIcon': {
							color: '#fff',
						}
					}}
					deleteIcon={<Close color="white"/>} 
					{...getTagProps({ ind })}
				/>))
			}
			multiple
      		renderInput={(params) => <WhiteTextField {...params} 
			placeholder={projects.length == 0 && "Set projects to User"}/> }
		/> */}

 

	<Autocomplete
      sx={{height: 100,  
			"& .MuiOutlinedInput-root": {
			borderColor: 'white', // Border color for the input field
			"& fieldset": {
				borderColor: 'white', // Ensures the white border is applied
			},
			"&:hover fieldset": {
				borderColor: 'white', // Border color on hover
			},
			"&.Mui-focused fieldset": {
				borderColor: 'white', // Border color when focused
			},
			},
			"& .MuiAutocomplete-inputRoot": {
			backgroundColor: '#ffffff',
			color: '#000', // Text color for better contrast with white borders
			},
			"& .MuiAutocomplete-paper": {
			backgroundColor: '#e0e0e0',
			border: '1px solid white', // Border for the dropdown list
			},
	   	}}
		multiple
		onChange={handleProjects}
		value={projects}
		defaultValue={projects}
		options={projectOptions}
		getOptionLabel={(option) => option.Name}
		disableCloseOnSelect
		renderInput={(params) => <WhiteTextField {...params} 
		placeholder={projects.length == 0 && "Set projects to User"}/>}
		// renderOption={(props, option, { selected }) => (
		// 	<MenuItem
		// 	{...props}
		// 	key={option._id}
		// 	value={option.Name}
		// 	sx={{ justifyContent: "space-between" }}
		// 	>
		// 	{option.Name}
		// 	{selected ? <CheckIcon color="info" /> : null}
		// 	</MenuItem>
		// )}
		renderOption={(props, option, { selected }) => {
			const isChecked = selected || projects.some(item => item._id === option._id);
			return (
			  <MenuItem
				{...props}
				key={option._id}
				value={option.Name}
				sx={{ 
				  justifyContent: "space-between",
				  border: '1px solid white',
				}}
			  >
				{option.Name}
				{isChecked ? <CheckIcon color="info" /> : null}
			  </MenuItem>
			);
		  }}
		renderTags={(value, getTagProps) => (
			<Box sx={{ maxHeight: 48, overflow: "hidden" }}>
			{value.slice(0, 1).map((option, index) => (
				<Chip
				key={option._id}
				label={`${value.length} Selected`}
				{...getTagProps({ index })}
				sx={{ height: 32 }}
				/>
			))}
			</Box>
		)}
    />
		{/* <Select
		  value={projects}
		  onChange={handleProjects}
		  className={`${classes.select} mb-9`}
		  placeholder="Set Projects"
		  multiple
		 >
		  {
		  	projectOptions.map(i => <MenuItem key={"project-"+i._id} value={i._id} name={i.Name}>{i.Name}</MenuItem>)
		  }
		</Select> */}
		{
			errors?.response && <Alert className="mb-2" variant="filled" severity="error">
				{ errors?.response }
			</Alert>
		}
		{
			user != null  && <Box className="flex flex-col">
				{
					passReset && passwordResetStatus.success ? 
					<Button
						variant="outlined"
						disabled="true"
						onClick={()=>showResetPass(true)}
						className="normal-case rounded-none mb-5 border-gray-200 text-gray-700"
					>
						<ErrorOutline />The link has been sent
					</Button>
					: <Button
					variant="outlined"
					onClick={()=>showResetPass(true)}
					className="normal-case rounded-none mb-5 border-gray-200 text-gray-700"
					loadingPosition="start"
				>
					<Refresh />Reset Password
				</Button>
				}
			<Button
				variant="text"
				onClick={()=>showDelete(true)}
				className="normal-case text-red-0 mb-5 pl-0 self-start"
			>
				<DeleteSVG />Delete User
			</Button>
			</Box>
		}
		<Box className="flex flex-col">
			<LoadingButton
				variant="contained"
				type="submit"
				color="error"
				className="normal-case rounded-none mb-5"
				loadingPosition="start"
				loading={ processing }
				// disabled={submitDisabled}
			>
				{ user != null ? "Update User" : "Create User"}
			</LoadingButton>
			<Button
				variant="text"
				color="black"
				onClick={()=>onCloseBtn()}
				className="normal-case rounded-none block text-black text-center"
			>
				Cancel
			</Button>
		</Box>
	  </form>
	  </Box>
	</Modal>
	{/*Notification modal*/}
	<Modal
	  open={formNotif}
	  onClose={()=>showFormNotif(false)}
	  aria-labelledby="notif-title"
	  aria-describedby="notif-description"
	>	
		<Box sx={style}>
		<Typography id="user-form-title"
			variant="h6"
			component="span"
			fontWeight="600"
			display="block"
			className="text-center mt-6"
		>
			<IconButton
		    aria-label="close"
		    onClick={()=>showFormNotif(false)}
		    sx={{ position: 'absolute', right: 8, top: 8 }}
		  >
		    <Close />
		  </IconButton>
		User Added
		</Typography>
		<Typography id="user-form-title"
			variant="p"
			component="span"
			display="block"
			className="text-center my-9"
		>
			The user will receive an email with their email/password credentials.
		</Typography>
		<Button
			variant="contained"
			onClick={handleNotifButton}
			color="error"
			sx={{ margin: "15px 0"}}
			onClick={()=>showFormNotif(false)}
			className="normal-case rounded-none w-full text-center"
		>
			Great!
		</Button>
		</Box>
	</Modal>
	{/*Reset Modal*/}
	<Modal
	  open={resetPass}
	  onClose={() => {
			showResetPass(false);
			setPasswordResetStatus({
				title: 'Password Reset',
				message: 'The new password has been emailed',
				success: true,
			});
		}}
	  aria-labelledby="reset-title"
	  aria-describedby="reset-description"
	>
		<Box sx={style}>
		<Typography id="user-form-title"
				variant="h6"
				component="span"
				fontWeight="600"
				display="block"
				className="text-center mt-6"
			>
				<IconButton
			    aria-label="close"
			    onClick={()=>showResetPass(false)}
			    sx={{ position: 'absolute', right: 8, top: 8 }}
			  >
			    <Close />
			  </IconButton>
			{passReset ? passwordResetStatus.title : 'Reset Password'}
		</Typography>
		<Typography id="user-form-title"
			variant="p"
			component="span"
			display="block"
			className="text-center my-9"
		>
			{passReset ? passwordResetStatus.message : 'Are you sure?'}
		</Typography>
		{
			passReset ? <Button
				variant="contained"
				color="error"
				sx={{ margin: "15px 0"}}
				onClick={()=>showResetPass(false)}
				className="normal-case rounded-none w-full text-center"
			>
				Ok
			</Button>
			: <Box>
			<Button
				variant="text"
				color="error"
				onClick={()=>showResetPass(false)}
				className="normal-case rounded-none text-center text-black w-1/2"
			>
				Cancel
			</Button>
			<LoadingButton
				variant="contained"
				color="error"
				onClick={handlePasswordReset}
				className="normal-case rounded-none text-center w-1/2"
				loadingPosition="start"
				loading={ sending }
			>
				Reset
			</LoadingButton>
			</Box> 			
		}
		</Box>
	</Modal>
	{/*Delete Modal*/}
	<Modal
	  open={remove}
	  onClose={()=>showDelete(false)}
	  aria-labelledby="delete-title"
	  aria-describedby="delete-description"
	>	
		<Box sx={style}>
		<Typography id="user-form-title"
				variant="h6"
				component="span"
				fontWeight="600"
				display="block"
				className="text-center mt-6"
			>
				<IconButton
			    aria-label="close"
			    onClick={()=>showDelete(false)}
			    sx={{ position: 'absolute', right: 8, top: 8 }}
			  >
			    <Close />
			  </IconButton>
			Delete User
		</Typography>
		<Typography id="user-form-title"
			variant="p"
			component="span"
			display="block"
			className="text-center my-9"
		>
			Are you sure? You won't be able to <br /> restore this user's information
		</Typography>
		<Box>
			<Button
				variant="text"
				color="black"
				onClick={()=>showDelete(false)}
				className="normal-case rounded-none text-center text-black w-1/2"
			>
				Cancel
			</Button>

			<LoadingButton
				variant="contained"
				color="error"
				onClick={deleteUser}
				className="normal-case rounded-none text-center w-1/2"
				loadingPosition="start"
				loading={ deleting }
			>
				Delete
			</LoadingButton>
		</Box>
		</Box> 
	</Modal>
	</Fragment>)
}
