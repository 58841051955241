import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"

import DefaultLayout from "layouts/default/index"
import AppAction from 'redux/modules/app/actions'

const Home = () => {
	const history = useHistory();
	const dispatch = useDispatch()
	const user = useSelector(state => state.user.user)
	
	useEffect(() => {
		if(user == null)
		{
			history.push('/login');
		}else{
			history.push('/dashboard');
		}
		dispatch(AppAction.setPageLoader({ title: '', message: '', show: false }))
	}, [user])

	return (
		<DefaultLayout>
			<div>
				<h1>Loading...</h1>
			</div>
		</DefaultLayout>
	)

}

export default Home