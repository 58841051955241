import React, {useState, useEffect} from "react"
import { useHistory } from 'react-router';

import { Box, Typography, Button, Link,
	List, ListItem, ListItemText,
	RadioGroup, Radio, FormControlLabel, Checkbox,
	Modal, IconButton, LinearProgress 
} from "@mui/material"
import './cart.css'
import { Close } from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton'

import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart, clearCart, removeFilter, addResync } from 'redux/modules/cart/actions';
import { setResyncInfo, setEVFilter } from 'redux/modules/reports/actions';
import UserAction from 'redux/modules/user/actions'
import AppAction from 'redux/modules/app/actions';

import { Elements, useElements, PaymentElement, useStripe } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { STRIPE_PK } from 'config/api';
import { currentCurrency } from "helpers/checkCurrency";

const stripePromise = loadStripe(STRIPE_PK);
// console.log('huuh>',stripePromise, STRIPE_PK) //Working on my side tho.
const ELEMENT_OPTIONS = {
  style: {
    base: {
      fontSize: '20px',
      lineHeight: '30px',
      border: '1px solid #D3D3D3 !important',
      color: '#000',
      '::placeholder': {
        // opacity: '20%',
    	letterSpacing: '0.020em',
        color: '#00000055'
      },
    },
    invalid: {
      color: '#9e2146',
    },
  },
};

const StripeElement = ({ paying, total = 0, setPaying = () => {}, afterPayment = () => {}, clientSecret, onError, ...props }) => {
	const { http } = global.services;
	const stripe = useStripe();
	const elements = useElements();
	const { userData, user } = useSelector(({user}) => user.user)
	const selectedProgramId = useSelector(({ app }) => app.selectedProgramId);
	const { organizationProgram } = userData;
	const selectedProgram = organizationProgram?.find(i => i?.programId?._id == selectedProgramId) || organizationProgram[0]
	const { items, filters, resync } = useSelector(({cart}) => cart)
	const [message, setMessage] = useState(null);


	useEffect(()=>{
		if(paying) triggerPayment();
	}, [paying])

	const loadClientPayment = async () => {
		if (!stripe) return;
    if (!clientSecret) return;

  	stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent, ...others }) => {
  		console.log('paymentIntent', paymentIntent, others)
      switch (paymentIntent.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
	}

	useEffect(loadClientPayment, [stripe, selectedProgram]);

	const triggerPayment = async () => {
		if(!stripe || !elements) return;
    const { error, ...others } = await stripe.confirmPayment({
      elements,
      redirect: 'if_required'
    });

		if(error){
			onError(true);
			setPaying(false)
			return;
		}

		setPaying(false)
		afterPayment()
	}

	return <div className="flex my-3">
    <PaymentElement id="payment-element" />
	</div>
}

const Checkout = () => {
	const { http } = global.services;
	const dispatch = useDispatch();
	const history = useHistory();
	const { items, filters, resync } = useSelector(({cart}) => cart)
	const [selectedValue, setSelectedValue] = useState('invoice');
	const [saveAccount, setSaveAccount] = useState(false);
	const [openErrorModal, setErrorModal]= useState(false);

	const { userData, user, ...otherDetails } = useSelector(({user}) => user.user)
	const selectedProgramId = useSelector(({ app }) => app.selectedProgramId);
	const { organizationProgram } = userData;
	const selectedProgram = organizationProgram?.find(i => i?.programId?._id == selectedProgramId) || organizationProgram[0]
	const resyncPrice = selectedProgram?.programId?.Data_Resort_Fee || 0;

	const canBuy = React.useMemo(() => {
		if(!selectedProgram) return false;
	  const hasPaidFDD = selectedProgram?.FDD_Payment_Details !== null;
	  const hasReport = ['WFR_Access', 'EV_Access', 'WBC_Access', 'BBP_Access'].find(i => (selectedProgram[i] || '').trim().toLowerCase() === 'yes');
	  const onCart = items.find(i => i.forPayment === 'FDD');
	  return hasPaidFDD || hasReport || onCart;
	}, [items, selectedProgramId])

	const [paying, setPaying] = useState(false);
	const [sending, setSending] = useState(false);
	const [success, setSuccess] = useState(false);
	const [clientSecret, setClientSecret] = useState("");
	const cartItems = [...items, ...filters];
	if(resync) {
		cartItems.push({ 
			title: 'Resort Data', 
			amount: resyncPrice || 0,
			forPayment: "Data_Resort",
			keys: {
				"Data_Resort_Payment_Details": new Date(),
				"Data_Resort_Payment_Type": ""
			} ,
		})
	}

	useEffect(async () => {
		const amount = getTotal()
		if (!selectedProgram && amount <= 0) {
      return;
    }
    let params = {
      selectedProgramId: selectedProgram?.programId?._id
    }
    if(selectedValue == 'card') {
    	params.stripe = true;
    	const paramQuery = new URLSearchParams(params).toString();
	    const { data } = await http.post(`/payment/checkout?${paramQuery}`, {
	  		total: amount,
	  		items: cartItems.map(({title, amount, keys, forPayment, accessGroup, access}) => {
	  			let accessList = {};
			    if(accessGroup?.length > 0) {
			      accessGroup?.forEach(i => {
			        accessList[i] = "Yes"; // selectOrgProgram[i]
			      })
			    }
			    if(forPayment == 'EV_Sorting') {
			    	keys.EV_Sorting_Payment_Details = new Date();
			    }
	  			return { 
	  				title, 
	  				amount, 
	  				keys: { ...keys, 
	  					[forPayment+'_Payment_Type']: 'Stripe',  
	  					[forPayment+'_Access']: forPayment !== 'KIA' ? 'Yes' : null,
	  					...accessList
	  				} 
	  			}
	  		})
	  	})
		  setClientSecret(data.client_secret)
    }
	}, [selectedProgram, selectedValue]);

	const handleChange = (event) => setSelectedValue(event.target.value);
	
	const controlProps = (item) => ({
		checked: selectedValue === item,
		onChange: handleChange,
		value: item,
		name: 'payment-method',
		inputProps: { 'aria-label': item },
		sx: {
      '& .MuiSvgIcon-root': {
        fontSize: 28,
      },
    }
	});

	const controlCheckbox = () => ({
		name: 'save-account',
		inputProps: { 'aria-label' : 'Save Account'},
		checked: saveAccount,
		onChange: (e) => setSaveAccount(e.target.checked)
	})

	const getTotal = () => cartItems.reduce((a,b) => a + parseFloat(b.amount || 0) , 0)

	const handleOrder = async (e) => {
		e.preventDefault();
		const amount = getTotal()

		if(!canBuy) {
			dispatch( AppAction.setAlert(`You need to include Feedback Data Dashboard Package to checkout!`) );
			return;
		}
		// if (amount <= 0 && !resync) {
  //     return;
  //   }
		if(selectedValue == 'card') {
			//Will trigger the handleSuccess after payment
			setPaying(true)
		} else {
			setSending(true);
			const paramQuery = new URLSearchParams({
			selectedProgramId: selectedProgram?.programId?._id
			}).toString();
			const { data, status } = await http.post(`/payment/checkout?${paramQuery}`, {
				total: amount,
				items: cartItems.map(({title, amount, keys, forPayment}) => {
					const _keys = JSON.parse(JSON.stringify(keys));
					delete _keys[forPayment+'_Payment_Details'];
					return { title, amount, keys: { ..._keys, [forPayment+'_Payment_Type']: 'Invoice'} };
				})
	  		})
			if(data == 'ok' && status == 200) {
					setSending(false)
					setSuccess(true)
					// Remove items on store.
					if(items.length > 0) {
						items.forEach(i => {
							selectedProgram[i.forPayment + '_Payment_Type'] = 'Invoice';
						});
					}
					if(filters.length > 0) {
						filters.forEach(i => {
							selectedProgram[i.forPayment + '_Payment_Type'] = 'Invoice';
						});
					}
					var newOrgProgramList = organizationProgram.map(i => i?.programId?._id == selectedProgramId ? selectedProgram : i);
					dispatch( UserAction.setUser(
						{
							...otherDetails, //secure other data on user redux is saved again.
							user: { // Technically the same as userData but i could not be bothered to check which one is called on each pages/components
								...userData,
								organizationProgram: [...newOrgProgramList]
							}, 
							userData: {
								...userData,
								organizationProgram: [...newOrgProgramList]
							}
						}) 
					)
					dispatch(clearCart())
					dispatch(setResyncInfo(false, false))
			}
		}
	}

	const handleSuccess = (resynced = false) => {
		setSuccess(true)
		setPaying(false)
		// Auto enable EV filter upon purchase
		if(filters.length > 0) {
			const EV_Sorting_Filter = filters[0].filterLabel;
			selectedProgram.EV_Sorting_Filter = filters[0].filterLabel;
			selectedProgram.EV_Sorting_Payment_Type = 'Stripe'
			selectedProgram.EV_Sorting_Payment_Details = new Date();
			dispatch( removeFilter(filters[0]) );
		}
		// Remove items on store.
		if(items.length > 0) {
			items.forEach(i => {
				selectedProgram[i.forPayment + '_Payment_Details'] = new Date();
				selectedProgram[i.forPayment + '_Payment_Type'] = "Stripe";
				selectedProgram[i.forPayment + '_Access'] = i.forPayment !== 'KIA' ? "Yes" : "No";
				let accessList = {};
		    if(i.accessGroup?.length > 0) {
		      i.accessGroup?.forEach(x => {
		        selectedProgram[x] = "Yes"; // selectOrgProgram[i]
		      })
		    }
			});
		}
		if(filters.length > 0) {
			filters.forEach(i => {
				selectedProgram[i.forPayment + '_Payment_Details'] = new Date();
				selectedProgram[i.forPayment + '_Payment_Type'] = 'Stripe';
			});
		}
		var newOrgProgramList = organizationProgram.map(i => i?.programId?._id == selectedProgramId ? selectedProgram : i);
		dispatch( UserAction.setUser(
			{
				...otherDetails, //secure other data on user redux is saved again.
				user: { // Technically the same as userData but i could not be bothered to check which one is called on each pages/components
					...userData,
					organizationProgram: [...newOrgProgramList]
				}, 
				userData: {
					...userData,
					organizationProgram: [...newOrgProgramList]
				}
			}) 
		)
		dispatch(clearCart())
		dispatch(setResyncInfo(false, resynced))
	}

	const handleCloseModal = () => {
		setSuccess(false)
	}

  const options = {
    clientSecret,
    appearance: {
    	theme: 'flat'
    },
  };


	return (
		<React.Fragment>
			<Box sx={{ display: 'flex' }}>
				<Typography variant="h5" sx={{ my:2 , fontWeight:'bold'}}>Checkout</Typography>
			</Box>

			<Box sx={{ display: 'flex' }}>
				<Typography variant="h6" sx={{ my:2 , fontWeight:'bold'}}>Payment Method</Typography>
			</Box>
			{/* Payment method contents */}
			<Box sx={{ display: 'flex',marginBottom: '50px'}}>
				{/*Cart Items*/}
				<Box sx={{ display: 'flex', flexDirection:'column', backgroundColor:'#fff', width: '850px', marginRight:'20px', padding:"25px"}}>
					<RadioGroup>
						<div className="payment-invoice mb-5">
							<FormControlLabel
						    value="invoice"
						    control={<Radio {...controlProps("invoice")}/>}
						    label="Invoice"
						    labelPlacement="end"
						  />
							<p className="text-gray-30">A WRG team member will send an invoice via email within 48 business hours.</p>
						</div>
						<div className="payment-card mb-5">
			        <FormControlLabel
			          value="card"
			          control={<Radio {...controlProps("card")}/>}
			          label="Credit/Debit Card"
			          labelPlacement="end"
			        />
			        {
			        	(clientSecret && selectedValue === 'card') &&
				        <Elements options={options} stripe={stripePromise}>
				        	<StripeElement 
				        		paying={paying} 
				        		clientSecret={clientSecret} 
				        		total={getTotal()} 
				        		setPaying={setPaying} 
				        		afterPayment={handleSuccess}
				        		onError={setErrorModal}
				        	/>
				        </Elements>
			        }
						</div>
					</RadioGroup>
				</Box>

			  {/* Cart Details*/}
				<Box className="flex flex-col" sx={{ backgroundColor: "#162630", color: '#fff', flexGrow: '1', padding:'30px'}}>
					<Typography variant="h6" sx={{ my:2 , fontWeight:'bold'}}>Summary</Typography>
					<List className="cart-list flex flex-col" sx={{flexGrow: '1'}} dense={true}>
						{
							items.map((i,k)=>
								<ListItem key={k} sx={{display:'flex', justifyContent:'space-between', margin: '10px 0'}}>
									<span className="title">{i.title}</span>
									<span>{currentCurrency()} {i.amount || 0}</span>
									{/* <span>$ {i.amount || 0}</span> */}
								</ListItem>)
						}
						{
							filters.map((i,k)=>
								<ListItem key={k} sx={{display:'flex', justifyContent:'space-between', margin: '10px 0'}}>
									<span className="title">Filter: {i.filterLabel}</span>
									<span>{currentCurrency()} {i.amount || 0}</span>
								</ListItem>)
						}
						{
							resync && <ListItem sx={{display:'flex', justifyContent:'space-between', margin: '10px 0'}}>
									<span className="title">Resync Reports</span>
									<span>{currentCurrency()} {resyncPrice || 0}</span>
								</ListItem>
						}
					</List>
					<List className="cart-list flex flex-col" dense={true}>
						<ListItem sx={{display:'flex', alignSelf: 'flex-end', justifyContent:'space-between', margin: '15px 0'}}>
							<span>Total</span>
							<span>{currentCurrency()} {getTotal().toFixed(2)}</span>
						</ListItem>
					</List>
					{
						(cartItems.length > 0 && getTotal() > 0) ?
				    <LoadingButton
				    	component="button"
				      variant="contained"
				      color="error"
				      className="normal-case rounded-none w-full"
				      onClick={handleOrder}
				      loading={paying || sending}
				     >
				      { selectedValue === 'card' ? 'Complete Purchase' : 'Submit Order'}
				    </LoadingButton> :
				    <Button
							variant="contained"
							color="error"
							// onClick={()=>history.push('/dashboard')}
							className="normal-case rounded-none w-full"
							disabled
						>
							Your cart is empty <br /> or valued 0
						</Button>
					}
				</Box>
			</Box>
			{/* Success modal */}
			<Modal
			  open={success}
			  onClose={handleCloseModal}
			  aria-labelledby="resort-title"
			  aria-describedby="resort-description"
			>
			  <Box sx={{
				  position: 'absolute',
				  top: '50%',
				  left: '50%',
				  transform: 'translate(-50%, -50%)',
				  width: 490,
				  bgcolor: '#f5f5f5',
				  boxShadow: 24,
				  p: 4,
				}}>
				<Typography id="role-form"
					variant="h5"
					component="span"
					fontWeight="600"
					display="block"
					className="text-center mt-6"
				>
					<IconButton
		        aria-label="close"
		        onClick={handleCloseModal}
		        sx={{ position: 'absolute', right: 8, top: 8 }}
		      >
		        <Close />
		      	</IconButton>
					{ selectedValue === 'card' ? 'Success' : ''}
				</Typography>
				{
					selectedValue === 'card' ?
					<Typography id="user-form-title"
						variant="p"
						display="block"
						className="text-center my-9"
					>
						The reports are being prepared. Once they're ready, <br/> you'll be able to see them in "My Reports."
					</Typography> :
					<Typography id="user-form-title"
						variant="p"
						display="block"
						className="text-center my-9"
					>
						Your order was successful! One of our team members will reach out to you via email with an invoice within 48 hours.
					</Typography>
				}
				<Button
					variant="contained"
					color="error"
					onClick={()=>history.push('/dashboard')}
					className="normal-case rounded-none w-full"
				>
					Back to the Dashboard
				</Button>
				</Box>
			</Modal>
			{/* Stripe Error Modal */}
			<Modal
			  open={openErrorModal}
			  onClose={() => setErrorModal(false)}
			  aria-labelledby="resort-title"
			  aria-describedby="resort-description"
			>
			  <Box sx={{
				  position: 'absolute',
				  top: '50%',
				  left: '50%',
				  transform: 'translate(-50%, -50%)',
				  width: 490,
				  bgcolor: '#f5f5f5',
				  boxShadow: 24,
				  p: 4,
				}}>
				<Typography id="role-form"
					variant="h5"
					component="span"
					fontWeight="600"
					display="block"
					className="text-center mt-6"
				>
					<IconButton
		        aria-label="close"
		        onClick={() => setErrorModal(false)}
		        sx={{ position: 'absolute', right: 8, top: 8 }}
		      >
		        <Close />
	      	</IconButton>
				</Typography>
				<Typography id="user-form-title"
					variant="p"
					display="block"
					className="text-center my-9"
				>
					Your payment was unsuccessful. Please review your payment details and try again. If you continue to experience problems, please contact <a className="text-red-10" href="mailto:Support@WorkforceRG.com">Support@WorkforceRG.com</a>.
				</Typography>
				</Box>
			</Modal>
		</React.Fragment>
	)
}

export default Checkout
