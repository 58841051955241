import React, { useRef } from "react";
import RadicalModified from "./radical-chart-modified";
import DownloadButton from "components/charts/DownloadButton"
import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';
import Typography from "@mui/material/Typography";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
	root: {
		margin: '5px 10px 35px',
		boxShadow: "0 0 2px rgb(0 0 0 / 30%)",
	},
	chart_item: {
		margin: '0px 15px'
	}
});

const reportsMap = [
	{
		name: "Category",
		subname: "Category average",
		data: [
			{
				data: {
					data: [ { field: 'Your Results', value: 0.90} ],
					metaConfig: {
						colors: ['#9C5EFB'],
						fields: [{ field: 'Your Results', title: 'Your Results'}]
					}
				}
			},
			{
				data: {
					data: [ { field: 'All your employees that made the list', value: 0.80} ],
					metaConfig: {
						colors: ['#00A46A'],
						fields: [{ field: 'All your employees that made the list', title: 'All your employees that made the list'}]
					}
				}
			}
		] 
	}
];

const GraphItem = ({ reportIndex, classes, selectedCategory, report, onClick = ()=>{} }) => {
	const ref = useRef()
	const handleClick = () => onClick(report.name)
	return (<div className={`${classes.root} bg-white p-3 relative ${selectedCategory === report.name ? 'border border-2 border-gray-500' : ''}`}>
		<div className="absolute top-4 right-4 z-10">
			<DownloadButton targetRef={ref} name={report.name} />
		</div>
		<div ref={ref} onClick={handleClick} className="bg-white p-4">
			<div className="items-center">
				<Typography variant="h5" className="font-semibold text-xl capitalize category-comparison-carousel-header">{report.name}</Typography>
				<Typography variant="h6" className="text-sm category-comparison-carousel-header">{report.subname || 'Category Average'}</Typography>
			</div>
			<div className="chart flex justify-center">
				{
					report.data.map((reportData, reportDataIndex) => (
						<div key={`report-chart-wrapper-${reportIndex}-${reportDataIndex}`} className={`pb-8 flex-1 ${classes.chart_item} ${reportDataIndex == 0 ? 'border-r' : ''}`}>
							{
								<>
									<div style={{ height: '30vh', width: '100%' }} className="relative pt-4 pb-4 annual-trends-carousel-chart-item">
										<RadicalModified
											data={reportData.data.data}
											metaConfig={reportData.data.metaConfig}
										/>
										<div className="absolute inset-0 w-full flex flex-col justify-center items-center">
											<Typography variant="h4" className="">
												{Math.round(reportData.data.data[0]?.value * 100)}%
											</Typography>
											<p className="text-sm">Agreement</p>
											<span className="text-sm opacity-50">(average)</span>
										</div>
									</div>
									<div className="flex flex-row flex-wrap mt-5 justify-center">
										{
											reportData.data.metaConfig.fields.map((field, fieldIndex) => (
												<div key={fieldIndex} className="flex flex-row items-center gap-x-2">
													<div className="w-2 h-2 rounded-full" style={{ backgroundColor: reportData.data.metaConfig.colors[fieldIndex] }}></div>
													<p className="text-sm">{field.field}</p>
												</div>
											))
										}
									</div>
								</>
							}
						</div>
					))
				}
			</div>
		</div>
	</div >)
}



export default function CarouselChart({ onSlideChange, reports = reportsMap, selectedCategory, onClick=()=>{} }) {

	const responsive = {
		superLargeDesktop: {
		  breakpoint: { max: 4000, min: 3000 },
		  items: 5
		},
		desktop: {
		  breakpoint: { max: 3000, min: 1024 },
		  items: 3
		},
		tablet: {
		  breakpoint: { max: 1024, min: 464 },
		  items: 2
		},
		mobile: {
		  breakpoint: { max: 464, min: 0 },
		  items: 1
		}
	  };
	const classes = useStyles();
	return (
		<React.Fragment>
			<Carousel
				responsive={responsive}
				arrows={true}
				showDots={true}
				afterChange={(nextSlide, { currentSlide }) => {
					if (onSlideChange)
						onSlideChange(currentSlide)
				}}
			>
				{
					reports.map((report, reportIndex) => (
						<GraphItem key={`report-wrapper-${reportIndex}`} report={report} selectedCategory={selectedCategory} classes={classes} onClick={onClick} />
					))
				}
			</Carousel>
		</React.Fragment>
	)
}
