import React from 'react';
import { Box, Typography, Breadcrumbs, Link, Stack, Paper, Grid, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField, InputLabel, Select, MenuItem, Input, 
		TableContainer, Table, TableHead, TableRow, TableCell, TableBody, Pagination} from '@mui/material';
import { FunnelSVG, ArrowPrevSVG, RotationRightSVG, LockSVG, ArrowNextSVG, CloseSVG, CopySVG } from "components/svg-components";
import { styled } from '@mui/material/styles';

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(2.5),
	color: theme.palette.text.secondary
}));

const UserDetails = () => {
	const [resetPwd, setResetPwd] = React.useState(false);
	const resetPwdOpen = () => setResetPwd(true);
	const resetPwdClose = () => setResetPwd(false);

	const [generatePwd, setGeneratePwd] = React.useState(false);
	const generatePwdOpen = () => setGeneratePwd(true);
	const generatePwdClose = () => {
		setGeneratePwd(false);
	}

	const [pwd, setPwd] = React.useState(null);
	const generateString = event => {
	    let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+-=';
	    let charactersLength = characters.length;
	    let result = '';
	    for ( var i = 0; i < 10; i++ ) {
	      result += characters.charAt(Math.floor(Math.random() * 
	 charactersLength));
	   }
	   setPwd(result);
	}

	const rows = [
	  {id : '1', name: 'Project name', date_created: '21/04/2021', role: 'Sales'},
	  {id : '2', name: 'Lorem ipsum dolor sit amet, consectetur', date_created: '16/05/2021', role: 'Operations'},
	  {id : '3', name: 'Adipiscing elit', date_created: '19/06/2021', role: 'Operations'},
	  {id : '4', name: 'Cras amet viverra ut eleifend', date_created: '10/07/2021', role: 'Sales'},
	  {id : '5', name: 'Amet viverra ut eleifend', date_created: '1/08/2021', role: 'Operations'},
	  {id : '6', name: 'Project name', date_created: '21/04/2021', role: 'Sales'},
	  {id : '7', name: 'Lorem ipsum dolor sit amet, consectetur', date_created: '16/05/2021', role: 'Sales'},
	  {id : '8', name: 'Adipiscing elit', date_created: '19/06/2021', role: 'Sales'},
	  {id : '9', name: 'Cras amet viverra ut eleifend', date_created: '10/07/2021', role: 'Sales'},
	  {id : '10', name: 'Amet viverra ut eleifend', date_created: '1/08/2021', role: 'Operations'},
	];
	
	const handleChange = (event, value) => {
		setPage(value);
		setDisplayed(value * itemsPerPage);
	};

	const [sortBy, setSortBy] = React.useState('Date');
	const handleSortBy = event => {
		setSortBy(event.target.value);
	};

	const itemsPerPage = 5;
	const [total, setTotal] = React.useState(rows.length);
	const [page, setPage] = React.useState(1);
	const [displayed, setDisplayed] = React.useState(itemsPerPage);

	const [noOfPages] = React.useState(
		Math.ceil(total / itemsPerPage)
	);

	const [name, setName] = React.useState('');
	const [foundRows, setFoundRows] = React.useState(rows);

	const filter = (e) => {
	    const keyword = e.target.value;

	    if (keyword !== '') {

	      const results = rows.filter((project) => {
	        return project.name.toLowerCase().startsWith(keyword.toLowerCase());
	      });
	      setFoundRows(results);

	      setTotal(results.length);
	      if(results.length <= itemsPerPage)
	      	setDisplayed(results.length);
	      else
	      	setDisplayed(itemsPerPage);

	    } else {
	    	setTotal(rows.length);
	      	setFoundRows(rows);
	    }

	    setName(keyword);
	  };

	return (
		<Box className="projects-container">
			<Breadcrumbs aria-label="breadcrumb">
		        <Link href="/admin/users" underline="hover" color="inherit">
		          Users
		        </Link>
		        <Link underline="hover" color="inherit">
		          User name
		        </Link>
		    </Breadcrumbs>
		    <Box>
				<Typography component="h1" fontSize="30px" fontWeight="700" marginBottom="30px">
	          		Users
	          	</Typography>
			</Box>
			<Link href="/admin/users" underline="hover" color="inherit"> 
				<Stack direction="row" flexWrap="wrap" spacing={2} alignItems="center" marginBottom="30px">
					<ArrowPrevSVG />
					<Typography component="span" fontSize="14px">Back to All Users</Typography>
				</Stack>
			</Link>
			<Box className="project-wrapper" >
		    	<Typography component="h2" fontSize="22px" lineHeight="33px" fontWeight="700" marginBottom=
		    	"30px">User name</Typography>
		    	<Box className="project-details-wrapper" marginBottom="30px">
		    		<Typography component="h3" fontSize="16px" lineHeight="33px" fontWeight="700" marginBottom=
		    	"30px">Info</Typography>
		    		<Stack direction="row" spacing={2} marginBottom="10px">
		    			<Typography component="label">Email:</Typography>
		    			<Link href="mailto:email@email.com" className="text-gray-40 no-underline">email@email.com</Link>
		    		</Stack>
		    		<Stack direction="row" spacing={2} marginBottom="10px">
		    			<Typography component="label">Password:</Typography>
		    			<Typography component="p" fontSize="22px" lineHeight="1">•••••••••</Typography>

		    			<Link href="#" className="text-gray-40" onClick={resetPwdOpen} underline="hover">
		    				<Stack direction="row" spacing={2} alignItems="center">
		    					<RotationRightSVG />
	    						<Typography component="span" className="text-gray-40">Reset Password</Typography>
	    					</Stack>
	    				</Link>

	    				<Link href="#" className="text-gray-40" onClick={generatePwdOpen} underline="hover">
		    				<Stack direction="row" spacing={2} alignItems="center">
		    					<LockSVG />
		    					<Typography component="span" className="text-gray-40">Generate New Password</Typography>
		    				</Stack>
	    				</Link>
		    		</Stack>
		    		<Stack direction="row" spacing={2} marginBottom="10px">
		    			<Typography component="label">Location:</Typography>
		    			<Typography component="p">Location</Typography>
		    		</Stack>
		    		<Stack direction="row" spacing={2} marginBottom="10px">
		    			<Typography component="label">Date Added:</Typography>
		    			<Typography component="p">00/00/0000</Typography>
		    		</Stack>
		    		<Stack direction="row" spacing={2} marginBottom="10px">
		    			<Typography component="label">Number of Projects:</Typography>
		    			<Typography component="p">00</Typography>
		    		</Stack>
		    	</Box>
		    	
		    	<Box className="programs" marginTop="30px">
		    		<Typography component="h3" fontSize="16px" lineHeight="33px" fontWeight="700" marginBottom=
		    	"30px">Projects</Typography>
			    </Box>

			    <Stack
				  spacing={5}
				  direction="row" 
				  className="filter-wrapper"
				  marginBottom="20px"
				  justifyContent="space-between">
					<Box className="flex"
					alignItems="center">
						<FunnelSVG />
						<InputLabel sx={{ margin:"0 15px 0 5px" }}>Sort by:</InputLabel>
						<Select value={sortBy} onChange={handleSortBy} sx={{ maxHeight:'40px', minHeight:'unset', background : '#fff' }}>
						  <MenuItem value="Date">Sort by Date</MenuItem>
						  <MenuItem value="Username">Sort by Username</MenuItem>
						  <MenuItem value="User Role">Sort by User Role</MenuItem>
					  	</Select>
					</Box>
					<Input 
					type="search" 
					placeholder="Project Search" 
			        value={name}
			        onChange={filter}
			        className="input search-list" />
				</Stack>

				<Box>
					<TableContainer component={Paper}>
				      <Table sx={{ minWidth: 650 }} aria-label="simple table">
				        <TableHead sx={{ backgroundColor: '#eaebeb'}}>
				          <TableRow>
				            <TableCell>Project Name</TableCell>
				            <TableCell>Date of Creation</TableCell>
				            <TableCell>Role</TableCell>
				            <TableCell  align="right">Actions</TableCell>
				          </TableRow>
				        </TableHead>
				        <TableBody>
				          {foundRows.slice((page - 1) * itemsPerPage, page * itemsPerPage)
				          	.map((row) => (
				            <TableRow
				              key={row.username}
				              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
				            >
				              <TableCell component="th" scope="row">
				                {row.name}
				              </TableCell>
				              <TableCell>{row.date_created}</TableCell>
				              <TableCell>{row.role}</TableCell>
				              <TableCell align="right">
				              	<Link href={`/admin/projects/${row.id}`}>View Details</Link>
				              </TableCell>
				            </TableRow>
				          ))}
				        </TableBody>
				      </Table>
				    </TableContainer>
					<Grid container justifyContent="space-between" marginTop="30px">
						<Grid item>
						 	{displayed <= rows.length ?
						 		(
									<Typography>{displayed} out of {total}</Typography>
								) : (
									<Typography>{rows.length} out of {total}</Typography>
								)
							}
						</Grid>
						<Grid item>
					        <Pagination
					          count={noOfPages}
					          page={page}
					          onChange={handleChange}
					          defaultPage={1}
					          color="primary"
					          size="large"
					          className="pagination"
					        />
					    </Grid>
				    </Grid>
				</Box>

		    </Box>

		    <Dialog
		        aria-labelledby="reset password"
			    aria-describedby="reset password"
			    open={resetPwd} 
			    onClose={resetPwdClose} 
			    maxWidth="xs"
			    fullWidth="true"
			    className="modal_add-user">
		        <DialogTitle sx={{ marginTop:'15px' }} >
		          <Typography  variant="h6" component="span" fontWeight="600" display="block" className="text-center">
		            Reset Password
		          </Typography>
		        </DialogTitle>
		        <DialogContent sx={{ overflow:'hidden' }}>
		          <DialogContentText>
		          	Reset Password
		          </DialogContentText>
		        </DialogContent>
		        <DialogActions sx={{ justifyContent:'flex-start', 'padding':'0 24px 30px' }} >
					<Button onClick={resetPwdClose} className="text-gray-40 capitalize">Cancel</Button>
		        </DialogActions>
		    </Dialog>

		    <Dialog
		        aria-labelledby="generate-password"
			    aria-describedby="generate password"
			    open={generatePwd} 
			    onClose={generatePwdClose}
			    maxWidth="xs"
			    fullWidth="true"
			    className="modal_generate-password rounded-none"
			    background="#F5F5F5"
			>
		        <DialogTitle sx={{ marginTop:'15px' }} >
		          <Link href="#" onClick={generatePwdClose} sx={{ position: 'absolute', right: '15px', top: '15px' }} > <CloseSVG /> </Link>
		          <Typography  variant="h6" component="span" fontWeight="600" display="block"  className="text-center">
		            Generate Password
		          </Typography>
		        </DialogTitle>
		        <DialogContent sx={{ overflow:'hidden' }}>
		          <DialogContentText textAlign="center">
		           	The new user will receive an invitation to the specified mail with a login link
		          </DialogContentText>
		        </DialogContent>
		        <DialogActions sx={{ justifyContent:'center', flexWrap:'wrap', 'padding':'0 24px 30px' }} >
		        	<Button variant="contained" color="error" onClick={generateString} className="normal-case rounded-none">Generate</Button>

		        	{ pwd !== null && 
		        		<Box sx={{width:'100%', marginTop:'50px'}}>
		        			<Typography fontSize="12px">New Password</Typography>
		        			<Box sx={{position:'relative'}}>
		        				<TextField value={pwd} border="0" borderRadius="0" className="plain-textfield" fullWidth />
		        				<Box sx={{ position: 'absolute', right: '15px', top: '50%', transform: 'translateY(-50%)', zIndex: '99', cursor: 'pointer' }}><CopySVG /></Box>
		        			</Box>
		        		</Box>
		        	}
		        </DialogActions>
		    </Dialog>
		</Box>
	)
}

export default UserDetails;