import React, { useRef } from "react";
import DonutChart from "components/charts/DonutChart";
import DownloadButton from "components/charts/DownloadButton"
import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';
import Typography from "@mui/material/Typography";
import { makeStyles } from '@material-ui/core/styles';

import { annualEmpty } from '../constant/empty-report'

const useStyles = makeStyles({
    root: {
        margin: '5px 10px 35px',
        background: '#fff',
        padding: '10px 25px',
        boxShadow: "0 0 2px rgb(0 0 0 / 70%)",
    },
    chart_item: {
        margin: '0px 15px'
    }
});

const empty_data = [
    {
        data: [
            {
                year: 2021,
                data: [
                    { field: "Sample_1", value: 0.12, color: "#DCDCDC"},
                    { field: "Sample_2", value: 0.28, color: "#C4C4C4"},
                    { field: "Sample_3", value: 0.60, color: "#AFAFAF"},
                ]
            }, 
             {
                year: 2022,
                data: [
                    { field: "Sample_1", value: 0.12, color: "#DCDCDC"},
                    { field: "Sample_2", value: 0.38, color: "#C4C4C4"},
                    { field: "Sample_3", value: 0.50, color: "#AFAFAF"},
                ]
            }
        ]
    },
    {
        data: [
            {
                year: 2021,
                data: [
                    { field: "Sample_1", value: 0.26, color: "#DCDCDC"},
                    { field: "Sample_2", value: 0.18, color: "#C4C4C4"},
                    { field: "Sample_3", value: 0.56, color: "#AFAFAF"},
                ]
            }, 
             {
                year: 2022,
                data: [
                    { field: "Sample_1", value: 0.52, color: "#DCDCDC"},
                    { field: "Sample_2", value: 0.38, color: "#C4C4C4"},
                    { field: "Sample_3", value: 0.20, color: "#AFAFAF"},
                ]
            }
        ]
    }
]

const metaConfig = {
    colors: ["#DCDCDC", "#C4C4C4", "#AFAFAF"],
    fieldKey: "field",
    fields: [
        {field: "Sample_1", title: "Sample_1"},
        {field: "Sample_2", title: "Sample_2"},
        {field: "Sample_3", title: "Sample_3"},
    ],
    valueKey: "value",
    centerText: [],
}

export default function CarouselChartEmpty ({onSlideChange, reports = empty_data}) {
    const responsive = {
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: 5
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: 1
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: 1
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: 1
        }
      };
    const classes = useStyles();
    return (
        <React.Fragment>
            <Carousel
                responsive={responsive}
                arrows={true}
                showDots={true}
                afterChange={(nextSlide, { currentSlide }) => {
                    if (onSlideChange)
                        onSlideChange(currentSlide)
                }}
            >
                {
                    reports.map((report, reportIndex) => (
                        <GraphItem key={`report-wrapper-${reportIndex}`} report={report} classes={classes} />
                    ))
                }
            </Carousel>
        </React.Fragment>
    )
}

const GraphItem = ({ reportIndex, classes, report }) => {
    const ref = useRef()
    return (<div className={classes.root + ' relative'}>
        <div ref={ref}>
            <div className="flex items-center justify-between">
                <Typography variant="h5" className="font-semibold text-xl capitalize annual-trends-carousel-header">{report.name}</Typography>
            </div>
            <div className="chart flex flex-row justify-center">
                {
                    report.data.map((reportData, reportDataIndex) => (
                        <div key={`report-chart-wrapper-${reportIndex}-${reportDataIndex}`} className={`pb-8 flex-1 ${classes.chart_item} ${reportDataIndex == 0 ? 'border-r' : ''}`}>
                            {
                                reportData.data.length > 0 && (
                                    <div style={{ height: '30vh', width: '100%' }} className="relative pt-4 pb-4 annual-trends-carousel-chart-item">
                                        <DonutChart
                                            data={reportData.data}
                                            metaConfig={metaConfig}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    ))
                }
            </div>
        </div>
        <div className="mt-5">
            <p className="text-sm text-center">Data not yet available</p>
        </div>
    </div >)
}

