const PaperSVG = () => {
	return (
		<svg className="stroke-current" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M2.83333 1H10.1667L15.6667 6.5V17.5V19.3333H13.8333H2.83333H1V17.5V2.83333V1H2.83333Z" strokeLinecap="round"/>
			<path d="M10.667 1C10.667 0.723858 10.4431 0.5 10.167 0.5C9.89085 0.5 9.66699 0.723858 9.66699 1H10.667ZM10.167 6.5H9.66699V7H10.167V6.5ZM15.667 7C15.9431 7 16.167 6.77614 16.167 6.5C16.167 6.22386 15.9431 6 15.667 6V7ZM9.66699 1V6.5H10.667V1H9.66699ZM10.167 7H15.667V6H10.167V7Z"/>
			<path d="M12.0003 11.082H4.66699" strokeLinecap="square" strokeLinejoin="round"/>
			<path d="M12.0003 14.75H4.66699" strokeLinecap="square" strokeLinejoin="round"/>
			<path d="M6.50032 7.41602H5.58366H4.66699" strokeLinecap="square" strokeLinejoin="round"/>
		</svg>

	)
}

export default PaperSVG