import React from 'react'
import { useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import AppActions from "redux/modules/app/actions"
import DashboardSideMenu from "./side-menu/index"

const SideMenuDrawer = () => {

	const dispatch = useDispatch()
	const showSideMenuDrawer = useSelector(state => state.app.showSideMenuDrawer)

	return (
		<React.Fragment>
			<div id="side-menu-drawer" className={ `${ showSideMenuDrawer ? 'show' : '' } z-20` }>
				<span id="side-menu-drawer-closer" className={ `${ !showSideMenuDrawer ? 'opacity-0' : '' } cursor-pointer` } onClick={ () => dispatch(AppActions.setSideMenuDrawerShow(false)) }>
					<i className='bx bxs-x-circle text-white text-4xl'></i>
				</span>
				 <DashboardSideMenu />
			</div>

			<div style={{ background: 'rgba(0, 0, 0, 0.5)' }}  className={ `${ !showSideMenuDrawer ? 'hidden' : '' } z-10 fixed inset-0` } onClick={ () => dispatch(AppActions.setSideMenuDrawerShow(false)) }>

			</div>
		</React.Fragment>
	)

}

export default SideMenuDrawer