import React from 'react'

import {
    InputLabel, MenuItem, Breadcrumbs, Select, Box, Grid, Button, Modal, Typography, TextField, FormControl, useFormControl, Stack,
    Link, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableSortLabel, Paper, Input, Pagination
} from '@mui/material';
import { FunnelSVG } from "components/svg-components";

import Status from '../components/status'
import { MailData } from '../constants/data'

const Content = () => {

    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const [filterBy, setFilterBy] = React.useState('all');
    const handleFilterBy = event => {
        setFilterBy(event.target.value);
    };

    const [sortBy, setSortBy] = React.useState('Date');
    const handleSortBy = event => {
        setSortBy(event.target.value);
    };

    const itemsPerPage = 5;
    const [total, setTotal] = React.useState(MailData.length);
    const [page, setPage] = React.useState(1);
    const [displayed, setDisplayed] = React.useState(itemsPerPage);

    const [noOfPages] = React.useState(
        Math.ceil(total / itemsPerPage)
    );

    const [search, setSearch] = React.useState('');
    const [foundRows, setFoundRows] = React.useState(MailData);

    const filter = (e) => {
        const keyword = e.target.value;

        if (keyword !== '') {

            const results = MailData.filter((mail) => {
                return mail.name.toLowerCase().startsWith(keyword.toLowerCase());
            });
            setFoundRows(results);

            setTotal(results.length);
            if (results.length <= itemsPerPage)
                setDisplayed(results.length);
            else
                setDisplayed(itemsPerPage);

        } else {
            setTotal(MailData.length);
            setFoundRows(MailData);
        }

        setSearch(keyword);
    };
    const handleChange = (event, value) => {
        setPage(value);
        setDisplayed(value * itemsPerPage);
    };


    return (
        <div className="users-wrapper">
            <Box>
                <Box className="users-lists_wrapper">
                    <Stack
                        spacing={2}
                        direction="row"
                        className="filter-wrapper"
                        marginBottom="20px"
                        justifyContent="space-between"
                        paddingRight='30px'
                    >
                        <Box className="flex" alignItems="center">
                            <Select
                                value={filterBy}
                                onChange={handleFilterBy}
                                sx={{
                                    maxHeight: "40px",
                                    minHeight: "unset",
                                    background: "#fff",
                                    fontSize: "14px",
                                }}
                            >
                                <MenuItem value="all">All Status</MenuItem>
                            </Select>
                        </Box>
                        <Input
                            type="search"
                            placeholder="Campaign Search"
                            value={search}
                            onChange={filter}
                            className="input search-list"
                        />
                    </Stack>
                    <Box>
                        <TableContainer component={Paper}>
                            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead sx={{ backgroundColor: "#eaebeb" }}>
                                    <TableRow>
                                        <TableCell>Campaign Name</TableCell>
                                        <TableCell>Status</TableCell>
                                        <TableCell>Total Subscribers</TableCell>
                                        <TableCell align="right">Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {foundRows
                                        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
                                        .map((row) => (
                                            <TableRow
                                                key={row.name}
                                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                                            >
                                                <TableCell component="th" scope="row">
                                                    {row.name}
                                                </TableCell>
                                                <TableCell className="flex flex-row items-center capitalize">
                                                    <Status status={row.status} /> 
                                                    {row.status}
                                                </TableCell>
                                                <TableCell>{row.total_subscriber}</TableCell>
                                                <TableCell align="right">
                                                    <Link className="mr-4" href={`/admin/email/${row.id}`}>View Details</Link>
                                                    <Link className="mr-4" href={`/admin/email/${row.id}/chart`}>View Chart</Link>
                                                    <Link href={`/admin/email/${row.id}/edit`}>Edit in Zoho Campaigns</Link>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Grid container justifyContent="space-between" marginTop="30px">
                            <Grid item>
                                {displayed <= MailData.length ? (
                                    <Typography>
                                        {displayed} out of {total}
                                    </Typography>
                                ) : (
                                    <Typography>
                                        {MailData.length} out of {total}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item>
                                <Pagination
                                    count={noOfPages}
                                    page={page}
                                    onChange={handleChange}
                                    defaultPage={1}
                                    color="primary"
                                    size="large"
                                    className="pagination"
                                />
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
            </Box>
        </div>
    )
}

export default Content