import { useHistory } from 'react-router'
import { Button, Link as DomLink, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { addToCart } from 'redux/modules/cart/actions';
import AppAction from 'redux/modules/app/actions';
import { currentCurrency } from 'helpers/checkCurrency';
import { checkIsUk } from 'helpers/checkIsUK';
const PackageReportList = () => {
  const history = useHistory()
  const handleClick = () => history.push('/reports-sample')

  return <ul className="default-list mb-4">
    <li>
      <p>
        <span className="list-title">Phone Call.</span> A 30-minute phone call with our Survey Specialist will help you go through the results and get answers to questions.
      </p>
    </li>
    <li>
      <p>
        <span className="list-title">Online Data Dashboard.</span> This is a place where you can get all your data in the ways you need it, including downloading it in charts and graphs.  
      </p>
    </li>
    <li>
      <p>
        <span className="list-title">Workforce Feedback Results.</span> This quantitative report reflects the perceptions of respondents to each question on the employee survey, both system-wide and segmented within various demographics of the population.
        <span className='view-report ml-4 text-red-10' onClick={()=>history.push('/employee-response-breakdown')}>View Report<i className='bx bx-chevron-right ml-2'></i></span>
      </p>
    </li>
    <li>
      <p>
        <span className="list-title">Employee Verbatims.</span> Any responses to open-ended survey questions are contained in this report.
        <span className='view-report ml-4 text-red-10' onClick={() => history.push('/employee-verbatims')}>View Report<i className='bx bx-chevron-right ml-2'></i></span>
      </p>
    </li>
    <li>
      <p>
        <span className="list-title">Workforce Benchmark Comparisons.</span> This report averages the percentage of employees’ positive responses to each survey question from all participating {checkIsUk() ? 'organisations' : 'organizations'}, presented in aggregate by all competitors that did and did not make the list.
        <span className='view-report ml-4 text-red-10' onClick={() => history.push('/workforce-benchmark-comparisons')}>View Report<i className='bx bx-chevron-right ml-2'></i></span>
      </p>
    </li>
    <li>
      <p>
        <span className="list-title">Benefits & Best Practices Report.</span> This report provides the percentage of winning and non-winning {checkIsUk() ? 'organisations' : 'organizations'} that offer various employee benefits and workplace practices.
        <span className='view-report ml-4 text-red-10' onClick={() => history.push('/benefits-and-best-practices')}>View Report<i className='bx bx-chevron-right ml-2'></i></span>
      </p>
    </li>
  </ul>
}

export default function ReportFDD ({ classGrid, item, index, onAddCart=() => {} }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(({user})=> user.user)
  const { items } = useSelector(({cart}) => cart);
  const onCart = items.find(i => i.forPayment === 'FDD');

  const handleAddToCart = () => {
    const { id, amount } = item;
    let accessList = {};
    // if(item.accessGroup?.length > 0) {
    //   item.accessGroup?.forEach(i => {
    //     accessList[i] = "Yes"; // selectOrgProgram[i]
    //   })
    // } else if(item.access) {
    //   accessList[item.access] = 'Yes';
    // }
    const checkout = { 
      id, 
      title: "Employee Feedback Data Dashboard", 
      amount, 
      forPayment: 'FDD',
      access: item.access,
      subTitle: "Workforce Feedback Results, Employee Verbatims, Workforce Benchmark Comparisons, Benefits & Best Practices",
      accessGroup: item.accessGroup || [],
      keys: {
        FDD_Payment_Details: new Date(),
        FDD_Payment_Type: null,
        // FDD_Access: "Yes", //If there's something
        // ...accessList
      }
    };

    onAddCart(checkout);
    dispatch(addToCart(checkout, item.amount))
    dispatch( AppAction.setAlert(`Feedback Data Dashboard added to cart!`) )
  }

  return (<div className={`w-full h-full ${classGrid}`}>
    <div className='h-full bg-white p-5 flex flex-col'>
      <div className='flex justify-between'>
        <img src={item.img} className="w-12 h-12 rounded-full mb-2"/>
        <div className="flex items-center">
          <Typography variant="h6">Price: <span className="text-red-10 mr-3">{currentCurrency()} {item.amount}</span></Typography>
          {/* <Typography variant="h6">Price: <span className="text-red-10 mr-3">$ {item.amount}</span></Typography> */}
          <Button
            variant="contained"
            type="submit"
            color="error"
            className="normal-case rounded-none px-5"
            onClick={handleAddToCart}
            disabled={onCart}
          >
          { !onCart ? 'Add to Cart' : 'Added to Cart' }
          </Button>
        </div>
      </div>
      <h2 className='text-md text-black-0 font-bold line-clamp-2 mt-4 mb-3'>{ item.title }</h2>
      <p className='mb-2 text-gray-30 text-sm'>
        This report identifies key motivators of employee engagement within your unique population. This information is vital to knowing what workplace attributes are most important. <span className=
      "font-bold">Here’s what you get</span>:
      </p> 
      <PackageReportList/>
      <span className='text-sm text-gray-30 font-italic my-2'>Note: Upon successful processing of a credit card payment, you’ll receive immediate access to the Feedback Data Dashboard.</span>
    </div>
  </div>)
}
