import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'

/** Source: https://mui.com/components/text-fields/ (Under "Customization" section.) */
const CustomizedTextField = styled(TextField)({
  border:0,
  borderRadius: 0,
  backgroundColor: '#fff',
  '& label.Mui-focused': {
    backgroundColor: '#fff',
    border:0,
    borderRadius: 0,
    color: 'white',
  },
  'input': {
    padding: '12px 15px',
    fontSize: '14px',
    lineHeight: '100%',
    '&::placeholder': {
      color: '#1D2124 !important',
      fontWeight: 'normal !important',
      opacity: '0.6 !important' 
    },
    '&::-webkit-input-placeholder': {
      color: '#1D2124 !important',
      fontWeight: 'normal !important',
      opacity: '0.6 !important' 
    },
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: '#fff',
    border:0,
    borderRadius: 0,
    '& fieldset': {
      borderColor: 'white',
      color: 'white'
    },
    '&:hover fieldset': {
      borderColor: 'white',
      color: 'white'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'white',
      color: 'white'
    },
  },
})

const WhiteTextField = (props) => {

  return (
    <CustomizedTextField
      // InputLabelProps={ { style: { color: '#000' } } }
      // InputProps={{ style: { color: '#000' } }}
      { ...props }
    />
  )
}

export default WhiteTextField