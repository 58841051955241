import { useCallback, useEffect, useRef } from "react";
import { pie as d3Pie, arc as d3Arc } from "d3-shape";
import { select as d3Select } from "d3-selection";
// Used for annual trends. If this is modified for other graphs. please check the percentage use for graph.
const DonutChart = (props) => {
  const { data, metaConfig, toFix = 1 } = props;
  const chartRef = useRef(null);

  const drawChart = useCallback(() => {
    if (chartRef.current) {
      const { width, height } = chartRef.current.getBoundingClientRect();
      const PIx2 = 2 * Math.PI,
        DONUT_WIDTH = 0.3,
        donutRadius = (Math.min(height, width) / 2) * 0.9;

      const pie = d3Pie(),
        arc = d3Arc();

      pie
        .sort(null) // Do not sort group by size
        .value(function (d) {
          return d[metaConfig.valueKey];
        });
      arc
        .outerRadius(donutRadius * 0.8)
        .innerRadius(donutRadius * (0.8 - DONUT_WIDTH));

      const transformedData = pie(data);
      const svgG = d3Select(chartRef.current)
        .append("svg")
        .attr("height", height)
        .attr("width", width)
        .append("g")
        .attr("transform", `translate(${width / 2}, ${height / 2})`);

      svgG
        .selectAll("slices")
        .data(transformedData)
        .join("path")
        .attr("d", arc)
        .attr("fill", (d) => metaConfig.colors[d.index])
        .attr("stroke", "white")
        .attr("stroke-width", 2)
        .on("mousemove", function () {
          d3Select(this)
            .attr("stroke-width", donutRadius * 0.04)
            .attr("stroke", "pink");
        })
        .on("mouseleave", function () {
          d3Select(this).attr("stroke", "white").attr("stroke-width", 2);
        });

      if (!metaConfig.hideLabels) {
        svgG
          .selectAll("allPolylines")
          .data(transformedData)
          .join("polyline")
          .style("fill", "none")
          .attr("stroke-width", 1)
          .attr("stroke", (d) => metaConfig.colors[d.index])
          .attr("points", (d) => {
            var posA = arc.centroid(d);
            var posB = posA.map((p) => p * 1.4);
            return [posA, posB];
          });

        svgG
          .selectAll("allLabels")
          .data(transformedData)
          .join("text")
          .text((d) => {
            const fraction = d.value;
            // const fraction = (d.endAngle - d.startAngle) / PIx2;
            // if(fraction > 0.99 && fraction < 1)
            //   return `> 99%`;
            // else if(fraction < 0.01) return `< 1%`;

            return `${fraction}%`;
          })
          .attr("transform", (d, a,b,c) => {
            var pos = arc.centroid(d).map((p, k) => p * (d.index % 2 == 0 ? 1.6 : 1.45));
            return `translate(${pos})`;
          })
          .style("text-anchor", "middle")
          .style("font-size", `${donutRadius * 0.08}px`);
      }
      if (metaConfig.centerText) {
        svgG
          .append("text")
          .text(metaConfig.centerText)
          .style("text-anchor", "middle")
          .style("font-size", `${donutRadius * 0.1}px`);
      } else if (metaConfig.showLargest) {
        const { i: mxI, a: mx } = transformedData
          .map((a) => (a.endAngle - a.startAngle) / PIx2)
          .reduce((res, a, i) => (a > res.a ? { a, i } : res), { a: 0, i: -1 });
        svgG
          .append("text")
          .text(`${(mx * 100).toFixed(1)}%`)
          .attr("fill", metaConfig.colors[mxI])
          .style("text-anchor", "middle")
          .style("font-size", `${donutRadius * 0.2}px`);
        svgG
          .append("text")
          .text(metaConfig.fields[mxI].title)
          .attr("fill", "grey")
          .style("text-anchor", "middle")
          .attr("y", 15);
      }
    }
  }, [data, metaConfig]);

  const destroyChart = useCallback(() => {
    if (chartRef.current) {
      d3Select(chartRef.current).select("svg").remove();
    }
  }, []);

  const rebuildChart = useCallback(() => {
    destroyChart();
    drawChart();
  }, [destroyChart, drawChart]);

  useEffect(() => {
    drawChart();
    window.addEventListener("resize", rebuildChart);
    return () => {
      destroyChart();
      window.removeEventListener("resize", rebuildChart);
    };
  }, [drawChart, destroyChart, rebuildChart]);

  return <div ref={chartRef} style={{ width: "100%", height: "100%" }}></div>;
};

export default DonutChart;
