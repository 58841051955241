import { useEffect } from 'react'
import { Alert } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import AppAction from 'redux/modules/app/actions';

export default function Alerter () {
	const dispatch = useDispatch();
	const { alert, alertType } = useSelector(({ app }) => app)
	const handleClose = () => dispatch( AppAction.setAlert() )
	useEffect(()=>{
		if(alert) {
			setTimeout(handleClose , 10000) //Remove after 10 secs
		}
	},[ alert ])

	if(!alert) return ("");

	return <Alert className="fixed right-4 top-6" variant="filled" severity={alertType || 'info'} onClose={handleClose}>{ alert }</Alert>
}