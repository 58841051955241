import * as React from "react"

const SvgComponent = (props) => (
  <svg
    width={60}
    height={60}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={30} cy={30} r={30} fill="#F5F5F5" />
    <path
      d="M27.5 33v-1.25h-8.738l-.012 5c0 1.388 1.113 2.5 2.5 2.5h17.5c1.388 0 2.5-1.112 2.5-2.5v-5H32.5V33h-5ZM40 21.75h-5.013v-2.5l-2.5-2.5h-5l-2.5 2.5v2.5H20a2.507 2.507 0 0 0-2.5 2.5V28c0 1.387 1.113 2.5 2.5 2.5h7.5V28h5v2.5H40c1.375 0 2.5-1.125 2.5-2.5v-3.75c0-1.375-1.125-2.5-2.5-2.5Zm-7.5 0h-5v-2.5h5v2.5Z"
      fill="#3F7CFB"
    />
    <path
      d="m41 27 2.445 4.634 5.163.894-3.652 3.758.746 5.186L41 39.16l-4.702 2.312.746-5.187-3.652-3.757 5.163-.893L41 27Z"
      fill="#FFC955"
    />
  </svg>
)

export default SvgComponent
