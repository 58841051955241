import { useRef, useEffect, useState } from 'react'
import { Link, Modal, 
	Button, IconButton, Box, Typography } from '@mui/material'
import { styled } from '@mui/system'
import {Sort, Close, TaskAlt, Refresh} from '@mui/icons-material';
import LoadingButton from '@mui/lab/LoadingButton'
import { makeStyles } from '@material-ui/core/styles';
import { useSelector, useDispatch } from "react-redux";
import { setResyncInfo } from 'redux/modules/reports/actions';
import { addResync } from 'redux/modules/cart/actions';
import AppAction from 'redux/modules/app/actions';

const useStyles = makeStyles({
	mainButton:{
		textTransform: 'none !important',
		color: '#1D2124 !important',
		// border: '0 !important',
		// backgroundColor: 'red !'
	}, 
	redButton: {
		textTransform: 'none !important',
		color: 'white !important',
		borderRadius: '0 !important',
		backgroundColor: 'red !important'
	},
	subButton: {
		'& svg': {
			marginRight: '5px',
			transform: "rotate(-90deg)"
		}
	}
})

const Root = styled('div')`
  font-size: 0.875rem;
  position: relative;
  display: flex;
  align-items: center;
  vertical-align: baseline;
  color: #000;
`;

export default function ReSortModal() {
	const { http } = global.services;
	const classes = useStyles();
	const dispatch = useDispatch();
	const { admin, user } = useSelector(({user}) => user)
	const { selectedProgramId } = useSelector(({app}) => app)
	const organizationProgram = user.userData?.organizationProgram || [];
	const selectOrgProgram = organizationProgram?.find(i => i.programId?._id == selectedProgramId) || organizationProgram[0];
	const resyncPrice = selectOrgProgram?.programId?.Data_Resort_Fee || 0;
	const { resynced, resyncing } = useSelector(({report}) => report)
	const [showModal, openModal] = useState(false);
	const listboxRef = useRef(null);
	const handleOpen = (open = false) => {
		openModal(open)
	}

	const addResortCart = () => {
		dispatch( setResyncInfo(true, false) )
		dispatch( addResync(true) )
		openModal(false)
	}

	const adminResync = async () => {
		// const op = organizationProgram.find(i => i.programId?._id == selectedProgramId) || organizationProgram[0];
		dispatch( AppAction.setAlert('Resyncing, please be back in 30mins') );
		dispatch( setResyncInfo(true, false) );
		openModal(false);
		try {
			const { data } = await http.post('/webhook/reSyncDataWithCrm', {
				dealid: [ selectOrgProgram?.DealId ]
			})
		} catch (e) {

		}
	}

	const showButtonContent = () => {
		if(resyncing)
			return "Resort Data in Progress"
		if(resynced)
			return "Resort Data Completed"

		if(admin) return "Re-Sync All Data"

		return "Resort Data"
	}
	
	if(!admin && resyncPrice <= 0) 
		return "" //Dont render.

	if(admin && admin?.roleId?.role !== 'admin' && !admin?.roleId?.permissions?.find(i => i === 'syncCheckmartketAndZohoAccess'))
		return ""

	return (<Root
    ref={listboxRef}
	>
		<LoadingButton
			className={`${admin ? classes.redButton : classes.mainButton} ${!(resyncing || resynced) ? classes.subButton : ''}`}
			color={"gray-40"}
			sx={{opacity: (resynced || resyncing) ? 0.65 : 1}}
      onClick={()=>handleOpen(true)}
      loadingPosition="start"
			startIcon={!resynced ? <Sort/> : <TaskAlt/>}
			loadingIndicator={<Refresh/>}
      disabled={resyncing || resynced}
      variant={admin ? 'contained' : 'text'}
      loading={ resyncing }
    >
      {showButtonContent()}
    </LoadingButton>
    <Modal
		  open={showModal}
		  onClose={()=>openModal(false)}
		  aria-labelledby="resort-title"
		  aria-describedby="resort-description"
		>
		  <Box sx={{
			  position: 'absolute',
			  top: '50%',
			  left: '50%',
			  transform: 'translate(-50%, -50%)',
			  width: 490,
			  bgcolor: '#f5f5f5',
			  boxShadow: 24,
			  p: 4,
			}}>
			<Typography id="role-form"
				variant="h5"
				component="span"
				fontWeight="600"
				display="block"
				className={`${admin ? 'text-center' : 'text-left'} mt-6`}
			>
				<IconButton
	        aria-label="close"
	        onClick={()=>openModal(false)}
	        sx={{ position: 'absolute', right: 8, top: 8 }}
	      >
	        <Close />
	      </IconButton>
				{ admin? 'Are You Sure?' :'Re-Sorted Workforce Feedback Report'}
			</Typography>
			<Typography id="user-form-title"
				variant="p"
				component="span"
				display="block"
				className="text-left my-9"
			> 
				Uh oh, you’ve realized that the demographic breakouts of 
				your employees’ responses don’t represent your unique workplace structure, or maybe you
				need to combine smaller groups of respondents in order to review their feedback. Not to fear! <br />
				A <a className="text-red-10" target="_blank" href="https://www.bestplacestoworkvermont.com/survey-professional">Survey Professional</a> can help you to re-sort the data to obtain a more meaningful and <br />
				actionable report
			</Typography>
			{
				!admin &&
				<Typography id="user-form-title"
					variant="p"
					component="span"
					display="block"
					className="text-left my-9"
				>
					<span className="text-xl text-red-0">VARIES</span>
				</Typography>
			}
			{
				admin ? <Box className="flex justify-between">
					<Button
						variant="text"
						onClick={()=>openModal(false)}
						className="normal-case rounded-none w-1/2"
					>
						Cancel
					</Button>
			    <LoadingButton
			    	type="button"
			      variant="contained"
			      disabled={resynced || resyncing}
			      color="error"
			      className="normal-case rounded-none w-1/2"
			      loading={resyncing}
			      onClick={adminResync}
			     >
			      Re-Sync Data
			    </LoadingButton>
				</Box> :
				<Button
					variant="contained"
					color="error"
					onClick={addResortCart}
					className="normal-case rounded-none w-full mb-8"
				>
					Add to Cart
				</Button>
			}
			</Box>
		</Modal>
	</Root>)
}