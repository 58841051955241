import React from 'react'
import { useHistory } from 'react-router'

const ReportItem = ({ item }) => {
	const history = useHistory()

	const renderItemOption = (item) => {
		if (item.comingSoonAt)
			return (
				<div className='flex flex-row items-center justify-end'>
					<span className='mt-2 text-sm'>Available in { item.comingSoonAt }</span>
				</div>
			) 

		if (item.isPurchased)
			return (
				<div className='flex flex-row items-center justify-end'>
					<span className='text-red-10 cursor-pointer text-sm' onClick={ () => history.push(item.redirectTo) }>More Info</span>
					<i className='bx bx-chevron-right text-xl'/>
				</div>
			) 
	}

	return <div className={`report-item w-1/4`}>
		<div className='bg-white p-5'>
			<img src={item.img} className="w-12 h-12 rounded-full mb-2"/>
			<h2 className='text-md text-black-0 font-bold line-clamp-2 h-12 mt-4 mb-3'>{ item.title }</h2>

			<p className='h-24 line-clamp-4 mb-3 text-gray-30 text-sm'>
				{ item.description }
			</p>

			<div className='mt-5'>
			{ renderItemOption(item) }
			</div>
		</div>
	</div>
}

export default ReportItem