import {
	SET_USER,
	TOGGLE_ROLE
} from './types';

const setUser = (params) => {
	return {
    type: SET_USER,
    payload: params,
  };
}

const toggleUser = (payload = false) => {
	return { type: TOGGLE_ROLE, payload };
}

const actions = {
	setUser,
	toggleUser
}

export default actions