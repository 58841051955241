const DashboardFooter = () => {
	return (
		<div id="content-layout-footer" className="content-horizontal-padding">
			<div className="flex-1 flex flex-row items-center">
				<div className="flex">
					<p className="text-sm">
						Workforce Research Group {new Date().getFullYear()} © 
					</p>
					<span className="px-3">|</span>
					<a href="https://workforcerg.com/privacy-policy" target="_blank">WRG Privacy Policy</a>
				</div>
			</div>

			<div className="flex-1 flex flex-row items-center justify-end">
				<div className="flex-1 flex justify-end pr-10">
					<p className="text-sm">
						(281) 602-5004 <span className="pl-5">answers@workforcerg.com</span>
					</p>
				</div>
				{/*<div className="flex justify-end space-x-3">
					<span className="bg-red-0 text-white p-2 rounded-full flex justify-center items-center">
						<i className='bx bxl-facebook' style={{ fontSize: '1rem' }}></i>
					</span>

					<span className="bg-red-0 text-white p-2 rounded-full flex justify-center items-center">
						<i className='bx bxl-linkedin' style={{ fontSize: '1rem' }}></i>
					</span>

					<span className="bg-red-0 text-white p-2 rounded-full flex justify-center items-center">
						<i className='bx bxl-twitter' style={{ fontSize: '1rem' }}></i>
					</span>
				</div>*/}
			</div>
		</div>
	)

}

export default DashboardFooter