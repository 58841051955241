import * as React from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from "react-redux"

import {Typography, Box, Link, Grid, InputLabel, MenuItem, Select, Button, Pagination, Breadcrumbs, Input, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, TablePagination, Paper, TableSortLabel} from '@mui/material';
import { FunnelSVG, ArrowNextSVG, EditSVG, DeleteSVG } from "components/svg-components";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import dayjs from 'dayjs'
import UserAction from 'redux/modules/user/actions'
import GenericLoader from 'components/loaders/generic'

const externalData = {
	borders: [ 'border-red-0', 'border-yellow-0', 'border-green-0', 'border-purple-0', 'border-blue-0' ],
	lastBorderIndexUsed: 0
}

const ClientsPage = () => {
    const { http } = global.services
	const history = useHistory()
    const dispatch = useDispatch()
	const itemsPerPage = 10;
	const [total, setTotal] = React.useState(0);
	const [page, setPage] = React.useState(1);
	const [displayed, setDisplayed] = React.useState(itemsPerPage);

	const [noOfPages, setNoOfPages] = React.useState(
		Math.ceil(total / displayed)
	);

	const [name, setName] = React.useState('');
	const [foundRows, setFoundRows] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [oldRows, setOldRows] = React.useState([]);

    React.useEffect(() => {
        loadData() //Pagination doesn't work now. but if it does.. add page to the array
    }, [])
    
    const loadData = async () => {
        try{
        setLoading(true);
        const { data } = await http.get('admin/getOrganizations', { page, limit: itemsPerPage });
        // const { count, totalPages } = data.pageInfo
        const rows = data.data || [];
        setFoundRows(rows);
        setOldRows(rows);
        setTotal(rows.length);
        setNoOfPages( Math.ceil(rows.length / displayed) )
        setLoading(false)
        } catch(err){
            console.log(err);
            setLoading(false)
            return;
        }
    }

	const handleChange = (event, value) => {
		setPage(value);
		setDisplayed(value * itemsPerPage);
	};

	const [sortBy, setSortBy] = React.useState('Name');
	const handleSortBy = event => {
		setSortBy(event.target.value);
        let sorted = [];

        switch(event.target.value) {
            case 'Login':
            sorted = oldRows.sort((a,b) => {
                var dA = a.lastLogin != null ? dayjs(a.lastLogin) : null;
                var dB = b.lastLogin != null ? dayjs(b.lastLogin) : null;
                if(dA && dB)
                    return dB.diff( dA );
                else if (dB)
                    return 0;
                return -1;
            } );
            break;

            case 'Name':
            sorted = oldRows.sort((a, b) => {
                const aName = a.Alias_Company_Name || a.Account_Name;
                const bName = b.Alias_Company_Name || b.Account_Name;
                return  aName > bName ? 1 : (bName > aName ? -1 : 0);
              } );
            break;

            case 'Program':
            sorted = oldRows.sort((a, b) => a.orgPrograms?.length - b.orgPrograms?.length);
            break;
        }
        // console.log('huuh?', foundRows, sorted)
        // setOldRows(foundRows);
        setFoundRows(sorted);
	};

	const filter = (e) => {
	    const keyword = e.target.value;

	    if (keyword !== '') {

	      const results = oldRows.filter((org) => {
            if(org.Alias_Company_Name)
                return org.Alias_Company_Name?.toLowerCase().startsWith(keyword.toLowerCase()); 
	        return org.Account_Name?.toLowerCase().startsWith(keyword.toLowerCase());
	      });
	      setFoundRows(results);

	      setTotal(results.length);
	      if(results.length <= itemsPerPage)
	      	setDisplayed(results.length);
	      else
	      	setDisplayed(itemsPerPage);

	    } else {
	    	setTotal(oldRows.length);
	      	setFoundRows(oldRows);
	    }

	    setName(keyword);
	  };

    const handleLoginToOrganization = async (row) => {
        const { users } = row
        const payload = {
            username: users[0]?.username,
        }

        try {
            const response = await http.post('/user/login?skipLastLogin=true', payload)
            const responseData = response.data.data
            await dispatch(UserAction.toggleUser(true));
            await dispatch(UserAction.setUser({...responseData, user: responseData.userData }))

            history.push('/dashboard')
        } catch (error) {
            console.log('error?', error)
        }
    }

	return (
  <Box className="projects-container">
   <Breadcrumbs separator="|" aria-label="breadcrumb">
    <Link href="#" underline="hover" color="inherit">
     Organizations
    </Link>
   </Breadcrumbs>
   <Box>
    <Typography
     component="h1"
     fontSize="30px"
     fontWeight="700"
     marginBottom="30px"
    >
     Organizations
    </Typography>
   </Box>
   <Stack
    spacing={5}
    direction="row"
    className="filter-wrapper"
    marginBottom="20px"
    justifyContent="space-between"
    paddingRight="30px"
   >
    <Box className="flex" alignItems="center">
     <Select
      value={sortBy}
      onChange={handleSortBy}
      sx={{
       maxHeight: "40px",
       minHeight: "unset",
       background: "#fff",
       fontSize: "14px",
      }}
     >
      <MenuItem value="Name">Sort by Name</MenuItem>
      <MenuItem value="Program">Sort by Program</MenuItem>
      <MenuItem value="Login">Sort by Last Login</MenuItem>
     </Select>
    </Box>
    <Input
     type="search"
     placeholder="Client Search"
     value={name}
     onChange={filter}
     className="input search-list"
    />
   </Stack>
   {
    loading ? <Box><GenericLoader /></Box>
    : <Box>
    <TableContainer component={Paper}>
     <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead sx={{ backgroundColor: "#eaebeb" }}>
       <TableRow>
        <TableCell>Organization</TableCell>
        <TableCell>Programs</TableCell>
        <TableCell>Last Login Date</TableCell>
        {/*<TableCell align="right">Actions</TableCell>*/}
       </TableRow>
      </TableHead>
      <TableBody>
       {foundRows
        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
        .map((row, key) => (
         <TableRow
          key={row.username+'-'+key}
          sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
         >
          <TableCell component="th" scope="row">
           {row.Alias_Company_Name || row.Account_Name}
          </TableCell>
          <TableCell>{row.orgPrograms.length > 0 ? row.orgPrograms
                .filter(i => i?.orgs?.programId?.length > 0 && i?.orgs?.programId[0])
                .map(i => i?.orgs?.programId[0]?.Name).join(', ') : "Missing programs"}</TableCell>
          <TableCell>{row.lastLogin != null ? dayjs(row.lastLogin).format('MM/DD/YYYY') : 'Never'}</TableCell>
{/*          <TableCell align="right">
            {
                row.users?.length > 0 ? 
                <Link type="button" className="text-red-10 flex items-center justify-end cursor-pointer" onClick={()=>handleLoginToOrganization(row)}>
                 View Dashboard <i className='bx bx-chevron-right text-xl'/>
                </Link> : <p className="text-red-0">No users</p>
            }
          </TableCell>*/}
         </TableRow>
        ))}
      </TableBody>
     </Table>
    </TableContainer>
    <Grid container justifyContent="space-between" marginTop="30px">
     <Grid item>
      {displayed <= foundRows.length ? (
       <Typography>
        {displayed} out of {total}
       </Typography>
      ) : (
       <Typography>
        {foundRows.length} out of {total}
       </Typography>
      )}
     </Grid>
     <Grid item>
      <Pagination
       count={noOfPages}
       page={page}
       onChange={handleChange}
       defaultPage={1}
       color="primary"
       size="large"
       className="pagination"
      />
     </Grid>
    </Grid>
   </Box>
   }
  </Box>
 );

}

export default ClientsPage
