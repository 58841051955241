import { Link } from "react-router-dom"
import { WorkforceLogoWhite } from 'components/svg-components'


const DefaultNavbar = () => {

	return (
		<div id="default-navbar">
			<div className="default-navbar-content-wrapper pr-10">
				<div className="flex-1 flex flex-row items-center">
					<span className="svg-w-64">
						<WorkforceLogoWhite />
					</span>
				</div>
				<div className="flex flex-row items-center justify-end pr-10">
					<Link to={`/login`} className="nav-item">Login</Link>
					<span className="block lg:hidden xl:hidden 2xl:hidden">
						<i className='bx bx-menu text-white text-3xl'></i>
					</span>
				</div>
			</div>
			
		</div>
	)

}

export default DefaultNavbar