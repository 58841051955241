import React, { useState, useRef } from "react";
import { Typography, Divider } from "@mui/material";
import Legend from "components/legend/Legend";
import DownloadButton from "components/charts/DownloadButton"

  const mockData = [
    {
      label:
        "1. Lorem ipsum dolor sit amet, consectetur adipiscing elit faucibus nec donec facilisi",
      "Your Results": 100,
      "All your employees that made the list": 70,
    },
    {
      label:
        "2. Lorem ipsum dolor sit amet, consectetur adipiscing elit faucibus nec donec facilisi",
      "Your Results": 90,
      "All your employees that made the list": 70,
    },
    {
      label:
        "3. Lorem ipsum dolor sit amet, consectetur adipiscing elit faucibus nec donec facilisi",
      "Your Results": 90,
      "All your employees that made the list": 70,
    },
    {
      label:
        "4. Lorem ipsum dolor sit amet, consectetur adipiscing elit faucibus nec donec facilisi",
      "Your Results": 90,
      "All your employees that made the list": 70,
    },
  ];

const TableBarChart = ({ name = "Category A", data = mockData, label = "All winners" }) => {
  const ref = useRef()
  // const [state, setState ] = useState({
  // 	filters: [],
  // 	summary: null
  // })

  // const download = {
  // 	data: '',
  // 	filters: state.filters,
  // 	types: ['JPEG', 'PDF', 'SVG', 'XLS']
  // }


  const BarSet = ({ labelKey, color1, color2, value1Key, value2Key, obj }) => (
    <>
      <div className="flex justify-between py-6 w-auto">
        <p className="flex-1">{obj[labelKey]}</p>
        <div className="flex-1">
          <Typography
            className="h5 text-center text-white"
            sx={{
              backgroundColor: color1,
              width: `${obj[value1Key] || 0}%`,
            }}
          >
            {(obj[value1Key] || 0).toFixed()}%
          </Typography>
          <Typography
            className="h5 text-center text-white mt-2.5"
            sx={{
              backgroundColor: color2,
              width: `${obj[value2Key] || 0}%`,
            }}
          >
            {(obj[value2Key] || 0).toFixed()}%
          </Typography>
        </div>
      </div>
      <Divider />
    </>
  );
  const color1 = "#9C5EFB";
  const color2 = "#00A46A";
  const label1 = "Your Results";
  const label2 = "All your employees that made the list";

  return (
    <div className="bg-white px-5 py-5 relative xs:flex-wrap sm:flex-wrap">
      <div className="absolute right-5 z-10">
			  <DownloadButton targetRef={ref} name={name} />
		  </div>
      <div ref={ref} className="bg-white" >
      <p className="font-bold">{name}</p>
      <div className="flex flex-1 justify-end gap-3 mb-5 mt-5">
          <Legend text={label1} color={color1} />
          <Legend text={label} color={color2} />
        </div>
        <Divider />
        {data.map((item, k) => (
          <BarSet
            key={"barset-"+k}
            labelKey="label"
            color1={color1}
            color2={color2}
            value1Key={label1}
            value2Key={label2}
            obj={item}
          />
        ))}
        <div className="flex mt-4 text-gray-400 sm:flex-wrap xs:flex-wrap">
          <div className="flex-1" />
          <div className="flex flex-1 justify-between">
            {Array(11)
              .fill("")
              .map((_, i) => (
                <p key={`item-${i}`}>{i * 10}</p>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TableBarChart;
