import React from "react";
import RouteComponent from "router";
import { ThemeProvider, createTheme } from '@mui/material/styles';

import 'assets/scss/index.scss';
import 'boxicons';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

/** The value of the colors below must be manually synced up to the tailwind.config.js and on the sass variables. */
const theme = createTheme({
  palette: {
    'white': {
      main: '#FFFFFF',
      contrastText: '#000000'
    },
    'red-0': {
      main: '#F3403C',
      contrastText: '#FFFFFF'
    },
    'error': {
      main: '#F3403C',
      contrastText: '#FFFFFF'
    },
    'blue-0': {
      main: '#3F7CFB',
      contrastText: '#FFFFFF'
    },
    'green-0': {
      main: '#00A46A',
      contrastText: '#FFFFFF'
    },
    "light-0": {
      main: '#FFFFFF',
      contrastText: '#1D2124'
    },
    'gray-40': {
      main: '#1D2124',
      contrastText: '#1D2124'
    },
    'black': {
      main: '#000'
    },
    action: {
      disabled: "white", // set the disable foreground color
    }
  },
  shadows: ["none"],
  components: {
    MuiPaper: {
      defaultProps:{
        elevation: 0
      }
    }
  }
})

function App() {
  return (
    <React.Fragment>
      <ThemeProvider theme={ theme }>
        <RouteComponent />
      </ThemeProvider>
      <ToastContainer
        className="app-toast"
        position="top-center"
        hideProgressBar={true}
        draggable={false}
        closeOnClick={false}
        pauseOnFocusLoss={false}
      />
    </React.Fragment>
  );
}

export default App;
