const NotAuthorized = () => {

	return (
		<div>
			<h1>Not Authorized...</h1>
		</div>
	)

}

export default NotAuthorized