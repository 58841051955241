import React from "react";
import StackedBarModifiedChart from "components/charts/StackedBarModifiedChart";
import { Box, Typography } from "@mui/material"
import DownloadButton from "components/charts/DownloadButton"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    root:{
		background:'#fff',
		boxShadow: "0 0 2px rgb(0 0 0 / 70%)",
		padding: '25px 25px',
		borderRadius: '5px',
		marginTop: '25px',
    }
});
const sourceSample =  { label: 'Category A' }
const propsSample = {
	data: [{
		label: '4. Lorem ipsum dolor sit amet, consectetur adipiscing elit faucibus nec donec facilisi',
		'Your Results': 80, 
		'All your employees that made the list': 20
	},{
		label: '3. Lorem ipsum dolor sit amet, consectetur adipiscing elit faucibus nec donec facilisi here',
		'Your Results': 40, 
		'All your employees that made the list': 60
	},{
		label: '2. Lorem ipsum dolor sit amet',
		'Your Results': 90, 
		'All your employees that made the list': 10
	},{
		label: '1.Lorem ipsum dolor sit amet, consectetur adipiscing elit faucibus nec donec facilisi. Consectetur adipiscing elit faucibus nec donec facilisi',
		'Your Results': 34, 
		'All your employees that made the list': 66
	}],
	metaConfig: {
		fields: [{ key: 'Your Results', label: 'Your Results'}, { key: 'All your employees that made the list', label: 'All your employees that made the list'}],
		colors: ['#9C5EFB', '#00A46A']
	}
}
export default function GroupedBarGraphTable ({sourceData = sourceSample, data = propsSample,...props}) {
	const ref = React.useRef(null);
	const classes = useStyles();

    return (
        <div className="relative">
        	<div className="absolute top-5 right-5 z-10">
	            <DownloadButton targetRef={ref} name={ sourceData?.label } />
	        </div>
            <Box className={classes.root} ref={ref}>
				<div className="flex justify-between mb-5">
					<Typography className='font-semibold text-xl capitalize'>{ sourceData?.label }</Typography>
				</div>

				<ul className="flex justify-end mb-4">
					{
						data?.metaConfig?.fields.map((field, fieldIndex) => (
							<li className="pl-4 pr-4 text-xs flex items-center" key={`horizontal-chart-legend-${fieldIndex}`}>
								<p className="rounded-full h-1 w-1 mr-1" style={{ background: data.metaConfig.colors[fieldIndex] }}></p>  <p className="text-gray-30 capitalize">
									{ field.label }
								</p>
							</li>
						))
					}
				</ul>
				<div className="w-full">
					<StackedBarModifiedChart 
						data={data.data}
						metaConfig={data.metaConfig}
					/>
				</div>
			</Box>
        </div>
    )
}