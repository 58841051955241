import React, { useState } from "react";
import { Typography } from "@mui/material";
// import { Close, Edit, LockOutlined, LogoutOutlined } from '@mui/icons-material';
// // import EditPassword from "./components/edit-password";
// // import EditProfile from "./components/edit-profile";

const SettingPage = () => {
    return(
        <>
            <Typography
                component="h1"
                fontSize="30px"
                fontWeight="700"
                marginTop="30px"
                marginBottom="30px"
                >
                Settings
            </Typography>
        </>
    )
}

export default SettingPage;