const ULListSVG = () => {
	return (
		<svg className="stroke-current" viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M6 1H19" strokeLinecap="square"/>
			<path d="M6 7H19" strokeLinecap="square"/>
			<path d="M6 13H19" strokeLinecap="square"/>
			<path d="M1 1H1.01" strokeLinecap="square"/>
			<path d="M1 7H1.01" strokeLinecap="square"/>
			<path d="M1 13H1.01" strokeLinecap="square"/>
		</svg>
	)
}

export default ULListSVG