import React from 'react'

import Content from './content'

import BreadcrumbComponent from '../../../../../components/breadcrumb'

const DataPage = () => {

	const navs = [
        {label: 'Settings', path: '/admin/settings'},
    ]

	return (
		<div>
			<BreadcrumbComponent items={navs}/>

			<div className='py-8'>
				<h1 className='font-bold text-2xl'>Settings</h1>
			</div>

			<Content />
		</div>
	)

}

export default DataPage