import React, { useState, useEffect } from "react"
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import SaveIcon from '@mui/icons-material/Save'
import { Typography } from "@mui/material"
import UserAction from 'redux/modules/user/actions'
import GrayTextField from 'components/form-utilities/gray-text-field'
import { makeStyles } from "@material-ui/styles"
import { useHistory } from "react-router"
import { useLocation } from 'react-router-dom'

const useStyles = makeStyles({
	heading:{
		textAlign:'center',
		fontWeight:'700 !important',
		fontSize:'32px',
		color:'#fff',
		marginBottom:'10px',
	},
	p:{
		fontSize:'14px',
		color:'#fff',
		width:'350px',
		textAlign:'center',
        margin: '25px 0px'
	},
	btn:{
		borderColor:'#C6411F !important',
		color:"#fff !important",
		width:'100% !important',
		backgroundColor: '#F75626 !important',
		borderRadius: '0 !important',
		"&:hover":{
			backgroundColor: '#F75626 !important',
		}
	}
})
const PasswordReset = () => {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const { http } = global.services;
	const [key, setKey] = useState(history.location?.state?.key);
	const [otp, setOtp] = useState(history.location?.state?.code);
	const [emailSent, setEmailSent] = useState(false);
	const [successMsg, setSuccessMsg] = useState(null);
	const [ state, setState ] = useState({
		password:'',
		confirmPassword:'',
		error: null
	})

	useEffect(()=>{
        const url = new URL(window.location.href)
        const token = url.searchParams.get('token')
        if(token) {
        	setKey(token);
        	setEmailSent(true);
        }
	},[location])

	const handleSubmission = async(event = null) => {
		if(event) {
			event.preventDefault();
			if(state.password && state.confirmPassword) {
				if(state.password == state.confirmPassword){
					setState(prev => ({ ...prev, error: null }))
					const payload = {
						key:key,
						otp:otp,
						password:state.password
					}
					try {
						const response = await http.put(!emailSent ? '/user/forgot-password' : '/user/admin-reset-password-verify', payload)
						const responseData = response.data
						if(responseData) {
							setSuccessMsg(responseData.message)

							setTimeout(() => {
								history.push({
									pathname:'/admin-login',
								});
							}, 2000)
						}
					} catch (error) {
						const response = error.response;
						if(!response) {
							setState((prev)=>({...prev, error: 'System Error. Try again later'}))
							return;
						}
						if(response.status == 404 || response.status == 403 || response.status == 400){
							history.push({
								pathname: '/forgot-password',
								state: {
									error:response.data.message
								}
							})
						}
					}
				}else{
					setState(prev => ({ ...prev, error: 'password mismatch' }))
				}
			}else{
				setState(prev => ({ ...prev, error: 'password field should not be empty' }))
			}
		}
	}

	return (
		
		<div>
			{
				successMsg && (
					<div className="mb-10">
						<Alert severity="success">
							{ successMsg }
						</Alert>
					</div>
				)
			}
			{
				state.error && (
					<div className="mb-10">
						<Alert severity="error">
							{ state.error }
						</Alert>
					</div>
				)
			}
			<Typography variant="h4" className={classes.heading}>
				Reset Password
			</Typography>
			<p className={classes.p}>
				Set the new password for your account.
			</p>
			<form onSubmit={ (event) => handleSubmission(event) }>
				<div className="input-group mb-5">
					<Typography
						component="h4"
						fontSize="14px"
						marginBottom="10px"
						color="#fff"
				    >
						Password
					</Typography>
					<GrayTextField
						id="email-outlined-basic"
						className="w-full"
						placeholder="Enter new Password "
						type="password"
						onChange={ (event) => setState(prev => ({ ...prev, password: event.target.value, error: null })) }
						variant="outlined"
						autoComplete="off"
					/>
				</div>
                <div className="input-group mb-5">
                	<Typography
						component="h4"
						fontSize="14px"
						marginBottom="10px"
						color="#fff"
				    >
						Confirm Password
					</Typography>
					<GrayTextField
						id="email-outlined-basic"
						className="w-full"
						placeholder="Confirm Password "
						type="password"
						onChange={ (event) => setState(prev => ({ ...prev, confirmPassword: event.target.value, error: null })) }
						variant="outlined"
						autoComplete="off"
					/>
				</div>
				<div className="flex flex-row">
					<Button
						type="submit"
						className={classes.btn}
						variant="contained"
						size="large"
					>
						Reset Password
					</Button>
				</div>
			</form>
		</div>
	)

}

export default PasswordReset