import { useState, useEffect } from 'react';
import {Box, Button, Typography, IconButton, Modal, Select, MenuItem, Alert} from '@mui/material';
import { Close } from '@mui/icons-material';
import { DeleteSVG } from "components/svg-components";
import WhiteTextField from 'components/form-utilities/white-text-field'
import TextField from '@mui/material/TextField'

import UserAction from 'redux/modules/user/actions'
import { useDispatch, useSelector } from "react-redux"
import { makeStyles } from '@material-ui/core/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const useStyles = makeStyles({
	select: {
		maxHeight: "40px",
		minHeight: "unset",
	 	border: 0,
		borderRadius: 0,
		width: "100%",
		background: "#fff",
		fontSize: "14px",
		'& fieldset': {
			border:0
		}
	}
})

export default function EditProfile({form, setForm = ()=>{}, ...props}) {
	const { http } = global.services
	const classes = useStyles()
	const genUser = useSelector(({user}) => user.user)
	const { user } = genUser
	const dispatch = useDispatch()
	const [tosubmit, addSubmit] = useState({});

	const style = {
	  position: 'absolute',
	  top: '50%',
	  left: '50%',
	  transform: 'translate(-50%, -50%)',
	  width: 400,
	  bgcolor: '#f5f5f5',
	  boxShadow: 24,
	  p: 4,
	};
	const [ vars, setVars ] = useState({
		fullName: user?.fullName || "", //SHould be full name
		email: user?.email || "",
		mobile: user?.mobile || "",
	});
	const [errors, setErrors] = useState({
		fullName: null,
		email: null,
		mobile: null,
		response: null
	});
	const [success, setSuccess] = useState(false);

	const [fa, setFa] = useState(user?.mfa || 'email');

	useEffect(()=>{
		if(success)
			setTimeout(()=>setSuccess(false), 2000);
	}, [success]);

	useEffect(()=>{
		if(form){
			setVars({
				fullName: user?.fullName || "", //SHould be full name
				email: user?.email || "",
				mobile: user?.mobile || "",
			})
			setFa(user?.mfa || 'email')
			addSubmit({})
		}
	}, [form])
	
	const handleSubmit = async (e) => {
		setErrors({
			fullName: null,
			email: null,
			mobile: null,
			response: null
		})
		e.preventDefault();
		try {
			const response = await http.put('user/update/'+user._id, {...tosubmit})
			// console.log(user, { ...genuser, mfaPreference: fa, ...vars, firstName: vars.fullName })
			dispatch(UserAction.setUser({ ...genUser, user: {...user, mfa: fa, ...tosubmit }}))
			setSuccess(true);
			addSubmit({})
		} catch (e) {
			setErrors({...errors, response: "Something's wrong. Check again later"})
			return;
		}

	}

	const handleForm = ({target}) => {
		setVars({
			...vars,
			[target.name]: target.value
		})
		addSubmit({...tosubmit, [target.name]: target.value})
	}

	const handle2FAOption = ({ target })=>{
		setFa(target.value)
		addSubmit({...tosubmit, mfa: target.value})
	}

	return (<Modal
	  open={form}
	  onClose={()=>setForm(false)}
	  aria-labelledby="role-delete-title"
	  aria-describedby="role-delete-description"
	>
	  <form onSubmit={handleSubmit}>
	  <Box sx={style}>
		<Typography id="role-form"
			variant="h6"
			component="span"
			fontWeight="600"
			display="block"
			className="text-center mt-6"
		>
			<IconButton
		    aria-label="close"
		    onClick={()=>setForm(false)}
		    sx={{ position: 'absolute', right: 8, top: 8 }}
		  >
		    <Close />
		  </IconButton>
		Edit Profile Info
		</Typography>
		<Box>
		<WhiteTextField
			id="email-outlined-basic"
			className="w-full mt-5 white-field"
			variant="outlined"
			label="Role"
			value={ user?.roleId?.role || user?.role }
			name="role"
			disabled
			readonly
		/>
		<WhiteTextField
			error={errors.fullName}
			id="email-fullName"
			className="w-full my-5 white-field"
			variant="outlined"
			placeholder="Enter Full Name"
			value={ vars.fullName }
			name="fullName"
			onChange={ handleForm }
			autoComplete="off"
			required
		/>
		<WhiteTextField
			error={errors.email}
			id="email-email"
			className="w-full mb-5 white-field"
			variant="outlined"
			placeholder="Enter Email"
			value={ vars.email }
			name="email"
			onChange={ handleForm }
			autoComplete="off"
			required
		/>
		<WhiteTextField
			error={errors.mobile}
			id="email-mobile"
			className="w-full mb-5 white-field"
			variant="outlined"
			placeholder="Enter Phone"
			value={ vars.mobile }
			name="mobile"
			onChange={ handleForm }
			autoComplete="off"
			required
		/>
		<Select
		  value={fa}
		  onChange={handle2FAOption}
		  className={`${classes.select} mb-9`}
		  IconComponent={KeyboardArrowDownIcon}
		 >
		  <MenuItem value="email">Email 2FA</MenuItem>
		  <MenuItem value="mobile">Phone 2FA</MenuItem>
		</Select>
		{
			errors.response && <Box className="mb-2">
			<Alert variant="filled" severity="error">
				{ errors.response }
			</Alert>
			</Box>
		}
		{
			success && <Box className="mb-2">
			<Alert variant="filled" severity="success">
				Profile has been updated!
			</Alert>
			</Box>
		}
		</Box>
		<Box className="flex flex-col">
			<Button
				variant="contained"
				type="submit"
				color="error"
				className="normal-case rounded-none"
				sx={{ margin: "15px 0"}}
			>
				Save Changes
			</Button>
			<Button
				variant="text"
				onClick={()=>setForm(false)}
				className="normal-case rounded-none block text-center"
			>
				Cancel
			</Button>
		</Box>
	  </Box>
	  </form>
	</Modal>)
}
