import React from 'react'
import {
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
} from '@mui/material';

import CloseIcon from '@mui/icons-material/Close';

const FormDialog = ({ title, onClose, children }) => {

    return (
        <div className='bg-white'>
            <DialogActions>
                <Button onClick={onClose} color="inherit">
                    <CloseIcon />
                </Button>
            </DialogActions>

            <DialogTitle className="font-semibold text-center">{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
        </div>
    )
}

export default FormDialog;