const ArrowPrevSVG = () => {
	return (
		<svg
	    xmlns="http://www.w3.org/2000/svg"
	    width={6}
	    height={12}
	    fill="none"
	  >
	    <path d="M5.25 10.5.75 6l4.5-4.5" stroke="#25282B" strokeLinecap="square" />
	  </svg>
		)
}

export default ArrowPrevSVG