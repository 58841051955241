import { API_ENDPOINT } from "config/api";
import streamSaver from "streamsaver";
import { WritableStream, TransformStream } from "web-streams-polyfill/ponyfill";
import LocalStorageService from "services/localstorage";

export default function downloadFile(url, body, method = "post") {
  const getAccessToken = LocalStorageService.get("user");
  const data = JSON.parse(getAccessToken.value);
  return new Promise((resolve, reject) => {
    fetch(url, {
      method,
      headers: {
        Authorization: `Bearer ${data?.accessToken}`,
        'Content-Type': 'application/json',
      },
      body: body && JSON.stringify(body),
    })
      .then((response) => {
        const { body, headers, status } = response;
        const contentType = headers.get('content-type');

        if (status >= 400) {
          reject(new Error("Server error here"));
          return;
        } else resolve(response); //Do additional process here
        
        if(contentType && contentType.indexOf("application/json") !== -1) {
          return;
        }

        let filename = "file.xlsx";

        if ((filename = headers.get("content-disposition"))) {
          filename =
            filename.split("filename=")[1].replaceAll('"', "") || "file.xlsx";
        }

        const fileStream = streamSaver.createWriteStream(filename);
        const writer = fileStream.getWriter();
        if (window.WritableStream != null) {
          writer.releaseLock();
          return body.pipeTo(fileStream);
        }
        const reader = body.getReader();
        const pump = () =>
          reader.read().then(({ done, value }) => {
            return done ? writer.close() : writer.write(value).then(pump);
          });
        return pump();
      })
      .catch((err) => {
		reject(err);
        return;
      });
  });
}
