import HttpService from 'services/http';
import store from 'redux/index'


const upload = (file, onUploadProgress, url = "/upload", otherParams = {}, fileAttr = 'file') => {
  let formData = new FormData();
  const { user } = store.getState();
  const { admin } = user
  
  formData.append(fileAttr, file);
  
  Object.keys(otherParams).forEach(x => {
    formData.append(x, otherParams[x] || null);
  });

  return HttpService.post(url, formData, {
    headers: {
      "Content-type": "application/json",
      "Content-Type": "multipart/form-data",
      "Authorization": `Bearer ${admin.accessToken}`
    },
    onUploadProgress
  });
};

const getFiles = (url = "/files") => {
  return HttpService.get(url);
};

const FileUploadService = {
  upload,
  getFiles,
};

export default FileUploadService;