import DefaultImg from 'assets/images/two-column-layout-default-img.png'
import { WorkforceLogoWhite } from 'components/svg-components'

const SingleColumnLayout = ({
	image = DefaultImg,
	children
}) => {

	return (
		<div className="flex flex-row items-center h-screen">
			<div className="w-full h-screen px-10 bg-gray-0">
				<div style={{ height: '10vh' }} className="flex flex-row items-center">
					<span className="svg-w-64">
						<WorkforceLogoWhite />
					</span>
				</div>
				<div className="flex flex-row items-center justify-center" style={{ height: '90vh' }}>
					{ children }
				</div>
			</div>
		</div>
	)

}

export default SingleColumnLayout