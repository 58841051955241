import React from "react"
import ReportStoreItemList from './components/reports-store-list'

const ReportsStore = () => {
	return (
		<React.Fragment>
			<ReportStoreItemList />
		</React.Fragment>
	)

}

export default ReportsStore