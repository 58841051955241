import React, { useCallback, useRef } from 'react';
import { styled } from '@mui/material/styles';
import RadialBarChart from "components/charts/RadialBarChart"
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { Button, Typography } from '@mui/material';
import DownloadIcon from 'assets/images/icons/download.png';
import ProgressBar from 'components/charts/ProgressBar';
import { progressBarData } from "components/charts/mock-data/charts-data";
import { makeStyles } from '@material-ui/core/styles';
import { toPng } from 'html-to-image'

const useStyles = makeStyles({
	paper:{
		backgroundColor:'#fff',
		borderRadius: '4px',
		boxShadow: 'none'
	},
	info_section:{
		color: 'black',
		marginTop: 2
	}

});

const Item = styled(Paper)(({ theme }) => ({
	...theme.typography.body2,
	padding: theme.spacing(1),
	textAlign: 'center',
	color: theme.palette.text.secondary,
}));

const EmployeeResponseSummary = ({ summaryData = null }) => {
    const radChart = useRef(null)
    const onDownload = useCallback(() => {
        if (radChart.current === null) {
          return
        }
        toPng(radChart.current, { cacheBust: true, })
        .then((dataUrl) => {
          const link = document.createElement('a')
          link.download = 'Employee Survey Response Information.png'
          link.href = dataUrl
          link.click()
        })
        .catch((err) => {
          console.log(err)
        })
    }, [radChart]);
	const classes = useStyles();
	//  this pie chart needs to reflect total # responses / total # employees, not what they agreed or did not agree
	return (
  <React.Fragment>
   <div className="flex flex-row space-x-5">
    <div className="flex-1">
     <div className="bg-white">
      <div className="p-6">
       <div className="flex flex-row items-center justify-between">
        <p className="text-md">Employee Survey Response Information</p>
        <button className="" onClick={onDownload}>
         <img src={DownloadIcon} alt="..." />
        </button>
       </div>

       <div className="flex flex-row mt-1" ref={radChart}>
        <div className="flex-1 flex flex-col justify-center space-y-2 pb-2">
         {summaryData?.radialChartData?.data.map((legend, index) => (
          <div
           className="flex flex-row items-center space-x-5"
           key={`radial-chart-legend-${index}`}
          >
           <div
            className="h-3 w-3 rounded-full"
            style={{
             backgroundColor:
              summaryData?.radialChartData.metaConfig.colors[index],
            }}
           />{" "}
           <span className="text-sm text-gray-30">{legend.label}</span>
          </div>
         ))}
        </div>
        <div style={{ height: "34vh", width: "65%" }}>
         <RadialBarChart
          data={summaryData?.radialChartData?.data}
          metaConfig={summaryData?.radialChartData?.metaConfig}
         />
        </div>
       </div>

       <div className="mt-0 text-gray-30 text-xs">
        <p>Survey Period: 01.07.2021 - 02.07.2021</p>
       </div>
      </div>
     </div>
    </div>
    <div className="flex-1 flex flex-col space-y-5">
     <div className="flex flex-row items-center space-x-5">
      <div className="bg-white border-l-4 border-blue-0 flex-1 pb-6 h-full">
       <div className="text-right pt-1 px-1">
        <i className="bx bx-dots-vertical-rounded text-lg"></i>
       </div>
       <div className="px-6">
        <div className="flex flex-row items-end space-x-2">
         <p className="text-sm">
          Total Number of Employees Nationally
         </p>
        </div>
       </div>

       <div className="mt-3 px-6">
        <p className="text-3xl font-medium">{summaryData?.nationalityCount}</p>
       </div>
      </div>
      <div className="bg-white border-l-4 border-red-0 flex-1 pb-6 h-full">
       <div className="text-right pt-1 px-1">
        <i className="bx bx-dots-vertical-rounded text-lg"></i>
       </div>
       <div className="px-6">
        <div className="flex flex-row items-end space-x-2">
         <p className="text-sm">Total Number of Employees Eligible to be Surveyed</p>
        </div>
       </div>

       <div className="mt-3 px-6">
        <p className="text-3xl text-black-0 font-medium">
         {summaryData?.eligibilityCount}%
        </p>
       </div>
      </div>
     </div>

     <div className="flex-1">
      <div className="bg-white border-l-4 border-green-0 pb-6">
       <div className="text-right pt-1 px-1">
        <i className="bx bx-dots-vertical-rounded text-lg"></i>
       </div>
       <div className="px-6 pb-5">
        <div className="flex flex-row items-end space-x-2">
         <p className="text-md">Response Rate</p>
        </div>
       </div>

       <div className="mt-3 px-6 flex flex-row items-center">
        <div className="">
         <p className="text-gray-30 mb-2 text-sm">
          Total Surveyed: {summaryData?.totalSurveyed}
         </p>
         <p className="text-gray-30 text-sm">
          Total Respondents: {summaryData?.totalRespondents}
         </p>
        </div>
        <div className="flex-1 flex flex-row justify-end items-end">
         <p className="text-5xl font-medium text-black-0">
          {summaryData?.responseRatePercentage}%
         </p>
        </div>
       </div>
      </div>
     </div>
    </div>
   </div>
  </React.Fragment>
 );

}

export default EmployeeResponseSummary