import {
  SET_ALERT,
	SET_PAGE_LOADER,
  SET_CURRENT_ROUTE,
  SET_SIDE_MENU_DRAWER_SHOW,
  SET_CURRENT_PAGE_MOUNTED,
  IMPORT_ORG_PROGRAMS,
  SELECT_PROGRAM_ID,
  SET_HEADER_YEAR,
  SET_TIME_ESTIMATE,
  VIEW_ORG_DASHBOARD,
  USE_CLEAR_CACHE,
  TOGGLE_FDD_MODAL,
} from './types';

const setPageLoader = (params) => {
	return {
    type: SET_PAGE_LOADER,
    payload: params,
  };
}

const selectProgram = (payload) => ({ type: SELECT_PROGRAM_ID, payload })

const setOrgPrograms = (params) => {
  return {
    type: IMPORT_ORG_PROGRAMS,
    payload: params,
  };
}

const toggleFDDModal = (payload = false) => {
  return {
    type: TOGGLE_FDD_MODAL,
    payload
  }
}

const updateHeaderYear = (payload) => {
  return {
    type: SET_HEADER_YEAR,
    payload
  }
}

const setCurrentRoute = (params) => {
  return {
    type: SET_CURRENT_ROUTE,
    payload: params
  }
}

const setSideMenuDrawerShow = (params) => {
  return {
    type: SET_SIDE_MENU_DRAWER_SHOW,
    payload: params
  }
}

const setCurrentPageMounted = (params) => {
  return {
    type: SET_CURRENT_PAGE_MOUNTED,
    payload: params
  }
}

const viewOrgDashboard = (organization = null, program = null) => {
  return {
    type: VIEW_ORG_DASHBOARD,
    payload: {
      organization,
      program
    }
  }
}

const setAlert = (alert = null, type="info") => {
  return {
    type: SET_ALERT,
    payload: { alert, type }
  }
}

const setTimeEstimate = (minutes = 1) => {
  return {
    type: SET_TIME_ESTIMATE,
    payload: minutes
  }
}

const setClearCache = (clearcache = false) => {
  return {
    type: USE_CLEAR_CACHE,
    payload: clearcache
  }
}

const actions = {
  setAlert,
  setTimeEstimate,
  selectProgram,
	setPageLoader,
  setCurrentRoute,
  updateHeaderYear,
  setSideMenuDrawerShow,
  setCurrentPageMounted,
  viewOrgDashboard,
  setClearCache,
  toggleFDDModal
}

export default actions
