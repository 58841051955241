import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import Stack from '@mui/material/Stack';
import { Link } from 'react-router-dom';

const FDDModal = (props) => {
  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    bgcolor: '#f5f5f5',
    boxShadow: 24,
    p: 4,
  };
  
  return (
    <Modal
      {...props}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Typography variant="h6" component="h2" fontWeight="600" marginBottom="30px" textAlign="center">
          The results are in!
        </Typography>
        <Typography component="p" textAlign="center" marginBottom="30px">
          Find out what your employees had to say. <br /> <br /> <Link className="text-red-10" to="/reports-store">Click here</Link> to discover the various reporting options we offer!
        </Typography>
      </Box>
    </Modal>
  )
}

export default FDDModal
