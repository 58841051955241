import React, { memo } from 'react'
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function SectionLoading(props) {
    return (
        <div>
            <div className={`section-loading-container d-flex justify-content-center pt-3 `}>
                <Box sx={{ display: 'flex' }}>
                    <CircularProgress />
                </Box>
            </div>
        </div>
    )
}

export default memo(SectionLoading)