import { useHistory } from 'react-router'
import { Button, Link as DomLink, Typography } from '@mui/material'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { addToCart } from 'redux/modules/cart/actions';
import IMG from 'assets/images/icons/Icon11.png';

export default function ReportResync ({ classGrid }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(({user})=> user.user)
  const { items } = useSelector(({cart}) => cart);
  const onCart = items.find(i => i.forPayment === 'FDD');
  
  const generateSubject = () => {
    const mainUser = user.user;

    var append = '';
    if(mainUser.CompanyName)
      append += mainUser?.CompanyName || '';
    if(mainUser.programs)
      append += (append != '' ? ', ': '') + mainUser.programs.map(i=>i.Name).join(', ');

    return append != '' ? append : mainUser.username;
  }

  return (<div className={`w-full h-full ${classGrid}`}>
    <div className='h-full bg-white p-5 flex flex-col'>
      <div className='flex justify-between'>
        <img src={IMG} className="w-12 h-12 rounded-full mb-2"/>
      </div>
      <h2 className='text-md text-black-0 font-bold line-clamp-2 mt-4 mb-3'>Re-Sorted Workforce Feedback Report</h2>
      <p className='mb-2 text-gray-30 text-sm'>
        Uh oh, you’ve realized that the demographic breakouts of your employees’ responses don’t represent your unique workplace structure, or maybe you need to combine smaller groups of respondents in order to review their feedback. Not to fear! A Survey Professional can help you to re-sort the data to obtain a more meaningful and actionable report. For more information about re-sorting your data, please contact:
      </p> 

      <DomLink 
        className="text-red-10 block"
        href={`mailto:SurveyPro@WorkforceRG.com?subject=Resort Workforce Feedback Report: ${generateSubject()}`}
      >
      SurveyPro@workforcerg.com
      </DomLink>
      <p className="my-5">Pricing: <span className="text-red-10 mr-3">VARIES</span></p>
    </div>
  </div>)
}
