const PageInitialization = () => {

	return (
		<div id="page-initialization">
			<h1>Page is currently loading...</h1>
		</div>
	)

}

export default PageInitialization