import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import ReportsHeader from "../components/reports-header";
import TableComponent from "./components/table";
import Alert from "@mui/material/Alert";
import Skeleton from "@mui/material/Skeleton";
import SectionLoading from "components/loaders/SectionLoading";
import downloadFile from "helpers/downloadFile";
import { API_ENDPOINT } from "config/api";

const BenefitsBestPractices = () => {
  const { http } = global.services;
  const [filters, setFilters] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [dataError, setDataError] = useState("");
  const [tableHeaders, setTableHeaders] = useState([]);
  const [tableData, setTableData] = useState([]);
  const {user, admin} = useSelector(({user}) => user);
  const { selectedProgramId, clearcache } = useSelector(({ app }) => app);
  const { organizationProgram } = user.userData ? user.userData : user.user;
  const selectOrgProgram =
    organizationProgram.find((i) => i.programId?._id == selectedProgramId) ||
    organizationProgram[0];
  const programId = selectedProgramId || selectOrgProgram?.programId?._id;

  const initialization = async () => {
    const query = { selectedProgramId: programId };
    if (clearcache) {
      query.clearCache = clearcache;
    }
    if ((selectOrgProgram["BBP_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) query.isDummy = true;

    setIsLoading(true);
    let response;
    try {
      response = await http.get("client/employerBenchmarkReport", query);
      if (response.status !== 200 || !response.data?.success) {
        throw (
          response.data ||
          new Error(
            `Failed with status: ${response.status} ${response.statusText}`
          )
        );
      }
    } catch (err) {
      console.log(err);
      setDataError(
        "An error occured while loading content. Please refresh the page and try again."
      );
      return;
    } finally {
      setIsLoading(false);
    }
    let { tableHeaders, tableData } = response.data.data;
    tableData = transformMainRow(tableData);
    console.log("BenifitsData response: ", response.data.data);
    // console.log("BenifitsData transformed: ", tData);
    setTableHeaders(tableHeaders);
    setTableData(tableData);
  };

  // const nestedDataFetcher = async (row) => {
  //   const query = { selectedProgramId: programId };
  //   const body = { questionId: row.id };
  //   let response;
  //   try {
  //     response = await http.post(
  //       "client/employerBenchmarkQuestionDescribe",
  //       body,
  //       {
  //         params: query,
  //       }
  //     );
  //     if (response.status !== 200 || !response.data?.success) {
  //       throw (
  //         response.data ||
  //         new Error(
  //           `Failed with status: ${response.status} ${response.statusText}`
  //         )
  //       );
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     throw err; // will be catch inside table
  //   }
  //   const tData = transformNestedRow(row.type, response.data.data.orgData);
  //   console.log("BenifitsData nested response: ", response.data.data.orgData);
  //   // console.log("BenifitsData nested transformed: ", tData);
  //   return tData;
  // };

  const transformMainRow = (data) => {
    return data
      .flatMap((chunk) => chunk.nestedData)
      .map((row) => {
        if (row.nestedData) {
          row.nestedData.forEach((nestedRow) => {
            if (nestedRow.dataValues) {
              nestedRow.dataValues = nestedRow.dataValues.map((dataValue) =>
                formatCellValue(nestedRow.type, dataValue)
              );
            } else {
              nestedRow.nestedData.forEach((deepNestedRow) => {
                deepNestedRow.dataValues = deepNestedRow.dataValues.map(
                  (dataValue) => formatCellValue(deepNestedRow.type, dataValue)
                );
              });
            }
          });
        } else {
          row.dataValues = row.dataValues.map((dataValue) =>
            formatCellValue(row.type, dataValue)
          );
        }
        return row;
      });
  };

  // const transformNestedRow = (type, data) => {
  //   const qMap = new Map();

  //   data.forEach((item) => {
  //     item.resData.forEach(({ ResponseCaption, count }) => {
  //       let resMap = qMap.get(ResponseCaption);

  //       if (!resMap) {
  //         resMap = new Map();
  //         qMap.set(ResponseCaption, resMap);
  //       }
  //       resMap.set(item.orgCat, count);
  //     });
  //   });

  //   return [...qMap].map(([title, resMap]) => {
  //     return {
  //       title,
  //       dataValues: headers.map((h) => formatCellValue(type, resMap.get(h.type))),
  //     };
  //   });
  // };
  const formatCellValue = (type, cellValue) => {
    // const cellValue = Math.round((val || 0) * 100) / 100;
    if (typeof cellValue === "string") {
      return cellValue;
    }
    if (cellValue === null) {
      return "";
    }
    switch (type) {
      case "%": {
        // Percentage
        return `${Math.round(cellValue)}%`;
      }

      case "number":
      case "exception":
      default: {
        // Number
        return cellValue;
      }
    }
  };

  const downloadFullReport = () => {
    let query = {
      clearCache: clearcache,
      selectedProgramId: programId,
    };

    if ((selectOrgProgram["BBP_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) query.isDummy = true;

    const queryString = new URLSearchParams(query).toString();

    setIsDownloading(true);
    downloadFile(
      `${API_ENDPOINT}/client/employerBenchmarkReportExcel?${queryString}`,
      undefined,
      "get"
    ).then((response)=>{
      const { headers } = response;
      const contentType = headers.get('content-type');
      if(contentType && contentType.indexOf("application/json") !== -1) {
        return response.json().then(({data}) => {
          const { signedUrl } = data.data
          const { pathname } = new URL(signedUrl);
          const dlFile = document.createElement("a");
          dlFile.download = pathname.replace('/', '');
          dlFile.href = signedUrl;
          dlFile.target = "_blank";
          dlFile.click();
        })
      }
    })
      .catch((err) => {
        console.log(err);
        return;
      })
      .finally(() => setIsDownloading(false));
  };

  useEffect(() => {
    initialization();
  }, [selectedProgramId]);

  const download = {
    data: "",
    filters: filters,
    types: ["XLS", "PDF", "JPEG", "SVG"],
  };

  const downloadConfigs = [
    {
      action: downloadFullReport,
    },
  ];

  return (
    <>
      <ReportsHeader
        download={download}
        pageName="Benefits & Best Practices"
        accessGroup="BBP_Access"
        downloadConfigs={downloadConfigs}
      />
      {isDownloading && <SectionLoading />}
      {isLoading ? (
        <>
          <Skeleton
            animation="wave"
            height={100}
            style={{ marginBottom: "-2rem" }}
          />
          <Skeleton
            animation="wave"
            height={100}
            style={{ marginBottom: "-2rem" }}
          />
          <Skeleton
            animation="wave"
            height={100}
            style={{ marginBottom: "-2rem" }}
          />
          <Skeleton
            animation="wave"
            height={100}
            style={{ marginBottom: "-2rem" }}
          />
          <Skeleton
            animation="wave"
            height={100}
            style={{ marginBottom: "-2rem" }}
          />
        </>
      ) : dataError ? (
        <div className="mt-5">
          <Alert variant="filled" severity="error">
            {dataError}
          </Alert>
        </div>
      ) : (
        <>
          <TableComponent
            title=""
            headers={tableHeaders}
            rows={tableData}
            // nestedDataFetcher={nestedDataFetcher}
          />
          <div className="bg-white p-2.5 text-sm">
            <p>x – Insufficient data to provide meaningful feedback.</p>
          </div>
        </>
      )}
    </>
  );
};

// const mockData = [
//   {
//     title: "Lorem ipsum dolor sit amet, consectetur adipiscing elit?",
//     dataValues: [10.5, 12.7, 10.5, 6.5, 8, 14, 22, 13.7],
//   },
//   {
//     title: "Lorem ipsum dolor sit amet, consectetur?",
//     dataValues: ["45%", "45%", "1%", "6%", "45%", "7%", "8%", "45%"],
//   },
//   {
//     title:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus?",
//     fetcher: async () => [
//       {
//         title: "Ornare ac nisi, dictum",
//         dataValues: ["78%", "78%", "5%", "55%", "23%", "44%", "14%", "76%"],
//       },
//       {
//         title: "Magna vulputate vel",
//         dataValues: ["6%", "6%", "25%", "15%", "89%", "12%", "56%", "34%"],
//       },
//     ],
//   },
//   {
//     title:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit?",
//     fetcher: async () => [
//       {
//         title: "Ornare ac nisi, dictum",
//         dataValues: ["78%", "78%", "5%", "55%", "23%", "44%", "14%", "76%"],
//       },
//       {
//         title: "Magna vulputate vel",
//         dataValues: ["6%", "6%", "25%", "15%", "89%", "12%", "56%", "34%"],
//       },
//     ],
//   },
//   {
//     title:
//       "Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus venenatis, lectus magna fringilla urna, porttitor?",
//     fetcher: async () => [
//       {
//         title: "Ornare ac nisi, dictum",
//         dataValues: ["78%", "78%", "5%", "55%", "23%", "44%", "14%", "76%"],
//       },
//       {
//         title: "Magna vulputate vel",
//         dataValues: ["6%", "6%", "25%", "15%", "89%", "12%", "56%", "34%"],
//       },
//     ],
//   },
// ];

export default BenefitsBestPractices;
