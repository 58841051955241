import { useHistory } from 'react-router'
import { Button, Link as DomLink, Typography,
  MenuItem, 
  Popper, 
  Grow, 
  Paper, 
  ClickAwayListener, 
  MenuList } from '@mui/material'
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux'
import { addToCart, addFilter } from 'redux/modules/cart/actions';
import { useState, useEffect, useMemo } from 'react';
import AppAction from 'redux/modules/app/actions';
import ReportStoreImg2 from 'assets/images/icons/Icon2.png';
import { makeStyles } from '@material-ui/core/styles';
import { currentCurrency } from 'helpers/checkCurrency';

const useStyles = makeStyles({
  root: {
    background: "#B483FF",
    boxShadow: "0 0 0 rgb(0 0 0 / 70%)",
  }
})

const MenuFilter = ({ item, onClick }) => {
  const handleClick = (e) => onClick(item)
  return <MenuItem
    className='text-sm text-white'
    onClick={handleClick}
  >
    <span className="pr-10">{item?.filterLabel}</span>
  </MenuItem>
}

export default function ReportSortedVerbatim ({ classGrid }) {
  const { http } = global.services
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { items, filters } = useSelector(({cart}) => cart);
  const { user } = useSelector(({user}) => user)
  const {selectedProgramId, clearcache} = useSelector(({app})=> app)
  const [selected, setSelected] = useState(null)
  const [soldFilters, setFilters] = useState([]);
  const [showMenu, setShowMenu] = useState(false)
  const [state, setState] = useState({
    menuElement: null
  })

  const { organizationProgram } = (user?.userData) ? user?.userData : user?.user;
  const selectOrgProgram = organizationProgram?.find(i => i.programId?._id == selectedProgramId) || organizationProgram[0];
  const { EV_Sorting_Fee } = selectOrgProgram?.programId || null;
  const EVFilter = selectOrgProgram?.EV_Sorting_Filter || null;
  const EVType = selectOrgProgram?.EV_Sorting_Payment_Type || null;

  const canBuy = useMemo(() => {
    const hasPaidFDD = selectOrgProgram?.FDD_Payment_Details !== null;
    const hasReport = ['WFR_Access', 'EV_Access', 'WBC_Access', 'BBP_Access'].find(i => (selectOrgProgram[i] || '').trim().toLowerCase() === 'yes');
    const onCart = items.find(i => i.forPayment === 'FDD');
    return hasPaidFDD || hasReport || onCart;
  }, [items, selectedProgramId])

  useEffect(()=>{
    loadItems();
  }, [])

  const handleClick = (item) => {
    setShowMenu(false)
    setSelected(item)
  }

  const loadItems = async () => {
    let query = {};
    if(organizationProgram) query.selectedProgramId = selectedProgramId || selectOrgProgram?.programId?._id;
    if(clearcache) query.clearCache = clearcache
    //if((selectOrgProgram["EV_Access"] || '').trim().toLowerCase() !== 'yes') query.isDummy = true;
    const { data } = await http.get('client/fetchSurveyFilter', {...query})
    setFilters(data?.data?.map((i) => ({ id: i.QuestionId, ...i })));
  }

  const handleAddToCart = () => {
    if(!canBuy) {
      dispatch( AppAction.setAlert(`You need to include Feedback Data Dashboard Package to checkout!`) );
      return;
    }

    if(selected && selectOrgProgram) {
      dispatch( addFilter({ ...selected, 
        title: "EV Filter Purchase",
        amount: EV_Sorting_Fee || 150,
        forPayment: "EV_Sorting", 
        keys: {
          EV_Access: selectOrgProgram?.EV_Access,
          EV_Sorting_Filter: selected.filterLabel,
          EV_Sorting_Payment_Details: selectOrgProgram?.EV_Sorting_Payment_Details || new Date(), 
          EV_Sorting_Payment_Type: null
        }
      }) )
      dispatch( AppAction.setAlert(`Filter added to cart!`) )
    }
  }

  if(EVType !== null || EVFilter !== null || EV_Sorting_Fee <= 0)
    return '';

  return (<div className={`w-full h-full ${classGrid}`}>
    <div className='h-full bg-white p-5 flex flex-col'>
      <div className='flex justify-between'>
        <img src={ReportStoreImg2} className="w-12 h-12 rounded-full mb-2"/>
        <div className="flex items-center">
          <Typography variant="h6">Price: <span className="text-red-10 mr-3">{currentCurrency()} {EV_Sorting_Fee}</span></Typography>
          {/* <Typography variant="h6">Price: <span className="text-red-10 mr-3">$ {EV_Sorting_Fee}</span></Typography> */}
          <Button
            variant="contained"
            type="submit"
            color="error"
            className="normal-case rounded-none px-5"
            onClick={handleAddToCart}
          >
          Add to Cart
          </Button>
        </div>
      </div>
      <h2 className='text-md text-black-0 font-bold line-clamp-2 mt-4 mb-3'>Sorted Employee Verbatims</h2>
      <p className='mb-2 text-gray-30 text-sm'>
        Sorting the employees’ open-ended responses by a demographic will allow you to better identify where the comments originated.
      </p> 
      <div className='flex flex-row flex-wrap items-center space-x-4 my-4'>
        <div
          className={`relative filter-dropdown ${showMenu ? 'border border-black-0' : ''}`}
          onClick={(event) => {
            if (!showMenu) {
              setState(prevState => ({ ...prevState, menuElement: event.currentTarget }))
              setShowMenu(true)
            }
          }}
          style={{minWidth: 200}}
        >
          <div
            className={`flex flex-row justify-between items-center bg-purple-400 px-5 py-2 space-x-7 cursor-pointer`}
          >
            <span className='text-white text-sm'>{selected?.filterLabel || `Select Filters`}</span>
            <i className='text-white bx bx-chevron-down'></i>
          </div>
          <Popper
            open={showMenu}
            anchorEl={state.menuElement}
            transition
            disablePortal
            style={{ position: 'absolute', zIndex: 100, transform: 'translate(0px, 45px)', width: '100%' }}
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: 'left'
                }}
              >
                <Paper variant="outlined" style={{ borderRadius: 0, width: 'fit-content' }}>
                  <ClickAwayListener onClickAway={() => setShowMenu(false)}>
                    <MenuList id="split-button-menu" className={`${classes.root}`}>
                      {
                        soldFilters.length > 0 && 
                          soldFilters
                          .filter(f => !filters.find(o => o.QuestionId == f.QuestionId )) //Check if it's already on cart
                          .map(i => <MenuFilter key={i.QuestionId} item={i} onClick={handleClick} />)
                      }
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        </div>
      <span className='text-sm text-gray-30 font-italic my-2'>Note: Upon successful processing of a credit card payment, you’ll receive instant access to your employees’ sorted verbatims.</span>
    </div>
  </div>)
}
