import React, { useState, useEffect } from 'react'
import Button from '@mui/material/Button'
import { useSelector, useDispatch } from 'react-redux'
import LinkButton from '@mui/material/Link'
import Badge from '@mui/material/Badge'

import AppActions from "redux/modules/app/actions"
import { WorkforceLogo } from 'components/svg-components/index.js'
import { toggleCart } from 'redux/modules/cart/actions';

import YearDropdown from './header/year-dropdown'
import NavbarUserMenu from './header/user-menu'
import { Link } from 'react-router-dom'

import HelpIcon from '@mui/icons-material/Help';
import TutorialVid from './tutorial-vid'
const DashboardHeader = () => {
	const dispatch = useDispatch()
	const user = useSelector(({user})=> user.user);
	console.log("=================header===================")
	const selectedProgramId = useSelector(({app})=> app.selectedProgramId)
	const programs = user.userData?.organizationProgram || [];
	const { items, filters, resync } = useSelector(({cart}) => cart) 
	const [showTutorial, setShowTutorial] = useState(false);

	useEffect(() => {
		window.addEventListener('resize', () => {
			const width = window.innerWidth
			if (width > 1439) {
				dispatch(AppActions.setSideMenuDrawerShow(false))
			}
		});

		return () => {
			window.removeEventListener('resize', () => {})
		}
	}, [])


	// const handleChangeYear = (year) => dispatch( AppActions.updateHeaderYear({ year }) )
	const handleChangeProgram = (program) => dispatch( AppActions.selectProgram(program.programId?._id) );

	const generateSubject = () => {
		const mainUser = user.user; 

		var append = '';
		if(mainUser.CompanyName)
			append += mainUser?.CompanyName || '';
		if(mainUser.programs)
			append += (append != '' ? ', ': '') + mainUser.programs.map(i=>i.Name).join(', ');

		return append != '' ? append : mainUser.username;
	}

	const openCart = () => dispatch(toggleCart(true))

	//Idk if this is okay
	let dealId = user?.userData?.dealId || null;
	let defaultProgramId = null; 
    let organizationprogramId = user?.userData?.organizationprogramId || null;
	if(organizationprogramId || dealId){
		defaultProgramId = programs.find(item => item._id === organizationprogramId);
		if(!defaultProgramId?.programId?._id){
			defaultProgramId = programs.find(item => item?.DealId === dealId);
		}
	}
	const programSelected = !selectedProgramId ? (defaultProgramId || programs[0] || {}) : programs.find(i => i.programId?._id == selectedProgramId);
	const programName = programSelected?.programId?.Project?.name || 'Missing Program';
	const year = (programSelected?.programId?.Name || 'No_Program 2000').toString().match(/\d{4}/g);
	const badgeContent = items.length + filters.length + (resync ? 1 : 0);
	// console.log(programSelected);
	// console.log(selectedProgramId);
	
	return (
		<React.Fragment>
			<div id="content-layout-header" className="content-horizontal-padding">

				<div className="flex-1 flex md:hidden lg:hidden xl:hidden 2xl:hidden flex-row items-center">		
					<span className="svg-w-32">
						<WorkforceLogo />
					</span>
				</div>

				<div className="flex-1 hidden md:flex lg:flex xl:flex 2xl:flex flex-row items-center space-x-10 text-sm">
					<p>{ programName }</p>

					<YearDropdown
						organizationProgram={programs}
						year={ year?.pop() }
						onChange={ handleChangeProgram }
					/>

				</div>
				<div className="flex-1 flex flex-row items-center justify-end space-x-10">
					{
						user.salesUser?.email && <LinkButton className="hidden md:block lg:block xl:block 2x:block cursor-pointer text-sm" href={`mailto:${user.salesUser?.email}?subject=Support: ${generateSubject()}`}>Contact Us</LinkButton>
					}
					
					<Button
						variant="text"
						sx={{
						color: "#000",
						borderColor: "#d3d3d3",
						fontSize: "12px",
						borderRadius: "0 !important", 
						}}
						onClick={()=>setShowTutorial(true)}
						className='hidden md:block lg:block xl:block 2x:block'> 
						<HelpIcon/>
						<LinkButton className='text-sm capitalize'>Dashboard Tutorial</LinkButton> 
					</Button> 

					 <Badge badgeContent={badgeContent} color="primary">
  						<LinkButton className="hidden md:block lg:block xl:block 2x:block cursor-pointer text-sm" onClick={openCart}>
 							Cart
 						</LinkButton>
					</Badge> 

					<div className="hidden md:block lg:block xl:block 2x:block">
						<NavbarUserMenu />
					</div>

					<div className="lg:hidden xl:hidden 2x:hidden" style={{ marginLeft: '0px' }}>
						<Button
							variant="text"
							onClick={() => {
								dispatch(AppActions.setSideMenuDrawerShow(true))
							}}
						>
							<i className='bx bx-menu text-3xl cursor-pointer'></i>
						</Button>
					</div>
				</div>

			</div>
			{
				showTutorial && 
				<TutorialVid open={showTutorial} onClose={()=>setShowTutorial(false)}/>
			}
		</React.Fragment>
	)

}

export default DashboardHeader
