import React, { useRef, useState } from "react";
import DonutChart from "components/charts/DonutChart";
import DownloadButton from "components/charts/DownloadButton"
import 'react-multi-carousel/lib/styles.css';
import Carousel from 'react-multi-carousel';
import Typography from "@mui/material/Typography";
import { makeStyles } from '@material-ui/core/styles';

import { annualEmpty } from '../constant/empty-report'

const useStyles = makeStyles({
  root: {
    margin: '5px 10px 35px',
    background: '#fff',
    padding: '10px 25px',
    boxShadow: "0 0 2px rgb(0 0 0 / 70%)",
  },
  chart_item: {
    margin: '0px 15px'
  }
});

const CarouselChart = (props) => {
  const [counter, setCounter] = useState(1);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
    };
  const classes = useStyles();
  return (
    <div className="relative">
      <Carousel
        responsive={responsive}
        arrows={true}
        showDots={true}
        afterChange={(nextSlide, { currentSlide, ...currObj }) => {
          if (props.onSlideChange)
            props.onSlideChange(currentSlide)

          setCounter(currentSlide + 1);
        }}
      >
        {
          props.reports.map((report, reportIndex) => (
            <GraphItem key={`report-wrapper-${reportIndex}`} report={report} classes={classes} />
          ))
        }
      </Carousel>
      {
        props.reports?.length > 0 &&
        <div className="absolute bottom-1 right-4">
          <p className="text-gray-30">{counter} out of {props.reports?.length}</p>
        </div>
      }
    </div>
  )
}
const GraphItem = ({ reportIndex, classes, report }) => {
  const ref = useRef()
  const { metaConfig } = report.data[0] || {}

  return (<div className={classes.root + ' relative'}>
    <div className="absolute top-5 right-5 z-10">
      <DownloadButton targetRef={ref} name={report.name} />
    </div>
    <div ref={ref} className="bg-white">
      <div className="flex flex-col items-center justify-center">
        <Typography variant="h5" className="text-2xl my-2">{report.name}</Typography>
        <Typography variant="h5" className="text-lg my-1">Category Averages</Typography>
      </div>
      <div className="chart flex justify-center">
        {
          report.data.map((reportData, reportDataIndex) => (
            <div key={`report-chart-wrapper-${reportIndex}-${reportDataIndex}`} className={`pb-8 flex-1 ${classes.chart_item} ${reportDataIndex == 0 ? 'border-r' : ''}`}>
              {
                reportData.data.length > 0 ? (
                  <>
                    <div style={{ height: '40vh', width: '100%' }} className="relative pt-4 pb-4 annual-trends-carousel-chart-item">
                      <DonutChart
                        toFix={0}
                        data={reportData.data}
                        metaConfig={{...reportData.metaConfig, colors: [reportDataIndex == 0 ? '#8C60F3' : '#52AF79', '#C4C4C4', '#FEC12F']}}
                      />
                    </div>
                    <div className="flex justify-center my-5">
                      {
                        reportData.metaConfig?.fields?.map((field, fieldIndex) => (
                          <div key={`field-${fieldIndex}`} className="flex flex-row items-center gap-x-1 mb-2 mx-4">
                            <div className="w-5 h-5 rounded-full" style={{ backgroundColor: [reportDataIndex == 0 ? '#8C60F3' : '#52AF79', '#C4C4C4', '#FEC12F'][fieldIndex] || '#C4C4C4' }} />
                            <p className="text-sm">{field.title}</p>
                          </div>
                        ))
                      }
                    </div>
                  </>
                ) : (
                  // <div className={`text-center flex items-center h-full text-red-10 text-sm justify-center`}>
                  //     <p style={{ marginTop: '-2rem' }}>No report available for { reportData.year }</p>
                  // </div>
                  <>
                    <div style={{ height: '30vh', width: '100%' }} className="relative pt-4 pb-4 annual-trends-carousel-chart-item">
                      <DonutChart
                        toFix={0}
                        data={annualEmpty[0].data}
                        metaConfig={annualEmpty[0].metaConfig}
                      />
                    </div>
                  </>
                )
              }
            </div>
          ))
        }
      </div>
      {/*{ metaConfig && 
        <div className="flex justify-center m-5">
          {
            metaConfig?.fields?.map((field, fieldIndex) => (
              <div key={`field-${fieldIndex}`} className="flex flex-row items-center gap-x-1 mb-2 mx-4">
                <div className="w-5 h-5 rounded-full" style={{ backgroundColor: metaConfig.colors[fieldIndex] }} />
                <p className="text-sm">{field.title}</p>
              </div>
            ))
          }
        </div>
      }*/}
    </div>
  </div >)
}

export default CarouselChart;
