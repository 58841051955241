const PaperBagSVG = () => {

	return (
		<svg className="stroke-current" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M1 4.66667L3.75 1H14.75L17.5 4.66667V17.5V19.3333H15.6667H2.83333H1V17.5V4.66667Z" strokeLinecap="round"/>
			<path d="M1 4.66602H17.5" strokeLinecap="round" strokeLinejoin="round"/>
			<path d="M12.9168 8.33203C12.9168 9.30449 12.5305 10.2371 11.8429 10.9248C11.1553 11.6124 10.2226 11.9987 9.25016 11.9987C8.2777 11.9987 7.34507 11.6124 6.65744 10.9248C5.9698 10.2371 5.5835 9.30449 5.5835 8.33203" strokeLinecap="square" strokeLinejoin="round"/>
		</svg>

	)

}

export default PaperBagSVG