import React, { useState, useRef } from "react";
import Carousel from "react-multi-carousel";
import { Typography } from "@mui/material";
import "react-multi-carousel/lib/styles.css";
import MoreIcon from "assets/images/icons/morevertical.png";
import DownloadButton from "components/charts/DownloadButton";
import GroupedBarChartSummary from "./GroupedBarChatSummary";
import IconButton from "@mui/material/IconButton";
import { makeStyles } from "@material-ui/core/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Legend from "components/legend/Legend";
import Tooltip from "@mui/material/Tooltip";

const useStyles = makeStyles({
  root: {
    background: "#fff",
    boxShadow: "0 0 2px rgb(0 0 0 / 70%)",
    margin: "25px 20px 30px 20px",
    transition: "transform .5s",
    "&:hover": {
      transform: "scale(1.05)",
    },
  },
});

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1
  }
};

const CarouselSummary = ({
  data = [],
  selectedSummary,
  onClick = () => {},
}) => {
  const classes = useStyles();
  const [step, setStep] = useState(1);
  const [test, setTest] = useState(1);
  // const sampleData = {
  //   legend: [
  //     { name: "Made the list", color: "#00a46a" },
  //     { name: "Not in the list", color: "#ffc955" },
  //   ],
  //   name: "Amet in adipiscing",
  //   data: [
  //     {
  //       value: { yourResult: 0.91, others: 0.85 },
  //     },
  //     {
  //       value: { yourResult: 0.96, others: 0.8 },
  //     },
  //     {
  //       value: { yourResult: 0.64, others: 0.74 },
  //     },
  //     {
  //       value: { yourResult: 0.83, others: 0.91 },
  //     },
  //   ],
  //   metaConfig: {
  //     valueKey: "value",
  //     types: [
  //       { field: "yourResult", label: "2020" },
  //       { field: "others", label: "2021" },
  //     ],
  //     colors: ["#00A46A", "#FFC955"],
  //   },
  // };

  const handleStep = (ps, cs) => {
    console.log(ps, cs);
    setStep(cs.currentSlide);
  };

  return (
    <React.Fragment>
      <Carousel
        swipeable={false}
        draggable={false}
        showDots={true}
        responsive={responsive}
        ssr={true} // means to render carousel on server-side.
        infinite={true}
        autoPlaySpeed={1000}
        keyBoardControl={true}
        customTransition="all .5"
        transitionDuration={500}
        containerClass="carousel-container"
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        itemClass="carousel-item-padding-40-px"
        afterChange={handleStep}
        className="min-h-32"
      >
        {data.map((d, key) => (
          <ReportCardItem
            key={key}
            data={d}
            selectedSummary={selectedSummary}
            onClick={onClick}
          />
        ))}
        {/* {testArr.map((i, key) => (
          <GraphItem key={key} classes={classes} num={i} data={data} />
        ))} */}
      </Carousel>
      <Typography variant="h6" className="text-sm text-right">
        {step} out of {data.length}
      </Typography>
    </React.Fragment>
  );
};

const CardBarSet = ({ title, dataValue1, dataValue2 }) => (
  <Typography className="flex flex-1 flex-col gap-2">
    <Tooltip title={`${dataValue1 || 0}% of ${title}`} placement="right">
      <Typography
        className="h-3"
        sx={{ background: "#00a46a", width: `${dataValue1 || 0}%` }}
      />
    </Tooltip>
    <Tooltip title={`${dataValue2 || 0}% ${title}`} placement="right">
      <Typography
        className="h-3"
        sx={{ background: "#ffc955", width: `${dataValue2 || 0}%` }}
      />
    </Tooltip>
  </Typography>
);

const ReportCardItem = ({ data, onClick, selectedSummary }) => {
  const ref = useRef();
  const handleClick = () => onClick(data.name);
  return (
    <div
      className={`bg-white p-3 relative w-auto mr-5 ${
        selectedSummary == data.name ? "border border-2 border-gray-500" : ""
      }`}
    >
      <Typography className="absolute top-4 right-4">
        <DownloadButton targetRef={ref} name={data.name} />
      </Typography>
      <div ref={ref} onClick={handleClick} className="bg-white p-4">
        <Typography className="text-base font-bold">{data.name}</Typography>

        {data && data.legend && data.legend.length && (
          <div className="flex flex-1 justify-start gap-3 mt-6">
            {data.legend.map((l, k) => (
              <Legend key={'legend-'+k} text={l.title} color={l.color} />
            ))}
          </div>
        )}
        {data && data.data && data.data.length ? (
          <div className="mt-6 h-full">
            <Typography
              className="border-solid border-gray-400"
              component="div"
              variant="div"
              sx={{ borderLeft: 1, borderBottom: 1 }}
            >
              <Typography className="flex flex-1 flex-col gap-8">
                {data.data.map((d, k) => (
                  <CardBarSet
                    key={'bar-'+k}
                    title={d.title}
                    dataValue1={d.value.yourResult}
                    dataValue2={d.value.others}
                  />
                ))}
                <p className="h-2"></p>
              </Typography>
            </Typography>

            <div className="flex flex-1 justify-between items-center">
              <p className="text-gray-400 text-xs">0</p>
              <p className="text-gray-400 text-xs">25</p>
              <p className="text-gray-400 text-xs">50</p>
              <p className="text-gray-400 text-xs">75</p>
              <p className="text-gray-400 text-xs">100</p>
            </div>
          </div>
        ) : (
          <div className="flex flex-1 flex-col items-center h-full mt-28">
            <Typography className="text-sm">Not yet Avaliable</Typography>
            <Typography className="text-gray-400 text-sm mt-3 text-center">
              we will notify you when the data on the report is ready
            </Typography>
          </div>
        )}
      </div>
    </div>
  );
};

// const GraphItem = ({ classes, data, num = 0 }) => {
//   const ref = React.useRef(null);

//   return (
//     <div
//       style={{ background: "#fff", position: "relative" }}
//       className={classes.root}
//     >
//       <div className="absolute top-5 right-5 z-10">
//         <DownloadButton targetRef={ref} name={"Amet in adipiscing"} />
//       </div>
//       <div ref={ref}>
//         <div className="top flex justify-between p-5">
//           <Typography variant="h5" className="text-sm font-semibold">
//             Amet in adipiscing {num}
//           </Typography>
//         </div>
//         <ul role="list" className="list-disc pl-6 flex flex-wrap mb-3">
//           <li className="w-1/3 pb-2 text-xs flex items-center">
//             <p
//               className="rounded-full h-1 w-1 mr-1"
//               style={{ background: "#00A46A" }}
//             ></p>{" "}
//             <p className="text-xs">Made the list</p>
//           </li>
//           <li className="w-1/2 pb-2 text-xs flex items-center">
//             <p
//               className="rounded-full h-1 w-1 mr-1"
//               style={{ background: "#F2403B" }}
//             ></p>{" "}
//             Not on the list
//           </li>
//         </ul>
//         <div style={{ height: "27vh", width: "100%" }}>
//           <GroupedBarChartSummary
//             data={data.data}
//             metaConfig={data.metaConfig}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

export default CarouselSummary;
