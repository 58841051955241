import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    grid: {
      width: '100%',
      display: 'flex',
      flexWrap: 'wrap',
    },
    grid_item:{
        float: 'left',
        width: '33.33%',
    },
    grid_item_content:{
        display: 'block',
        lineHeight: '1.4',
        backgroundColor: '#fff',
        boxShadow: '0 0 5px rgb(0 0 0 / 16%)',
        border: 'none',
        padding: '0',
        borderRadius: '12px',
        margin: '0 15px 30px',
        transition: 'transform .5s',
    },
    title:{
        float: 'left',
        width: '100%',
        textAlign: 'center',
        borderBottom: 'solid 1px rgba(112,112,112,.5)',
        textAlign: 'center',
        padding: '15px 0 5px 0',
    },
    h4:{
        textTransform: 'uppercase',
        fontWeight: 'bold',
        fontSize: '16px',
        lineHeight: '20px',
        color: '#579DFC',
        margin: '',
    },
    inner_cell:{
        padding: '10px 30px 20px',
        clear: 'both',
        overflow: 'hidden',
    },
    ul:{
        listStyle: 'none',
        padding:'0',
        margin: '0',
    },
    li:{
        fontSize: '14px',
        color: 'rgba(73, 73, 73, 0.5)',
        borderBottom: 'solid 1px #579DFC',
        padding: '10px 0px',
        color: '#555',
        textAlign:'center',
    },
    strong:{
        color: '#579DFC',
    }

});
  
export { useStyles };