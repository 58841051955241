export const UsersData = [
    {
        id: 1,
        name: 'User name',
        role: 'Sales',
        projects: 2,
        date_added: '21/04/2021',
    },
    {
        id: 2,
        name: 'User name',
        role: 'Sales',
        projects: 3,
        date_added: '16/05/2021',
    },
    {
        id: 3,
        name: 'User name',
        role: 'Sales',
        projects: 12,
        date_added: '19/06/2021',
    },
]