import DefaultImg from 'assets/images/two-column-layout-default-img.png'
import { WorkforceLogoWhite } from 'components/svg-components'

const TwoColumnLayout = ({
	image = DefaultImg,
	children
}) => {

	return (
		<div className="flex flex-row items-center h-screen">
			<div className="w-1/2 h-screen px-10 bg-gray-0">
				<div style={{ height: '10vh' }} className="flex flex-row items-center">
					<span className="svg-w-48">
						<WorkforceLogoWhite />
					</span>
				</div>
				<div className="flex flex-row items-center" style={{ height: '90vh' }}>
					{ children }
				</div>
			</div>
			<div className="w-1/2 h-full">
				<img src={ image } className="object-center object-cover w-full h-screen" alt={'two-column-layout-img'} />
			</div>
		</div>
	)

}

export default TwoColumnLayout