import React, { useState } from "react"
import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import SaveIcon from '@mui/icons-material/Save'
import { Typography } from "@mui/material"
import UserAction from 'redux/modules/user/actions'
import GrayTextField from 'components/form-utilities/gray-text-field'
import { makeStyles } from "@material-ui/styles"
import { useHistory } from "react-router"

const useStyles = makeStyles({
	heading:{
		textAlign:'center',
		fontWeight:'bold',
		fontSize:'32px',
		color:'#fff',
		marginBottom:'10px',
	},
	p:{
		fontSize:'16px',
		color:'#fff',
		width:'350px',
		padding:'25px',
		textAlign:'center',
	},
	btn:{
		borderColor:'#C6411F !important',
		borderRadius: '0 !important',
		color:"#fff !important",
		width:'100% !important',
		"&:hover":{
			backgroundColor: '#F75626 !important',
		}
	}
})
const ForgotUsername = () => {
	const classes = useStyles();
	const history = useHistory();
	const { http } = global.services
	const [ state, setState ] = useState({
		email:'',
		submitting: false,
		usernameError:false,
		emailSent: false,		
		error: (history.location.state) ? history.location.state.error : null
	})
	const handleSubmission = async (event = null, forAdmin = false) => {
		if (event){
			event.preventDefault()
			let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

			if(state.email && regex.test(state.email)) {
				try {
					const response = await http.post('/user/forgot-username', { email: state.email })
					const responseData = response.data.success
						console.log('Huuhh?', responseData)
					if(responseData) {
						setState(prev => ({...prev, emailSent:true}))
					}
				} catch (error) {
					const response = error.response;
					setState(prev => ({ ...prev, usernameError: true }))
					const defaultErrorMsg = 'An unknown error occured. Please refresh the page and try again.'
					if (response) {
						if (response.status === 403 || response.status === 400 || response.status === 404) {
							setState(prev => ({ ...prev, error: response.data.message }))
						}
					}
				}
			}else{
				setState(prev => ({ ...prev, error: 'Email Field Required' }))
			}
		}
	}

	if(state.emailSent)
		return (<div>
			<div className="mb-10 flex flex-col justify-center items-center">
				<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
					<circle cx="16" cy="16" r="16" fill="#00A46A"/>
					<path d="M22.8571 11.4277L12.3077 21.9877L9.14282 18.8229" stroke="white" stroke-width="2" stroke-linecap="square"/>
				</svg>

				<Typography variant="h4" className={ classes.heading } sx={{fontWeight: 'bolder', margin: '30px 0'}}>
					Check your mail
				</Typography>
				<p className="text-white mb-6">Your username was sent to the specified email</p>

				<a style={{color:'#FFF', cursor: 'pointer'}} onClick={() => history.push('/login')}>Try log in username</a>
			</div>
		</div>)

	return (
		<div>
			{
				state.error && (
					<div className="mb-10">
						<Alert severity="error">
							{ state.error }
						</Alert>
					</div>
				)
			}
			<Typography variant="h4" className={ classes.heading}>
				Forgot Username
			</Typography>
			<p className={classes.p}>
				Please enter your email. We will send you a username.
			</p>
			<form onSubmit={ (event) => handleSubmission(event) }>
				
				<div className="input-group mb-6">
					<Typography
						component="h4"
						fontSize="14px"
						marginBottom="10px"
						color="#fff"
				    >
						Email
					</Typography>
					<GrayTextField
						id="email-outlined-basic"
						className="w-full"
						variant="outlined"
						placeholder="Enter Email"
						onChange={ (event) => setState(prev => ({ ...prev, email: event.target.value, error: null })) }
						autoComplete="off"
						error={state.usernameError}
					/>
				</div>

				<div className="flex flex-row">
					<Button
						type="submit"
						className={classes.btn}
						variant="outlined"
						size="large"
					>
						Continue
					</Button>
				</div>
			</form>
		</div>
	)

}

export default ForgotUsername