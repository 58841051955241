const ResourceNotFound = () => {

	return (
		<div>
			<h1>404 Page...</h1>
		</div>
	)

}

export default ResourceNotFound