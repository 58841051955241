import React from 'react'

import Content from './pages/content'

import BreadcrumbComponent from '../../../../../components/breadcrumb'

const RolePermissionsPage = () => {

	const navs = [
        {label: 'Role Permissions', path: '/admin/role-permissions'},
    ]

	return (
		<div>
			<BreadcrumbComponent items={navs}/>

			<div className='py-8'>
				<h1 className='font-bold text-2xl'>Role Permissions</h1>
			</div>

			<Content />
		</div>
	)
}

export default RolePermissionsPage














// import * as React from 'react';
// import { useSelector } from "react-redux"
// import Typography from '@mui/material/Typography';
// import Breadcrumbs from '@mui/material/Breadcrumbs';
// import Box from '@mui/material/Box';
// import Grid from '@mui/material/Grid';
// import InputLabel from '@mui/material/InputLabel';
// import MenuItem from '@mui/material/MenuItem';
// import FormControl from '@mui/material/FormControl';
// import Select from '@mui/material/Select';
// import Switch from '@mui/material/Switch';
// import Button from '@mui/material/Button';
// import TextField from '@mui/material/TextField';
// import SectionLoading from '../../../../../components/loaders/SectionLoading';
// import { useFormik } from "formik";
// import { useHistory } from 'react-router-dom';
// import * as Yup from "yup";
// import { toast } from 'react-toastify';

// const validationSchema = Yup.object({
//     roleName: Yup.string().max(50).required("Required"),
// });

// const initialValues = {
//     roleName: "",
// }
// const RolePermissionsPage = (props) => {
// 	const history = useHistory();
// 	const { http } = global.services
// 	const [addRole, setAddRole] = React.useState(false);
// 	const [rolesDiv, setRolesDiv] = React.useState(true);
// 	const [sectionLoadingVisible, setSectionLoadingVisible] = React.useState(true)
// 	const [roles, setRoles] = React.useState([]);
// 	const[initialValue, setInitialValue] = React.useState(initialValues);
// 	const [permission, setPermission] = React.useState([]);
// 	const user = useSelector(state => state.user.user)
// 	const [localValue, setLocalValue] = React.useState('');

// 	React.useEffect(() =>{
// 		setSectionLoadingVisible(true);
// 		http.get('/admin/getroles').then((response) => {
// 			setRoles(response.data.roleData);
// 			setSectionLoadingVisible(false);
// 		}).catch(err => {
// 			console.log(err);
// 		})
// 		if(user){
// 			http.get('/admin/getpermissions/admin').then(res => {
// 				setSectionLoadingVisible(false);
// 				const resData = res.data;
// 				if(resData){
// 					setLocalValue(resData.roleData.role)
// 					setPermission(resData.roleData.permissions)
// 					setSectionLoadingVisible(false)
// 				}
// 			}).catch(err => {
// 				setSectionLoadingVisible(false);
// 			})
// 		}
// 	}, [])
	
// 	const addRoleInput = () => {
// 		setAddRole(true);
// 		setRolesDiv(false);
// 	}

// 	const { handleSubmit, handleChange, values, errors} = useFormik({
//         initialValues: initialValue,
//         validationSchema,
//         onSubmit(values){
//             setSectionLoadingVisible(true);
// 			const data = {
// 				roleName:values.roleName,
// 				permissions:[]
// 			}
// 			http.post('/admin/addrole', data).then(res => {
// 				setSectionLoadingVisible(false);
// 				const resData = res.data;
// 				if(resData){
// 					setRoles(resData.roleData);
// 					toast.success('Role Added', {
// 						autoClose:2500,
// 						onClose:() =>{
// 							setAddRole(false);
// 							setRolesDiv(true);
// 						}
// 					})
// 				}
// 				console.log(resData);
// 			}).catch(err => {
// 				setSectionLoadingVisible(false);
// 				const error = err.response;
// 				if(error){
// 					toast.error(error.data.message, {
// 						autoClose:2500,
// 						onClose:() =>{
// 							setAddRole(true);
// 							setRolesDiv(false);
// 						}
// 					})
// 				}
// 				console.log(err.response);
// 			})
// 		}
//     });

// 	const handleRolePermission = async (e) =>{
// 		setSectionLoadingVisible(true);
// 		const value = e.target.value;
// 		setLocalValue(value);
// 		try {
// 			const response = await http.get('/admin/getpermissions/'+value)
// 			setPermission(response.data.roleData.permissions);
//             setSectionLoadingVisible(false);
// 		} catch (error) {
// 			const defaultErrorMsg = 'An unknown error occured. Please refresh the page and try again.'
//             setSectionLoadingVisible(false);
// 		}
// 	}

// 	const revokePermission = async (e, value) => {
// 		const addRemove = permission.includes(value);
// 		if(addRemove == false){
// 			//Add Permission
// 			setSectionLoadingVisible(true);
// 			try {
// 				const data = {
// 					roleName:localValue,
// 					permissions:value
// 				}
// 				const response = await http.post('/admin/managerole', data)
// 				setPermission(response.data.roleData.permissions)
// 				console.log(response);
// 				if(response) {
// 					setSectionLoadingVisible(false);
// 					toast.success('Permission granted', {
// 						autoClose:2500,
// 						onClose:() => {
// 							history.push('/admin/role-permissions', {
// 								shouldReload:true
// 							})
// 						}
// 					})
// 				}
// 			} catch (error) {
// 				const defaultErrorMsg = 'An unknown error occured. Please refresh the page and try again.'
// 				setSectionLoadingVisible(false);
// 			}
// 		}
// 		if(addRemove == true){
// 			//remove Permission
// 			setSectionLoadingVisible(true);
// 			try {
// 				const data = {
// 					roleName:localValue,
// 					permissions:value
// 				}
// 				const response = await http.put('/admin/managerole', data)
// 				setPermission(response.data.roleData.permissions)
// 				console.log(response);
// 				if(response) {
// 					setSectionLoadingVisible(false);
// 					toast.success('Permission Removed', {
// 						autoClose:2500,
// 						onClose:() => {
// 							history.push('/admin/role-permissions', {
// 								shouldReload:true
// 							})
// 						}
// 					})
// 				}
// 			} catch (error) {
// 				const defaultErrorMsg = 'An unknown error occured. Please refresh the page and try again.'
// 				setSectionLoadingVisible(false);
// 			}
// 		}
// 	}
// 	console.log(permission);
// 	return (
// 		<React.Fragment>
// 			{
//                 /* SECTION LOADING */
//                 sectionLoadingVisible && (
//                     <SectionLoading />
//                 )
//             }
// 			<Box>
// 				<Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }} >
// 					<Grid item xs={6}>
// 					<div className="role-permission-section">
// 						<Breadcrumbs aria-label="breadcrumb">
// 							<Typography varient="h5" color="text.primary">Role Permissions</Typography>
// 						</Breadcrumbs>
// 						<div className="heading">
// 							<Typography mt={2} mb={2} sx={{ fontWeight:'bold', fontSize:"26px"}}>Role Permissions</Typography>
// 						</div>
// 						<div className="add-role-form mt-10">
// 							{ rolesDiv &&
// 								<div className="form">
// 									<FormControl sx={{ width:"100%" }} className="form-control">
// 										<InputLabel className="label" id="demo-simple-select-helper-label">Add New Role</InputLabel>
// 										<Select
// 										value={localValue}
// 										id="demo-simple-select-helper"
// 										label="Add New Role"
// 										onChange={handleRolePermission}
// 										size="small"
// 										>
// 										{
// 											(roles && roles.length) ? roles.map((role, index) =>(
// 												<MenuItem value={role.role} key={index}>{role.role}</MenuItem>
// 											)):null
// 										}
// 										</Select>
// 									</FormControl>
// 									<button className="add-role-btn" onClick={addRoleInput}> + Add New Role</button>
// 								</div>
// 							}
// 							{
// 								addRole && 
// 								<form className="form" onSubmit={handleSubmit}>
// 									<TextField
// 										sx={{ width:"100%" }}
// 										name="roleName"
// 										size="small"
// 										value={values.roleName}
// 										onChange={handleChange}
// 										className="form-control"
// 									/>
// 									{errors.roleName ? <p className="error text-danger">{errors.roleName}</p> : null}
// 									<Button sx={{ml:1}} type="submit" variant="contained">Submit</Button>
// 								</form>					
// 							}
// 						</div>
// 						{
// 							(addRole == false && rolesDiv == true ) ? (
// 								<div className="permission-list">
// 								<div className="heading">
// 									<p>Permission</p>
// 									<p>On/Off</p>
// 								</div>
// 								<div className="data">
// 									<div className="title">Access</div>
// 									<div className="list">
// 										<p>View all Projects</p>
// 										<Switch 
// 											onClick={(e) =>revokePermission(e, 'project')}
// 											checked={permission.includes('project') ? true : false }
// 											aria-label="status" 
// 										/>
// 									</div>
// 									<div className="list">
// 										<p>View all Programs</p>
// 										<Switch 
// 											onClick={(e) =>revokePermission(e, 'program')}
// 											checked={permission.includes('program') ? true : false }
// 											aria-label="status" 
// 										/>
// 									</div>
// 								</div>
// 								<div className="data">
// 									<div className="title">Data</div>
// 									<div className="list">
// 										<p>Manage Pull Data from Checkmarket</p>
// 										<Switch 
// 											onClick={(e) =>revokePermission(e, 'checkmarket')}
// 											checked={permission.includes('checkmarket') ? true : false }
// 											aria-label="status" 
// 										/>
// 									</div>
// 									<div className="list">
// 										<p>Export Reports</p>
// 										<Switch 
// 											onClick={(e) =>revokePermission(e, 'exportReport')}
// 											checked={permission.includes('exportReport') ? true : false }
// 											aria-label="status" 
// 										/>
// 									</div>
// 									<div className="list">
// 										<p>Upload Custom Reports for Clients</p>
// 										<Switch 
// 											onClick={(e) =>revokePermission(e, 'customReport')}
// 											checked={permission.includes('customReport') ? true : false }
// 											aria-label="status" 
// 										/>
// 									</div>
// 									<div className="list">
// 										<p>Upload Key Driver Data</p>
// 										<Switch 
// 											onClick={(e) =>revokePermission(e, 'keyDriver')}
// 											checked={permission.includes('keyDriver') ? true : false }
// 											aria-label="status" 
// 										/>
// 									</div>
// 									<div className="list">
// 										<p>Edit Best Companies List</p>
// 										<Switch 
// 											onClick={(e) =>revokePermission(e, 'editCompanies')}
// 											checked={permission.includes('editCompanies') ? true : false }
// 											aria-label="status" 
// 										/>
// 									</div>
// 								</div>
// 								</div>
// 							) : ''
// 						}

// 					</div>
// 					</Grid>
// 				</Grid>
// 			</Box>
// 		</React.Fragment>
// 	)

// }

// export default RolePermissionsPage