import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { Box, Typography } from "@mui/material";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import GreeenIcon from "assets/images/icons/Icongreen.png";
import RedIcon from "assets/images/icons/Iconred.png";
import ReportsHeader from "../components/reports-header";
import CarouselSummary from "./components/carousel-summary";
import CarouselSurveys from "./components/survey-averages";
import SectionLoading from "components/loaders/SectionLoading";
import downloadFile from "helpers/downloadFile";
import { API_ENDPOINT } from "config/api";
import { useSelector, useDispatch } from "react-redux";
import AppAction from "redux/modules/app/actions";
import DownloadIcon from "assets/images/icons/download.png";
import { Avatar } from "@mui/material";
import ReportDescription from "../components/report-description";
import TableComponent from "../benefits-best-practices/components/table";
import useAccessible from "components/hooks/useSideBarAccessible";
import PageDropdown from "../components/page-dropdown";
import Alert from "@mui/material/Alert";

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
})); 

const sequence = ["allEmployee", "small", "medium", "large"];
const title = {
  allEmployee: "All Employers",
  small: "Small Employers",
  medium: "Medium Employers",
  large: "Large Employers",
  major: "Major Employers",
};
const color = {
  small: "#3F7CFB",
  medium: "#FFC955",
  large: "#00A46A",
  allEmployee: "#162630",
};

const pages = [
  {
    id: "/workforce-benchmark-comparisons",
    path: "/workforce-benchmark-comparisons",
    title: "Benchmark Data",
  },
  {
    id: "comparison-data",
    path: "/comparison-data",
    title: "Comparison Data",
  },
];

const WorkforceBenchmarkComparisons = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { http } = global.services;
  const isAvailable = useAccessible("WBC_Access");
  const {user, admin} = useSelector(({user}) => user);
  const { selectedProgramId, timeEstimate, clearcache } = useSelector(
    ({ app }) => app
  );
  const { organizationProgram } = user.userData ? user.userData : user.user;
  const selectOrgProgram =
    organizationProgram.find((i) => i.programId?._id == selectedProgramId) ||
    organizationProgram[0];
  const programId = selectedProgramId || selectOrgProgram?.programId?._id;
  const [sectionLoadingVisible, setSectionLoadingVisible] = useState(false);
  const [selectedSummary, selectSummary] = useState(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [responseError, setResponseError] = useState(false);
  const [allCategoryData, setAllCategoryData] = useState();

  const [state, setState] = useState({
    filters: [],
    summary: null,
  });
  const [empCompReport, setEmpCompReport] = useState({
    loaded: false,
    data: [],
  });
  const [empSectionCompReport, setEmpSectionCompReport] = useState({
    loaded: false,
    data: [],
  });
  const [tableHeaders, setTableHeaders] = useState([]);
  const [empQuesSectionCompReport, setEmpQuesSectionCompReport] = useState([]);
  

  useEffect(() => {
    if (programId) {
      reloadMain();
    }
  }, [programId]);

  useEffect(() => {
    if (empCompReport.loaded && empSectionCompReport.loaded)
      setSectionLoadingVisible(false);
  }, [empCompReport, empSectionCompReport]);

  useEffect(() => {
    if (selectedSummary) {
      handleSectionSummary();
    }
  }, [selectedSummary]);

  const reloadMain = async () => {
    setSectionLoadingVisible(true);
    // const query = {
    //   selectedProgramId: programId,
    // };
    // if (clearcache) query.clearCache = clearcache;
    // if (!selectOrgProgram["EV_Access"]) query.isDummy = true;
    // const { data } = await http.get(`client/getWinnersList`, query);
    handleComparisonReport();
  };
  const downloadFullReport = () => {
    setIsDownloading(true);
    const query = {
      selectedProgramId: programId,
    };
    if (clearcache) query.clearCache = clearcache;
    if ((selectOrgProgram["WBC_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) query.isDummy = true;
    const queryString = new URLSearchParams(query).toString();
    var downloadable = false;
    setTimeout(() => {
      if (!downloadable) {
        dispatch(
          AppAction.setAlert(
            `Compiling report. Estimated download time is ${timeEstimate} minute${
              timeEstimate > 1 ? "s" : ""
            }`
          )
        );
      }
    }, [3000]);

    downloadFile(
      `${API_ENDPOINT}/client/v2/generateBenchmarkReport?${queryString}`,
      undefined,
      "get"
    )
      .then((response) => {
        const { headers } = response;
        const contentType = headers.get("content-type");
        if (contentType && contentType.indexOf("application/json") !== -1) {
          return response.json().then(({ data }) => {
            const { signedUrl } = data.data;
            const { pathname } = new URL(signedUrl);
            const dlFile = document.createElement("a");
            dlFile.download = pathname.replace("/", "");
            dlFile.href = signedUrl;
            dlFile.target = "_blank";
            dlFile.click();
          });
        }
      })
      .catch((err) => {
        console.log(err);
        return;
      })
      .finally(() => {
        downloadable = true;
        setIsDownloading(false);
      });
  };

  const download = {
    data: "",
    filters: state.filters,
    types: ["JPEG", "PDF", "SVG", "XLS"],
  };

  const downloadConfigs = [
    {
      action: downloadFullReport,
    },
  ];

  const handleComparisonReport = async () => {
    var { organizationProgram } = user.userData ? user.userData : user.user;
    const programId =
      selectedProgramId || organizationProgram[0]?.programId?._id;
    const query = { selectedProgramId: programId };
    if (clearcache) query.clearCache = clearcache;
    if ((selectOrgProgram["WBC_Access"] || '').trim().toLowerCase() !== 'yes' && !admin) query.isDummy = true;

    setEmpCompReport({ loaded: false, data: [] });
    try {
      const response = await http.get(
        `/client/v2/employeeComparisonReport`,
        query
      );

      const { tableHeaders, surveyAverage, data } = response.data.data;
      setAllCategoryData(data);
      setTableHeaders(tableHeaders);
      let surveyAverageData = surveyAverage.map((avg) => ({
        title: avg.title,
        madeToList: avg.Yes.value,
        notMadeToList: avg.No.value,
      }));
      setEmpCompReport({
        loaded: true,
        data: surveyAverageData,
      });

      setEmpSectionCompReport({
        loaded: true,
        data: data.map((data) => {
          let values = [];
          tableHeaders.forEach((th, i) => {
            if (th.type.includes("Yes")) {
              values.push({
                title: `${th.type.replace("_Yes", "")} Employers`,
                value: {
                  yourResult:
                    data.dataValues[i] == "x" ? undefined : data.dataValues[i],
                  others:
                    data.dataValues[i + 1] == "x"
                      ? undefined
                      : data.dataValues[i + 1],
                },
              });
            }
          });
          return {
            name: data.title,
            legend: data.legends,
            data: values,
          };
        }),
      });
      setResponseError(false);
    } catch (err) {
      console.log(err);
      setResponseError(true);
      setSectionLoadingVisible(false);
      return;
    }
  };

  const handleSectionSummary = async () => {
    const formatCellValue = (cellValue) => {
      if (typeof cellValue === "string") {
        return cellValue;
      }
      return `${Math.round(cellValue)}%`;
    };
    let tableData = allCategoryData.find(
      (category) => category.title === selectedSummary
    );
    tableData = tableData.nestedData.map((row) => {
      row.dataValues = row.dataValues.map((dataValue) =>
        formatCellValue(dataValue)
      );
      return row;
    });

    setEmpQuesSectionCompReport(tableData);
  };

  const handleClick = (newSummary) =>
    selectSummary(selectedSummary !== newSummary ? newSummary : null);

  return (
    <React.Fragment>
      {
        /* SECTION LOADING */
        sectionLoadingVisible && <SectionLoading />
      }
      <ReportsHeader
        download={download}
        benchmark
        pageName="Benchmark Data"
        accessGroup="WBC_Access"
        downloadConfigs={downloadConfigs}
      />

      {!isAvailable && (
        <div className="mt-4">
          <PageDropdown pages={pages} />
        </div>
      )}

      {responseError && (
        <Alert variant="filled" severity="error">
          Data Error
        </Alert>
      )}
      
      {isDownloading && <SectionLoading />}
      {/* <ReportDescription /> disable temporarily */}
      <div className="employee-category mt-8">
        {empCompReport.loaded && (
          <Box sx={{ width: "100%" }}>
            <CarouselSurveys data={empCompReport.data} />
          </Box>
        )}
        {empSectionCompReport.loaded && (
          <div className="carousel-section mt-8">
            <CarouselSummary
              data={empSectionCompReport.data}
              selectedSummary={selectedSummary}
              onClick={handleClick}
            />
          </div>
        )}
        { selectedSummary && ( 
            <TableComponent
              title={selectedSummary}
              headers={tableHeaders}
              rows={empQuesSectionCompReport}
            />
        )}
      </div>
    </React.Fragment>
  );
};

export default WorkforceBenchmarkComparisons;
