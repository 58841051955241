import React, { useState, useEffect, useMemo } from 'react'
import { 
	Box, 
	Button, 
	MenuItem, 
	Popper, 
	Grow, 
	Paper, 
	ClickAwayListener, 
	MenuList } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles';

import IconSortingPurpleBG from 'assets/images/icons/icon-sorting-purple-bg.png'

import { useSelector, useDispatch } from "react-redux";
import { addFilter } from 'redux/modules/cart/actions';
import AppAction from 'redux/modules/app/actions';
import { currentCurrency } from 'helpers/checkCurrency';
const useStyles = makeStyles({
	root: {
		background: "#B483FF",
		boxShadow: "0 0 0 rgb(0 0 0 / 70%)",
	}
})

const MenuFilter = ({ item, onClick }) => {
	const handleClick = (e) => onClick(item)
	return <MenuItem
		className='text-sm text-white'
		onClick={handleClick}
	>
		<span className="pr-10">{item?.filterLabel}</span>
	</MenuItem>
}

export default function PurchaseFilter () {
	const { http } = global.services
	const classes = useStyles();
	const dispatch = useDispatch(); 
	const { user } = useSelector(({user}) => user)
	const { items, filters: onCart } = useSelector(({cart})=> cart)
	const {selectedProgramId, clearcache} = useSelector(({app})=> app)
	const { organizationProgram } = (user?.userData) ? user?.userData : user?.user;
  	const selectOrgProgram = organizationProgram?.find(i => i.programId?._id == selectedProgramId) || organizationProgram[0];
  const { EV_Sorting_Payment_Details, EV_Sorting_Payment_Type } = selectOrgProgram;
	const { EV_Sorting_Fee } = selectOrgProgram?.programId

	 const canBuy = useMemo(() => {
		if(!selectOrgProgram) return false;
    const hasPaidFDD = selectOrgProgram?.FDD_Payment_Details !== null;
    const hasReport = ['WFR_Access', 'EV_Access', 'WBC_Access', 'BBP_Access'].find(i => (selectOrgProgram[i] || '').trim().toLowerCase() === 'yes');
    const onCart = items.find(i => i.forPayment === 'FDD');
    return hasPaidFDD || hasReport || onCart;
  }, [items, selectedProgramId])

	const [selected, setSelected] = useState(null)
	const [soldFilters, setFilters] = useState([]);
	const [showMenu, setShowMenu] = useState(false)
	const [state, setState] = useState({
		menuElement: null
	})

	useEffect(()=>{
		loadItems();
	}, [])

	const loadItems = async () => {
		let query = {};
		if(organizationProgram) query.selectedProgramId = selectedProgramId || selectOrgProgram?.programId?._id;
		if(clearcache) query.clearCache = clearcache
    	// if((selectOrgProgram["EV_Access"] || '').trim().toLowerCase() !== 'yes') query.isDummy = true;

		const { data } = await http.get('client/fetchSurveyFilter', {...query})
		
		setFilters(data?.data?.map((i) => ({ id: i.QuestionId, ...i })));
	}

	const handleClick = (item) => {
		setShowMenu(false)
		setSelected(item)
	}

	const onPurchase = () => {
    if(!canBuy) {
      dispatch( AppAction.setAlert(`You need to include Feedback Data Dashboard Package to checkout!`) );
      return;
    }
    
		if(selected && selectOrgProgram) {
			dispatch( addFilter({ ...selected, 
				title: "EV Filter Purchase",
				amount: EV_Sorting_Fee || 150,
				forPayment: "EV_Sorting", 
				keys: {
					EV_Access: selectOrgProgram?.EV_Access,
					EV_Sorting_Filter: selected.filterLabel,
					EV_Sorting_Payment_Details: selectOrgProgram?.EV_Sorting_Payment_Details || null, 
					EV_Sorting_Payment_Type: null
				}
			}) )
	    	dispatch( AppAction.setAlert(`Filter added to cart!`) )
		}
	}
	//Hide component if no fee found
	if( EV_Sorting_Fee <= 0 || EV_Sorting_Payment_Type !== null)
		return "";

	return (
		// <div className="p-5 bg-purple-0 flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row items-center my-8">
		<div className="p-5 bg-purple-0 my-8">
			<div className="flex flex-col md:flex-row lg:flex-row xl:flex-row 2xl:flex-row">
				<div className="text-center mb-5 ml-10 md:ml-0 lg:ml-0 xl:ml-0 2xl:ml-0">
					<img src={IconSortingPurpleBG} className="w-18 h-18 rounded-full" />
				</div>

				<div className="px-10 flex-1">
					<p className='text-white font-bold mb-5'>Filtering your report</p>

					<p className="text-white font-light mb-5">
						Sorting the employees’ open-ended responses by a demographic will allow you to better identify where the comments originated..
					</p>

					<p className="text-white font-light mb-3">
						Select one of these options:
					</p>

					<div className='flex flex-row flex-wrap items-center space-x-4 mb-8'>
						<div
							className={`relative filter-dropdown ${showMenu ? 'border border-black-0' : ''}`}
							onClick={(event) => {
								if (!showMenu) {
									setState(prevState => ({ ...prevState, menuElement: event.currentTarget }))
									setShowMenu(true)
								}
							}}
							style={{minWidth: 200}}
						>
							<div
								className={`flex flex-row justify-between items-center bg-purple-400 px-5 py-2 space-x-7 cursor-pointer`}
							>
								<span className='text-white text-sm'>{selected?.filterLabel || `Select Filters`}</span>
								<i className='text-white bx bx-chevron-down'></i>
							</div>
							<Popper
								open={showMenu}
								anchorEl={state.menuElement}
								transition
								disablePortal
								style={{ position: 'absolute', zIndex: 100, transform: 'translate(0px, 45px)', width: '100%' }}
							>
								{({ TransitionProps, placement }) => (
									<Grow
										{...TransitionProps}
										style={{
											transformOrigin: 'left'
										}}
									>
										<Paper variant="outlined" style={{ borderRadius: 0, width: 'fit-content' }}>
											<ClickAwayListener onClickAway={() => setShowMenu(false)}>
												<MenuList id="split-button-menu" className={`${classes.root}`}>
													{
														soldFilters.length > 0 && 
															soldFilters
															.filter(f => !onCart.find(o => o.QuestionId == f.QuestionId )) //Check if it's already on cart
															.map(i => <MenuFilter key={i.QuestionId} item={i} onClick={handleClick} />)
													}
												</MenuList>
											</ClickAwayListener>
										</Paper>
									</Grow>
								)}
							</Popper>
						</div>

						<div className="flex text-white font-light">
							<p className='font-bold text-2xl'>{currentCurrency()} {EV_Sorting_Fee || 0}</p>
							{/* <p className='font-bold text-2xl'>$ {EV_Sorting_Fee || 0}</p> */}
						</div>
					</div>

					<Box mb={2}>
						<Button sx={{width: 160}} variant="contained" color="light-0" mb={5} onClick={onPurchase}>
							Add to Cart
						</Button>
					</Box>
				</div>
			</div>
		</div>
	)
}
