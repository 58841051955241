import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useState } from 'react'; 
import SectionLoading from 'components/loaders/SectionLoading';
const TutorialVid = (props)=>{
    const [isVideoLoading, setIsVideoLoading] = useState(true);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '80%',
        bgcolor: '#f5f5f5',
        boxShadow: 24,
        p: 1, 
        backgroundColor:'transparent'
      };
    
    const handleVideoLoad = ()=>{ 
        setIsVideoLoading(false);
    }
    return (
        <Modal
        {...props}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box sx={style}>
            <div
                style={{
                    position: 'relative',
                    width: '100%',
                    height: 0,
                    paddingTop: '56.2500%',
                    paddingBottom: 0,
                    boxShadow: '0 2px 8px 0 rgba(63,69,81,0.16)', 
                    overflow: 'hidden',
                    borderRadius: '8px',
                    willChange: 'transform',
                }}
                >
                {
                    isVideoLoading && <SectionLoading/> 
                }
                <iframe
                    loading="lazy"
                    style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    top: 0,
                    left: 0,
                    border: 'none',
                    padding: 0,
                    margin: 0,
                    }}
                    src="https://www.canva.com/design/DAFpLvlaZ80/watch?embed&autoplay=1"
                    allow="autoplay"
                    allowFullScreen
                    autoPlay
                    frameborder="0"
                    onLoad={handleVideoLoad}
                ></iframe> 
                
            </div>
        </Box>
        </Modal>
    )
}

export default TutorialVid;