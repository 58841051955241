import { styled } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import { makeStyles } from '@material-ui/core/styles';

/** Source: https://mui.com/components/text-fields/ (Under "Customization" section.) */
const CustomizedTextField = styled(TextField)({
  backgroundColor: 'rgba(255, 255, 255, 0.1)',
  border:0,
  borderRadius: 0,
  '& label.Mui-focused': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    color: 'white',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: 'white',
  },
  '& .MuiOutlinedInput-root': {
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    border:0,
    borderRadius: 0,
    '& fieldset': {
			color: 'white'
    },
		'&:hover fieldset': {
			color: 'white'
    },
    '&.Mui-focused fieldset': {
			color: 'white'
    },
  },
})
const useStyles = makeStyles((theme) => ({
  input: {
    color: 'white',
    '&:-webkit-autofill': {
      WebKitBoxShadow: '0 0 0 10px white inset'
    },
    '&:-webkit-autofill:focus': {
      WebKitBoxShadow: '0 0 0 10px white inset'
    }
  }
}))

const GrayTextField = (props) => {
  const classes = useStyles();
	return (
		<CustomizedTextField
			InputLabelProps={ { style: { color: 'white' } } }
			InputProps={{ style: { color: 'white' }, classes: {input: classes.input} }}
			{ ...props }
		/>
	)

}

export default GrayTextField