/**
 * TODO LIST
 * - This component is being re-rendered multiple times when page is changed. Find out why it is happening for optimization purposes.
 * - Menu items are just being combined as of this moment. It will be changed once we have control of the user permissions.
 */

import React, { useState, useEffect } from 'react'
import { Link, matchPath } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { ListItem, ListItemText, Collapse, List, ListItemButton } from '@mui/material'

import AppActions from "redux/modules/app/actions"
import { WorkforceLogoWhite } from 'components/svg-components/index.js'
import ClientItemsList from './menu-items/client'
import AdminItemsList from './menu-items/admin'
import { Add, Remove } from '@mui/icons-material';
import useSideBarAccessible from './menu-items/useSideBarAccessible'

const DashboardSideMenu = () => {
	const history = useHistory()
	const dispatch = useDispatch()
	const [menuItems, setMenuItems] = useState([]);
	const currentRoute = useSelector(({app}) => app.currentRoute)
	const user = useSelector(({user})=> user?.user)
	const role = (user?.user) ? user?.user?.role : user?.userData?.role;

	// var menuItems = [];
	useEffect(()=>{
		//Safer process
		//temporarily hide reports store
		if(user){
			setMenuItems( role == "client" ? [...ClientItemsList.items] :  [ ...AdminItemsList.items ] )
		}
	}, [user])

	return (
		<>
			<div id="side-menu-header">
				<Link className="svg-w-48" to={role == 'client' ? '/dashboard' : '/admin/projects'} >
					<WorkforceLogoWhite />
				</Link>
			</div>

			<div id="side-menu-content">
				<div id="side-menu-list-wrapper">
					<List sx={{ width: '100%', padding: '0 10px' }} id="side-menu-list">
						{
							menuItems && menuItems.map(i => <MainLink item={i} 
								key={`list-item-button-dropdown-${ i.id }`}
							/>)
						}
					</List>
				</div>
			</div>
		</>
	)

}

const MainLink = ({item}) => {
	const [open, setOpen] = useState(false)
	const history = useHistory()
	const location = useLocation()
	const dispatch = useDispatch()
	const { user } = useSelector(({user}) => user?.user || {});

	useEffect(()=>{
		var base = false;
		if(item.path) {
			base = matchPath(location?.pathname, item.path);
		}
		if (item.sections.length > 0) {
			//assuming the path is on myReports;
			base = item.sections.filter(i => {
				if(i.path && matchPath(location?.pathname, i.path)) return true;

				if(i.items.length > 0)
					return i.items.find(x => {
						if(x.path && matchPath(location?.pathname, x.path)) return true;
						if(x.subitems)
							return x.subitems.find(y => y.path && matchPath(location?.pathname, y.path));
						return false;
					});

				return false;
			}).length >= 1;

		}
		setOpen( base )
	}, [location])

	const handleClick = () => {
		setOpen(!open)
		if(item.path && !open)
			history.push(item.path)
	}

	if( item.adminAccess && 
		user?.roleId?.role !== 'admin' &&
		Array.isArray(user?.roleId?.permissions) && 
		!user?.roleId?.permissions?.find(i => i === item.adminAccess) )
		return "";

	return (<React.Fragment>
		<ListItemButton
			onClick={handleClick}
			// This doesnt work right; update later
			className={`${ item.id == 'admin-settings' ? 'mt-40' : 'nope'}`}
		>
			<span className={`flex flex-row items-center space-x-3 w-full ${ (item.path && matchPath(location?.pathname, item.path)) ? 'text-red-0' : 'text-white' } `}>
				{
					item.icon && (
						<span className={`svg-w-4 `}>
							{ item.icon }
						</span>
					)
				}
				<ListItemText
					primary={ item.title }
				/>
				{
					item.sections?.length !== 0 &&
					<i className={`bx bx-chevron-down text text-2xl float-right ${open ? 'transform rotate-180' : ''}`}></i>
				}
			</span>
		</ListItemButton>

		<Collapse in={ open ? true : false } timeout="auto" unmountOnExit>
			<List sx={{ width: '100%' }} component="div" disablePadding>
				{ item.sections?.map(section => {
					return (
						<PackageLink 
							key={`list-item-button-section-dropdown-${ section.id }`}
							section={section}
							history={history}
							location={location}
							dispatch={dispatch}
						/>
					)
				}) }
			</List>
		</Collapse>
	</React.Fragment>)
}

const PackageLink = ({ section, history, location, dispatch }) => {
	// const reducedTruthValue = section.items?.reduce((i) => useSideBarAccessible(i.access), true); //Cant do that
	// console.log('huuh>>',  section.items, reducedTruthValue)
	return <React.Fragment key={`list-item-button-section-dropdown-${ section.id }`}>
		<ListItem>
			<ListItemText key={ section.id } primary={ section.title } className="text-purple-500 pb-0 menu-list-section-header"/>
		</ListItem>

		<List component="div" disablePadding className="">
			{
				section.items?.map(i => <RenderLink key={'sublink-'+i.id} 
					item={i} 
					isChildren={true}
					history={history}
					location={location}
					dispatch={dispatch}
					/>)
			}
		</List>
	</React.Fragment>
}

const RenderSubLink = ({subitem, isChildren, check, history }) => {
	const onClick = () => history.push(subitem.path);
	const pathCheck = useSideBarAccessible(subitem.access);

	if(!pathCheck)
		return "";

	return <ListItemButton
		onClick={ onClick }
	>
		<span className={`flex flex-row items-center space-x-3 w-full ${ check(subitem.path) ? 'text-red-0 text-opacity-100' : 'text-white text-opacity-50'}`}>								
			<ListItemText
				primary={ subitem.title }
				className={`${ isChildren ? 'menu-item-sub-child' : 'menu-item' } font-small`}
			/>
		</span>
	</ListItemButton>
}
// Render Links
const RenderLink = ({item, isChildren = false, parentIDs = [], history, location, dispatch}) => {
	const [open, setOpen] = useState(false)
	const pathCheck = useSideBarAccessible(item.access);

	useEffect(()=>{
		var base = false;
		if (item.subitems) {
			for (var i = 0; i < item.subitems.length; i++) {
				if(base || matchPath(location?.pathname, item.subitems[i].path)) {
					base = true
					break;
				}
			}
		} else if(item.path) {
			base = matchPath(location?.pathname, item.path);
		}
		setOpen( base )
	}, [location])

	const handleClick = () => {
		if(item.subitems) {
			setOpen( !open )
		} else {
			dispatch(AppActions.setSideMenuDrawerShow(false))
			history.push(item.path)
		}
	}

	const renderIcon = () => {
		if(item.icon) {
			return (<span className={`svg-w-4 min-h-4 min-w-4`}>
				{ item.icon }
			</span>)
		}

		if(!item.subitems)
			return <span className="svg-w-4 min-h-4 min-w-4"></span>;

		return (<span className={`svg-w-4 min-h-4 min-w-4`}>
			{open ? <Remove /> : <Add />}
		</span>)
	}

	const check = (path) => {
		return matchPath(location?.pathname, path);
	}

	if(!pathCheck)
		return "";

	return (
		<React.Fragment>
		<ListItemButton
			key={`menu-item-${item.id}`}
			onClick={ handleClick }
		>
			<span className={`flex flex-row items-center mr-0 space-x-3 w-full ${check(item.path) ? 'text-red-0' : 'text-white'} ${ open ?  'text-opacity-100' : 'text-opacity-50' }`}>
				{ renderIcon() }
				<ListItemText
					primary={ item.title }
					className={`${ isChildren ? 'text-opacity-50 menu-item-sub-child' : 'menu-item' } font-small`}
				/>
			</span>
		</ListItemButton>
		<Collapse in={ open } timeout="auto" unmountOnExit>
			<List className={'pl-11'} sx={{ width: '100%' }} component="div" disablePadding>
				{ item.subitems && item.subitems.map(subitem => <RenderSubLink key={`menu-item-sub-${subitem.id}`} subitem={subitem} check={check} isChildren={isChildren} history={history}/>) }
			</List>
		</Collapse>
		</React.Fragment>
	)
}
export default DashboardSideMenu