import { useState } from 'react'
import { useDispatch } from "react-redux"
import { useHistory } from "react-router"

import Button from '@mui/material/Button'
import LoadingButton from '@mui/lab/LoadingButton'
import Alert from '@mui/material/Alert'
import AlertTitle from '@mui/material/AlertTitle'
import Typography from '@mui/material/Typography'
import SaveIcon from '@mui/icons-material/Save'

import UserAction from 'redux/modules/user/actions'
import AppActions from 'redux/modules/app/actions'

import GrayTextField from 'components/form-utilities/gray-text-field'

import { makeStyles } from '@material-ui/core/styles';
import {Box, Grid, InputLabel, MenuItem, Select, List, ListItem, Pagination, Breadcrumbs, Link, Input, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TableFooter, TablePagination, Paper, TableSortLabel,
    Modal
} from '@mui/material';

const useStyles = makeStyles({
	forgetPass:{
		fontSize: '14px',
		lineHeight: '100%',
		margin: '0 !important',
		padding: '0 !important',
		color: '#fff',
		textTransform: 'none !important'
	},
	login:{
		backgroundColor: '#F3403C !important',
		width: '100%',
		borderRadius: '0 !important',
		textTransform: 'none !important',
		boxShadow: 'none !important'
	}

})
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4,
	borderRadius: '8px',
  };

function ForgotUsernameModal ({ item, onClose }) {
   

    return <Modal
    open={item}
    onClose={onClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Box sx={style}>
      <Typography variant="h6" component="h2" fontWeight="600" marginBottom="30px" textAlign="center">
	  	Forgot Your Username?
      </Typography>
	  	<Typography id="modal-description" sx={{ mt: 2 }}>
		  	Please check your email for messages with subject 
			lines starting with "Your Reports are Ready!" from{' '}
			<Link
              href="mailto:surveys@workforcerg.com"
              sx={{ color: 'blue', textDecoration: 'none' }}
            >
			<Typography>surveys@workforcerg.com.</Typography>
            </Link>{' '}
          </Typography>
		  <Typography sx={{ mt: 2 }}>
			Look for the username needed to log in, which can be found in the red text, 
			point number 2 within the email body, below the Feedback Data Dashboard link. 
          </Typography>
          <Typography  sx={{ mt: 2 }}>
		  		If you are unable to find the username, contact {' '}
            <Link 
              href="mailto:support@workforcerg.com"
              sx={{ fontSize: '1rem !important', display: 'inline-block' }}
            >
              support@workforcerg.com
            </Link>{' '}
            for help.
          </Typography>
		  <Typography sx={{ mt: 2 }}>Thank you!</Typography>
		  <Typography sx={{ mt: 2 }}>Workforce Research Group</Typography>
          <Box sx={{ textAlign: 'right', mt: 4 }}>
            <Button onClick={onClose} variant="contained">
              Close
            </Button>
          </Box>
    </Box>
  </Modal>
}
 
const ClientAuthForm = () => {
	const classes = useStyles()
	const dispatch = useDispatch()
	const history = useHistory()
	const { http } = global.services
	const [ state, setState ] = useState({
		username: '',
		submitting: false,
		usernameError:false,
		passwordError:false,
		error: null
	})

	const [showModal, setShowModal] = useState(false);

	const handleSubmission = async (event = null, forAdminLogin = false) => {
		if (event)
			event.preventDefault()

		if(state.username){
			setState(prevState => ({ ...prevState, submitting: true, error: null, usernameError: false, passwordError: false }))

			const payload = {
				username: state.username.trim(),
			}

			try {
				const response = await http.post('/user/login', payload)
				const responseData = response.data.data
				dispatch(UserAction.setUser({...responseData, user: responseData.userData }))
				if(responseData.userData?.organizationProgram?.length > 0){
					// const program = responseData.userData?.organizationProgram?.slice(-1)[0];
					const organizationprogramId = responseData.userData?.organizationprogramId;
					var { organizationProgram } = responseData.userData ? responseData.userData : responseData.user;

					let dealId = responseData?.userData?.dealId || null;
					let defaultProgramId = null; 
					if(organizationprogramId || dealId){
						defaultProgramId = organizationProgram.find(item => item._id === organizationprogramId);
						if(!defaultProgramId?.programId?._id){
							defaultProgramId = organizationProgram.find(item => item?.DealId === dealId);
						}
					}
					console.log("************************");
					// console.log(defaultProgramId);
					// const program = responseData.userData?.organizationProgram?.find(orgProg => orgProg['_id'] === organizationprogramId);
					if(organizationProgram){
						dispatch(AppActions.selectProgram(defaultProgramId?.programId?._id || organizationProgram[0]?.programId?._id));
					}
					// if(program) dispatch( AppActions.selectProgram(program.programId?._id) );
					else dispatch( AppActions.selectProgram(responseData.userData?.organizationProgram[0]?.programId?._id));
				}

				history.push('/dashboard')
			} catch (error) {
				const response = error.response;
				console.log('Authentication Error: ', response);
				let defaultErrorMsg = 'An unknown error occured. Please refresh the page and try again.'
				const stateToReplace = state

				if (error?.response) {
					stateToReplace.submitting = false
					stateToReplace.error = { title: 'Unknown Error', message: defaultErrorMsg }
					stateToReplace.usernameError = true
					stateToReplace.passwordError = true
					if(error?.response?.status == 404) {
						stateToReplace.error = { title: 'Invalid Credentials', message: error?.response?.data?.message }
					}
				}

				setState(stateToReplace)
			}
		}
	}

	return (
		<>
		<form className="w-1/2" onSubmit={ (event) => handleSubmission(event) }>

{
	state.error && (
		<div className="mb-10">
			<Alert severity="error">
				<AlertTitle>{ state.error?.title }</AlertTitle>
				{ state.error?.message }
			</Alert>
		</div>
	)
}
<Typography
	component="h1"
	fontSize="30px"
	fontWeight="700"
	marginBottom="30px"
	color="#fff"
>
	Log In
</Typography>

<div className="input-group mb-5">
	<Typography
		component="h4"
		fontSize="14px"
		marginBottom="10px"
		color="#fff"
	>
		Username
	</Typography>
	<GrayTextField
		id="email-outlined-basic"
		className="w-full rounded-none"
		variant="outlined"
		placeholder="Enter your Username"
		onChange={ (event) => setState(prev => ({ ...prev, username: event.target.value })) }
		autoComplete="off"
		error={state.usernameError}
	/>
</div>

<div className="flex flex-row mb-5">
	<LoadingButton
		variant="contained"
		className={classes.login}
		onClick={() => handleSubmission(null) }
		size={'large'}
		loadingPosition="start"
		startIcon={<></>}
		loading={ state.submitting }
	>
		<span className={ state.submitting ? 'pl-5' : '' }>
			{ state.submitting ? 'Authenticating' : 'Log In' }
		</span>
	</LoadingButton>				
</div>

<div className="flex">
	<Button
		variant="text"
		color={'white'}
		size={'large'}
		className={classes.forgetPass}
		// onClick={() => history.push('/forgot-username')}
		onClick={() => setShowModal(true)}
	>
		Forgot Username?
	</Button>
</div>
</form>
<ForgotUsernameModal item={showModal} onClose={() => setShowModal(false)}/>
		</>
	)

}

export default ClientAuthForm
