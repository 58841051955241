export default function CloseSVG(){
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={24}
      height={24}
      fill="none"
    >
      <path
        d="M18 6 6 18M6 6l12 12"
        stroke="#1D2124"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </svg>
  )
}
