import { useState, useCallback, useEffect, useRef, useMemo } from "react";
import { min, max } from "d3-array";
import { scaleLinear as scale } from "d3-scale";
import { select as d3Select } from "d3-selection";
import { hierarchy, pack } from "d3-hierarchy";
import sampleData from "./sampleData";

const BubblePackGraph = ({
	height,
	width,
	data = sampleData,
	hideText = false,
	callback = () => {},
}) => {
	const chartRef = useRef(null);
	const drawChart = useCallback(() => {
		if (chartRef.current) {
			const total = data.reduce((t, i) => t + i.value, 1);
			const dataDom = data.map((i) => i.value);
			const scaling = scale()
				.domain([min(dataDom), max(dataDom)])
				.range([45, 120]);
			const textScaling = scale()
				.domain([min(dataDom), max(dataDom)])
				.range([10, 16]);
			const svgPack = pack()
				//Border
				.size([width, height])
				//Make smallest value sizable
				.radius((a) => scaling(a.data?.value * 2.5 || 1))
				.padding(7);

			const wrap = (text) => {
				//Width should be from the radius calculated from `scaling` variable
				text.each(function () {
					var text = d3Select(this),
						datum = text.datum(),
						width = datum?.r * 1.8,
						words = text.text().replaceAll("_", " ").split(/\s+/).reverse(),
						word,
						line = [],
						nextLine = 0.5,
						lineNumber = 0,
						lineHeight = 1.1, // ems
						y = text.attr("y"),
						// dy = parseFloat(text.attr("dy")) || 0,
						tspan = text
							.text(null)
							.append("tspan")
							.attr("x", 0)
							.attr("y", y)
							.attr("dy", "0em");

					while ((word = words.pop()) && nextLine <= 2) {
						line.push(word);
						tspan.text(line.join(" "));
						if (tspan.node().getComputedTextLength() > width) {
							line.pop();
							++nextLine;
							if (nextLine == 3) {
								line.push("...");
								tspan.text(line.join(" "));
								continue;
							}
							tspan.text(line.join(" "));
							line = [word];
							tspan = text
								.append("tspan")
								.attr("x", 0)
								.attr("y", y)
								.attr("dy", (++lineNumber * lineHeight).toFixed(1) + "em")
								.text(word);
						}
					}
				});
			};

			const magnifyValues = data.map((i, index) => ({ ...i, index }));
			const root = hierarchy({ children: magnifyValues }).sum((i) => i.value);

			const svgG = d3Select(chartRef.current)
				.append("svg")
				.attr("height", height)
				.attr("width", width);

			const node = svgG
				.selectAll(".node")
				.data(svgPack(root).leaves())
				.enter()
				.append("g")
				.attr("class", "node")
				.attr("transform", (d) => `translate(${d.x}, ${d.y})`);

			node
				.append("circle")
				.attr("id", (d) => d.data?.index)
				.attr("r", (d) => d.r)
				.style("fill", (d) => d.data?.color)
				.on("click", (_, d) => callback(d.data));

			node.append("clipPath").attr("id", (d) => "clip-" + d.data?.index);

			if (!hideText) {
				node
					.append("text")
					.append("tspan")
					.attr("x", 0)
					.attr("y", 0)
					.attr("text-anchor", "middle")
					.style("fill", (d) => d.data?.fontColor || "#000")
					.style("font-weight", 700)
					.style("font-size", (d) => textScaling(d.data.value * 3.5))
					.style("pointer-events", "none")
					.text((d) => d.data?.name)
					.call(wrap);
			}

			node.append("title").text((d) => d.data?.name);
		}
	}, [data, chartRef, hideText]);

	const destroyChart = useCallback(() => {
		if (chartRef.current) {
			d3Select(chartRef.current).select("svg").remove();
		}
	}, []);

	const rebuildChart = useCallback(() => {
		destroyChart();
		drawChart();
	}, [destroyChart, drawChart]);

	useEffect(() => {
		drawChart();
		window.addEventListener("resize", rebuildChart);
		return () => {
			destroyChart();
			window.removeEventListener("resize", rebuildChart);
		};
	}, [drawChart, destroyChart, rebuildChart]);

	return <div ref={chartRef}></div>;
};

export default BubblePackGraph;
