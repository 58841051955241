import React, { useState } from 'react'
import {
    Button,
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    FormControl,
    InputLabel,
    Select,
    MenuItem
} from '@mui/material';

import RefreshIcon from '../../../../../assets/svg/refresh'
import DownloadIcon from '../../../../../assets/svg/download'

const SelectDialogContent = () => {

    const [client, setClient] = useState('')
    const [project, setProject] = useState('')

    const handleSelect = (e) => {
        console.log(e);
    }

    return (
        <>
            <DialogContent>
                <div className='mb-4'>
                    <DialogContentText>
                        Select Client
                    </DialogContentText>
                    <FormControl fullWidth>
                        <Select
                            value={client}
                            onChange={handleSelect}
                            renderValue={(val) => {
                                console.log(val);
                                return <em>Placeholder</em>;
                            }}
                        >
                            <MenuItem value={'Default'}>Default</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className='mb-4'>
                    <DialogContentText>
                        Select Project
                    </DialogContentText>
                    <FormControl fullWidth>
                        <Select
                            value={project}
                            onChange={handleSelect}
                        >
                            <MenuItem value={'Default'}>Default</MenuItem>
                        </Select>
                    </FormControl>
                </div>
            </DialogContent>
            <DialogActions className="flex justify-center mb-6 px-8">
                <Button fullWidth variant="outlined" color="inherit" startIcon={<RefreshIcon />}>
                    Resync Data
                </Button>
                <Button fullWidth variant="contained" sx={{background: '#F3403C'}} color="error" startIcon={<DownloadIcon />}>
                    Pull Data
                </Button>
            </DialogActions>
        </>
    )
}



export default React.memo(SelectDialogContent)