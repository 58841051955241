import { useCallback, useEffect, useRef } from "react";
import { select as d3Select } from "d3-selection";
import { arc as d3Arc } from "d3-shape";

const RadialBarChart = (props) => {
  let { data, metaConfig, thickness = 15, rotate = 0, notPaidFDD } = props;
  const chartRef = useRef(null);
  data[0].value = notPaidFDD ? 1 : data[0].value;
  const makeTooltip = (
    d3Tooltip,
    { dataValue, theta, radius, width, height }
  ) => {
    d3Tooltip
      .attr("id", "tooltip")
      .style("transform", `translate(${width / 2}px, ${height / 2}px)`)
      .style("background", "white")
      .style("box-shadow", "0px 4px 25px rgba(22, 38, 48, 0.16)")
      .style("display", "flex")
      .style("font-size", "14px")
      .style("align-items", "center")
      .style("position", "absolute")
      .style("justify-content", "center")
      .style("z-index", "10")
      .style("height", "24px")
      .style("width", "40px");

    d3Tooltip
      .append("div")
      .attr("class", "tooltip-value")
      .html(`${dataValue}%`);

    const x = radius * Math.cos(theta);
    const y = radius * Math.sin(theta);

    if (dataValue < 30) {
      d3Tooltip
        .style("border-radius", `0px 5px 5px 5px`)
        .style("left", `${x + thickness / 2}px`)
        .style("top", `${y - thickness / 2}px`);
    } else if (dataValue >= 30 && dataValue < 54) {
      d3Tooltip
        .style("border-radius", `5px 5px 5px 0px`)
        .style("left", `${x - thickness / 2}px`)
        .style("top", `${y - thickness * 2}px`);
    } else if (dataValue >= 54 && dataValue < 70) {
      d3Tooltip
        .style("border-radius", `5px 5px 0px 5px`)
        .style("left", `${x - thickness * 3}px`)
        .style("top", `${y - thickness * 1.5}px`);
    } else if (dataValue >= 70) {
      d3Tooltip
        .style("border-radius", `5px 0px 5px 5px`)
        .style("left", `${x - thickness * 2.5}px`)
        .style("top", `${y + thickness / 2}px`);
    }
  };

  const drawChart = useCallback(() => {
    if (chartRef.current) {
      const { width, height } = chartRef.current.getBoundingClientRect();
      const { colors, valueKey } = metaConfig;
      const radius = Math.min(height, width) / 2;
      const svg = d3Select(chartRef.current)
        .append("svg")
        .attr("height", height)
        .attr("width", width);
      const svgG = svg
        .append("g")
        .attr("transform", `translate(${width / 2}, ${height / 2})`)
        .selectAll("slices")
        .data(data);

      const fixedRotation = Math.PI + (rotate * Math.PI) / 180;
      const theta = fixedRotation - Math.PI / 2 - data[0].value * 2 * Math.PI;

      svgG
        .join("path")
        .attr("d", (d, i) => {
          const arc = d3Arc()
            .innerRadius(radius - (i + 1) * thickness)
            .outerRadius(radius - i * thickness);
          return arc({
            startAngle: fixedRotation,
            endAngle: fixedRotation - d[valueKey] * 2 * Math.PI,
          });
        })
        .attr("fill", (_, i) => colors[i]);

      const firstDataValue = Math.round(data[0]?.value * 100);
        if(!notPaidFDD){
          makeTooltip(d3Select(chartRef.current).append("div"), {
          dataValue: firstDataValue,
          theta,
          radius,
          width,
          height,
        });
        }
      
    }
  }, [data, metaConfig]);

  const destroyChart = useCallback(() => {
    if (chartRef.current) {
      d3Select(chartRef.current).select("svg").remove();
      d3Select(chartRef.current).select("div").remove();
    }
  }, []);

  const rebuildChart = useCallback(() => {
    destroyChart();
    drawChart();
  }, [destroyChart, drawChart]);

  useEffect(() => {
    drawChart();
    window.addEventListener("resize", rebuildChart);
    return () => {
      destroyChart();
      window.removeEventListener("resize", rebuildChart);
    };
  }, [drawChart, destroyChart, rebuildChart]);

  useEffect(rebuildChart, [data, metaConfig, rebuildChart]);

  return (
    <div
      ref={chartRef}
      style={{ width: "100%", height: "100%", position: "relative" }}
    ></div>
  );
};

export default RadialBarChart;
