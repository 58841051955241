import {Button, Drawer, Divider, List, Box, Typography, IconButton, Table, TableBody, TableRow, TableCell} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { removeFromCart, removeFilter, clearCart, toggleCart, addResync } from 'redux/modules/cart/actions';
import { setResyncInfo } from 'redux/modules/reports/actions';
import { useHistory } from "react-router";
import ReportStoreImg2 from 'assets/images/icons/Icon2.png'
import AppAction from 'redux/modules/app/actions';
import { currentCurrency } from 'helpers/checkCurrency';

export default function CartDrawer () {
	const dispatch = useDispatch();
	const { items, resync, filters, openCart } = useSelector(({cart}) => cart)
	const { user, userData } = useSelector(({ user }) => user?.user || {});
	const { selectedProgramId, timeEstimate, clearcache } = useSelector(({ app }) => app);
	const organizationProgram = userData?.organizationProgram || [];
	const selectOrgProgram = organizationProgram?.find(i => i.programId?._id == selectedProgramId) || organizationProgram[0];
	const resyncPrice = selectOrgProgram?.programId?.Data_Resort_Fee || 0;

	const history = useHistory();

	const canBuy = useMemo(() => {
		if(!selectOrgProgram) return false;
	  const hasPaidFDD = selectOrgProgram?.FDD_Payment_Details !== null;
	  const hasReport = ['WFR_Access', 'EV_Access', 'WBC_Access', 'BBP_Access'].find(i => (selectOrgProgram[i] || '').trim().toLowerCase() === 'yes');
	  const onCart = items.find(i => i.forPayment === 'FDD');
	  return hasPaidFDD || hasReport || onCart;
	}, [items, selectedProgramId])

	const onClick = () => {
		if(!canBuy) {
			dispatch( AppAction.setAlert(`You need to include Feedback Data Dashboard Package to checkout!`) );
			dispatch(toggleCart(false))
			return;
		}

		history.push('/checkout')
		dispatch(toggleCart(false))
	}
	const onClose = () => {
		dispatch(toggleCart(false))
	}

	const handleDelete = (item) => dispatch(removeFromCart(item))

	const handleFilterDelete = (item) => dispatch(removeFilter(item))
	
	const resetAll = () => {
		dispatch(clearCart())
		dispatch(setResyncInfo(false, false))
	}
	const revertSync = () => {
		dispatch(addResync( false ))
		dispatch(setResyncInfo(false, false))
	}

	const cartItems = [...items, ...filters];
	if(resync) {
		cartItems.push({ title: 'Resort Data', amount: resyncPrice || 0 })
	}
	const getTotal = () => cartItems.reduce((a,b) => a + parseFloat(b.amount || 0) , 0)

	return <Drawer
		anchor={'right'}
		open={openCart}
		onClose={onClose}
	>
		<Box
			className="flex flex-col h-full m-5"
			sx={{ width: 300 }}
		    role="presentation"
		    // onClick={onClick}
		>
			<Typography id="user-form-title"
				variant="h6"
				component="span"
				fontWeight="600"
				display="block"
				className="mt-6"
			>
				<IconButton
			    aria-label="close"
			    onClick={onClose}
			    sx={{ position: 'absolute', right: 8, top: 8 }}
			  >
			    <Close />
			  </IconButton>
			Cart
			</Typography>
			<Box className="flex h-full">
				<div className="w-full">
				<Table className="cart-table" sx={{marginBottom: "5px"}}>
					<TableBody>
						{
							items.map((i,k) => <TableRow
			          key={k}
			          sx={{ border: 0 }}
			        >
			        	<TableCell style={{verticalAlign: 'top'}} component="th" scope="row">
			          	<img src={i.img}/>
			          </TableCell>
			          <TableCell style={{verticalAlign: 'top'}}>
			          	<div>
			          		<p className="font-bold">{currentCurrency()} {i.amount || 0}</p>
			          		{/* <p className="font-bold">$ {i.amount || 0}</p> */}
			          		<p>{i.name || i.title}</p>
			          		{i.subTitle && <p className="mt-1 text-gray-400">{i.subTitle}</p>}
			          	</div>
			          </TableCell>
			          <TableCell style={{verticalAlign: 'top'}} align="right" onClick={()=>handleDelete(i)}>
			          	Delete
			          </TableCell>
			        </TableRow>)
						}
						{
							filters.map((i,k) => <TableRow
			          key={k}
			          sx={{ border: 0 }}
			        >
			        	<TableCell style={{verticalAlign: 'top'}} component="th" scope="row">
			          	<img src={ReportStoreImg2}/>
			          </TableCell>
			          <TableCell style={{verticalAlign: 'top'}}>
			          	<div>
			          		<p className="font-bold">{currentCurrency()} {i.amount || 0}</p>
			          		{/* <p className="font-bold">$ {i.amount || 0}</p> */}
			          		<p>Filter: {i.filterLabel || i.name || i.title}</p>
			          	</div>
			          </TableCell>
			          <TableCell style={{verticalAlign: 'top'}} align="right" onClick={()=>handleFilterDelete(i)}>
			          	Delete
			          </TableCell>
			        </TableRow>)
						}
						{
							resync && <TableRow
			          sx={{ border: 0 }}
			        >
			        	<TableCell style={{verticalAlign: 'top'}} component="th" scope="row">
			          	{/*<img src={i.img}/>*/}
			          </TableCell>
			          <TableCell style={{verticalAlign: 'top'}}>
			          	<div>
			          		<p className="font-bold">{currentCurrency()} {resyncPrice || 0}</p>
			          		{/* <p className="font-bold">$ {resyncPrice || 0}</p> */}
			          		<p>Resync Reports</p>
			          	</div>
			          </TableCell>
			          <TableCell style={{verticalAlign: 'top'}} align="right" onClick={revertSync}>
			          	Delete
			          </TableCell>
			        </TableRow>
						}
					</TableBody>
				</Table>
				</div>
			</Box>
			<Box className="flex flex-col">
				<Divider className="my-5"/>
				<div className="flex justify-between mb-5">
					<span>Total: </span>
					<span>{currentCurrency()} {getTotal()}</span>
					{/* <span>$ {getTotal()}</span> */}
				</div>
				<Button
					variant="contained"
					onClick={onClick}
					color="error"
					className="normal-case rounded-none"
					disabled={getTotal() <= 0}
				>
				{getTotal() > 0 ? 
					'Checkout' : 'Total Amount is 0'
				}
				</Button>
			</Box>
		</Box>
	</Drawer>
}
